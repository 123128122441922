// Admin
export const ADD_CAT_CODE = 'admin/ADD_CAT_CODE'; 

export const REQUEST_ADMIN_TYPE_LIST = 'admin/REQUEST_ADMIN_TYPE_LIST';
export const RECEIVE_ADMIN_TYPE_LIST = 'admin/RECEIVE_ADMIN_TYPE_LIST';

export const REQUEST_CAT_CODES_LIST = 'admin/REQUEST_CAT_CODES_LIST'; 
export const RECEIVE_CAT_CODES_LIST = 'admin/RECEIVE_CAT_CODES_LIST'; 

export const REQUEST_CAUSES_LIST = 'admin/REQUEST_CAUSES_LIST'; 
export const RECEIVE_CAUSES_LIST = 'admin/RECEIVE_CAUSES_LIST'; 

export const REQUEST_COVERAGE_TYPE_LIST = 'admin/REQUEST_COVERAGE_TYPE_LIST'; 
export const RECEIVE_COVERAGE_TYPE_LIST = 'admin/RECEIVE_COVERAGE_TYPE_LIST'; 

export const REQUEST_ROLE_LIST = 'admin/REQUEST_ROLE_LIST'; 
export const RECEIVE_ROLE_LIST = 'admin/RECEIVE_ROLE_LIST'; 

// Document Type
export const REQUEST_DOCUMENT_TYPE_LIST = 'documentType/REQUEST_DOCUMNET_TYPE_LIST'; 
export const RECEIVE_DOCUMENT_TYPE_LIST = 'documentType/RECEIVE_DOCUMENT_TYPE_LIST'; 

export const REQUEST_DOCUMENT_TYPE_LNS = 'documnetType/REQUEST_DOCUMENT_TYPE_LNS'; 
export const RECEIVE_DOCUMENT_TYPE_LNS = 'documnetType/RECEIVE_DOCUMENT_TYPE_LNS'; 

// Dropdowns
export const REQUEST_CAT_CODES_DD = 'dropdowns/REQUEST_CAT_CODES_DD'; 
export const RECEIVE_CAT_CODES_DD = 'dropdowns/RECEIVE_CAT_CODES_DD'; 
