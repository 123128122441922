import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const addCatCode = async(catCode: models.CatCode) => {
  const response = await request ({
    method: 'POST',
    url: `/CatCodes`,
    data: catCode
  }); 
  return response; 
}

export const getAdminTypes = async (adminTypesRequest: models.AdminTypesRequest) => {
  var qStr =  utils.helpers.encodeQueryString(adminTypesRequest);

  const response = await request ({
    method: 'GET', 
    url: `/${adminTypesRequest.adminType}?${qStr}`,
  });
  return response; 
}; 

export const getCatCodes = async (catCodeRequest: models.CatCodesRequest) => {
  var qStr = utils.helpers.encodeQueryString(catCodeRequest); 

  const response = await request ({
    method: 'GET', 
    url: `/CatCodes?${qStr}`,
  }); 
  return response; 
}

export const getCatCodesDD = async () => {
  const response = await request ({
    method: 'GET', 
    url: `/CatCodes/dd`,
  }); 
  return response; 
}

export const getCauses = async () => {
  const response = await request ({
    method: 'GET', 
    url: `/Causes`,
  }); 
  return response; 
}

export const getContactByID = async (contactId: number) => {  
  const response = await request ({
    method: 'GET', 
    url: `/Contacts/${contactId}`,
  }); 
  return response; 
}; 

export const getCoverageTypes = async (coverageTypeRequest: models.CoverageTypesRequest) => {
  var qStr = utils.helpers.encodeQueryString(coverageTypeRequest); 

  const response = await request ({
    method: 'GET', 
    url: `/CoverageTypes?${qStr}`, 
  }); 
  return response; 
}; 

export const getDocumentTypes = async (documentTypesReqeust: models.DocumentTypesRequest) => {  
  var qStr = utils.helpers.encodeQueryString(documentTypesReqeust); 

  const response = await request ({
    method: 'GET', 
    url: `/DocumentTypes?${qStr}`, 
  }); 
  return response; 
}; 

export const getRoles = async (paging: models.Paging) => {  
  const response = await request ({
    method: 'GET', 
    url: `/Roles?&page=${paging.pageNo}&pageSize=${paging.pageSize}`, 
  }); 
  return response; 
}; 