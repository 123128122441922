import * as React from 'react';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import OpenClaimsCard from './OpenClaimsCard';
import OpenYTDCard from './OpenYTDCard';
import ClosedYTDCard from './ClosedYTDCard';
import MonthlyClaims2 from './MonthlyClaims2';
import ClaimList from 'components/claims-list/claimList';
import OpenClaimsByCompanyChart from './OpenClaimsByCompany';
import { claimsActions } from '../../store/claims';
import * as models from '../../models/'
import '../../assets/styles/myDashboard.css';
import { withRouter } from 'react-router-dom';

interface Props extends PropsFromRedux {
}

interface State {
}

class Dashboard extends React.PureComponent<Props, State> {
    public state = {
    }

    public componentDidMount() {
        const claimRequest: models.ClaimsRequest = {
            ...models.defaultClaimsRequest,
            status: "Open,Reopened",
        }
        this.props.requestClaims(claimRequest);
    }

    public navigateQueue(name: string) {
        window.location.href = `/queues?tab=${name}`;
    }

    public render() {

        return (
            <>
                <Row className="dash-cards-row">
                    <Col>
                        <div className="card">
                            <OpenClaimsCard />
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <OpenYTDCard />
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <ClosedYTDCard />
                        </div>
                    </Col>
                    <Col>
                    <Card className="card-for-queue" onClick={() => this.navigateQueue("review")} style={{ cursor: "pointer" }}>
                            <Card.Title>Review</Card.Title>
                            <Card.Subtitle>Queue</Card.Subtitle>
                            <Card.Body>
                                <div className='dash-card-value'>23</div>
                                <br></br>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="card-for-queue" onClick={() => this.navigateQueue("assign")} style={{ cursor: "pointer" }}>
                            <Card.Title>Assign</Card.Title>
                            <Card.Subtitle>Queue</Card.Subtitle>
                            <Card.Body>
                                <div className='dash-card-value'>12</div>
                                <br></br>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    <Card className="card-for-queue" onClick={() => this.navigateQueue("srv")} style={{ cursor: "pointer" }}>
                            <Card.Title>SR Verification</Card.Title>
                            <Card.Subtitle>Queue</Card.Subtitle>
                            <Card.Body>
                                <div className='dash-card-value'>0</div>
                                <br></br>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="dash-cards-row">
                    <Col>
                        <Card>
                            <MonthlyClaims2 />
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <OpenClaimsByCompanyChart />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ClaimList
                            title="Most Recent Claims"
                            isSortable={false}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimsListResponse: state.claims.search.claimsListResponse,
    claimsRequest: state.claims.search.claimsRequest,
    isLoading: state.claims.search.isClaimsListLoading,
    isClaimsListDownloading: state.claims.search.isClaimsListDownloading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestClaims: claimsActions.requestClaims,
            requestClaimCSV: claimsActions.requestClaimCSV,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Dashboard); 
