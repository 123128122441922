import * as React from 'react';
import ReportHeader from './ReportHeader';

interface Props {
}

interface State {
    activeTab: string;
}

class Reports extends React.PureComponent<Props, State> {
    public handleTabs = (name: string) => {
        this.setState({ activeTab: name });
    };

    public render() {
        return (
            <React.Fragment>
                <ReportHeader
                    getTabName={this.handleTabs}
                />
            </React.Fragment>
        );
    }
}

export default Reports;
