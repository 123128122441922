import * as models from '../';

export interface DropDownListItem  {
    id: number; 
    name: string; 
}

export interface DropdownListResponse extends models.ResponseBase<DropDownListItem> {
}

export const defaultDropDownListItem: DropDownListItem = {
    id: 0, 
    name: ''
}