import * as models from '../'; 

export interface Paging {
    pageNo: number, 
    pageSize: number
}

export const defaultPaging: models.Paging = {
    pageNo: 1,
    pageSize: 20,
}
