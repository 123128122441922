import * as models from '../';

export interface InsuredPerson {
    id: number; 
    insuredName: string;
    mailingAddress: models.Address;
    homeNumber: models.PhoneNumber;
    cellNumber: models.PhoneNumber; 
    workNumber: models.PhoneNumber; 
}

export const defaultInsuredPerson: InsuredPerson = {
    id: 0, 
    insuredName: '',
    mailingAddress: models.defaultAddress,
    homeNumber: models.defaultPhoneNumber,
    cellNumber: models.defaultPhoneNumber,
    workNumber: models.defaultPhoneNumber,
}