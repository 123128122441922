import * as models from '..';

export interface Policy {
    policyNumber: string;
    policyType: string;
    policyDeductible: number;
    policyDeductible2: number; 
    namedStormDeductible: number; 
    effectiveDate: Date | null;
    expirationDate: Date | null; 
    policyMortgageCompanyName: string; 
    policyAgentCompanyName: string;
    policyCoverages: models.PolicyCoverage[]; 
}

export const defaultPolicy: Policy = {
    policyNumber: "",
    policyType: "",
    policyAgentCompanyName: "",
    policyDeductible: 0,
    policyDeductible2: 0,
    policyMortgageCompanyName: "",
    effectiveDate: null,
    expirationDate: null,
    namedStormDeductible: 0,
    policyCoverages: [models.defaultPolicyCoverage]
}