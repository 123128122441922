import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer , { history } from './root-reducer';
import rootSaga from './root-saga';
import { composeEnhancers } from './utils';
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export * from './root-reducer';

// configure middlewares
const middlewares = [routerMiddleware(history), sagaMiddleware];

// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};

// create store
const store = createStore(rootReducer, initialState, enhancer);

// then run the saga
sagaMiddleware.run(rootSaga);

// export store singleton instance
export default store;
