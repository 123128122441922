import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';
import { financialsActions } from 'store/financials';
import AddInvoiceGroupModal from './AddInvoiceGroupModal'; 

interface Props extends PropsFromRedux {    
    showModal: boolean;
    closeModal: () => void;
}

interface State {
    addNewText: string, 
    bulkInvoiceName: string, 
    showCreateGroupModal: boolean, 
}

class AddInvoiceToBulk extends React.Component<Props, State> {
    public state = {
        addNewText: "<Add New>", 
        bulkInvoiceName: '', 
        showCreateGroupModal: false, 
    };

    componentDidMount() {
        const invoiceGroupRequest: models.InvoiceGroupsRequest = {
            ...models.defaultInvoiceGroupsRequest,
            companyName: this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === this.props.selectedInvoices[0])?.insCompany || ''
        }
        this.props.requestBulkInvoiceByCompany(invoiceGroupRequest);
    }

    public resetFormStateAndClose = () => {
        this.setState({
            bulkInvoiceName: '',
        });         
        this.props.clearSelectedInvoices(); 
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        this.props.selectedInvoices.forEach(e => this.props.addInvoiceToGroup(e, this.state.bulkInvoiceName)); 
        this.resetFormStateAndClose(); 
    }

    public handleChangeWithAddNew = (e:any) => {
        const field: string = e.target.name;
        switch (field.toLowerCase()) {
            case "bulkname" :                
                if (e.target.value === this.state.addNewText) {
                    this.toggleAddGroupModal(); 
                }
                else                     
                    this.setState({bulkInvoiceName: e.target.value}); 
            break;
        }        
    }

    public addInvoiceGroupCallback = (invoiceGroup: models.InvoiceGroup) => {
        this.setState({bulkInvoiceName: invoiceGroup.invoiceGroupName}); 
        this.props.addInvoiceGroup(invoiceGroup); 
    }

    public toggleAddGroupModal = () => {
        this.setState({showCreateGroupModal: !this.state.showCreateGroupModal}); 
    }

    public render() {
        const { bulkInvoiceList, closeModal, isBulkInvoiceListLoading, showModal } = this.props;
        const { addNewText,  showCreateGroupModal } = this.state; 

        return (
            <>
            <AddInvoiceGroupModal
                companyName={this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === this.props.selectedInvoices[0])?.insCompany || ''}
                showModal={showCreateGroupModal}
                closeModal={this.toggleAddGroupModal}
                callback={this.addInvoiceGroupCallback}
            />
            <Modal centered={true} show={showModal} onHide={closeModal} >                                
                <Modal.Header closeButton>
                    <Modal.Title>Add Invoice to Bulk</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>                    
                    <Modal.Body>
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container">
                            <Form.Label column sm="4">Bulk Invoice</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="bulkName" 
                                    value={this.state.bulkInvoiceName || 'Choose...'} 
                                    onChange={(e) => this.handleChangeWithAddNew(e)} 
                                >
                                        {isBulkInvoiceListLoading && <option>Loading...</option>}                                        
                                        {!isBulkInvoiceListLoading && <option key={-1}>Choose...</option>}
                                        <option key={-99}>{ addNewText }</option>
                                        {bulkInvoiceList.totalCount > 0 && bulkInvoiceList.resourceList.map((c: models.InvoiceGroup, i: number) => ( 
                                            <option key={i} value={c.invoiceGroupName} >{c.invoiceGroupName}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>  
            </>          
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    bulkInvoiceList: state.financials.financials.invoiceGroupListResponse,
    isBulkInvoiceListLoading: state.financials.financials.isInvoiceGroupListLoading,
    invoicesListResponse: state.financials.financials.invoicesListResponse,
    selectedInvoices: state.financials.financials.selectedInvoices,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {         
            addInvoiceGroup: financialsActions.addInvoiceGroup,
            clearSelectedInvoices: financialsActions.clearSelectedInvoices,
            addInvoiceToGroup: financialsActions.addInvoiceToGroup, 
            requestBulkInvoiceByCompany: financialsActions.requestInvoiceGroupsList,            
            requestInvoice: financialsActions.requestInvoice, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddInvoiceToBulk);