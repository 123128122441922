import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface LocationStateShape {
    cityList: models.CityList; 
    countyList: models.CountyList; 
    zipSearch: models.ZipSearch; 
}

export const locationDefaultState: LocationStateShape = {
    cityList: models.defaultZipSearch.cityList, 
    countyList: models.defaultZipSearch.countyList, 
    zipSearch: models.defaultZipSearch, 
};

export interface DropdownsShape {
    locationDDs: models.LocationsDDResponse, 
    isLocationDDsLoading: boolean, 
    stateAbbrDD: models.DropdownListResponse,
    stateDD: models.DropdownListResponse,
    isStateAbbrDDLoading: boolean, 
    isStateDDLoading: boolean,     
}

export const dropdownsDefaultState: DropdownsShape = {
    locationDDs: {
        citiesDDResponse: models.defaultResponse,
        statesDDResponse: models.defaultResponse,
        countiesDDResponse: models.defaultResponse,
        zipCodesDDResponse: models.defaultResponse,
    }, 
    isLocationDDsLoading: false, 
    stateAbbrDD: models.defaultResponse, 
    stateDD: models.defaultResponse, 
    isStateAbbrDDLoading: false, 
    isStateDDLoading: false, 
}

export interface State {
    locations: LocationStateShape;
    dropdowns: DropdownsShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    locations: (state = locationDefaultState, action) => {
        switch (action.type) {                      
            case constants.REQUEST_ZIPSEARCH: 
                return {
                    ...state,
                }; 

            case constants.RECEIVE_ZIPSEARCH: 
                return {
                    ...state, 
                    cityList: action.payload.response.cityList,
                    countyList: action.payload.response.countyList,
                    zipSearch: action.payload.response
                }; 

            default: 
                return state; 
        }
    },
    dropdowns: (state = dropdownsDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_LOCATION_DDs: 
                return {
                    ...state, 
                    isLocationDDsLoading: true, 
                }
            case constants.RECEIVE_LOCATION_DDs: 
                return {
                    ...state, 
                    locationDDs: action.payload.response,
                    isLocationDDsLoading: false,
                }

            case constants.REQUEST_STATE_ABBR_DD: 
                return {
                    ...state,
                    isStateAbbrDDLoading: true,
                }
            case constants.RECEIVE_STATE_ABBR_DD:             
                return {
                    ...state,
                    stateAbbrDD: action.payload.response,
                    isStateAbbrDDLoading: false, 
                }

            case constants.REQUEST_STATE_DD: 
                return {
                    ...state,
                    isStateDDLoading: true, 
                }
            case constants.RECEIVE_STATE_DD: 
                return {
                    ...state,
                    stateDD: action.payload.response,
                    isStateDDLoading: false, 
                }

            default: 
                return state; 
        }
    }
});
