export const REQUEST_ZIPSEARCH = 'locations/REQUEST_ZIPSEARCH'; 
export const RECEIVE_ZIPSEARCH = 'locations/RECEIVE_ZIPSEARCH'; 

// dropdowns
export const REQUEST_STATE_ABBR_DD = 'dropdowns/REQUEST_STATE_ABBR_DD'; 
export const RECEIVE_STATE_ABBR_DD = 'dropdowns/RECEIVE_STATE_ABBR_DD'; 

export const REQUEST_STATE_DD = 'dropdowns/REQUEST_STATE_DD'; 
export const RECEIVE_STATE_DD = 'dropdowns/RECEIVE_STATE_DD'; 

export const REQUEST_LOCATION_DDs = 'dropdowns/REQUEST_LOCATION_DDs'; 
export const RECEIVE_LOCATION_DDs = 'dropdowns/RECEIVE_LOCATION_DDs'; 