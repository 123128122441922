import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Table } from 'react-bootstrap'; 
import * as models from '../../models';
import NumberFormat from 'react-number-format';
import addIcon from '../../assets/images/Blue/add.svg';
import SingleActionButton from '../common/SingleActionButton';
import GearButton from 'components/common/GearButton';
import ReservesModal from './ReservesModal'; 
import { ButtonAction } from '../../models/app/buttonAction'; 
import { claimsActions } from 'store/claims';
import { userHasPermission } from '../../utils/helpers';

interface Props extends PropsFromRedux {
}

interface State {
    activeItem: string | undefined;
    showModal: boolean;
    editReserveCoverage: models.ReserveCoverage | undefined;  
}

class ResComments extends React.Component<Props, State> {
    public state = {
        activeItem: 'all',
        showModal: false, 
        editReserveCoverage: undefined, 
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    }

    public editReserveCoverage = (rowId: string) => {
        const item: models.ReserveCoverage | undefined = this.props.reserveCoverages.resourceList.find(x => x.reserveCoverageId === parseInt(rowId)); 
        if (item)
            this.setState({editReserveCoverage: item}); 
        this.toggleModal(); 
    }    

    public deleteReserveCoverage = (rowId: string) => {
        this.props.deleteReserveCoverage(this.props.claimId, parseInt(rowId)); 
    }

    public render() {
        const { claim, reserveCoverages } = this.props;
        const { editReserveCoverage, showModal } = this.state; 
        const canEditClaim = userHasPermission("Can Edit Claims Info") && claim.status.toLowerCase() !== 'closed'; 

        const reserveActions: ButtonAction[] = [
            {
                name: 'Edit', 
                callBack: this.editReserveCoverage
            }, 
            {
                name: 'Delete', 
                callBack: this.deleteReserveCoverage
            }, 
        ]

        return (
            <>
            <ReservesModal
                reserveCoverage={editReserveCoverage}
                showModal={showModal}
                closeModal={this.toggleModal}
                />
            <Card className="claim-card">
                <Card.Title>Reserves
                    {canEditClaim && 
                        <SingleActionButton
                            action="Add"
                            title="Reserve Coverage"
                            img={addIcon}
                            onClick={this.toggleModal}
                        />
                    }
                </Card.Title>
                {reserveCoverages.resourceList[0] &&
                    <Table striped>
                        <thead>          
                            <tr>
                                <th>Coverage Type</th>
                                <th>Amount</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reserveCoverages.resourceList.map((reserveCoverage, i) => (
                                <tr key={reserveCoverage?.reserveCoverageId}>
                                    <td>{reserveCoverage?.coverageType}</td>
                                    <td><NumberFormat 
                                            value={reserveCoverage?.amount} 
                                            decimalScale={2} 
                                            fixedDecimalScale 
                                            displayType={'text'} 
                                            thousandSeparator={true} 
                                            prefix={'$'} 
                                        />
                                    </td>
                                    <td>{reserveCoverage?.reason}</td>
                                    <td style={{overflow:'visible'}}>
                                        <GearButton
                                            actions={reserveActions}
                                            rowId={reserveCoverage?.reserveCoverageId.toString()}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
    claimId: state.claims.claim.claim.claimID,
    reserveCoverages: state.claims.claim.reserveCoverages, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deleteReserveCoverage: claimsActions.deleteReserveCoverage
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ResComments);