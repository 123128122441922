import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { Card, Form, Row, Col, Button, Accordion, Image, Spinner } from 'react-bootstrap'; 
import * as models from '../../models'; 
import _ from 'lodash'; 
import { adminActions } from 'store/admin/';
import keyIcon from '../../assets/images/key.svg'; 
import { companyActions } from 'store/companies';
import { contactsActions } from 'store/contacts';
import { locationActions } from 'store/locations';
import { createPatch } from 'rfc6902';

interface Props extends PropsFromRedux {    
}

interface State {
    contact: models.Contact, 
    isUpdated: boolean, 
    timer: ReturnType<typeof setTimeout>, 
}

interface IReactRouterParams {
    contactId?: string; 
}

class ContactDetail extends React.Component<Props & RouteComponentProps<IReactRouterParams>, State> {  
    public state = {
        contact: models.defaultContact, 
        isUpdated: false,     
        timer: setTimeout(function(){}, 1000),
    }

    public componentDidMount() {
            const paging: models.Paging = {
            pageNo: 1,
            pageSize: 1000,
        }
        const companiesRequest: models.CompaniesRequest = {
            ...paging,
            type: "",
            activeOnly: true,
        }
        this.props.requestRoleList(paging); 
        this.props.requestCompanyDropdown(companiesRequest); 
        this.props.requestContact(parseInt(this.props.match.params.contactId!)); 
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!_.isEqual(prevProps.contact, this.props.contact)) {            
            this.setState({contact: {...this.props.contact}}); 
        }

        if (  !_.isEqual(prevProps.zipSearch,this.props.zipSearch)) { 
            let contact: models.Contact = {
                ...this.state.contact,
                mailingAddress: {...this.props.contact.mailingAddress}
            }
            contact = {
                ...this.state.contact, 
                mailingAddress: {
                    ...this.state.contact.mailingAddress
                    , city: this.props.zipSearch.cityName
                    , state: this.props.zipSearch.stateName
                    , postalCode: this.props.zipSearch.zipCode
                }
            }
            this.setState({contact: contact}); 
        }

        if(!_.isEqual(prevState.contact.mailingAddress.postalCode, this.state.contact.mailingAddress.postalCode)) {
            this.handleTimelapse(); 
        }
    }

    handleTimelapse = () => {        
        clearTimeout(this.state.timer); 
        const zipCode = this.state.contact.mailingAddress.postalCode; 

        if(zipCode !== '')
            this.setState({timer: setTimeout(() => {this.props.requestZipSearch(this.state.contact.mailingAddress.postalCode)}, 1000)});
    }

    public submitForm = (e: any) => {
        e.preventDefault();          
        const patchDocument = createPatch(this.props.contact, this.state.contact); 
        const contactId = this.props.contact.contactId;                 
        this.props.updateContact(contactId, patchDocument);
        this.setState({isUpdated: false}); 
    }    

    public handleContactTypeChange = (e: any) => {
        let contactTypes = new Array<string>(); 

        if (!this.state.contact.contactTypes.includes(e.target.name)) {
            contactTypes = this.state.contact.contactTypes.concat(e.target.name); 
        }
        else {
            contactTypes = this.state.contact.contactTypes.filter(x => x !== e.target.name); 
        }
        this.setState({contact: {...this.state.contact, contactTypes: contactTypes}, isUpdated: true});
    }

    public handlePhoneNumberChange = (e: any) => {        
        const inputItem = e.target.name; 
        const inputvalue = e.target.value; 
        let phoneNumbers: models.PhoneNumber[]; 

        if (inputvalue != "") {
            phoneNumbers = this.state.contact.phoneNumbers.filter(x => x.phoneNumberType !== inputItem).concat({phoneNumberType: inputItem, phoneNumber: inputvalue}); 
        }       
        else {
            phoneNumbers = this.state.contact.phoneNumbers.filter(x => x.phoneNumberType !== inputItem);
        }         
        this.setState({
            contact: {
                ...this.state.contact, 
                phoneNumbers: phoneNumbers
            }, isUpdated: true
        }); 
    }

    public render() {
        const { companyResponse, isContactLoading, isCompanyListLoading, isRoleListLoading, isStateAbbrDDLoading, roleResponse, stateResponse } = this.props; 
        const { contact, isUpdated } = this.state; 

        return (
            <>
            {contact &&                
            <Form onSubmit={this.submitForm}>                                 
                <Row >
                    <Col></Col>
                    <Button className="submit-contact-btn" type="submit" disabled={!isUpdated}>
                        SUBMIT
                    </Button>
                </Row>                
                <Card className="claim-card">
                    <Card.Title>Contact Info
                        {isContactLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    </Card.Title>                    
                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} id="formIsActive">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Active" 
                                    checked={contact.isActive || false} 
                                    onChange={(e) => this.setState({contact: {...contact, isActive: !contact.isActive}, isUpdated: true})}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter First Name..." 
                                    value={contact.firstName || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, firstName: e.target.value}, isUpdated: true})}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formMiddleName">
                                <Form.Label>Middle Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Middle Name..." 
                                    value={contact.middleName || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, middleName: e.target.value}, isUpdated: true})}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Last Name..." 
                                    value={contact.lastName || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, lastName: e.target.value}, isUpdated: true})}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formPreferredName">
                                <Form.Label>Preferred Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Preferred Name..." 
                                    value={contact.preferredName || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, preferredName: e.target.value}, isUpdated: true})}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email"
                                    placeholder="Enter Email Address..." 
                                    value={contact.email || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, email: e.target.value}, isUpdated: true})}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formXnAddress">
                                <Form.Label>XnAddress</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Xn Address..." 
                                    value={contact.xnAddress || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, xnAddress: e.target.value}, isUpdated: true})}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formCompany">
                                <Form.Label>Company</Form.Label>
                                <Form.Select 
                                    name="companies" 
                                    value={contact.companyName} 
                                    onChange={(e) => this.setState({contact: {...contact, companyName: e.target.value}, isUpdated: true})}
                                >
                                        {isCompanyListLoading && <option>Loading...</option>}
                                        {!isCompanyListLoading && <option key={-1}>Choose...</option>}
                                        {companyResponse.resourceList?.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                </Form.Select> 
                            </Form.Group>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter Title..." 
                                    value={contact.title || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, title: e.target.value}, isUpdated: true})}
                                />
                            </Form.Group>                            
                        </Row>
                        <Row>
                            <Form.Group className="checkbox-group-hor">
                                <Form.Check 
                                    inline={true}
                                    name="Adjuster"
                                    type="checkbox"
                                    label="Adjuster"
                                    id="chkAdjuster"
                                    checked={contact.contactTypes?.includes("Adjuster") ? true : false}
                                    onChange={this.handleContactTypeChange}
                                />
                                <Form.Check 
                                    inline={true}
                                    name="Admin"
                                    type="checkbox"
                                    label="Admin"
                                    id="chkAdmin"
                                    checked={contact.contactTypes?.includes("Admin") ? true : false}
                                    onChange={this.handleContactTypeChange}
                                />
                                <Form.Check 
                                    inline={true}
                                    name="Examiner"
                                    type="checkbox"
                                    label="Examiner"
                                    id="chkExaminer"
                                    checked={contact.contactTypes?.includes("Examiner") ? true : false}
                                    onChange={this.handleContactTypeChange}
                                />
                                <Form.Check 
                                    inline={true}
                                    name="Field Adjuster"
                                    type="checkbox"
                                    label="Field Adjuster"
                                    id="chkFieldAdjuster"
                                    checked={contact.contactTypes?.includes("Field Adjuster") ? true : false}
                                    onChange={this.handleContactTypeChange}
                                />
                                <Form.Check 
                                    inline={true}
                                    name="Field Director"
                                    type="checkbox"
                                    label="Field Director"
                                    id="chkFieldDirector"
                                    checked={contact.contactTypes?.includes("Field Director") ? true : false}
                                    onChange={this.handleContactTypeChange}
                                />
                                <Form.Check 
                                    inline={true}
                                    name="Reviewer"
                                    type="checkbox"
                                    label="Reviewer"
                                    id="chkReviewer"
                                    checked={contact.contactTypes?.includes("Reviewer") ? true : false}
                                    onChange={this.handleContactTypeChange}
                                />
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>    
                <Accordion className="accordion-card" defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header> 
                            { contact.hasComtrackAccess && 
                                <Image src={keyIcon} id="keyIcon"/>
                            }                           
                            Comtrack Access
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-3">
                                <Form.Group>
                                    <Form.Switch
                                        id="tglComtrackAccess"
                                        label="Comtrack Access"
                                        checked={contact.hasComtrackAccess || false}
                                        onChange={(e) => alert(e.target.value)}
                                    />                                    
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">                            
                                <Form.Group as={Col}>
                                    <Form.Label>Application Role</Form.Label>
                                    <Form.Select 
                                        name="appRole" 
                                        value={contact.role || -1 } 
                                        onChange={(e) => alert(e.target.value)}
                                    >
                                        {isRoleListLoading && <option>Loading...</option>}
                                        {!isRoleListLoading && <option key={-1}>Choose...</option>}
                                        {roleResponse?.resourceList.map((r: models.Role, i: number) => ( 
                                            <option key={i} value={r.roleName} >{r.roleName}</option>
                                        ))}
                                    </Form.Select>   
                                </Form.Group>                        
                                <Form.Group as={Col}>
                                    <Form.Label>Additional Features</Form.Label>
                                    <Form.Select defaultValue="Choose...">
                                        <option>Choose...</option>
                                        <option>...</option>
                                    </Form.Select>
                                </Form.Group>        
                            </Row>                            
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>                     
                <Card className="claim-card">
                    <Card.Title>Contact Numbers</Card.Title>
                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formCell">
                                <Form.Label>Cell</Form.Label>
                                <Form.Control 
                                    name="Cell"
                                    type="text" 
                                    placeholder="Enter Cell #..." 
                                    value={contact.phoneNumbers?.find(p => p?.phoneNumberType == "Cell")?.phoneNumber || ''} 
                                    onChange={this.handlePhoneNumberChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formHome">
                                <Form.Label>Home</Form.Label>
                                <Form.Control 
                                    name="Home"
                                    type="text" 
                                    placeholder="Enter Home #..." 
                                    value={contact.phoneNumbers?.find(p => p?.phoneNumberType == "Home")?.phoneNumber || ''} 
                                    onChange={this.handlePhoneNumberChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formBusiness">
                                <Form.Label>Business</Form.Label>
                                <Form.Control 
                                    name="Business"
                                    type="text" 
                                    placeholder="Enter Business #..." 
                                    value={contact.phoneNumbers?.find(p => p?.phoneNumberType == "Business")?.phoneNumber || ''} 
                                    onChange={this.handlePhoneNumberChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formFax">
                                <Form.Label>Fax</Form.Label>
                                <Form.Control 
                                    name="Fax"
                                    type="text" 
                                    placeholder="Enter Fax #..." 
                                    value={contact.phoneNumbers?.find(p => p?.phoneNumberType == "Fax")?.phoneNumber || ''} 
                                    onChange={this.handlePhoneNumberChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formOther">
                                <Form.Label>Other</Form.Label>
                                <Form.Control 
                                    name="Other"
                                    type="text" 
                                    placeholder="Enter Other #..." 
                                    value={contact.phoneNumbers?.find(p => p?.phoneNumberType == "Other")?.phoneNumber || ''} 
                                    onChange={this.handlePhoneNumberChange}
                                />
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="claim-card">
                    <Card.Title>Mailing Address</Card.Title>
                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formAddress1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control 
                                    placeholder="1234 Main St" 
                                    value={contact.mailingAddress?.addressLine1 || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, mailingAddress: {...contact.mailingAddress, addressLine1: e.target.value}}, isUpdated: true})}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formAddress2" >
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control 
                                    placeholder="Apartment, studio, or floor"  
                                    value={contact.mailingAddress?.addressLine2 || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, mailingAddress: {...contact.mailingAddress, addressLine2: e.target.value}}, isUpdated: true})}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control 
                                    placeholder='Enter City...' 
                                    value={contact.mailingAddress?.city || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, mailingAddress: {...contact.mailingAddress, city: e.target.value}}, isUpdated: true})}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formState">
                                <Form.Label>State</Form.Label>
                                    <Form.Select 
                                        name="states" 
                                        value={contact.mailingAddress?.state || ""} 
                                        onChange={(e) => this.setState({contact: {...contact, mailingAddress: {...contact.mailingAddress, state: e.target.value}}, isUpdated: true})}
                                    >
                                        {isStateAbbrDDLoading && <option>Loading...</option>}
                                        {!isStateAbbrDDLoading && <option key={-1}>Choose...</option>}
                                        {stateResponse && stateResponse.resourceList?.map((s: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={s.name} >{s.name}</option>
                                        ))}
                                    </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formZip">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control 
                                    placeholder='Enter Zip...' 
                                    value={contact.mailingAddress?.postalCode  || ""} 
                                    onChange={(e) => this.setState({contact: {...contact, mailingAddress: {...contact.mailingAddress, postalCode: e.target.value}}, isUpdated: true})}
                                />
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
            }
            </>
        );         
    }

}

const mapStateToProps = (state: MyTypes.RootState) => ({
    companyResponse: state.companies.dropdowns.companyDD,
    contact: state.contacts.contacts.contact,
    isContactLoading: state.contacts.contacts.isContactLoading,
    isCompanyListLoading: state.companies.dropdowns.isCompanyDDLoading,
    isRoleListLoading: state.admin.admin.isRoleListLoading,             
    isStateAbbrDDLoading: state.locations.dropdowns.isStateDDLoading,
    roleResponse: state.admin.admin.roleResponse,
    stateResponse: state.locations.dropdowns.stateDD,    
    zipSearch: state.locations.locations.zipSearch
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            requestCompanyDropdown: companyActions.requestCompanyDropdown,
            requestContact: contactsActions.requestContact,
            requestRoleList: adminActions.requestRoleList,             
            requestZipSearch: locationActions.requestZipSearch,
            updateContact: contactsActions.updateContact, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ContactDetail);