import React from 'react';
import { Image } from 'react-bootstrap';
import cancelIcon from '../../assets/images/Blue/cancel.svg';

const NoResults = (props: any) => {
  return (
    <div className="no-results-container">
      <div>
        <Image src={cancelIcon}/> No Results
      </div>
    </div>
  );
};

export default(NoResults); 