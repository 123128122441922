import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Card, CardGroup, Col, Form, Modal, ModalTitle, Row, Table, Spinner, ButtonGroup } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';
import SingleActionButton from '../common/SingleActionButton'; 
import downloadIcon from '../../assets/images/Blue/download.svg';
import GearButton from 'components/common/GearButton';
import attachIcon from  '../../assets/images/Blue/attach.svg';
import checkIcon from '../../assets/images/Blue/check.svg'; 
import { financialsActions } from 'store/financials';
import NumberFormat from 'react-number-format'; 
import FeeAmount from './FeeAmt'; 
import Select from 'react-select'; 
import { createPatch } from 'rfc6902';
import services from 'services';
import { requestCatCodeDD } from 'store/admin/saga';
import { adminActions } from 'store/admin';

interface Props extends PropsFromRedux {    
    company: string; 
    feeScheduleId: number; 
    closeModal: () => void; 
    showModal: boolean; 
}

interface State {
    editFeeIndex: number; 
    editRangeIndex: number; 
    feeSchedule: models.FeeSchedule; 
    feeSceduleFees: models.FeeScheduleFee[]; 
    templateId: number; 
    newRange: models.FeeScheduleRange;
    newFee: models.FeeScheduleFee; 
    screenMode: string; 
    selectedCats: ddList[]; 
    selectedStates: ddList[];         
}

interface ddList {
    readonly value: string;
    readonly label: string; 
}

class FeeScheduleBuilder extends React.Component<Props, State> {
    public state = {
        editFeeIndex: -1, 
        editRangeIndex: -1, 
        feeSchedule: models.defaultFeeSchedule,
        feeSceduleFees: new Array<models.FeeScheduleFee>, 
        templateId: -1, 
        newRange: models.defaultFeeScheduleRange, 
        newFee: models.defaultFeeScheduleFee, 
        screenMode: "init", 
        selectedCats: new Array<ddList>, 
        selectedStates: new Array<ddList>,   
    }    

    // Lifecycle Functions
    componentDidMount(): void {        
        this.setState({newRange: {...this.state.newRange, feeType: 'Flat'}});         
        this.props.requestFeesList(); 
        this.props.requestCatCodeDD(); 
    }    
    
    componentDidUpdate(prevProps: any, prevState: any) {
        // Fee Schedule Prop Change
        if (!_.isEqual(prevProps.feeScheduleId, this.props.feeScheduleId)) {
            switch (this.props.feeScheduleId) {
                case 0: // creating a new schedule                    
                    this.resetFeeScheduleState();                     
                    break; 
                default: // editing existing schedule
                    if(this.props.feeSchedule.feeScheduleId !== this.props.feeScheduleId){
                        this.props.requestFeeSchedule(this.props.feeScheduleId); 
                    }
                    else {
                        this.refreshFeeScheduleStateFromProps(); 
                    }
                    break;                
            }        
        }        
        if (!_.isEqual(prevProps.feeSchedule, this.props.feeSchedule) && this.props.feeSchedule !== undefined) {
            this.refreshFeeScheduleStateFromProps();
        }
        if(!_.isEqual(prevProps.isFeeScheduleUpdating, this.props.isFeeScheduleUpdating) && !this.props.isFeeScheduleUpdating) {
            this.resetFormStateAndClose(); 
        }
        if(!_.isEqual(prevState.newRange, this.state.newRange)) {
            if(!_.isEqual(this.state.newRange, models.defaultFeeScheduleRange))
                this.setState({editFeeIndex: -1, newFee: models.defaultFeeScheduleFee, screenMode: "range"});
        }
        if(!_.isEqual(prevState.newFee, this.state.newFee)) {
            if(!_.isEqual(this.state.newFee, models.defaultFeeScheduleFee)) 
                this.setState({editRangeIndex: -1, newRange: models.defaultFeeScheduleRange, screenMode: "fee"}); 
        }
        if(!_.isEqual(prevProps.showModal, this.props.showModal) && this.props.showModal) {
            if(this.props.feeScheduleId === 0) {
                this.setState({
                    feeSchedule: {...this.state.feeSchedule, insuranceCompany: this.props.company}
                }); 
            }
        }
    }

    // Form, state & usability stuff
    public submitForm = (e:any) => {
        e.preventDefault();
        if(this.state.feeSchedule.scheduleName === ''){            
           alert("Schedule Name Required!"); 
            return; 
        }
        if (this.state.feeSchedule.feeScheduleId === 0){
            this.props.createFeeSchedule(this.state.feeSchedule);
            this.resetFormStateAndClose();  
        }
        else {            
            const patchDocument = createPatch(this.props.feeSchedule, this.state.feeSchedule);
            this.props.patchFeeSchedule(this.state.feeSchedule.feeScheduleId, patchDocument); 
        }        
    }
   
    resetFeeScheduleState = () => {
        this.setState({
            editFeeIndex: -1, 
            editRangeIndex: -1, 
            feeSchedule: models.defaultFeeSchedule,
            feeSceduleFees: new Array<models.FeeScheduleFee>,             
            newRange: models.defaultFeeScheduleRange, 
            newFee: models.defaultFeeScheduleFee, 
            screenMode: "init", 
            selectedCats: new Array<ddList>, 
            selectedStates: new Array<ddList>,   
            templateId: -1, 
        }); 
    }
    
    refreshFeeScheduleStateFromProps = () => {
        this.setState({
            feeSchedule: {
                ...this.props.feeSchedule, 
                feeScheduleFeeList: [...this.props.feeSchedule.feeScheduleFeeList!], 
                feeScheduleRangeList: [...this.props.feeSchedule.feeScheduleRangeList!]                    
            },
            editFeeIndex: -1, 
            editRangeIndex: -1, 
            newRange: models.defaultFeeScheduleRange, 
            newFee: models.defaultFeeScheduleFee, 
            screenMode: "init",
            selectedCats: this.getCatCodeDDList(this.props.catCodeDD.resourceList.filter(x => this.props.feeSchedule.catCodeList?.includes(x.id))),
            selectedStates: this.getStateDDList(this.props.statesResponse.resourceList.filter(x => this.props.feeSchedule.stateList?.includes(x.name))),
            templateId: -1, 
        }); 
    }
    
    public resetFormStateAndClose = () => {
        if (this.props.feeScheduleId === 0){
            this.resetFeeScheduleState(); 
        }
        else {
            this.refreshFeeScheduleStateFromProps(); 
        }
        this.props.closeModal(); 
    }

    public handleTemplateSelect = (feeScheduleId: number) => {
        this.setState({templateId: feeScheduleId});          
    }

    public applyTemplate = async () => {
        const response = await services.api.financials.getFeeSchedule(this.state.templateId); 
        if(response.isSuccess) {
            const feeSchedule: models.FeeSchedule = response.data;             
            this.setState({
                feeSchedule: {
                    ...this.state.feeSchedule,
                    isCat: feeSchedule.isCat,
                    isGeo: feeSchedule.isGeo,
                    feeScheduleFeeList: [...feeSchedule.feeScheduleFeeList!],
                    feeScheduleRangeList: [...feeSchedule.feeScheduleRangeList!],
                    catCodeList: [...feeSchedule.catCodeList!],
                    stateList: [...feeSchedule.stateList!]
                },
                selectedCats: this.getCatCodeDDList(this.props.catCodeDD.resourceList.filter(x => feeSchedule.catCodeList?.includes(x.id))),
                selectedStates: this.getStateDDList(this.props.statesResponse.resourceList.filter(x => feeSchedule.stateList?.includes(x.name)))
            }); 
        }
    }     

    public handleCatCodeChange = (e: any) => {        
        const newArray: number[] = e.map((item: ddList) => {return parseInt(item.value)});
        this.setState({
            feeSchedule: {
                ...this.state.feeSchedule,
                catCodeList: [...newArray]
            },
            selectedCats: e
        });         
    }

    public handleStateChange = (e: any) => {        
        const newArray: string[] = e.map((item: ddList) => {return item.value});
        this.setState({
            feeSchedule: {
                ...this.state.feeSchedule,
                stateList: [...newArray]
            },
            selectedStates: e
        });         
    }

    // Fee Functions
    public handleClearFee = () => {
        this.setState({editFeeIndex: -1, newFee: models.defaultFeeScheduleFee, screenMode: "init"}); 
    }

    public handleDeleteFee = (e: any) => {        
        const newArray = this.state.feeSchedule.feeScheduleFeeList?.filter((f, i) => i !== parseInt(e)); 
        this.setState({feeSchedule: {...this.state.feeSchedule, feeScheduleFeeList: newArray}}); 
    }

    public handleEditFee = (e: any) => {
        this.setState({editFeeIndex: parseInt(e), newFee: this.state.feeSchedule.feeScheduleFeeList![parseInt(e)]}); 
    }

    public handleAddFeeToSchedule = () => {
        if(this.state.editFeeIndex !== -1) {
            var fees = this.state.feeSchedule.feeScheduleFeeList; 
            fees![this.state.editFeeIndex] = {...this.state.newFee}; 
            this.setState({editFeeIndex: -1, feeSchedule: {...this.state.feeSchedule, feeScheduleFeeList: fees}}); 
        }
        else {        
            let newFeeScheduleFeeList: models.FeeScheduleFee[]; 
            if (!this.state.feeSchedule.feeScheduleFeeList){
                newFeeScheduleFeeList = new Array<models.FeeScheduleFee>().concat(this.state.newFee); 
            }
            else {
                newFeeScheduleFeeList = this.state.feeSchedule.feeScheduleFeeList.concat(this.state.newFee); 
            }
            this.setState({feeSchedule: {...this.state.feeSchedule, feeScheduleFeeList: newFeeScheduleFeeList}}); 
        }
        this.handleClearFee(); 
    }    

    // Range Fee Functions
    public handleClearRange = () => {
        this.setState({editRangeIndex: -1, newRange: models.defaultFeeScheduleRange, screenMode: "init"}); 
    }

    public handleFeeAmtChange = (e: any) => {              
        this.setState({newRange: {...this.state.newRange, amount: parseFloat(e.target.value)}}); 
        e.preventDefault(); 
    }

    public handleDeleteRangeFee = (e: any) => {        
        const newArray = this.state.feeSchedule.feeScheduleRangeList?.filter((f, i) => i !== parseInt(e)); 
        this.setState({feeSchedule: {...this.state.feeSchedule, feeScheduleRangeList: newArray}}); 
    }

    public handleEditRangeFee = (e: any) => {         
        this.setState({editRangeIndex: parseInt(e), newRange: this.state.feeSchedule.feeScheduleRangeList![parseInt(e)]}); 
    }

    public handleFeeTypeSelect = (feeType: any) => {
        let feeTypeName: string = ''; 

        switch (feeType.target.id) {
            case "chkFlatFee":
                feeTypeName = "Flat"; 
                break;
            case "chkPercentMin": 
                feeTypeName = "PercentMin"; 
                break;
            case "chkTandE": 
                feeTypeName = "TandE"; 
                break;
        }       
        this.setState({newRange: {...this.state.newRange, feeType: feeTypeName}});
    }

    public handleAddRangeToSchedule = () => {
        if (this.state.editRangeIndex !== -1) {
            var rangeFees = this.state.feeSchedule.feeScheduleRangeList; 
            rangeFees![this.state.editRangeIndex] = {...this.state.newRange}; 
            this.setState({editRangeIndex: -1, feeSchedule: {...this.state.feeSchedule, feeScheduleRangeList: rangeFees}});
        }
        else {         
            let newFeeScheduleRangeList: models.FeeScheduleRange[]; 

            if (!this.state.feeSchedule.feeScheduleRangeList) {
                newFeeScheduleRangeList = new Array<models.FeeScheduleRange>().concat(this.state.newRange);     
            }
            else {
                newFeeScheduleRangeList = this.state.feeSchedule.feeScheduleRangeList.concat(this.state.newRange); 
            }
            this.setState({feeSchedule: {...this.state.feeSchedule, feeScheduleRangeList: newFeeScheduleRangeList }});            
        }
        this.handleClearRange(); 
    }   

    // local helpers
    getCatCodeDDList = (catCodes: models.DropDownListItem[]) => catCodes.map((c:models.DropDownListItem) => {
        return {value: c.id.toString(), label: c.name}
    });

    getStateDDList = (states: models.DropDownListItem[]) => states.map((c:models.DropDownListItem) => {
        return {value: c.name, label: c.name}
    }); 
    
    public render() {
        const { catCodeDD, feesList, feeSchedulesList, insCompanyDD, isInsCompanyDDLoading, isFeeScheduleLoading, isFeeScheduleUpdating, isStateAbbrDDLoading, showModal, statesResponse } = this.props; 
        const { editFeeIndex, editRangeIndex, feeSchedule, newFee, newRange, templateId, screenMode, selectedCats, selectedStates } = this.state;        

        const catCodeDDList: ddList[] = this.getCatCodeDDList(catCodeDD.resourceList);         
        const stateDDList: ddList[] = this.getStateDDList(statesResponse.resourceList);

        const buttonActions: models.ButtonAction[] = [
            {
                name: 'Edit', 
                callBack: (e) => this.handleEditRangeFee(e)
            }, 
            {
                name: 'Delete', 
                callBack: (e) => this.handleDeleteRangeFee(e)
            }, 
        ];        

        const buttonActions2: models.ButtonAction[] = [
            {
                name: 'Edit', 
                callBack: (e) => this.handleEditFee(e)
            }, 
            {
                name: 'Delete', 
                callBack: (e) => this.handleDeleteFee(e)
            }, 
        ];        

        return (
            <Modal show={showModal} fullscreen={true} onHide={this.resetFormStateAndClose} >                                
                <Modal.Header closeButton>
                    <ModalTitle>Fee Schedule Builder
                        {isFeeScheduleLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    </ModalTitle>
                </Modal.Header>               
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>
                    <Modal.Body>
                        <Row>
                            <Col sm={4}>
                                <Form.Group controlId="scheduleName" className="input-field-container">
                                    <Form.Label column sm="4" className="card-label">Schedule Name</Form.Label>                                    
                                        <Form.Control                                             
                                            type="text" 
                                            value={feeSchedule.scheduleName}
                                            placeholder='Enter Name...'
                                            onChange={(e) => this.setState({feeSchedule: {...feeSchedule, scheduleName: e.target.value}})} />
                                </Form.Group>                               
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="insCompany" className="input-field-container">
                                    <Form.Label column sm="4">Company Name</Form.Label>
                                    <Form.Select name="insCompany" 
                                        value={feeSchedule.insuranceCompany} 
                                        onChange={(e) => this.setState({feeSchedule: {...feeSchedule, insuranceCompany: e.target.value}})}
                                        >
                                            {isInsCompanyDDLoading && <option>Loading...</option>}
                                            {!isInsCompanyDDLoading && <option key={-1}>Choose...</option>}                                        
                                            {insCompanyDD.totalCount > 0 && insCompanyDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                <option key={i} value={c.name} >{c.name}</option>
                                            ))}
                                    </Form.Select>
                                </Form.Group>    
                            </Col>                            
                            <Col sm={4}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="isActive" className="input-field-container">
                                            <Form.Switch
                                                id="tglStatus"
                                                checked={feeSchedule.isActive}
                                                label="Active"
                                                onChange={(e) => this.setState({feeSchedule: {...feeSchedule, isActive: !feeSchedule.isActive}})}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Col>
                                            <SingleActionButton
                                                    action="Upload"
                                                    title="Fee Schedule"
                                                    img={attachIcon}
                                                    onClick={() => alert("Upload Schedule")}
                                                />
                                        </Col>
                                        <Col>
                                            <SingleActionButton
                                                    action="Download"
                                                    title="Fee Schedule"
                                                    img={downloadIcon}
                                                    onClick={() => alert("Download Schedule")}
                                                    disabled={!('attachment' in feeSchedule)}
                                                />
                                        </Col>
                                    </Col>
                                </Row>                                
                            </Col>
                            </Row>
                        <Row>                                                                          
                            <Col sm={4}>
                                <Form.Group controlId="template" className="input-field-container">
                                    <Form.Label column sm="4" className="card-label">Template</Form.Label>
                                        <Form.Select name="template" 
                                            value={templateId} 
                                            disabled={feeSchedule.feeScheduleId === 0 ? false : true}
                                            onChange={(e) => this.handleTemplateSelect(parseInt(e.target.value))}
                                        >
                                            {<option key={-1} value={-1}>Choose...</option>}                                        
                                            {feeSchedulesList.totalCount > 0 && feeSchedulesList.resourceList.map((f: models.FeeSchedule, i: number) => ( 
                                                <option key={i} value={f.feeScheduleId} >{f.scheduleName}</option>
                                            ))}
                                        </Form.Select>
                                        {templateId !== -1 && 
                                        <SingleActionButton
                                                    action="Apply"
                                                    title="Fee Schedule"
                                                    img={checkIcon}
                                                    onClick={() => this.applyTemplate()}                                                                                                        
                                                />
                                            }
                                </Form.Group>
                            </Col>                            
                            <Col sm={4}>
                                <Form.Group controlId="description" className="input-field-container">
                                    <Form.Label column sm="4">Description</Form.Label>
                                    <Col sm="8">
                                        <Form.Control 
                                            as="textarea" 
                                            value={feeSchedule.description}
                                            onChange={(e) => this.setState({feeSchedule: {...feeSchedule, description: e.target.value}})} 
                                        />
                                    </Col>
                                </Form.Group>
                             </Col>
                            <Col sm={4}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="isCat" className="input-field-container">
                                            <Form.Check 
                                                inline={true}
                                                name="isCAT"
                                                type="checkbox"
                                                label="Is CAT"
                                                id="chkIsCAT"
                                                checked={feeSchedule.isCat}
                                                onChange={(e) => this.setState({feeSchedule: {...feeSchedule, isCat: !feeSchedule.isCat}})}
                                            />                                
                                        </Form.Group>
                                    </Col>                                    
                                    <Col>
                                        <Select                                             
                                            isDisabled={!feeSchedule.isCat}
                                            isMulti={true}
                                            onChange={(e) => this.handleCatCodeChange(e)}
                                            value={selectedCats}
                                            options={catCodeDDList}
                                        />
                                    </Col>
                                </Row>    
                                <Row>
                                    <Col>
                                        <Form.Group controlId="isGeo" className="input-field-container">
                                            <Form.Check 
                                                inline={true}
                                                name="isGeo"
                                                type="checkbox"
                                                label="Is GEO"
                                                id="chkIsGEO"
                                                checked={feeSchedule.isGeo}
                                                onChange={(e) => this.setState({feeSchedule: {...feeSchedule, isGeo: !feeSchedule.isGeo}})}
                                            />                                
                                        </Form.Group>
                                    </Col>
                                        <Col>
                                            <Select 
                                                options={stateDDList}
                                                isDisabled={!feeSchedule.isGeo}
                                                isMulti={true}
                                                value={selectedStates}
                                                onChange={(e) => this.handleStateChange(e)}
                                            />
                                        </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <CardGroup>
                                <Card className="fee-sch-bldr-card">
                                    <Card.Title>Ranges</Card.Title>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="rangeBegin" className="input-field-container">
                                                    <Form.Label column sm="4" className="card-label">Range Begin</Form.Label>
                                                        <Form.Control 
                                                            type="number" 
                                                            value={newRange.rangeStart || (newRange.rangeStart == 0 ? 0.00 : '')}
                                                            placeholder='Enter Value...'
                                                            onChange={(e) => this.setState({newRange: {...this.state.newRange, rangeStart: parseFloat(e.target.value)}})} 
                                                        />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="checkbox-group-hor" >
                                                    <Form.Label column sm="4" className="card-label">Fee Type</Form.Label>
                                                    <Form.Check 
                                                        inline={true}
                                                        name="feeTypes"
                                                        type="radio"
                                                        label="Flat"
                                                        id="chkFlatFee"
                                                        checked={newRange.feeType === "Flat" ? true : false }
                                                        onChange={(e) => this.handleFeeTypeSelect(e)}
                                                    />
                                                    <Form.Check 
                                                        inline={true}
                                                        name="feeTypes"
                                                        type="radio"
                                                        label="%"
                                                        id="chkPercentMin"
                                                        checked={newRange.feeType === "PercentMin" ? true : false }
                                                        onChange={(e) => this.handleFeeTypeSelect(e)}
                                                    />
                                                    <Form.Check 
                                                        inline={true}
                                                        name="feeTypes"
                                                        type="radio"
                                                        label="T&E"
                                                        id="chkTandE"
                                                        checked={newRange.feeType === "TandE" ? true : false }
                                                        onChange={(e) => this.handleFeeTypeSelect(e)}
                                                    />
                                                </Form.Group>                                                            
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="rangeEnd" className="input-field-container">
                                                    <Form.Label column sm="4" className="card-label">Range End</Form.Label>
                                                        <Form.Control 
                                                            type="number" 
                                                            value={newRange.rangeEnd || ''}
                                                            placeholder='Enter Value...'
                                                            onChange={(e) => this.setState({newRange: {...newRange, rangeEnd: parseFloat(e.target.value)}})} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <FeeAmount
                                                    activeFeeType={newRange.feeType}
                                                    value={newRange.amount}
                                                    percent={newRange.percent}
                                                    rate={newRange.rate}
                                                    setPercent={(e) => this.setState({newRange: {...newRange, percent: parseFloat(e.target.value)}})}
                                                    setValue={(e) => this.setState({newRange: {...newRange, amount: parseFloat(e.target.value)}})}
                                                    setRate={(e) => this.setState({newRange: {...newRange, rate: parseFloat(e.target.value)}})}
                                                />
                                            </Col>
                                        </Row>                                    
                                        <ButtonGroup className="fsb-btn-grp">
                                            <Button 
                                                onClick={this.handleAddRangeToSchedule}
                                                disabled={screenMode !== "range"}
                                                >{editRangeIndex === -1 ? "ADD" : "UPDATE"}
                                            </Button>
                                            <Button 
                                                onClick={this.handleClearRange}
                                                disabled={screenMode !== "range"}
                                                >CLEAR
                                            </Button>                                            
                                        </ButtonGroup>
                                        <Row>
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>Begin</th>
                                                        <th>End</th>
                                                        <th>Type</th>
                                                        <th>Fee</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!(feeSchedule.feeScheduleRangeList === undefined) && feeSchedule.feeScheduleRangeList?.map((f: models.FeeScheduleRange, i: number) => (
                                                        <tr key={i}>                                                        
                                                            <td><NumberFormat value={f.rangeStart?.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></td>
                                                            <td><NumberFormat value={f.rangeEnd?.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></td>
                                                            <td>{f.feeType}</td>
                                                            <td>
                                                                {f.feeType === "PercentMin" ? f.percent + "% or " : (f.feeType === "TandE" ? "$" + f.rate + "/hr or " : "")}
                                                                <NumberFormat value={f.amount?.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'} />
                                                            </td>
                                                            <td style={{overflow:'visible'}}>
                                                                <GearButton
                                                                    actions={buttonActions}
                                                                    rowId={i.toString()}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}  
                                                </tbody>
                                            </Table>   
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className="fee-sch-bldr-card">
                                    <Card.Title>Fees</Card.Title>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                            <Form.Group controlId="feesList" className="input-field-container">
                                                <Form.Select name="feesList" 
                                                    value={newFee.feeName || ''}
                                                    onChange={(e) => this.setState({
                                                        newFee: {
                                                            ...newFee, 
                                                            feeName: e.target.value,
                                                            amount: this.props.feesList.resourceList.find(x => x.feeName === e.target.value)?.defaultValue || 0
                                                        }}
                                                    )}
                                                    >
                                                        {<option key={-1}>Choose...</option>}                                        
                                                        {feesList.totalCount > 0 && feesList.resourceList.map((f: models.Fee, i: number) => ( 
                                                            <option key={i} value={f.feeName} >{f.feeName}</option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="feeQty" className="input-field-container">
                                                    <Form.Control 
                                                        type="number" 
                                                        value={newFee.quantity || ''}
                                                        placeholder='Enter Qty...'
                                                        onChange={(e) => this.setState({newFee: {...newFee, quantity: parseFloat(e.target.value)}})}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="feeAmt" className="input-field-container">
                                                    <Form.Control 
                                                        type="number" 
                                                        value={newFee.amount || ''}
                                                        placeholder='Enter Value...'
                                                        onChange={(e) => this.setState({newFee: {...newFee, amount: parseFloat(e.target.value)}})}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>          
                                        <ButtonGroup className="fsb-btn-grp">
                                            <Button 
                                                onClick={this.handleAddFeeToSchedule}
                                                disabled={screenMode !== "fee"}
                                                >{editFeeIndex === -1 ? "ADD" : "UPDATE"}
                                            </Button>
                                            <Button 
                                                onClick={this.handleClearFee}
                                                disabled={screenMode !== "fee"}
                                                >CLEAR
                                            </Button>
                                        </ButtonGroup>
                                        <Row>
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>Fee</th>
                                                        <th>Qty</th>
                                                        <th>Rate</th>
                                                        <th>Amount</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!(feeSchedule.feeScheduleFeeList === undefined) && feeSchedule.feeScheduleFeeList?.map((f: models.FeeScheduleFee, i: number) => (
                                                        <tr key={i}>                                                        
                                                            <td>{f.feeName}</td>
                                                            <td>{f.quantity}</td>                                                            
                                                            <td><NumberFormat value={f.amount.toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></td>
                                                            <td><NumberFormat value={(f.quantity * f.amount).toFixed(2)} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></td>
                                                            <td style={{overflow:'visible'}}>
                                                                <GearButton
                                                                    actions={buttonActions2}
                                                                    rowId={i.toString()}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}  
                                                </tbody>
                                            </Table>   
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Row>
                    </Modal.Body>                    
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            {isFeeScheduleUpdating ? "SAVING..." : "SAVE"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    catCodeDD: state.admin.dropdowns.catCodeDD, 
    feesList: state.financials.financials.feesResponse,
    feeSchedulesList: state.financials.financials.feeSchedulesResponse,
    feeSchedule: state.financials.financials.feeSchedule,
    insCompanyDD: state.companies.dropdowns.insCompanyDD, 
    isInsCompanyDDLoading: state.companies.dropdowns.isInsCompanyDDLoading,     
    isFeeScheduleLoading: state.financials.financials.isFeeScheduleLoading, 
    isFeeScheduleUpdating: state.financials.financials.isFeeScheduleUpdating, 
    isStateAbbrDDLoading: state.locations.dropdowns.isStateAbbrDDLoading,
    statesResponse: state.locations.dropdowns.stateAbbrDD, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            createFeeSchedule: financialsActions.createFeeSchedule, 
            patchFeeSchedule: financialsActions.patchFeeSchedule, 
            requestCatCodeDD: adminActions.requestCatCodeDD,
            requestFeesList: financialsActions.requestFeesList,
            requestFeeSchedule: financialsActions.requestFeeSchedule,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FeeScheduleBuilder);
