import * as React from 'react';

interface Props {
}

interface State {
}

class Unauthorized extends React.PureComponent<Props, State> {
    public state = {
    };

    public render() {
        return (
            <>
                <h3>USER NOT AUTHORIZED</h3>
            </>
        );
    }
}

export default Unauthorized;