import * as models from '..';

export interface ClaimSimple {
    claimID: number; 
    insClaimNumber: string;
    status: string; 
    insuranceCompany: string; 
    insuredPerson: string;
    adjuster: models.DropDownListItem; 
    examiner: models.DropDownListItem;
    director: models.DropDownListItem; 
    reviewer: models.DropDownListItem; 
    lossCause: string; 
    lossDescription: string; 
    catCode: string; 
    claimType: string; 
    reopenReason: string; 
    grossLoss: number; 
    dateLoss: Date | null;
    dateReported: Date | null;
    dateReceived: Date | null;
    dateReopened: Date | null;
    dateClosed: Date | null;
}

export const defaultClaimSimple: ClaimSimple = {
    claimID: 0, 
    insClaimNumber: '',
    status: '', 
    insuranceCompany: '', 
    insuredPerson: '',
    adjuster: models.defaultDropDownListItem, 
    examiner: models.defaultDropDownListItem, 
    director: models.defaultDropDownListItem, 
    reviewer: models.defaultDropDownListItem,
    lossCause: '', 
    lossDescription: '', 
    catCode: '', 
    claimType: '', 
    reopenReason: '', 
    grossLoss: 0, 
    dateLoss: null,
    dateReported: null,
    dateReceived: null,
    dateReopened: null,
    dateClosed: null,
}