import * as models from '..'; 

export interface InvoiceGroupsRequest extends models.RequestBase {
    companyName: string; 
    groupName: string; 
    deliveryDateFrom: string;
    deliveryDateTo: string;
    createdDateFrom: string;
    createdDateTo: string;
    sort: string;
}

export const defaultInvoiceGroupsRequest: InvoiceGroupsRequest = {
    pageNo: 1, 
    pageSize: 20, 
    companyName: '', 
    groupName: '', 
    deliveryDateTo: '',
    deliveryDateFrom: '',
    createdDateTo: '',
    createdDateFrom: '',
    sort: '-createdDate',
}