import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Accordion, Button, Card, CardGroup, Col, Container, Form, InputGroup, Modal, Row, Table, Spinner } from 'react-bootstrap'; 
import checkIcon from '../../assets/images/icons8-ok.svg'; 
import * as models from '../../models';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import _ from 'lodash'; 
import { financialsActions } from 'store/financials';

interface Props extends PropsFromRedux {
    closeModal: () => void; 
    showModal: boolean;     
}

interface State {    
    payment: models.Payment,
    reconcileList: models.InvoicesFullResponse | null,
    selectAll: boolean, 
    selectedInvoices: number[],
    selectedFees: feeObj[],
}

interface feeObj {    
    invoiceFeeId: number, 
    invoiceId: number 
}

class Reconcile extends React.Component<Props, State> {
    public state = {    
        payment: models.defaultPayment,                
        reconcileList: {...this.props.reconcileList}, 
        selectAll: false, 
        selectedInvoices: new Array<number>(),
        selectedFees: new Array<feeObj>(),
    }

    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.payment, this.props.payment)) {       
            this.setState({payment: {...this.props.payment}}); 
        }
        if (!_.isEqual(prevProps.reconcileList, this.props.reconcileList)) {
            let newInvoiceList = [...this.props.reconcileList.resourceList];
            newInvoiceList.forEach((x) => {
                x.invoiceFees?.forEach((y) => y.paymentAmt = y.total - this.sumPayments2(y.payments!)); 
            }); 
                        
            this.setState({                
                payment: {
                    ...this.props.payment
                },
                reconcileList: {
                    ...this.props.reconcileList, 
                    resourceList: [...newInvoiceList],
                } 
            }); 
        }
    }
   
    public submitForm = (e:any) => {                
        e.preventDefault();
        if(this.state.payment.amount === 0){
            alert("Payment Amount missing...")
            return; 
        }
        const fees = this.state.selectedFees.map(x => (
            {
                id: x.invoiceFeeId, 
                amount: this.getAllInvoiceFees(this.state.reconcileList.resourceList).find(y => y.invoiceFeeId === x.invoiceFeeId)?.paymentAmt || 0
            })); 
        const payment: models.AddPayment = {
            paymentInfo: this.state.payment.paymentInfo,
            paymentAmount: this.state.payment.amount,
            paymentDate: this.state.payment.paymentDate,
            comments: this.state.payment.comments,
            fees: fees, 
        }
        this.props.createPayment(payment)
        this.resetFormStateAndClose(); 
    }

    public resetFormStateAndClose = () => {
        this.setState({payment: models.defaultPayment, selectedInvoices: new Array<number>(), selectedFees: new Array<feeObj>(), reconcileList: {...this.props.reconcileList}}); 
        this.props.clearReconcileList(); 
        this.props.clearSelectedInvoices();
        this.props.closeModal(); 
    }

    public handleToggleInvoice = (invoiceId: number) => {
        if(!this.validatePayment()) return; 
        
        let newFees = new Array<feeObj>;
        let newInvoices = new Array<number>;          
        if(this.state.selectedInvoices.includes(invoiceId)){        
            newFees = this.removeFeesByInvoice(invoiceId);                         
            newInvoices = this.state.selectedInvoices.filter(x => x !== invoiceId); 
        }
        else {       
            newFees = this.addFeesByInvoice(invoiceId);
            newInvoices = this.state.selectedInvoices.concat(invoiceId); 
        }
        const selectAll = this.numberArrayChecker(newFees.map(x => x.invoiceFeeId), this.getAllInvoiceFees(this.state.reconcileList.resourceList).map(x => x.invoiceFeeId)); 
        this.setState({selectAll: selectAll, selectedFees: newFees, selectedInvoices: newInvoices});
    }     

    public handleToggleFee = (invoiceFeeId: number, paymentAmt: number) => {
        let feesList: feeObj[];         
        const invoiceId = this.getInvoiceIdByFeeId(invoiceFeeId); 
        if(this.state.selectedFees.find(x => x.invoiceFeeId === invoiceFeeId) !== undefined){
            feesList = this.state.selectedFees.filter(x => x.invoiceFeeId !== invoiceFeeId); 
        }
        else {            
            feesList = this.state.selectedFees.concat({invoiceFeeId: invoiceFeeId, invoiceId: invoiceId});
        }
        const selectAll = this.numberArrayChecker(feesList.map(x => x.invoiceFeeId), this.getAllInvoiceFees(this.state.reconcileList.resourceList).map(x => x.invoiceFeeId)); 
        const selectedInvoices = this.checkInvoiceAllFeeFlag(invoiceId, feesList); 
        this.setState({selectAll: selectAll, selectedFees: feesList, selectedInvoices: selectedInvoices}); 
    }        

    validatePayment = () => {
        if(this.state.payment.amount <= 0){
            alert("Payment must be greater than 0!");             
            return false; 
        }
        return true; 
    }

    handlePaymentAmtChange = (id: number, amt: number) => {
        const invoiceId = this.getInvoiceIdByFeeId(id);         
        let newInvoiceList = [...this.state.reconcileList.resourceList];         
        const invIndex = newInvoiceList.findIndex(x => x.invoiceId === invoiceId); 
        let newInvoieFeesList = [...newInvoiceList[invIndex].invoiceFees!]; 
        const feeIndex = newInvoieFeesList.findIndex(x => x.invoiceFeeId === id); 
        newInvoieFeesList[feeIndex].paymentAmt = amt; 
        newInvoiceList[invIndex].invoiceFees = newInvoieFeesList; 
        this.setState({
            reconcileList: {
                ...this.state.reconcileList,
                resourceList: [...newInvoiceList]
            }
        }); 
    }

    handleSelectAll = () => {
        let selectedInvoices = new Array<number>;  
        let selectedFees =  new Array<feeObj>; 

        if(!this.state.selectAll) {
            selectedInvoices = this.state.reconcileList.resourceList.map(x => x.invoiceId); 
            selectedFees = this.getAllInvoiceFees(this.state.reconcileList.resourceList).map(i => ({invoiceFeeId: i.invoiceFeeId, invoiceId: this.getInvoiceIdByFeeId(i.invoiceFeeId), feeAmt: i.paymentAmt}));
        }
        this.setState({
            selectAll: !this.state.selectAll, 
            selectedInvoices: selectedInvoices,
            selectedFees: selectedFees
        });         
    }

    // local helpers
    getInvoiceIdByFeeId = (invoiceFeeId: number) => this.state.reconcileList.resourceList.find(x => x.invoiceFees?.find(y => y.invoiceFeeId === invoiceFeeId))?.invoiceId!; 
    numberArrayChecker = (arr: number[], target: number[]) => target.every(v => arr.includes(v)); 
    getAllInvoiceFees = (invoices: models.Invoice[]) => invoices.reduce((c, v) => c.concat(v.invoiceFees!), new Array<models.InvoiceFee>());     
    sumPaymentTotals = (invoiceFees: models.InvoiceFee[]) => invoiceFees.reduce((a, c) => a = a + c.total, 0); 
    sumPaymentAmounts = (invoiceFees: models.InvoiceFee[]) => invoiceFees.reduce((a, c) => a = a + c.paymentAmt, 0); 
    sumPayments2 = (payments: {id: number, amount: number}[]) => payments.reduce((a, b) => a + b.amount, 0);     

    addFeesByInvoice = (invoiceId: number) => {
        const invoice = this.state.reconcileList.resourceList.find(x => x.invoiceId === invoiceId)!; 
        const invoiceFees = this.state.reconcileList.resourceList.find(x => x.invoiceId === invoiceId)?.invoiceFees!;         
        return this.state.selectedFees.concat(invoiceFees.map(i => ({invoiceFeeId: i.invoiceFeeId, invoiceId: invoice.invoiceId, feeAmt: i.paymentAmt}))); 
    }

    checkInvoiceAllFeeFlag = (invoiceId: number, feesList: feeObj[]) => {        
        // determine if all fees are selected, if so, make sure invoice is selected if not, deselect invoice...        
        const invoice = this.state.reconcileList.resourceList.find(x => x.invoiceId === invoiceId);
        const invoiceFeeIds = invoice?.invoiceFees?.map(x => x.invoiceFeeId) || new Array<number>();         
        const areAllFeesChecked = this.numberArrayChecker(feesList.map(x => x.invoiceFeeId), invoiceFeeIds);
        if (!areAllFeesChecked) {
            return this.state.selectedInvoices.filter(x => x !== invoice?.invoiceId); 
            
        }         
        return this.state.selectedInvoices.concat(invoice?.invoiceId!); 
    }
 
    checkAllInvoicesSelected = () => {
        let i: number = 0; 
        const invoiceCount = this.state.reconcileList.totalCount; 
        let selectAll: boolean = true; 
        do {
            const invoice = this.state.reconcileList.resourceList[i]; 
            this.checkInvoiceAllFeeFlag(invoice.invoiceId, this.state.selectedFees); 
            i += 1;             
            if(i === 3) {
                selectAll = false;
            }
        } while(i < invoiceCount && selectAll); 
    }

    removeFeesByInvoice = (invoiceId: number) => {
        const invoiceFees = this.state.reconcileList.resourceList.find(x => x.invoiceId === invoiceId)?.invoiceFees?.map(a => a.invoiceFeeId); 
        const newFees = this.state.selectedFees.filter((fee) => {return !invoiceFees?.includes(fee.invoiceFeeId)});         
        return newFees; 
    }   

    public render() {
        const { isReconcileListLoading, showModal } = this.props; 
        const { payment, reconcileList, selectAll, selectedFees, selectedInvoices } = this.state;         

        const totalAmount = reconcileList?.resourceList.reduce((s, o) => s + this.sumPaymentTotals(o.invoiceFees!), 0);
        const remainingAmout = reconcileList?.resourceList.reduce((s, o) => s + this.sumPaymentAmounts(o.invoiceFees!), 0);
        const totalFees = reconcileList?.resourceList.reduce((s, o) => s + (o.invoiceFees?.length || 0), 0); 

        const selectedFeesAsIdArray = selectedFees.map(x => x.invoiceFeeId); 
        const combinedFees = this.getAllInvoiceFees(reconcileList.resourceList); 
        const filteredFees = combinedFees.filter(x => selectedFeesAsIdArray.includes(x.invoiceFeeId));                 
        const selectedAmt = this.sumPaymentAmounts(filteredFees); 
        const outstandingAmt = remainingAmout - selectedAmt; 
        const remainingPymtAmt = payment.amount - selectedAmt; 

        return (
            <>
            <Modal fullscreen={true} centered={true} show={showModal} onHide={this.resetFormStateAndClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Invoice Reconcile</Modal.Title>
                </Modal.Header>
                <Form 
                    className="claim-modal" 
                    onSubmit={this.submitForm}
                >
                    <Modal.Body>          
                        <CardGroup>
                            <Card className="claim-card">
                                <Card.Title className="inv-card-title">Payment Info</Card.Title>
                                <Card.Body>
                                    <Row className="vertical-center">
                                        <Col>
                                            <Form.Group controlId="paymentInfo" className="input-field-container2">                                                                                    
                                                    <Form.Label column >Info</Form.Label>                                        
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Check or Confirmation #..." 
                                                        value={payment.paymentInfo} 
                                                        onChange={(e) => this.setState({payment: {...payment, paymentInfo: e.target.value}})} 
                                                    />
                                            </Form.Group>
                                        </Col>                                    
                                        <Col>
                                            <Form.Group controlId="amt" className="input-field-container2">
                                                    <Form.Label>Amount</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control 
                                                            type="number" 
                                                            value={payment.amount || ''} 
                                                            onChange={(e) => this.setState({payment: {...payment, amount: parseFloat(e.target.value)}})} 
                                                        />
                                                    </InputGroup>
                                            </Form.Group>
                                        </Col>                                                                              
                                    </Row>                                    
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Group controlId="date" className="input-field-container2">
                                                    <Form.Label>Date</Form.Label>
                                                    <Form.Control                                                     
                                                        type="date" 
                                                        value={payment.paymentDate} 
                                                        onChange={(e) => this.setState({payment: {...payment, paymentDate: e.target.value}})} 
                                                    />
                                            </Form.Group>                                                 
                                        </Col>  
                                        <Col sm={8}>
                                        <Form.Group controlId="comment" className="input-field-container2">
                                                <Form.Label>Comment</Form.Label>
                                                <Form.Control                                                     
                                                    as="textarea" 
                                                    value={payment.comments || ""} 
                                                    onChange={(e) => this.setState({payment: {...payment, comments: e.target.value}})} 
                                                />
                                        </Form.Group>                                                 
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="claim-card">
                                <Card.Title className="inv-card-title">{reconcileList.resourceList[0]?.invoiceGroup && reconcileList.resourceList[0]?.invoiceGroup + " Bulk Inv. - "} Applied Payment Details</Card.Title>
                                <Card.Body>
                                    <Row>
                                        <Col>Invoices:</Col>
                                        <Col><NumberFormat value={reconcileList.totalCount} displayType={'text'} thousandSeparator={true} /></Col>
                                        <Col>Selected:</Col>
                                        <Col><NumberFormat value={selectedInvoices.length} displayType={'text'} thousandSeparator={true}/></Col>
                                    </Row>
                                    <Row>
                                        <Col>Total Fees:</Col>
                                        <Col><NumberFormat value={totalFees} displayType={'text'} thousandSeparator={true} /></Col>
                                        <Col>Selected Fees:</Col>
                                        <Col><NumberFormat value={selectedFees.length} displayType={'text'} thousandSeparator={true}/></Col>
                                    </Row>
                                    <Row>
                                        <Col>Total Amt:</Col>
                                        <Col><NumberFormat value={totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                        <Col>Selected Amt:</Col>
                                        <Col><NumberFormat value={selectedAmt.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                    </Row>
                                    <div style={{marginTop: "3em"}}></div>
                                    <Row>
                                        <Col>Remaining Pymt:</Col>
                                        <Col><NumberFormat value={remainingPymtAmt.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                        <Col>Remaining Balance:</Col>
                                        <Col><NumberFormat value={outstandingAmt.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                    </Row>
                                </Card.Body>
                            </Card>                            
                        </CardGroup>
                        <Card className="claim-card">
                            <Card.Title className="inv-card-title">                                
                                Invoices
                                {isReconcileListLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                            </Card.Title>
                            <Card.Body>
                                <Form.Group as={Col} controlId="selectAll">
                                    <Form.Check 
                                        name="selectAll"
                                        type="checkbox"
                                        id="selectAll"
                                        label="Select All Invoices/Fees"
                                        checked={selectAll}
                                        onChange={() => this.handleSelectAll()}
                                    />                                
                                </Form.Group>
                                <Accordion className="inv-acc-card" alwaysOpen>
                                    {reconcileList?.resourceList?.map((x:models.Invoice, i:number) => {
                                        const feeTotal = x.invoiceFees?.reduce((a, c) => a = a + c.total, 0) || 0; 
                                        const selectedFeeTotal = x.invoiceFees?.filter(x => selectedFeesAsIdArray.includes(x.invoiceFeeId)).reduce((a, c) => a = a + c.paymentAmt, 0) || 0; 

                                        return (
                                            <Accordion.Item key={i} eventKey={x.invoiceNumber}>
                                                <Accordion.Header className="inv-acc-hdr"> 
                                                    <Container fluid>
                                                        <Row>
                                                            <Col sm={1}>Claim #:</Col>
                                                            <Col sm={1}>{x.claimNumber}</Col>
                                                            {selectedInvoices.includes(x.invoiceId) && 
                                                                <Col><img style={{height: "15px"}} src={checkIcon}/>(All fees selected)</Col>
                                                            }
                                                        </Row>
                                                        <Row>
                                                            <Col sm={1}>Total:</Col>
                                                            <Col>
                                                                <NumberFormat value={feeTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>                                                    
                                                            </Col>
                                                        </Row>
                                                        <Row>                                                        
                                                            <Col sm={1}>Available:</Col>
                                                            <Col sm={1}>
                                                                <NumberFormat value={x.outstandingBalance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                                                            </Col>
                                                            <Col sm={1}>Selected:</Col>
                                                            <Col sm={1}>
                                                                <NumberFormat value={selectedFeeTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>                                                    
                                                            </Col>
                                                        </Row>                                                        

                                                    </Container>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Table>
                                                        <thead>
                                                            <tr className="claim-table-header">
                                                                <th id="isChecked">
                                                                    <Form.Group as={Col} controlId="selectAll">
                                                                        <Form.Check 
                                                                            name="selectAll"
                                                                            type="checkbox"
                                                                            id="selectAll"
                                                                            checked={selectedInvoices.includes(x.invoiceId)}
                                                                            onChange={() => this.handleToggleInvoice(x.invoiceId)}
                                                                        />                                
                                                                    </Form.Group>
                                                                </th>
                                                                <th id="invoiceNumber">Invoice Number</th>
                                                                <th id="invoiceGroup">Invoice Group</th>
                                                                <th id="claimNumber">Claim Number</th>                                
                                                                <th id="insCompany">Ins Company</th>
                                                                <th id="adjusterName">Adjuster</th>
                                                                <th id="invoiceDate">Invoice Date</th>
                                                                <th id="outstandingBalance">Outstanding Balance</th>
                                                                <th id="grandTotal">Grand Total</th>                                        
                                                            </tr>                                    
                                                        </thead>
                                                        <tbody>
                                                            <tr key={x.invoiceId}>
                                                                <td></td>
                                                                <td>{x.invoiceNumber}</td>
                                                                <td>{x.invoiceGroup}</td>
                                                                <td>{x.claimNumber}</td>
                                                                <td>{x.insCompany}</td>
                                                                <td>{x.adjuster}</td>
                                                                <td>{x.invoiceDate && dateFormat(x.invoiceDate, 'mm/dd/yyyy')}</td>
                                                                <td><NumberFormat value={x.outstandingBalance?.toFixed(2) || 0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                                <td><NumberFormat value={x.grandTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>                                                                                        
                                                    <Table striped>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Fee</th>
                                                                <th>Qty</th>
                                                                <th>Rate</th>
                                                                <th>Total</th>
                                                                <th>Paid</th>
                                                                <th>Balance</th>
                                                                <th style={{width: "10em"}}>Payment Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {x.invoiceFees?.map((f:models.InvoiceFee, n:number) => {
                                                                const paid = f.payments?.reduce((a, b) => a + b.amount, 0) || 0; 
                                                                const balance = f.total - paid; 
                                                                return (
                                                                    <tr key={f.invoiceFeeId}>
                                                                        <td>
                                                                            <Form.Group as={Col} controlId="selectFee">
                                                                                <Form.Check 
                                                                                    name="selectAFee"
                                                                                    type="checkbox"
                                                                                    id="selectFee"
                                                                                    checked={selectedFeesAsIdArray.includes(f.invoiceFeeId)}
                                                                                    onChange={() => this.handleToggleFee(f.invoiceFeeId, f.paymentAmt)}
                                                                                />                                
                                                                            </Form.Group>
                                                                        </td>
                                                                        <td>{f.feeName}</td>
                                                                        <td>{f.quantity}</td>
                                                                        <td><NumberFormat value={f.rate.toFixed(2) || 0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                                        <td><NumberFormat value={f.total.toFixed(2) || 0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                                        <td><NumberFormat value={paid.toFixed(2) || 0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                                        <td><NumberFormat value={balance.toFixed(2) || 0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                                                                        <td>
                                                                            <Form.Group as={Col} className='inv-rcn-fee-amt'>
                                                                                <Form.Control 
                                                                                    name="paymnetAmt"
                                                                                    type="number"
                                                                                    id="selectFee"
                                                                                    value={f.paymentAmt || ''}
                                                                                    onChange={(e) => this.handlePaymentAmtChange(f.invoiceFeeId, parseFloat(e.target.value))}
                                                                                />                                
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                ); 
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ); 
                                    })}
                                </Accordion>      
                            </Card.Body>                      
                        </Card>                        
                    </Modal.Body>                
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            APPLY
                        </Button>
                    </Modal.Footer>
                </Form>    
            </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    payment: state.financials.payments.payment,
    reconcileList: state.financials.payments.reconcileList,
    isReconcileListLoading: state.financials.payments.isReconcileListLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            clearReconcileList: financialsActions.clearReconcileList, 
            clearSelectedInvoices: financialsActions.clearSelectedInvoices, 
            createPayment: financialsActions.createPayment,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Reconcile);