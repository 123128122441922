import MyTypes from 'MyTypes';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Alert, Button, Card, Col, Form, Image, Modal, Row, Spinner, Toast, ToastContainer } from 'react-bootstrap'; 
import PageHeader from 'components/common/PageHeader';
import PhoneNumberFormCard from '../common/PhoneNumberFormCard'; 
import * as models from '../../models';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg'; 
import { locationActions } from 'store/locations';
import '../../assets/styles/MyProfile.css'; 
import services from 'services';
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import { userActions } from 'store/user';
import { claimsActions } from 'store/claims';
import { CLAIM_ACTION_STATUSES } from 'store/enums'; 

interface Props extends PropsFromRedux {
    showModal: boolean;
    closeModal: () => void;  
}

interface State {
    activeTab: string,
    me: models.Contact, 
    disableSave: boolean, 
    disableSyncBtn: boolean,     
    isLoading: boolean,
    showSuccess: boolean,  
    validated: boolean;
    zipSync: boolean, 
}

class Profile extends React.PureComponent<Props, State> {
    public getDefaultState = () => {
        return {
            activeTab: "Details",
            me: {...this.props.me},
            disableSave: true, 
            disableSyncBtn: true, 
            isLoading: true, 
            showSuccess: false,
            validated: false,
            zipSync: false, 
        }
    }

    public state = this.getDefaultState(); 

    componentDidUpdate(prevProps: any, prevState: any) {
        if(prevProps.lastActionStatus === CLAIM_ACTION_STATUSES.UPDATING_PROFILE && this.props.lastActionStatus === CLAIM_ACTION_STATUSES.UPDATING_PROFILE_SUCCESS) {
            this.setState({showSuccess: true});             
        }
        if (!_.isEqual(prevProps.me, this.props.me)) {  
            this.setState({
                disableSyncBtn: false,
                isLoading: false,  
                me: {...this.props.me},
            }); 
        }
        if(!_.isEqual(prevProps.showModal, this.props.showModal) && this.props.me.contactId > 0) {
            this.setState({isLoading: false, disableSyncBtn: false}); 
        }
        if(!_.isEqual(prevProps.zipSearch, this.props.zipSearch) && this.state.zipSync){
            this.setState({
                me: {
                    ...this.state.me,
                    mailingAddress: {
                        ...this.props.me.mailingAddress,
                        city: this.props.zipSearch.cityName,
                        state: this.props.zipSearch.stateName,
                    }                    
                },
                zipSync: false, 
            }); 
        }
    }
           
    public handleTabs = (name: string) => {
        this.setState({ activeTab: name });
    };

    public resetFormStateAndClose = () => {
        this.setState(this.getDefaultState()); 
        this.props.closeModal(); 
    }

    public updatePhoneNumbers = (e: any, type: string) => {                        
        const { phoneNumbers } = this.state.me; 
        const number = e.value; 
        let newPhoneNumbers: models.PhoneNumber[]; 

        newPhoneNumbers = phoneNumbers.filter(x => x.phoneNumberType != type);

        if(number.length != 0) {
            const thisNumber: models.PhoneNumber = {
                phoneNumberType: type, 
                phoneNumber: number,
            }
            newPhoneNumbers.push(thisNumber); 
        }        
        this.setState({disableSave: false, me: {...this.state.me, phoneNumbers: newPhoneNumbers}}); 
    }

    public syncContactFromConnect = async () => {
        this.setState({isLoading: true}); 
        const connectAdjuster = await services.api.contact.getConnectAdjuster(this.state.me.contactId); 
        if (connectAdjuster.isSuccess) {            
            this.updatePhoneNumbers({value: connectAdjuster.data.mobilePhone}, "Cell"); 
            if (connectAdjuster.data.zip != null) {
                this.props.requestZipSearch(connectAdjuster.data.zip); 
            }
            this.setState({
                disableSave: false,
                isLoading: false, 
                me: {
                    ...this.state.me,
                    adjusterPercents: {
                        serviceFeePercent: parseInt(connectAdjuster.data.serviceFeePercent),
                        tAndEPercent: parseInt(connectAdjuster.data.tAndEPercent),
                        photosPercent: parseInt(connectAdjuster.data.photosPercent),
                        mileagePercent: parseInt(connectAdjuster.data.mileagePercent),
                        miscExpensePercent: parseInt(connectAdjuster.data.miscExpensePercent),
                    }, 
                    firstName: connectAdjuster.data.firstName,
                    lastName: connectAdjuster.data.lastName,
                    preferredName: connectAdjuster.data.preferredName,
                    email: connectAdjuster.data.email,
                    compassEmail: connectAdjuster.data.compassEmail,
                    middleName: connectAdjuster.data.middleName,
                    xnAddress: connectAdjuster.data.xactnetAddress,
                    mailingAddress: {
                        ...this.state.me.mailingAddress,
                        addressId: this.props.me.mailingAddress.addressId,
                        addressType: "Mailing", 
                        addressLine1: connectAdjuster.data.addressLine1,
                        addressLine2: connectAdjuster.data.addressLine2,
                        postalCode: connectAdjuster.data.zip,
                    }
                },
                zipSync: true, 
            }); 
        }
        else {
            this.setState({isLoading: false}); 
            this.props.toastSet(["Could not find user in Connect."]); 
        }        
    }

    public submitForm = async (e:any) => {
        e.preventDefault(); 
        const patchDocument = createPatch(this.props.me, this.state.me);
        console.log(patchDocument); 
        await this.props.updateProfile(this.props.me.contactId, patchDocument); 
    }

    public clearError = () => {
        this.setState({validated: false}); 
        this.props.toastClear(); 
    }

    public renderTab = () => {
        const { activeTab, me } = this.state;
        const { isStateAbbrDDLoading, stateList, requestZipSearch } = this.props; 

        switch (activeTab) {
            case "Details":
                return (
                    <>                    
                    <Card style={{margin: "1em"}} className="claim-card">
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container2">
                            <Form.Label column sm="4">First Name</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    placeholder='Enter First Name...' 
                                    value={me.firstName}
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, firstName: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container2">
                            <Form.Label column sm="4">Preferred Name</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    placeholder='Enter Preferred Name...' 
                                    value={me.preferredName}
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, preferredName: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container2">
                            <Form.Label column sm="4">Last Name</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    placeholder='Enter Last Name...' 
                                    value={me.lastName}
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, lastName: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container2">                                
                            <Form.Label column sm="4" >XN Addresses</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    placeholder='Enter XN Address...' 
                                    value={me.xnAddress || ''}
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, xnAddress: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container2">                                
                            <Form.Label column sm="4" >XN Addresses 2</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    placeholder='Enter XN Address 2...' 
                                    value={me.xnAddress2 || ''}
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, xnAddress2: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                    </Card>
                    <Card style={{margin: "0 1em .5em 1em"}} className="claim-card">
                        <Row>
                            <Form.Group as={Col} controlId="bulkName" className="input-field-container">
                                <Form.Label column sm="4">Service Fee %</Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        disabled={true}
                                        type="text" 
                                        placeholder='Enter Service Fee %...' 
                                        value={me.adjusterPercents?.serviceFeePercent}                                        
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Col} controlId="bulkName" className="input-field-container">
                                <Form.Label column sm="4">T&E %</Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        disabled={true}
                                        type="text" 
                                        placeholder='Enter T&E %...' 
                                        value={me.adjusterPercents?.tAndEPercent}                                        
                                    />
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="bulkName" className="input-field-container">
                                <Form.Label column sm="4">Photos %</Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        disabled={true}
                                        type="text" 
                                        placeholder='Enter Photos %...' 
                                        value={me.adjusterPercents?.photosPercent}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Col} controlId="bulkName" className="input-field-container">
                                <Form.Label column sm="4">Mileage %</Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        disabled={true}
                                        type="text" 
                                        placeholder='Enter Mileage %...' 
                                        value={me.adjusterPercents?.mileagePercent}
                                    />
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="bulkName" className="input-field-container">
                                <Form.Label column sm="4">Misc %</Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        disabled={true}
                                        type="text" 
                                        placeholder='Enter Misc %...' 
                                        value={me.adjusterPercents?.miscExpensePercent}
                                    />
                                </Col>
                            </Form.Group>
                            <Col></Col>                            
                        </Row>                        
                    </Card>
                    </>
                );
            case "Address":
                return (
                    <Card style={{margin: "1em"}} className="claim-card">
                        <Form.Group as={Row} controlId="mailingAddress1" className="input-field-container2">
                            <Form.Label column sm="4">Address Line 1</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    value={me.mailingAddress?.addressLine1 || ""} 
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, mailingAddress: {...me.mailingAddress, addressLine1: e.target.value}}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="mailingAddress2" className="input-field-container2">
                            <Form.Label column sm="4">Address Line 2</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    value={me.mailingAddress?.addressLine2 || ""} 
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, mailingAddress: {...me.mailingAddress, addressLine2: e.target.value}}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="mailingZip" className="input-field-container2">
                            <Form.Label column sm="4">Zip Code</Form.Label>                            
                                <Col sm="4" className="flush-right">
                                    <Form.Control 
                                        disabled={true}
                                        type="text"
                                        value={me?.mailingAddress?.postalCode || ""} 
                                        onChange={(e) => this.setState({disableSave: false, me: {...me, mailingAddress: {...me.mailingAddress, postalCode: e.target.value}}})} 
                                    />
                                </Col>
                                <Col sm="2" className="inpput-search-col">
                                    <Button 
                                        className="input-search-btn" 
                                        onClick={() => requestZipSearch(me.mailingAddress.postalCode)}>
                                            <Image src={searchIcon}/>
                                    </Button>
                                </Col>
                        </Form.Group>          
                        <Form.Group as={Row} controlId="maillingCity" className="input-field-container2">
                            <Form.Label column sm="4">City</Form.Label>
                            <Col sm="5">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    value={me.mailingAddress?.city || ""} 
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, mailingAddress: {...me.mailingAddress, city: e.target.value}}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="mailingState" className="input-field-container2">
                            <Form.Label column sm="4">State</Form.Label>
                            <Col sm="5">
                                <Form.Select 
                                    disabled={true}
                                    name="statesList" 
                                    value={me.mailingAddress?.state || ""} 
                                    onChange={(e) => this.setState({disableSave: false, me: {...me, mailingAddress: {...me.mailingAddress, state: e.target.value}}})} 
                                >
                                    {isStateAbbrDDLoading && <option>Loading...</option>}
                                    {!isStateAbbrDDLoading && <option key={-1}>Choose...</option>}
                                    {stateList.totalCount > 0 && stateList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                        <option key={i} value={c.name} >{c.name}</option>
                                    ))}
                            </Form.Select>  
                            </Col>
                        </Form.Group>                                                
                    </Card>
                );
            case "Contact Info":
                return (
                    <>
                    <Card style={{margin: "0 1em .5em 1em"}} className="claim-card">
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container2">
                            <Form.Label column sm="4">Email</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    placeholder='Enter Email...' 
                                    value={me.email}
                                    onChange={(e) => this.setState({disableSave: true, me: {...me, email: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container2">
                            <Form.Label column sm="4">Compass Email</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    disabled={true}
                                    type="text" 
                                    placeholder='Enter Compass Email...' 
                                    value={me.compassEmail || ''}
                                    onChange={(e) => this.setState({disableSave: true, me: {...me, compassEmail: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                    </Card>                    
                    <div style={{margin: "0 1em 0 1em"}}>
                        <PhoneNumberFormCard 
                            enableEdit={false}
                            validTypes={["Home", "Cell", "Business"]}
                            phoneNumbers={me.phoneNumbers}
                            handleChange={this.updatePhoneNumbers}
                        />
                    </div>
                    </>
                );
        }
    }

    public render() {
        const { showModal, toastMessage, toastShow } = this.props; 
        const { disableSave, disableSyncBtn, isLoading, showSuccess } = this.state; 

        const tabs: string[] = ["Details","Address","Contact Info"]; 
        return (
            <Modal size="lg" show={showModal} onHide={this.resetFormStateAndClose} >
                <Modal.Header closeButton>
                    <Modal.Title>User Profile
                        {isLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    </Modal.Title>
                </Modal.Header>
                <ToastContainer position='middle-center'>
                    <Toast show={toastShow} onClose={this.clearError}>
                        <Toast.Header style={{color: "red"}} closeButton>"Profile Edit Issue!"</Toast.Header>
                        <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                    <Toast autohide delay={1500} show={showSuccess} onClose={this.resetFormStateAndClose}>
                        <Toast.Body style={{backgroundColor: 'green', color: 'white'}}>Profile Updated</Toast.Body>
                    </Toast>
                </ToastContainer>
                <Form 
                    className="claim-modal" 
                    onSubmit={this.submitForm}
                >
                    <Modal.Body>
                        <Row>
                            <Col sm="8">
                                <div style={{padding: "1.5em", margin: "0"}}>
                                    <PageHeader
                                        getTabName={this.handleTabs}
                                        tabs={tabs}
                                    />                        
                                </div>                
                            </Col>
                            <Col style={{display: "flex", alignItems: "center"}}>
                                <Button                             
                                    className="btn-sync" 
                                    disabled={disableSyncBtn}
                                    onClick={this.syncContactFromConnect}
                                    >SYNC FROM CONNECT
                                </Button>                    
                            </Col>
                        </Row>
                        {this.renderTab()}
                    </Modal.Body>                    
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit" disabled={disableSave}>
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>                                
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    me: state.user.user.currentUser,
    cityList: state.locations.locations.cityList,
    countyList: state.locations.locations.countyList,
    isStateAbbrDDLoading: state.locations.dropdowns.isStateDDLoading,
    lastActionStatus: state.claims.claim.lastActionStatus,
    stateList: state.locations.dropdowns.stateDD, 
    toastMessage: state.claims.claim.toastMessage,
    toastShow: state.claims.claim.toastShow, 
    zipSearch: state.locations.locations.zipSearch 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestCurrentUser: userActions.requestCurrentUser, 
            requestZipSearch: locationActions.requestZipSearch,            
            toastClear: claimsActions.toastClear,
            toastSet: claimsActions.toastSet, 
            updateProfile: userActions.updateProfile, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Profile);
