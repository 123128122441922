import * as React from 'react'; 
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import Chart from 'react-apexcharts'; 
import services from '../../services';
import * as models from '../../models'; 
import { claimsActions } from 'store/claims';
import { dashboardActions } from 'store/dashboard'; 
import _ from 'lodash'; 

interface Props extends PropsFromRedux {
}

interface State {
    series: any;  
    options: any; 
}

class MonthlyClaims2 extends React.PureComponent<Props, State> {
    public state = {
        options: {},
        series: []        
    }

    public componentDidMount = async () => {
        const { monthlyClaimActivity } = this.props; 
        if(monthlyClaimActivity.totalCount === 0) {
            this.props.requestMonthlyClaimActivity(11,12);
        }
        if (monthlyClaimActivity.totalCount > 0)
            this.loadDataToChart(); 
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if(!_.isEqual(prevProps.monthlyClaimActivity, this.props.monthlyClaimActivity) && this.props.monthlyClaimActivity.totalCount > 0){
            this.loadDataToChart(); 
        }        
    }

    loadDataToChart = () => {
        const { monthlyClaimActivity } = this.props;             
        const months: string[] = monthlyClaimActivity.dataSet!.map(x => {return x.month}); 
        const claimsOpened: number[] =  monthlyClaimActivity.dataSet!.map(x => {return x.claimsOpened}); 
        const claimsClosed: number[] =  monthlyClaimActivity.dataSet!.map(x => {return x.claimsClosed});             

        this.setState({
            options: {
                title: {
                    text: "Monthly Claim Activity",
                    align: 'center',                    
                },
                labels: months,
                stroke: {
                    width: [0, 4]
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                chart: {                    
                    zoom: {
                        enabled: false,
                    },
                    events: {
                        dataPointSelection: (event: any, chartContext: any, config: any) => {                                                    
                            this.getClaims(months[config.dataPointIndex], config.seriesIndex);
                        }
                    }
                }, 
            }, 
            series: [
                {
                    name: 'Claims Opened',
                    type: 'column',
                    data: claimsOpened
                }, 
                {
                    name: 'Claims Closed',
                    type: 'line',
                    data: claimsClosed
                }
            ],
        }); 
    }

    getMonthRange = (monthName: string, series: number) => {
        const year = monthName.split("-")[0]; 
        const datePair = series === 0 ? "received" : "closed"; 
        switch (monthName.split("-")[1].toLowerCase()) {
            case "jan": 
                return {[datePair+"DateFrom"]: year + "-01-01", [datePair+"DateTo"]: year + "-01-31"}; 
            case "feb":
                return {[datePair+"DateFrom"]: year + "-02-01", [datePair+"DateTo"]: year + "-02-28"}; 
            case "mar":
                return {[datePair+"DateFrom"]: year + "-03-01", [datePair+"DateTo"]: year + "-03-31"}; 
            case "apr":
                return {[datePair+"DateFrom"]: year + "-04-01", [datePair+"DateTo"]: year + "-04-30"}; 
            case "may":
                return {[datePair+"DateFrom"]: year + "-05-01", [datePair+"DateTo"]: year + "-05-31"}; 
            case "jun":
                return {[datePair+"DateFrom"]: year + "-06-01", [datePair+"DateTo"]: year + "-06-30"}; 
            case "jul":
                return {[datePair+"DateFrom"]: year + "-07-01", [datePair+"DateTo"]: year + "-07-31"}; 
            case "aug":
                return {[datePair+"DateFrom"]: year + "-08-01", [datePair+"DateTo"]: year + "-08-31"}; 
            case "sept":
                return {[datePair+"DateFrom"]: year + "-09-01", [datePair+"DateTo"]: year + "-09-30"}; 
            case "oct":
                return {[datePair+"DateFrom"]: year + "-10-01", [datePair+"DateTo"]: year + "-10-31"}; 
            case "nov":
                return {[datePair+"DateFrom"]: year + "-11-01", [datePair+"DateTo"]: year + "-11-30"}; 
            case "dec":
                return {[datePair+"DateFrom"]: year + "-12-01", [datePair+"DateTo"]: year + "-12-31"}; 
        }
    }

    getClaims = (monthName: string, series: number) => {        
        const claimsRequest: models.ClaimsRequest = {
            ...models.defaultClaimsRequest, 
            ...this.getMonthRange(monthName, series),            
        }
        this.props.requestClaims(claimsRequest); 
        services.router.goto(services.router.url.claimList); 
    }    

    render() {
        return (
            <div id="chart">
                <Chart 
                    options={this.state.options} 
                    series={this.state.series} 
                    type="line" 
                    height={350} 
                />
            </div>     
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    monthlyClaimActivity: state.dashboards.dashboards.monthlyClaimsActivity,
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestClaims: claimsActions.requestClaims,
            requestMonthlyClaimActivity: dashboardActions.requestMonthlyClaimActivity,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(MonthlyClaims2); 