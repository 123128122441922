import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col, Image } from 'react-bootstrap'; 
import { adminActions } from 'store/admin';
import { claimsActions } from 'store/claims'; 
import * as models from '../../models'; 
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg'; 
import { locationActions } from 'store/locations';

interface Props extends PropsFromRedux {  
    showModal: boolean;
    closeModal: () => void; 
}

interface State {    
    claim: models.ClaimSimple, 
    lossLocation: models.Address, 
    zipSearch: models.ZipSearch, 
}

class LossInformationModal extends React.Component<Props, State> {
    public state = {
        claim: this.props.claim,
        lossLocation: this.props.lossLocation, 
        zipSearch: models.defaultZipSearch,
    };

    componentDidMount() {     
        this.setState({zipSearch: this.props.zipSearch}); 
    }

    componentDidUpdate(prevProps: any) {
        if (  !_.isEqual(prevProps.claim, this.props.claim)) {
            this.setState({claim: {...this.props.claim}});
        }

        if(!_.isEqual(prevProps.lossLocation, this.props.lossLocation)) {
            this.setState({lossLocation: {...this.props.lossLocation}}); 
        }

        if (  !_.isEqual(prevProps.zipSearch, this.props.zipSearch)) { 
            const newAddress: models.Address = {
                ...this.state.lossLocation
                , city: this.props.zipSearch.cityName
                , state: this.props.zipSearch.stateName
                , postalCode: this.props.zipSearch.zipCode
            }
            this.setState({lossLocation: newAddress}); 
        }
    }

    public zipSearch = (zipCode: string) => {
        this.props.requestZipSearch(zipCode); 
    }

    public resetFormStateAndClose = () => {
        this.setState({claim: this.props.claim});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault(); 
        const claimId = this.props.claim.claimID;         
        
        const patchDocument = createPatch(this.props.claim, this.state.claim);
        if (patchDocument.length > 0)             
            this.props.patchClaim(claimId, patchDocument);
        
        const patchDocument2 = createPatch(this.props.lossLocation, this.state.lossLocation); 
        if (patchDocument2.length > 0) {             
            this.props.patchLossLocation(claimId, patchDocument2); 
        }

        this.props.closeModal();
    }

    public render() {
        const { closeModal, isStateAbbrDDLoading, lossCausesDD, showModal, stateList } = this.props; 
        const { claim, lossLocation } = this.state; 

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Loss Info</Modal.Title>
                </Modal.Header>                
                <Form className="claim-modal" onSubmit={this.submitForm}>
                    <Modal.Body>
                        <Form.Group as={Row} controlId="lossCause" className="input-field-container">
                            <Form.Label column sm="4">Cause</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="causesList" 
                                    value={claim?.lossCause || ""} 
                                    onChange={(e) => this.setState({claim: {...claim, lossCause: e.target.value}})} >
                                        <option key={-1}>Choose...</option>
                                        {lossCausesDD.totalCount > 0 && lossCausesDD.resourceList.map((c: models.Cause, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                </Form.Select>  
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="lossDescription" className="input-field-container">
                            <Form.Label column sm="4">Loss Description</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="text" 
                                    value={claim?.lossDescription || ""} 
                                    onChange={(e) => this.setState({claim: {...claim, lossDescription: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="lossAddress1" className="input-field-container">
                            <Form.Label column sm="4">Address Line 1</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="text" 
                                    value={lossLocation?.addressLine1 || ""} 
                                    onChange={(e) => this.setState({lossLocation: {...lossLocation, addressLine1: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="lossAddress2" className="input-field-container">
                            <Form.Label column sm="4">Address Line 2</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="text"
                                    value={lossLocation?.addressLine2 || ""}  
                                    onChange={(e) => this.setState({lossLocation: {...lossLocation, addressLine2: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="lossZip" className="input-field-container">
                            <Form.Label column sm="4">Zip Code</Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    value={lossLocation?.postalCode || ""} 
                                    onChange={(e) => this.setState({lossLocation: {...lossLocation, postalCode: e.target.value}})} 
                                />
                            </Col>
                            <Col sm="2" className="inpput-search-col">
                                <Button 
                                    className="input-search-btn" 
                                    onClick={() => this.zipSearch(lossLocation.postalCode)}>
                                        <Image src={searchIcon}/>
                                </Button>
                            </Col>
                        </Form.Group>                        
                        <Form.Group as={Row} controlId="lossCity" className="input-field-container">
                            <Form.Label column sm="4">City</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="text" 
                                    value={lossLocation?.city || ""} 
                                    onChange={(e) => this.setState({lossLocation: {...lossLocation, city: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="lossState" className="input-field-container">
                            <Form.Label column sm="4">State</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="statesList" 
                                    value={lossLocation?.state || ""} 
                                    onChange={(e) => this.setState({lossLocation: {...lossLocation, state: e.target.value}})} >
                                        {isStateAbbrDDLoading && <option>Loading...</option>}
                                        {!isStateAbbrDDLoading && <option key={-1}>Choose...</option>}
                                        {stateList.totalCount > 0 && stateList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                </Form.Select>  
                            </Col>
                        </Form.Group>                                            
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                        CANCEL
                    </Button>
                    <Button variant="primary" type="submit">
                        SAVE
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({    
    claim: state.claims.claim.claim,
    isStateAbbrDDLoading: state.locations.dropdowns.isStateAbbrDDLoading,
    lossCausesDD: state.claims.dropdowns.lossCausesDD,
    lossLocation: state.claims.claim.lossLocation, 
    stateList: state.locations.dropdowns.stateDD, 
    zipSearch: state.locations.locations.zipSearch
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            patchClaim: claimsActions.patchClaim,
            patchLossLocation: claimsActions.patchLossLocation, 
            requestZipSearch: locationActions.requestZipSearch,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(LossInformationModal);