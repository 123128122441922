import * as React from 'react';
import { Table, Card } from 'react-bootstrap'; 
import * as models from '../../models';
import dateFormat from 'dateformat';
import addIcon from '../../assets/images/Blue/add.svg';
import SingleActionButton from '../common/SingleActionButton';
import CommentModal from './CommentModal'; 
import { CardBody } from 'reactstrap';
import { userHasPermission } from '../../utils/helpers';

interface Props {
    title: string;
    commentType: string; 
    comments: models.CommentsResponse;    
}

interface State {
    showModal: boolean; 
}

class Comments extends React.Component<Props, State> {  
    public state = {
        showModal: false, 
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    } 

    public render() {
        const { comments, title, commentType } = this.props; 
        const { showModal } = this.state; 
        const canEditClaim = userHasPermission("Can Edit Claims Info"); 

        return (
            <>
            <CommentModal
                    commentType={ commentType }
                    showModal={showModal}
                    closeModal={this.toggleModal}
                /> 
            <Card className="claim-card">
                <Card.Title>{title}
                    {canEditClaim && 
                        <SingleActionButton
                            action="Add"
                            title={title}
                            img={addIcon}
                            onClick={this.toggleModal}
                        />
                    }
                </Card.Title>
                <CardBody>
                    {comments.totalCount > 0 && 
                        <Table striped >
                            <thead>
                                <tr>
                                    <th style={{width:100}}>Modified Date</th>
                                    <th style={{width:100}}>Modified By</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>                           
                                {comments.resourceList.map((comment, i) => (
                                    <tr key={i}>                                    
                                        <td>{dateFormat(comment.modifiedDate, 'mm/dd/yyyy')}</td>
                                        <td>{comment.modifiedBy}</td>
                                        <td>{comment.comment}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        }
                </CardBody>
            </Card>
            </>
        );
    }
}

export default (Comments);