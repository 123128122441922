import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Form, Image, OverlayTrigger, Table, Tooltip, Card } from 'react-bootstrap';
import * as models from '../../models';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import SingleActionButton from 'components/common/SingleActionButton'; 
import downloadIcon from '../../assets/images/Blue/download.svg';
import sortDesc from '../../assets/images/sort-up.svg'; 
import sortAsc from '../../assets/images/sort-down-alt.svg'; 
import receivePymtIcon from '../../assets/images/icons8-receive-dollar-50.png'; 
import GearButton from 'components/common/GearButton';
import AddInvoiceToBulkModal from './AddInvoiceToBulkModal';
import Invoice from '../financials/Invoice'; 
import Reconcile from './Reconcile';
import QuickPayModal from './QuickPayModal';
import { financialsActions } from 'store/financials';
import _ from 'lodash'; 
import services from 'services';
import { claimsActions } from 'store/claims';
import { userHasPermission } from '../../utils/helpers';
import GetReasonModal from 'components/common/GetReasonModal';
import { USER_ACTIONS } from 'store/enums';

interface Props extends PropsFromRedux {
    title: string; 
    isSortable: boolean;     
}
interface InvoiceFeeShort {
    id: number; 
    amount: number;     
}

interface State {    
    claimId: number, 
    invoiceAction: string; 
    invoiceId: number, 
    invoiceShort: {
        title: string, 
        amount: number, 
        invoiceFees?: InvoiceFeeShort[],
    },     
    isDesc: boolean,    
    quickPayTitle: string, 
    selectAll: boolean,      
    showAddInvoiceToBulkModal: boolean;
    showGetReasonModal: boolean; 
    showInvoiceModal: boolean;
    showReconcileModal: boolean; 
    showQuickPayModal: boolean; 
    sortField: string,    
}

class InvoiceList extends React.Component<Props, State> {
    public state = {
        claimId: 0, 
        invoiceAction: '', 
        invoiceId: 0, 
        invoiceShort: {
            title: '',
            amount: 0, 
            invoiceFees: [],
        }, 
        sortField: this.props.invoicesRequest.sort,
        quickPayTitle: '', 
        isDesc: true, 
        showQuickPayModal: false,
        showAddInvoiceToBulkModal: false,
        showGetReasonModal: false, 
        showInvoiceModal: false, 
        showReconcileModal: false, 
        selectAll: false,          
    };

    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.lastUserAction, this.props.lastUserAction) && this.props.lastUserAction === USER_ACTIONS.INVOICE_ADD_CORRECTION_SUCCESS) {
            this.setState({invoiceId: 0}); 
            this.toggleGetReasonModal();             
        }        
        if (!_.isEqual(prevProps.selectedInvoices, this.props.selectedInvoices) || !_.isEqual(prevProps.invoicesListResponse, this.props.invoicesListResponse)) {
            this.evaluateSelectAllStatus(); 
        }        
        if (!_.isEqual(prevProps.claimId, this.props.claimId) && this.state.invoiceAction === 'create') {
            console.log(`Enter Create: ${this.props.claimId}`); 
            this.toggleInvoiceModal(); 
        }        
    } 
    
    public sortTable(e: any) {
        const currentSortField = this.props.invoicesRequest.sort.replace("-",""); 
        const newSortField = e.target.id; 
        if (newSortField !== 'selectAll') {        
            const isFieldChanged = !(newSortField == currentSortField); 
            let isDesc = this.state.isDesc ; 
            if (isFieldChanged) {
                isDesc = (isFieldChanged ? !this.state.isDesc : false); 
                this.setState({sortField: newSortField, isDesc: isDesc});
            }
            else {
                isDesc = !isDesc; 
                this.setState({isDesc: isDesc});
            }

            const invoicesRequest: models.InvoicesRequest = {
                ...this.props.invoicesRequest, 
                sort: (isDesc ? "-" : "") + (isFieldChanged ? newSortField : currentSortField)
            }
            this.props.requestInvoices(invoicesRequest); 
        }
    }

    public exportClaims = async () => {
        const exportQuery: models.InvoicesRequest = {
            ...this.props.invoicesRequest,
            pageNo: 1,
            pageSize: this.props.invoicesListResponse.totalCount,
        }
        const response = await this.props.requestInvoicesCSV(exportQuery); 
    }

    public evaluateSelectAllStatus = () => {
        let selectAll:boolean = this.state.selectAll; 
        if(this.props.selectedInvoices.length === this.props.invoicesListResponse.resourceList.length) {
            selectAll = true; 
        }
        else {
            selectAll = false;
        }        
        this.setState({selectAll: selectAll});
    }

    public toggleInvoice = (e:any) => {
        const { selectedInvoices } = this.props; 
        const invoiceId: number = parseInt(e.target.value); 
                
        if (!selectedInvoices.includes(invoiceId))
        {
            var invoiceList: number[] = selectedInvoices; 
            invoiceList.push(invoiceId); 
        }
        else invoiceList = selectedInvoices.filter(i => i !== invoiceId);         
        this.props.setSelectedInvoices(invoiceList); 
        this.evaluateSelectAllStatus(); 
    }

    public toggleSelectAll = () => {
        var invoiceList: number[] = this.props.selectedInvoices;  

        if (invoiceList.length === this.props.invoicesListResponse.resourceList.length) {
            invoiceList = new Array<number>();
        }
        else {            
            {this.props.invoicesListResponse.resourceList?.map((x: models.InvoicesList, i: number) => (
                !this.props.selectedInvoices.includes(x.invoiceId) && invoiceList.push(x.invoiceId)
            ))}
        }
        this.evaluateSelectAllStatus(); 
        this.props.setSelectedInvoices(invoiceList); 
    }        

    // Invoice Actions    
    public createInvoice = (invoiceId: number) => {                                    
        const claimId = this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === invoiceId)?.claimId; 
        if(claimId) {
            this.props.requestClaim(claimId);
            this.setState({claimId: claimId, invoiceAction: 'create', invoiceId: 0}); 
        }
    }

    public editInvoice = (invoiceId: number) => {
        const claimId = this.props.invoicesListResponse.resourceList.find(x => x.invoiceId == invoiceId)?.claimId; 
        if (claimId && claimId !== 0) {
            this.setState({invoiceAction: 'edit', invoiceId: invoiceId, claimId: claimId}); 
            this.toggleInvoiceModal(); 
        }
    }

    public handleCloseInvoice = (invoiceId: number) => {
        this.setState({invoiceId: invoiceId}); 
        this.toggleGetReasonModal(); 
    }   

    public closeInvoice = (reason: string) => {
        this.props.addInvoiceCorrection(this.state.invoiceId, {reason: reason});
    }  

    public handleQuickPay = async (i: models.InvoicesList) => {
        let props: {title: string, amount: number, invoiceFees: {id: number, amount: number}[]} = {title: '', amount: 0, invoiceFees: [{id: 0, amount: 0}]}; 
        if(i.invoiceGroup !== null) {
            const invoiceList = this.props.invoicesListResponse.resourceList.filter(x => x.invoiceGroup === i.invoiceGroup);             
            const feesList = invoiceList.map(a => a.invoiceFees).reduce((a, c) => a?.concat(c!)); 
            const invGrpTotal = invoiceList.reduce((a, c) => a = a + c.outstandingBalance, 0); ; 
            props = {title: 'Bulk: ' + i.invoiceGroup, amount: invGrpTotal, invoiceFees: feesList || new Array<{id: number, amount: number}>() }; 
        }
        else {
            const feeRequest: models.AdjusterFeeRequest = {
                invoiceId: i.invoiceId
            }
            const response = await services.api.financials.getAdjusterFeeList(feeRequest); 
    
            if(response.isSuccess){
                const adjusterFeeResponse: models.AdjusterFeeResponse = response.data; 
                props = {
                    title: 'Claim: ' + i.claimNumber, 
                    amount: i.outstandingBalance, 
                    invoiceFees: adjusterFeeResponse.resourceList.map(({invoiceFeeId, outstandingBalance}) => ({id: invoiceFeeId, amount: outstandingBalance})) || new Array<{id: number, amount: number}>()
                }; 
            }
            
        }        
        this.setState({invoiceShort: props}); 
        this.toggleQuickPayModal(); 
    }

    public handleReconcile = (invoiceId: number) => {
        const invoiceGroup = this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === invoiceId)?.invoiceGroup; 
        if (invoiceGroup === undefined || invoiceGroup === null) {            
            this.props.requestReconcileList([invoiceId]); 
        }
        else {
            this.props.requestReconcileListBulk(invoiceGroup);
        }
        this.toggleReconcileModal(); 
    }

    public handleShareInvoice = (invoiceId: number) => {
        alert("Comming soon..."); 
    }

    public removeInvoiceFromGroup = () => {
        if(this.props.selectedInvoiceCount === 0) {
            alert("You must select at least one invoice!"); 
            return; 
        }
        this.props.selectedInvoices.forEach(e => this.props.removeInvoiceFromGroup(e));
        this.props.requestInvoiceList(this.props.invoicesRequest); 
        this.props.clearSelectedInvoices(); 
    }        

    // Modal Toggles
    public fetchGroupsAndShowModal = () => {
        const invoiceGroupRequest: models.InvoiceGroupsRequest = {
            ...models.defaultInvoiceGroupsRequest,
            companyName: this.props.invoicesListResponse.resourceList.find(x => x.invoiceId === this.props.selectedInvoices[0])?.insCompany || ''
        }
        this.props.requestInvoiceGroupByCompany(invoiceGroupRequest); 
        this.setState({showAddInvoiceToBulkModal: !this.state.showAddInvoiceToBulkModal}); 
    }

    public toggleAddInvoiceToBulkModal = () => {        
        if(!this.state.showAddInvoiceToBulkModal) {
            const list = this.props.invoicesListResponse.resourceList.filter(x2 =>
                this.props.selectedInvoices.some(x1 => x2.invoiceId === x1)
            ); 
            
            if(this.props.selectedInvoiceCount === 0) {
                alert("You must select at least one invoice!"); 
                return; 
            }
                
            const distinctCompanies = new Set(list.map(a => a.insCompany)).size;
            if(distinctCompanies !== 1)
                alert("All invoices must be for the same company!"); 
            else this.fetchGroupsAndShowModal(); 
        }
        else this.fetchGroupsAndShowModal(); 
    }

    public toggleGetReasonModal = () => {
        this.setState({showGetReasonModal: !this.state.showGetReasonModal}); 
    }
    
    public toggleInvoiceModal = () => {
        if(this.state.showInvoiceModal) {
            this.setState({claimId: 0, invoiceAction: '', invoiceId: 0, showInvoiceModal: !this.state.showInvoiceModal}); 
        }
        else 
            this.setState({invoiceAction: '', showInvoiceModal: !this.state.showInvoiceModal});         
    }
    
    public toggleQuickPayModal = () => {
        this.setState({showQuickPayModal: !this.state.showQuickPayModal}); 
    }
    
    public toggleReconcileModal = () => {
        this.setState({showReconcileModal: !this.state.showReconcileModal}); 
    }   

    public render() {
        const { invoicesListResponse, isInvoiceListDownloading, isInvoiceListLoading, isSortable, title, selectedInvoices } = this.props;        
        const { 
            claimId,
            invoiceId,
            isDesc, 
            selectAll, 
            showAddInvoiceToBulkModal,
            showGetReasonModal, 
            showInvoiceModal,
            showReconcileModal,
            showQuickPayModal,             
            invoiceShort 
        } = this.state;

        const sortField = this.props.invoicesRequest.sort.replace("-", ""); 
        const sumGrandTotal = invoicesListResponse.resourceList.reduce((a, c) => a = a + c.grandTotal, 0); 
        const sumOutstandingBalance = invoicesListResponse.resourceList.reduce((a, c) => a = a + c.outstandingBalance, 0);         

        let buttonActions: models.ButtonAction[] = new Array<models.ButtonAction>();    
        if(selectedInvoices.length === 0){
            if(userHasPermission("Invoicing - Can Zero")) {
                buttonActions = buttonActions.concat({
                    name: 'Close', 
                    callBack: (e) => this.handleCloseInvoice(e)
                });            
            }
            if(userHasPermission("Can Edit Invoice")) {
                buttonActions = buttonActions.concat({
                    name: 'Edit', 
                    callBack: (e) => this.editInvoice(parseInt(e))
                }); 
            }
            if(userHasPermission("Can create invoices")) {
                buttonActions = buttonActions.concat({
                    name: 'New', 
                    callBack: (e) => this.createInvoice(parseInt(e))
                }); 
            }
            buttonActions = buttonActions.concat({
                name: 'Reconcile', 
                callBack: (e) => this.handleReconcile(parseInt(e))
            }); 
        }

        const buttonActions2: models.ButtonAction[] = [
            {
                name: 'Add To Bulk', 
                callBack: () => this.toggleAddInvoiceToBulkModal()
            },
            {
                name: 'Remove From Bulk', 
                callBack: () => this.removeInvoiceFromGroup()
            } 
        ]; 

        return (
            <>            
            <AddInvoiceToBulkModal
                showModal={showAddInvoiceToBulkModal}
                closeModal={this.toggleAddInvoiceToBulkModal}
            />
            <GetReasonModal
                handleSubmit={this.closeInvoice}
                showModal={showGetReasonModal}
                closeModal={this.toggleGetReasonModal}
            />
            <Invoice 
                claimId={claimId}
                invoiceId={invoiceId}
                closeModal={this.toggleInvoiceModal}
                showModal={showInvoiceModal}                
            />
            <QuickPayModal
                showModal={showQuickPayModal}
                closeModal={this.toggleQuickPayModal}
                invoiceShort={invoiceShort}
            /> 
            <Reconcile
                closeModal={this.toggleReconcileModal}
                showModal={showReconcileModal}
            />
            <Card className="claim-card">                
                {isInvoiceListDownloading && 
                    <span>Downloading...</span> 
                }
                <Card.Title>                                           
                    <div style={{display: "flex", justifyContent: "space-between"}}>{title}                        
                        <div style={{display: "flex", justifyContent: "flex-end"}}> 
                            {selectedInvoices.length > 1 &&        
                                <div className='inv-bulk-actions'> 
                                    <GearButton
                                        actions={buttonActions2}
                                        rowId={""}
                                    />                                        
                                </div>                
                            }                                           
                            <SingleActionButton
                                action="Export"
                                title="Invoices"
                                img={downloadIcon}
                                onClick={this.exportClaims }
                                disabled={isInvoiceListDownloading}
                            />                                                    
                        </div> 
                    </div>
                    <div className='table-hdr-totals'>
                        <Col>
                            Outstanding: <NumberFormat value={sumOutstandingBalance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                        </Col>
                        <Col>
                            Total: <NumberFormat value={sumGrandTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </Col>
                    </div>
                </Card.Title>
                {isInvoiceListLoading 
                    && <span>Loading...</span> 
                }   
                {invoicesListResponse?.totalCount > 0 &&
                    <Table striped>
                        <thead>
                            <tr 
                                className="claim-table-header"
                                onClick={this.sortTable.bind(this)}
                                >
                                <th id="isChecked">
                                    <Form>
                                        <Form.Group as={Col} controlId="selectAll">
                                            <Form.Check 
                                                name="selectAll"
                                                type="checkbox"
                                                id="selectAll"
                                                checked={selectAll}
                                                onChange={this.toggleSelectAll}
                                            />                                
                                        </Form.Group>
                                    </Form>
                                </th>
                                <th id="invoiceNumber">Invoice Number
                                    {isSortable && sortField ==="invoiceNumber" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="invoiceGroup">Invoice Group
                                    {isSortable && sortField ==="invoiceGroup" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="claimNumber">Claim Number
                                    {isSortable && sortField ==="claimNumber" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>                                
                                <th id="insCompany">Ins Company
                                    {isSortable && sortField ==="insCompany" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="adjusterName">Adjuster
                                    {isSortable && sortField ==="adjusterName" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="invoiceDate">Invoice Date
                                    {isSortable && sortField ==="invoiceDate" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="outstandingBalance">Outstanding Balance
                                    {isSortable && sortField ==="outstandingBalance" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                <th id="grandTotal">Grand Total
                                    {isSortable && sortField ==="grandTotal" && <Image src={isDesc ? sortDesc : sortAsc}/>}
                                </th>
                                {buttonActions.length > 0 && <th id="pay"/>}
                            </tr>
                        </thead>
                        <tbody>
                            {invoicesListResponse.resourceList?.map((x: models.InvoicesList, i: number) => (
                                <tr key={x.invoiceId}>
                                    <td>
                                        <Form>
                                            <Form.Group as={Col} controlId="isChecked" className="input-field-container">
                                                <Form.Check 
                                                    name="isChecked"
                                                    type="checkbox"
                                                    id="chkSelected"
                                                    value={x.invoiceId}
                                                    checked={selectedInvoices.includes(x.invoiceId)}
                                                    onChange={(e) => this.toggleInvoice(e)}
                                                />                                
                                            </Form.Group>
                                        </Form>
                                    </td>
                                    <td 
                                        className="value-link" 
                                        onClick={() => this.editInvoice(x.invoiceId)} 
                                        >{x.invoiceNumber}
                                    </td>
                                    <td>{x.invoiceGroup}</td>
                                    <td>{x.claimNumber}</td>
                                    <td>{x.insCompany}</td>
                                    <td>{x.adjuster}</td>
                                    <td>{x.invoiceDate && dateFormat(x.invoiceDate, 'mm/dd/yyyy')}</td>
                                    <td><NumberFormat value={x.outstandingBalance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                                    <td><NumberFormat value={x.grandTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>                                    
                                    {selectedInvoices.length === 0 &&
                                        <td style={{overflow: "visible", display: "flex", justifyContent: "space-between", paddingRight: "0" }}>
                                        {x.outstandingBalance !== 0 && !x.invoiceGroup && 
                                            <OverlayTrigger
                                                key="overlayTrigger"
                                                placement="top"
                                                overlay={<Tooltip className="my-tooltip" id="toolTip">Quick Pay (Full)</Tooltip>}
                                            >                                            
                                                <img onClick={(e) => this.handleQuickPay(x)} style={{height: "25px", cursor: "pointer"}} src={receivePymtIcon}/>
                                            </OverlayTrigger>                                
                                        }
                                        {buttonActions.length > 0 &&
                                            <div style={{margin: "auto"}}>
                                                <GearButton
                                                    actions={buttonActions || new Array<models.ButtonAction>()}
                                                    rowId={x.invoiceId.toString()}
                                                />   
                                            </div>                  
                                        }
                                        </td>
                                    }      
                                </tr>                                
                            ))}
                        </tbody>
                    </Table>
                }                
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimNumber: state.claims.claim.claim.insClaimNumber,
    claimId: state.claims.claim.claim.claimID,
    invoicesListResponse: state.financials.financials.invoicesListResponse,     
    invoicesRequest: state.financials.financials.invoicesRequest, 
    isInvoiceListDownloading: state.financials.financials.isInvoicesListDownloading, 
    isInvoiceListLoading: state.financials.financials.isInvoiceListLoading, 
    lastUserAction: state.user.actions.lastUserAction, 
    selectedInvoices: state.financials.financials.selectedInvoices, 
    selectedInvoiceCount: state.financials.financials.selectedInvoices.length, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            addInvoiceCorrection: financialsActions.addInvoiceCorrection, 
            clearSelectedInvoices: financialsActions.clearSelectedInvoices,             
            removeInvoiceFromGroup: financialsActions.removeInvoiceFromGroup, 
            requestClaim : claimsActions.requestClaimSingle, 
            requestInvoice: financialsActions.requestInvoice,
            requestInvoices: financialsActions.requestInvoiceList, 
            requestInvoiceList: financialsActions.requestInvoiceList,             
            requestInvoicesCSV: financialsActions.requestInvoicesCSV,             
            requestInvoiceGroupByCompany: financialsActions.requestInvoiceGroupsList,
            requestReconcileList: financialsActions.requestReconcileList, 
            requestReconcileListBulk: financialsActions.requestReconcileListBulk,             
            setSelectedInvoices: financialsActions.setSelectedIinvoices,            
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(InvoiceList); 