import * as React from 'react'; 
import Chart from 'react-apexcharts'; 

interface Props {
}

interface State {
    series: any;  
    options: any; 
}

interface openClaimsByCompany {
    companyName: string; 
    openClaims: number; 
}

class OpenClaimsCard extends React.PureComponent<Props, State> {
    public state = {
        options: {},
        series: []        
    }     
    
    public componentDidMount = async () => {
        this.setState({
            series: [{
                name: 'Weekly Opened', 
                data: [290,768,1345,974,847,543,567,535,653,475,474,392,829,484,389,355,204]
            }],
            options: {               
                chart: {               
                    background: '#fff',
                    type: 'line',
                    height: 90,                    
                    toolbar: false, 
                    sparkline: {
                        enabled: true
                    }, 
                    zoom: {
                        enabled: false,
                    }                   
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: undefined, 
                }, 
                labels: ['2023-05-29','2023-06-05','2023-06-12','2023-06-19','2023-06-26','2023-07-03','2023-07-10','2023-07-17',
                        '2023-07-24','2023-07-31','2023-08-07','2023-08-15','2023-08-14','2023-08-21','2023-08-28','2023-09-04',
                        '2023-09-11','2023-09-18'], 
                markers: {
                    size: 0
                }, 
                stroke: {
                    curve: 'straight',
                    width: 2
                },
                fill: {
                    opacity: 0.4,
                    type: 'solid'
                },
                grid: {
                    show: false,
                    padding: {
                        top: 0,
                        right: 2,
                        bottom: 0,
                        left: 2
                    },
                },
                yaxis: {
                    show: false
                },        
                title: {
                    text: '526',
                    offsetX: 0,
                    style: {
                    fontSize: '24px',
                    }
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false
                    },
                },
                subtitle: {
                    text: 'Open - Current',
                    offsetX: 0,
                    style: {
                    fontSize: '14px',
                    }
                }
            },             
        }); 
    }    

    render() {
        return (
            <div id="chart">
                <Chart 
                    options={this.state.options} 
                    series={this.state.series} 
                    type="line" 
                    height={190} 
                />
            </div>     
        );
    }
}

export default OpenClaimsCard; 