// @ts-nocheck
import * as React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Actions {
    name: string;
}

interface Props {
    img: React.Component;
    actions: Actions[]; 
 }

interface State {
}

class MultiActionButton extends React.Component<Props, State> {
    public render() {
        const { img, actions } = this.props;
        return (
            <OverlayTrigger
                key="overlayTrigger"
                placement="top"
                overlay={
                    <Tooltip className="my-tooltip" id="toolTip">Actions</Tooltip>
                }
            >
                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <img src={img}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {actions.map((action, i) => (
                            <Dropdown.Item key={i} >{action.name}</Dropdown.Item>
                        ))}
                        
                    </Dropdown.Menu>
                </Dropdown>
            </OverlayTrigger>
        ); 
    }
}

export default MultiActionButton;
