import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import * as models from '../../models';
import { claimsActions } from 'store/claims';
import _ from 'lodash'; 

interface Props extends PropsFromRedux {
    patchClaim: (oldClaim: models.ClaimSimple, newClaim: models.ClaimSimple) => void; 
    stateClaim: models.ClaimSimple; 
    closeModal: () => void;
    showModal: boolean;     
}

interface State {
    reason: string; 
}

class ReOpenReasonModal extends React.Component<Props, State> {
    public state = {
        reason: this.props.claim.reopenReason, 
    };

    public resetFormStateAndClose = () => {
        this.setState({reason: this.props.claim.reopenReason});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault();         
        if (this.state.reason == '' ){
            alert("Reason cannot be empty"); 
        }
        else {
            const newClaim: models.ClaimSimple = {
                ...this.props.stateClaim,
                reopenReason: this.state.reason
            }
            this.props.patchClaim(this.props.claim, newClaim);             
            this.props.closeModal(); 
        }
    }

    public render() {
        const {closeModal, showModal } = this.props;
        const { reason } = this.state; 

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Reason for Reopen</Modal.Title>
                </Modal.Header>                
                <Form className="claim-modal" onSubmit={this.submitForm} >                        
                    <Modal.Body> 
                        <Form.Group as={Row} controlId="reason" className="input-field-container">
                            <Form.Label column sm="4" >Reason</Form.Label>
                            <Col sm="8">
                                <Form.Control as="textarea" onChange={(e) => this.setState({ reason: e.target.value })} />
                            </Col>
                        </Form.Group> 
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                        CANCEL
                    </Button>
                    <Button variant="primary" type="submit">
                        SAVE
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ReOpenReasonModal);