import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
    yield takeEvery(constants.ADD_CAT_CODE, addCatCode); 
    yield takeEvery(constants.REQUEST_ADMIN_TYPE_LIST, requestAdminTypeList);
    yield takeEvery(constants.REQUEST_CAT_CODES_DD, requestCatCodeDD); 
    yield takeEvery(constants.REQUEST_CAT_CODES_LIST, requestCatCodesList); 
    yield takeEvery(constants.REQUEST_CAUSES_LIST, requestCausesList); 
    yield takeEvery(constants.REQUEST_COVERAGE_TYPE_LIST, requestCoverageTypeList); 
    yield takeEvery(constants.REQUEST_DOCUMENT_TYPE_LIST, requestDocumentTypeList);
    yield takeEvery(constants.RECEIVE_DOCUMENT_TYPE_LNS, requestLNSDocumentList);  
    yield takeEvery(constants.REQUEST_ROLE_LIST, requestRoleList); 
}

// Admin Types
export function* requestAdminTypeList(action: ActionType<typeof actions.requestAdminTypeList>): any {
    let adminPage =""; 

    switch (action.payload.request.adminType) {
        case 'Contacts': 
            adminPage = 'ContactTypes';
            break; 
        case 'Companies': 
            adminPage = 'CompanyTypes'; 
            break; 
        default: adminPage = action.payload.request.adminType; 
    }
    const adminTypeRequest: models.AdminTypesRequest = {
        ...action.payload.request, 
        adminType: adminPage,
    }
    
    const userRequest = yield call(services.api.admin.getAdminTypes, adminTypeRequest);
    if (userRequest.isSuccess) {
        yield put(actions.receiveAdminTypeList(userRequest.data));
    }
}

// Cat Codes 
export function* addCatCode(action: ActionType<typeof actions.addCatCode>): any {
    const userRequest = yield call(services.api.admin.addCatCode, action.payload.request); 
    if (userRequest.isSuccess) {
        yield put(actions.requestCatCodesList({pageNo: 1, pageSize:1000})); 
    }
    else {
        console.log(userRequest); 
    }
}
export function* requestCatCodesList(action: ActionType<typeof actions.requestCatCodesList>): any {
    const userRequest = yield call(services.api.admin.getCatCodes, action.payload.request);
    if (userRequest.isSuccess) {
        yield put(actions.receiveCatCodesList(userRequest.data)); 
    }
}

// Causes
export function* requestCausesList(): any {
    const userRequest = yield call(services.api.admin.getCauses);
    if (userRequest.isSuccess) {
        yield put(actions.receiveCausesList(userRequest.data)); 
    }
}

// Coverage Types
export function* requestCoverageTypeList(action: ActionType<typeof actions.requestCoverageTypeList>): any {
    const userRequest = yield call(services.api.admin.getCoverageTypes, action.payload.request); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCoverageTypeList(userRequest.data)); 
    }
}

// Documnet Types
export function* requestDocumentTypeList(action: ActionType<typeof actions.requestDocumentTypeList>): any {
    const userRequest = yield call(services.api.admin.getDocumentTypes, action.payload.request); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveDocumentTypeList(userRequest.data)); 
    }
}

export function* requestLNSDocumentList(action: ActionType<typeof actions.requestLNSDocumentList>): any {
    const userRequest = yield call(services.api.admin.getDocumentTypes, action.payload.request); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveLNSDocumentList(userRequest.data)); 
    }
}

// Roles
export function* requestRoleList(action: ActionType<typeof actions.requestRoleList>): any {
    const userRequest = yield call(services.api.admin.getRoles, action.payload.paging); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveRoleList(userRequest.data)); 
    }
}

// Dropdowns
export function* requestCatCodeDD(action: ActionType<typeof actions.requestCatCodeDD>): any {
    const userRequest = yield call(services.api.admin.getCatCodesDD); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCatCodeDD(userRequest.data)); 
    }
}