import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

// Admin Types
export const requestAdminTypeList = (request: models.AdminTypesRequest) => 
    action(constants.REQUEST_ADMIN_TYPE_LIST, { request });

export const receiveAdminTypeList = (response: models.AdminTypesResponse) => 
    action(constants.RECEIVE_ADMIN_TYPE_LIST, { response });

// Cat Codes
export const addCatCode = (request: models.CatCode) =>
    action(constants.ADD_CAT_CODE, { request }); 
    
export const requestCatCodesList = (request: models.CatCodesRequest) =>
    action(constants.REQUEST_CAT_CODES_LIST, { request }); 

export const receiveCatCodesList = (response: models.CatCodesResponse) =>
    action(constants.RECEIVE_CAT_CODES_LIST, { response }); 

// Causes
export const requestCausesList = () =>
    action(constants.REQUEST_CAUSES_LIST); 

export const receiveCausesList = (response: models.CausesResponse) =>
    action(constants.RECEIVE_CAUSES_LIST, { response }); 

// Coverage Types
export const requestCoverageTypeList = (request: models.CoverageTypesRequest) =>
    action(constants.REQUEST_COVERAGE_TYPE_LIST, { request }); 

export const receiveCoverageTypeList = (response: models.CoverageTypesResponse) =>
    action(constants.RECEIVE_COVERAGE_TYPE_LIST, { response }); 

// Document Types    
export const requestDocumentTypeList = (request: models.DocumentTypesRequest) =>
    action(constants.REQUEST_DOCUMENT_TYPE_LIST, { request }); 

export const receiveDocumentTypeList = (response: models.DocumentTypesResponse) =>
    action(constants.RECEIVE_DOCUMENT_TYPE_LIST, { response }); 

export const requestLNSDocumentList = (request: models.DocumentTypesRequest) =>
    action(constants.REQUEST_DOCUMENT_TYPE_LNS, { request }); 

export const receiveLNSDocumentList = (response: models.DocumentTypesResponse) =>
    action(constants.RECEIVE_DOCUMENT_TYPE_LNS, { response }); 
    
// Roles    
export const requestRoleList = (paging: models.Paging) => 
    action(constants.REQUEST_ROLE_LIST, { paging });

export const receiveRoleList = (response: models.RoleResponse) =>
    action(constants.RECEIVE_ROLE_LIST, { response });    

// Dropdowns
export const requestCatCodeDD = () => 
    action(constants.REQUEST_CAT_CODES_DD);
export const receiveCatCodeDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_CAT_CODES_DD, { response });