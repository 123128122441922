import { trackPromise } from 'react-promise-tracker';
import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const getCurrentUser = async (email: string) => {  
    const response = await request ({
      method: 'GET', 
      url: `/User/${email}`, 
    }); 
    return response; 
  }; 

export const getCurrentUserInfo = async(token:string) => {
  const response = await request ({
    method: 'GET',
    url: `/Auth2User/CurrentUserInfo`
  });
  return response;
}