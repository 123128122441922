import React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Row, Col, Form, Spinner } from 'react-bootstrap';
import { createPatch } from 'rfc6902';
import * as models from '../../models';
import dateFormat from 'dateformat';
import { claimsActions } from 'store/claims';
import ReOpenReasonModal from './ReOpenReasonModal'; 
import { userHasPermission } from '../../utils/helpers';
import SingleActionButton from 'components/common/SingleActionButton';
import msgIcon from '../../assets/images/Blue/message.svg'; 
import Mail from '../common/Mail'; 
import { USER_ACTIONS } from 'store/enums';

interface Props extends PropsFromRedux {
}

interface State {
    claim: models.ClaimSimple; 
    showModal: boolean;
    showMailModal: boolean; 
}

class ClaimOverview extends React.Component<Props, State> {
    public state = {
        claim: this.props.claim, 
        showModal: false, 
        showMailModal: false, 
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    }
    public toggleMailModal = () => {
        this.setState({showMailModal: !this.state.showMailModal}); 
    }

    public toggleStatus = () => {
        const newStatus = this.props.claim?.status == "Closed" ? "Reopened" : "Closed";  
        const newClaim: models.ClaimSimple = {
            ...this.props.claim
            , status: newStatus
        }
        this.setState( { claim: newClaim });
        if (newStatus === "Reopened") {
            this.toggleModal(); 
        }
        else {
            this.callPatchClaim(this.props.claim, newClaim);     
        }
    };

    public callPatchClaim = (oldClaim: models.ClaimSimple, newClaim: models.ClaimSimple) => {
        const patchDocument = createPatch(oldClaim, newClaim); 
        const claimId = this.props.claim.claimID;         
        this.props.patchClaim(claimId, patchDocument);
    }

    public render() {
        const { claim, isClaimLoading, lastUserAction } = this.props;
        const { showModal, showMailModal } = this.state; 
        const stateClaim = this.state.claim;    
        const canOpenCloseClaim = userHasPermission("Can close/reopen claims");    
        const showSpinner = isClaimLoading || lastUserAction === USER_ACTIONS.CLAIM_UPDATING;         

        return (
            <>
            <Mail
                showModal={showMailModal}
                closeModal={this.toggleMailModal}
            />
            <ReOpenReasonModal
                patchClaim={this.callPatchClaim}
                stateClaim={stateClaim}
                showModal={showModal}
                closeModal={this.toggleModal}
            />
            <Card className="claim-card">
                <Card.Title>Claim Overview
                    {showSpinner && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    {userHasPermission("Send Email Notifications") && claim.status.toLowerCase() !== 'closed' &&
                        <SingleActionButton
                                action="Send"
                                title="Message"
                                img={msgIcon}
                                onClick={this.toggleMailModal}
                            />
                    }
                </Card.Title>
                <Row>
                    <Col md={3} lg={3} className="card-label">Insurance Company:</Col>
                    <Col md={3} lg={3} >{claim.insuranceCompany}</Col>
                    <Col md={3} lg={3} className="card-label">Status:</Col>
                    <Col md={3} lg={3} >
                        {claim.claimID !== 0 &&
                            <Form>
                                <Form.Switch
                                    disabled={!canOpenCloseClaim}
                                    id="tglStatus"
                                    checked={!(claim.status == "Closed")}
                                    label={"(" + claim.status + ")"}
                                    onChange={this.toggleStatus}
                                />
                            </Form>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Insurance Claim Number:</Col>
                    <Col md={3} lg={3} >{claim.insClaimNumber}</Col>
                    <Col md={3} lg={3} className="card-label">Date Entered:</Col>
                    <Col md={3} lg={3} >{claim.claimID !== 0 && dateFormat(claim.dateReceived || "", 'mm/dd/yyyy')}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Insured:</Col>
                    <Col md={3} lg={3} >{claim.insuredPerson}</Col>                    
                </Row>
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
    isClaimLoading: state.claims.claim.isClaimLoading,     
    lastUserAction: state.user.actions.lastUserAction,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            patchClaim: claimsActions.patchClaim, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ClaimOverview);

