import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap'; 
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import { adminActions } from '../../store/admin';
import * as models from '../../models'; 
import { claimsActions } from '../../store/claims';

interface Props extends PropsFromRedux {  
    reserveCoverage?: models.ReserveCoverage; 
    showModal: boolean;
    closeModal: () => void; 
}

interface State {    
    reserveCoverage?: models.ReserveCoverage; 
}

class ReservesModal extends React.Component<Props, State> {
    public state = {
        reserveCoverage: models.defaultReserveCoverage, 
    };

    componentDidMount() {        
        this.props.requestCoverageTypes({pageNo: 1, pageSize: 100}); 
    }

    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.reserveCoverage, this.props.reserveCoverage) && this.props.reserveCoverage !== undefined) {
            this.setState({reserveCoverage: this.props.reserveCoverage});
        }
    }

    public resetFormStateAndClose = () => {
        this.setState({reserveCoverage: this.props.reserveCoverage});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault();
        const claimId = this.props.claimId;
        if(this.props.reserveCoverage != undefined){
            const patchDocument = createPatch(this.props.reserveCoverage, this.state.reserveCoverage);
            this.props.patchClaimReserveCoverage(claimId, this.state.reserveCoverage.reserveCoverageId, patchDocument);
        }
        else {
            this.props.addReserveCoverage(claimId, this.state.reserveCoverage);
        }        
        this.props.closeModal(); 
    }

    public render() {
        const { showModal, closeModal, coverageTypesResponse } = this.props;
        const { reserveCoverage } = this.state; 

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                <Modal.Title>Add Reserve Coverage</Modal.Title>
                </Modal.Header>
                <Form 
                        className="claim-modal" 
                        onSubmit={this.submitForm}
                    >
                    <Modal.Body>                                           
                        <Form.Group as={Row} controlId="covType" className="input-field-container">
                            <Form.Label column sm="4">Coverage Type</Form.Label>
                            <Col sm="8">
                                <Form.Select name="insCompany" value={reserveCoverage.coverageType} onChange={(e) => this.setState({reserveCoverage: {...reserveCoverage, coverageType: e.target.value}})} >
                                    <option key={-1}>Choose...</option>
                                    {coverageTypesResponse?.totalCount > 0 && coverageTypesResponse.resourceList.map((c: models.CoverageType, i: number) => (                                             
                                        <option key={i} value={c.coverageType} >{c.coverageType}</option>
                                    ))}
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="amt" className="input-field-container">
                            <Form.Label column sm="4">Amount</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control type="number" placeholder="0.00" value={reserveCoverage.amount} onChange={(e) => this.setState({reserveCoverage: {...reserveCoverage, amount: Number(e.target.value)}})} />
                                </InputGroup>
                            </Col>
                        </Form.Group>                        
                        <Form.Group as={Row} controlId="reason" className="input-field-container">
                            <Form.Label column sm="4" className='disabled-label'>Reason</Form.Label>
                            <Col sm="8">
                                <Form.Control as="textarea" value={reserveCoverage.reason} onChange={(e) => this.setState({reserveCoverage: {...reserveCoverage, reason: e.target.value}})} />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    claimId: state.claims.claim.claim.claimID,
    coverageTypesResponse : state.admin.admin.coverageTypesResponse, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addReserveCoverage: claimsActions.addReserveCoverage, 
            patchClaimReserveCoverage: claimsActions.patchClaimReserveCoverage, 
            requestCoverageTypes: adminActions.requestCoverageTypeList, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ReservesModal);