import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface AdminStateShape {
    adminTypesResponse: models.AdminTypesResponse;
    catCodesResponse: models.CatCodesResponse; 
    causesResponse: models.CausesResponse; 
    company: models.Company; 
    companiesResponse: models.CompaniesResponse;
    coverageTypesResponse: models.CoverageTypesResponse;     
    roleResponse: models.RoleResponse;
    isCompanyListLoading: boolean; 
    isLoading: boolean;    
    isRoleListLoading: boolean;    
}

export const adminDefaultState: AdminStateShape = {
    adminTypesResponse: models.defaultResponse,
    catCodesResponse: models.defaultResponse,
    causesResponse: models.defaultResponse, 
    company: models.defaultCompany, 
    companiesResponse: models.defaultResponse, 
    coverageTypesResponse: models.defaultResponse,    
    roleResponse: models.defaultResponse,
    isCompanyListLoading: false,    
    isLoading: false,     
    isRoleListLoading: false,     
};

export interface DocumentTypeShape {
    documentTypesResponse: models.DocumentTypesResponse;
    lossNoticeResponse: models.DocumentTypesResponse;
}

export const documentTypeDefaultState: DocumentTypeShape = {
    documentTypesResponse: models.defaultResponse,
    lossNoticeResponse: models.defaultResponse,
}

export interface DropdownsShape {
    catCodeDD: models.DropdownListResponse, 
    isCatCodeDDLoading: boolean; 
}

export const dropdownsDefaultState: DropdownsShape = {
    catCodeDD: models.defaultResponse,
    isCatCodeDDLoading: false, 
}

export interface State {
    admin: AdminStateShape;
    documentType: DocumentTypeShape;  
    dropdowns: DropdownsShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    admin: (state = adminDefaultState, action) => {
        switch (action.type) {            
            case constants.REQUEST_ADMIN_TYPE_LIST:
                return {
                    ...state, 
                }; 

            case constants.RECEIVE_ADMIN_TYPE_LIST: 
                return { 
                    ...state,
                    adminTypesResponse: action.payload.response,
                 }; 

            case constants.REQUEST_CAT_CODES_LIST: 
                 return {
                     ...state,
                 }; 

            case constants.RECEIVE_CAT_CODES_LIST: 
                 return {
                     ...state,
                     catCodesResponse: action.payload.response,
                 }; 

            case constants.REQUEST_CAUSES_LIST: 
                 return {
                     ...state,
                 }; 

            case constants.RECEIVE_CAUSES_LIST: 
                 return {
                     ...state,
                     causesResponse: action.payload.response,
                 }; 

            case constants.REQUEST_COVERAGE_TYPE_LIST: 
                return {
                    ...state,                    
                }; 

            case constants.RECEIVE_COVERAGE_TYPE_LIST: 
                return {
                    ...state, 
                    coverageTypesResponse: action.payload.response
                };             

            case constants.REQUEST_ROLE_LIST: 
                return {                    
                    ...state,
                    isRoleListLoading: true, 
                };
            
            case constants.RECEIVE_ROLE_LIST: 
                return {
                    ...state, 
                    isRoleListLoading: false, 
                    roleResponse: action.payload.response
                };           

            default: 
                return state; 
        }
    }, 
    documentType: (state = documentTypeDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_DOCUMENT_TYPE_LIST:
                return {
                    ...state,
                };

            case constants.RECEIVE_DOCUMENT_TYPE_LIST:
                return {
                    ...state, 
                    documentTypesResponse: action.payload.response
                };

            case constants.REQUEST_DOCUMENT_TYPE_LNS: 
                return {
                    ...state,
                }; 
            
            case constants.RECEIVE_DOCUMENT_TYPE_LNS: 
                return {
                    ...state, 
                    lossNoticeResponse: action.payload.response
                }; 

            default: 
                return state; 
        }
    },
    dropdowns: (state = dropdownsDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_CAT_CODES_DD: 
                return {
                    ...state, 
                    isCatCodeDDLoading: true,
                }
            case constants.RECEIVE_CAT_CODES_DD: 
                return {
                    ...state, 
                    catCodeDD: action.payload.response,
                    isCatCodeDDLoading: false, 
                }            

            default: 
                return state; 
        }
    },
});
