export interface ReserveCoverage {
    reserveCoverageId: number; 
    coverageType: string;
    reason: string; 
    amount: number;
}

export const defaultReserveCoverage: ReserveCoverage = {
    reserveCoverageId: 0, 
    coverageType: '',
    reason: '',
    amount: 0
}