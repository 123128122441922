import * as models from '..';

export interface FeeSchedule {
    feeScheduleId: number; 
    insuranceCompany: string; 
    scheduleName: string; 
    isActive: boolean; 
    description: string; 
    expiryDate: string; 
    isCat: boolean; 
    isGeo: boolean; 
    createdDate: string; 
    modifiedDate: string; 
    createdBy: string; 
    feeScheduleFeeList?: models.FeeScheduleFee[]; 
    feeScheduleRangeList?: models.FeeScheduleRange[]; 
    catCodeList?: number[]; 
    stateList?: string[]; 
    attachment?: models.Attachment; 
}

export interface FeeScheduleResponse extends models.ResponseBase<FeeSchedule>{}

export const defaultFeeSchedule : FeeSchedule =  {
    feeScheduleId: 0, 
    insuranceCompany: '',
    scheduleName: '',     
    isActive: true, 
    description: '', 
    expiryDate: '', 
    isCat: false, 
    isGeo: false, 
    createdDate: '',
    modifiedDate: '',
    createdBy: '',
    catCodeList: new Array<number>, 
    stateList: new Array<string>
}