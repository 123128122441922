import * as React from 'react';
import { Table, Card, Spinner } from 'react-bootstrap'; 
import * as models from '../../models';
import dateFormat from 'dateformat'; 

interface Props {
    emailedFiles: models.EmailedClaim[],
    isLoading: boolean, 
}

interface State {
}

class EmailedFiles extends React.Component<Props, State> {    
    public render() {
        const { emailedFiles, isLoading } = this.props;
        
        return (
            <Card className="claim-card">
                <Card.Title>Emailed Files</Card.Title>
                {isLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                { emailedFiles[0] && 
                    <Table striped>
                        <thead>
                            <tr>
                                <th>To</th>
                                <th>From</th>
                                <th>Files</th>
                                <th>Sent Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailedFiles.map((emailedFile, i) => (
                                <tr key={i}>
                                    <td>{emailedFile.emailedTo}</td>
                                    <td>{emailedFile.emailedFrom}</td>
                                    <td>{emailedFile.fileList}</td>
                                    <td>{dateFormat(emailedFile.sentDate, 'mm/dd/yyyy')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
            </Card>            
        );
    }
}

export default EmailedFiles;