import * as React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';

interface Props {  
    companyName: string, 
    showModal: boolean;
    closeModal: () => void;
    callback: (invoiceGroup: models.InvoiceGroup) => void;
}

interface State {    
    invoiceGroup: string; 
}

class AddInvoiceGroupModal extends React.Component<Props, State> {
    public state = {
        invoiceGroup: ""
    };

    public resetFormStateAndClose = () => {
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        const invoiceGroup: models.InvoiceGroup = {
            invoiceGroupId: 0, 
            invoiceGroupName: this.state.invoiceGroup, 
            insCompany: this.props.companyName,
        }
        this.props.callback(invoiceGroup);  
        this.props.closeModal(); 
    }

    public render() {
        const { companyName, closeModal, showModal } = this.props;         
        
        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >                                
                <Modal.Header closeButton>
                    <Modal.Title>Create Invoice Group</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>
                    <Modal.Body>           
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container">
                            <Form.Label column sm="4">Company</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text"
                                    disabled={true}
                                    value={companyName}
                                />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Body>           
                        <Form.Group as={Row} controlId="bulkName" className="input-field-container">
                            <Form.Label column sm="4">Bulk Invoice</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="text" 
                                    placeholder='Enter Bulk Invoice Name...' 
                                    onChange={(e) => this.setState({invoiceGroup: e.target.value})} 
                                />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>            
        );
    }
}

export default AddInvoiceGroupModal;
