import * as React from 'react';
import { Button } from 'react-bootstrap'; 
import '../../assets/styles/Login2.css';
import { decode } from 'querystring';

interface Props {
}
interface State {
}

class Login extends React.PureComponent<Props, State> {

    public state = {
    };
    
    public async componentDidMount() {
    }

    private devLogins() {
        var redirectUrl = "https://" + window.location.host + "/login_callback";
        if (process.env.REACT_APP_TEST_LOGIN_PATH && 
            process.env.REACT_APP_TEST_ADMIN_LOGIN_PATH) {
            return (
                <React.Fragment>
                <p><strong>Test Sites</strong></p>
                <p>
                    <Button variant="primary" 
                            href={process.env.REACT_APP_TEST_LOGIN_PATH + 
                                "?redirect_url=" + encodeURIComponent(redirectUrl) +
                                "&token=" + process.env.REACT_APP_LOGIN_TOKEN}>
                        Compass Login
                    </Button>
                </p>

                <p>
                    <Button variant="primary" 
                            href={process.env.REACT_APP_TEST_ADMIN_LOGIN_PATH + 
                                "?redirect_url=" + encodeURIComponent(redirectUrl) +
                                "&token=" + process.env.REACT_APP_LOGIN_TOKEN}>
                        Compass Admin Login
                    </Button>
                </p>
                <p><strong>Prod Sites</strong></p>
            </React.Fragment>
            );
        }
        return null;
    }

    private prodLogins() {
        var redirectUrl = "https://" + window.location.host + "/login_callback";
        return (
            <React.Fragment>
                <p>
                    <Button variant="primary" 
                            href={process.env.REACT_APP_LOGIN_PATH + 
                                "?redirect_url=" + encodeURIComponent(redirectUrl) +
                                "&token=" + process.env.REACT_APP_LOGIN_TOKEN}>
                        Compass Login
                    </Button>
                </p>

                <p>
                    <Button variant="primary" 
                            href={process.env.REACT_APP_ADMIN_LOGIN_PATH + 
                                "?redirect_url=" + encodeURIComponent(redirectUrl) +
                                "&token=" + process.env.REACT_APP_LOGIN_TOKEN}>
                        Compass Admin Login
                    </Button>
                </p>
            </React.Fragment>
        );
    }

    public render() {
        return (
            <React.Fragment>
                <div className="text-center">
                    <h2>Welcome to Compass Connect</h2>
                    {/* {this.devLogins()} */}
                    {this.prodLogins()}
                </div>
            </React.Fragment> 
        );
    }
}

export default Login;