import { ApiResponse, request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const getPolicyTypeDD = async () => {  
  const response = await request ({
    method: 'GET', 
    url: `/PolicyTypes/dd`, 
  }); 
  return response; 
}; 