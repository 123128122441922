import * as React from 'react';
import { Nav, Row, Col } from 'react-bootstrap';

interface Props {
    getTabName: (name: string) => void;        
    tabs: string[];
    HeaderComponent?: React.ReactNode;  
}

interface State {
    activeTab: string | null;
    
}

class PageHeader extends React.Component<Props, State> {
    public state = {
        activeTab: null,
    }

    componentDidMount () {
        this.setState({activeTab: this.props.tabs[0]}); 
    }

    public handleSelect = (eventKey: string | null) =>  {
        if (eventKey != null) {
            this.props.getTabName(eventKey);
            this.setState({ activeTab: eventKey });
        }
    }

    public render() {
        const { activeTab } = this.state;
        const { HeaderComponent, tabs } = this.props; 

        return (
            <div className="page-header-container">
                <Row>
                    <Col>
                        <Nav variant="tabs" activeKey={activeTab || ""} onSelect={this.handleSelect} >
                            {tabs.map((tab:string, i:number) => (
                                <Nav.Item key={i}>
                                    <Nav.Link eventKey={tab}>{tab}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col lg="2" className="claim-header-search">
                        {HeaderComponent}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PageHeader;