// @ts-nocheck
import * as React from 'react';
import { Nav } from 'react-bootstrap';


interface Props {
    getTabName: (name: string) => void;
}

interface State {
    activeTab: string;    
}

class ReportHeader extends React.Component<Props, State> {
    public state = {
        activeTab: 'AAA Daily Report',
    };

    public handleItemClick = (e: any, { name }: any) => {        
        this.props.getTabName(name);
        this.setState({ activeTab: name });
    };

    public render() {
        const { activeTab } = this.state;
        return (
            <div className="page-header-container">
                <Nav variant="tabs" text={true} className="tabs-menu">
                    <Nav.Link
                        name="AAA Daily Report"
                        active={activeTab === 'AAA Daily Report'}
                        // onClick={this.handleItemClick}
                    >
                        AAA Daily Report
                    </Nav.Link>
                    <Nav.Link
                        name="Financial Reporting"
                        active={activeTab === 'Financial Reporting'}
                        // onClick={this.handleItemClick}
                    >
                        Financial Reporting
                    </Nav.Link>
                    <Nav.Link
                        name="Internal Admin Reports"
                        active={activeTab === 'Internal Admin Reports'}
                        // onClick={this.handleItemClick}
                    >
                        Internal Admin Reports
                    </Nav.Link>
                    <Nav.Link
                        name="Internal Claim Reports"
                        active={activeTab === 'Internal Claim Reports'}
                        // onClick={this.handleItemClick}
                    >
                        Internal Claim Reports
                    </Nav.Link>
                    <Nav.Link
                        name="Management"
                        active={activeTab === 'Management'}
                        // onClick={this.handleItemClick}
                    >
                        Management
                    </Nav.Link>
                    <Nav.Link
                        name="System"
                        active={activeTab === 'System'}
                        // onClick={this.handleItemClick}
                    >
                        System
                    </Nav.Link>
                </Nav>                
            </div>
        );
    }
}

export default ReportHeader;
