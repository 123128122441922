import * as React from 'react';
import { Card } from 'react-bootstrap'; 
import services from '../../services'; 
import * as models from '../../models';

interface Props {
    contact: models.ContactSimple; 
}

interface State {
}

class Contact extends React.Component<Props, State> {
    public getPhoneNumberToDisplay() {
            return this.props.contact.cellNumber && this.props.contact.homeNumber && this.props.contact.workNumber && ""; 
    }

    public onCardClick = (contactId: number) => {
        let path = services.router.url.contactDetail.replace(":contactId", contactId.toString());
        services.router.goto(path);
    }; 

    public render() {
        const {contact} = this.props; 
        
        return (
        <Card style={{cursor: 'pointer'}} className="admin-card" onClick={() => this.onCardClick(contact.id)}>
            <Card.Title>
                {contact.fullName}                                
                <div className="float-end">{this.getPhoneNumberToDisplay()}</div>                
            </Card.Title>
            <Card.Text as="span">
                <div className="float-end">{contact.email}</div>
            </Card.Text>
        </Card>
        );
    }
}

export default Contact;