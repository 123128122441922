import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';
import { USER_ACTIONS } from 'store/enums';

export const requestCurrentUser = (email: string) =>
    action(constants.REQUEST_CURRENT_USER, { email }); 

export const receiveCurrentUser = (response: models.Contact) =>
    action(constants.RECEIVE_CURRENT_USER, { response }); 

export const updateProfile = (contactId: number, patch: object) =>
    action(constants.UPDATE_PROFILE, {contactId, patch}); 

// Application Toasts
export const clearAppToast = () =>
    action(constants.CLEAR_APP_TOAST); 

export const setAppToast = (appToast: models.AppToast) => 
    action(constants.SET_APP_TOAST, { appToast }); 

export const setLastUserAction = (request: USER_ACTIONS) =>
    action(constants.SET_LAST_USER_ACTION, { request }); 

// events
export const setLastInvoiceCreatedId = (invoiceId: number) =>
    action(constants.SET_LAST_INVOICE_CREATED_ID, { invoiceId }); 
