import { request } from 'http';
import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
    yield takeEvery(constants.REQUEST_AUTH2_GROUPS, requestGroups);
    yield takeEvery(constants.REQUEST_AUTH2_USERS, requestUsers);
    yield takeEvery(constants.REQUEST_AUTH2_PERMISSIONS, requestPermissions); 
    yield takeEvery(constants.UPDATE_USER_GROUP_MEMBERSHIP, updateUserGroupMembership);
    yield takeEvery(constants.UPDATE_GROUP_PERMISSION, updateGroupPermission);
    yield takeEvery(constants.UPDATE_USER_PERMISSION, updateUserPermission);
}

export function* requestGroups(action: ActionType<typeof actions.requestGroups>):any {
    const request = yield call(services.api.security.getAuth2Groups);
    if (request.isSuccess) {
        yield put(actions.receiveGroups(request.data));
    }
}

export function* requestUsers(action: ActionType<typeof actions.requestUsers>):any {
    const request = yield call(services.api.security.getAuth2UsersSearch, action.payload.request);
    if (request.isSuccess) {
        yield put(actions.receiveUsers(request.data));
    }
}

export function* requestPermissions(action: ActionType<typeof actions.requestPermissions>): any {
    const request = yield call(services.api.security.getAuth2Permissions); 
    if (request.isSuccess) {
        yield put(actions.receivePermissions(request.data)); 
    }
}

export function* updateUserGroupMembership(action: ActionType<typeof actions.updateUserGroupMembership>):any {
    const request1 = yield call(services.api.security.updateUserGroupMembership, action.payload.request);
    if (request1.isSuccess) {
        const request = yield call(services.api.security.getAuth2Users);
        if (request.isSuccess) {
            yield put(actions.receiveUsers(request.data));
            yield put(actions.updateUserGroupMembershipDone());
        }
    }
}

export function* updateGroupPermission(action: ActionType<typeof actions.updateGroupPermission>):any {
    const request1 = yield call(services.api.security.updateGroupPermission, action.payload.request);
    if (request1.isSuccess) {
        const request = yield call(services.api.security.getAuth2Groups);
        if (request.isSuccess) {
            yield put(actions.receiveGroups(request.data));
            yield put(actions.updateGroupPermissionDone());
        }
    }
}

export function *updateUserPermission(action: ActionType<typeof actions.updateUserPermission>):any {
    const request1 = yield call(services.api.security.updateUserPermission, action.payload.request);
    if (request1.isSuccess) {
        const request2 = yield call(services.api.security.getAuth2Users);
        if (request2.isSuccess) { 
            yield put(actions.receiveUsers(request2.data));
            yield put(actions.updateUserPermissionDone(action.payload.request.userId));
        }
    }
}


