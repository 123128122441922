import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

// Contacts
export const addContactSimple = (request: models.ContactSimple) =>
    action(constants.ADD_CONTACT_SIMPLE, { request }); 

export const addCompanyExaminer = (contact: models.ContactSimple) =>
    action(constants.ADD_COMPANY_EXAMINER, { contact }); 

export const addReviewer = (contact: models.ContactSimple) =>
    action(constants.ADD_REVIEWER, { contact }); 

export const requestCompanyExaminersList = (paging: models.Paging, companyName: string) => 
    action(constants.REQUEST_COMPANY_EXAMINERS, { paging, companyName }); 

export const receiveCompanyExaminersList = (response: models.ContactSimpleResponse) => 
    action(constants.RECEIVE_COMPANY_EXAMINERS, { response }); 

export const requestContact = (contactId: number) =>
    action(constants.REQUEST_CONTACT, { contactId }); 

export const receiveContact = (contact : models.Contact) =>
    action(constants.RECEIVE_CONTACT, { contact });    

export const requestContactList = (request: models.ContactsRequest) =>
    action(constants.REQUEST_CONTACT_LIST, { request }); 

export const receiveContactList = (response: models.ContactSimpleResponse) =>
    action(constants.RECEIVE_CONTACT_LIST, { response }); 

export const requestExaminersList = (request: models.ContactsRequest) =>
    action(constants.REQUEST_EXAMINERS_LIST, { request }); 

export const receiveExaminersList = (response: models.ContactSimpleResponse) =>
    action(constants.RECEIVE_EXAMINERS_LIST, { response }); 

export const requestFieldAdjustersList = (request: models.Paging) =>
    action(constants.REQUEST_FIELD_ADJSTERS_LIST, { request }); 

export const receiveFieldAdjsutersList = (response: models.ContactSimpleResponse) =>
    action(constants.RECEIVE_FIELD_ADJSTERS_LIST, { response }); 

export const requestFieldDirectorsList = (request: models.Paging) =>
    action(constants.REQUEST_FIELD_DIRECTORS_LIST, { request }); 

export const receiveFieldDirectorsList = (response: models.ContactSimpleResponse) =>
    action(constants.RECEIVE_FIELD_DIRECTORS_LIST, { response });     

export const requestReviewersList = (request: models.Paging) =>
    action(constants.REQUEST_REVIEWERS_LIST, { request }); 

export const receiveReviewersList = (response: models.ContactSimpleResponse) =>
    action(constants.RECEIVE_REVIEWERS_LIST, { response });
    
export const updateContact = (contactId: number, patch: object) =>
    action(constants.UPDATE_CONTACT, { contactId, patch }); 

// dropdowns
export const requestAdjustersDD = () =>
    action(constants.REQUEST_ADJUSTERS_DD); 
export const receiveAdjustersDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_ADJUSTERS_DD, { response }); 

export const requestCompanyExaminersDD = (companyName: string) =>
    action(constants.REQUEST_COMPANY_EXAMINERS_DD, { companyName }); 
export const receiveCompanyExaminersDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_COMPANY_EXAMINERS_DD, { response }); 

export const requestExaminersDD = () =>
    action(constants.REQUEST_EXAMINERS_DD); 
export const receiveExaminersDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_EXAMINERS_DD, { response }); 

export const requestFieldDirectorsDD = () =>
    action(constants.REQUEST_FIELD_DIRECTORS_DD); 
export const receiveFieldDirectorsDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_FIELD_DIRECTORS_DD, { response }); 

export const requestReviewersDD = () =>
    action(constants.REQUEST_REVIEWERS_DD); 
export const receiveReviewersDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_REVIEWERS_DD, { response }); 