import axios from 'axios';
import { head } from 'lodash';
import { api } from '../../config';
import { getLocalToken } from '../../utils/helpers';

export class ApiResponse {
    public status: number;
    public data: any;
    public headers?: any;

    public isSuccess: boolean;
    public isError: boolean;

    constructor(status: number, data: any, headers?: any) {
        this.status = status;
        this.data = data;
        this.headers = headers;

        this.isSuccess = status >= 200 && status <= 299;
        this.isError = !this.isSuccess;
    }

    public get(x: string) {
        return this.data[x];
    }

    public getErrorList() {
        return this.data.errors.map((errorData: any) => {
            return errorData.msg;
        });
    }

    public getError() {
        return this.getErrorList().join(' ');
    }

    public isServerError() {
        return this.status >= 500 && this.status < 600;
    }
}

export const request = (options: any): Promise<ApiResponse> => {
    const headers: any = {
        Accept: 'application/json',        
    };

    const accessToken = getLocalToken(); 
    if (accessToken) {
        headers.Authorization = `${accessToken}`        
    }

    return axios
        .request({
            baseURL: api.endpoint,
            responseType: 'json',
            ...options,
            headers: {
                ...headers,
                ...options.headers,
            },
        })
        .then(function (response) {            
            const { status, data, headers } = response;            
            return new ApiResponse(status, data, headers);
        })
        .catch(function (error) {
            if (error.response) {
                const { status, data } = error.response;
                console.log(error); 

                return new ApiResponse(status, data);
            }
            return new ApiResponse(400, {});
        });
};
/*
axios.interceptors.response.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2)); 
    return request; 
}); 
*/