import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
    yield takeEvery(constants.REQUEST_POLICY_TYPE_DD, requestPolicyTypeDD); 
}

export function* requestPolicyTypeDD(action: ActionType<typeof actions.requestPolicyTypeDD>): any {
    const userRequest = yield call(services.api.policy.getPolicyTypeDD); 
    if (userRequest.isSuccess) {
        yield put(actions.receivePolicyTypeDD(userRequest.data)); 
    }
}
