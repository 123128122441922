import * as React from 'react';
import { Form, Image } from 'react-bootstrap'; 
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg'; 

interface Props {
    search: (searchString: string) => void; 
    value?: string; 
    searchLoading: boolean;
}

interface State {
}

class QuickSearch extends React.Component<Props, State> {
    public state ={
    }

    public runCallback = (searchString?: string) => {
        if (searchString != undefined)
            this.props.search(searchString);
    }
  
    public render() {
        const { value, searchLoading } = this.props; 

        return (

            <div className="quick-search">                
                <Form 
                    onSubmit={(e) => this.runCallback(value)}>
                    <Form.Control 
                        name="quickSearch"
                        placeholder="Search..."
                        value = {value}
                        onChange={(e) => this.runCallback(e.target.value)}
                />
                </Form>
                <Image src={searchIcon} className="icon"/>
            </div>
        );
    }
}

export default (QuickSearch); 
