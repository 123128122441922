import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Col, Form, Row, Table } from 'react-bootstrap'; 
import VerticalMenu from 'components/common/VerticalMenu'; 
import SingleActionButton from '../common/SingleActionButton'; 
import addIcon from '../../assets/images/Blue/add.svg';
import GearButton from 'components/common/GearButton';
import { companyActions } from 'store/companies';
import _ from 'lodash'; 
import dateFormat from 'dateformat';
import { financialsActions } from 'store/financials';
import FeeScheduleBuilder from './FeeScheduleBuilder';
import * as models from '../../models';
import { userHasPermission } from '../../utils/helpers';

interface Props extends PropsFromRedux {    
}

interface State {
    activeCompany: string; 
    feeScheduleId: number; 
    showModal: boolean; 
}

class FeeSchedules extends React.Component<Props, State> {
    public state = {
        activeCompany: '',
        feeScheduleId: 0, 
        showModal: false, 
    }

    componentDidMount(): void {
        this.props.requestInsCompanyDD(); 
    }
    
    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.insCompanyDD, this.props.insCompanyDD) && this.props.insCompanyDD.totalCount > 0) {
            const company = this.props.insCompanyDD.resourceList[0].name;
            this.setActiveCompany(company); 
        }
    }

    public setActiveCompany(company: string) { 
        this.setState({...this.state, activeCompany: company}); 
        this.props.getFeeSchedulesByCompany(company); 
    }

    public loadCompanySchedules = (name: string) => {
        this.setActiveCompany(name); 
    }

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    } 

    public toggleFeeScheduleStatus = (rowId: string) => {
        this.props.toggleFeeScheduleStatus(parseInt(rowId));        
    }

    public deleteFeeSchedule = (rowId: string) => {        
        this.props.deleteFeeSchedule(parseInt(rowId));
    }

    public editFeeSchedule = (rowId: string) => {
        const item: models.FeeSchedule | undefined = this.props.feeSchedulesList.resourceList.find(x => x.feeScheduleId === parseInt(rowId)); 
        if (item) 
            this.setState({feeScheduleId: parseInt(rowId)}); 
        this.toggleModal(); 
    }    

    public handleAddFeeSchedule = () => {
        this.setState({feeScheduleId: 0}); 
        this.toggleModal(); 
    }

    public render() {
        const { feeSchedulesList, insCompanyDD, isInsCompanyDDLoading } = this.props;
        const { activeCompany, feeScheduleId, showModal } = this.state; 
        const canEditCreateFeeSchedules = userHasPermission("Can edit/create fee schedules"); 

        return (
            <>
            <FeeScheduleBuilder
                company={activeCompany}
                feeScheduleId={feeScheduleId}
                showModal={showModal}
                closeModal={this.toggleModal}
            /> 
            <Row className="fee-schedule-page">
                <Col sm="4" className="fee-vertical-nav-col">
                    <VerticalMenu
                        isLoading={isInsCompanyDDLoading}
                        activeTab={activeCompany}
                        tabs={Array.from(insCompanyDD.resourceList.map(x => x.name))}
                        selectItem={this.loadCompanySchedules}
                    />
                </Col>
                <Col sm="9">
                    <Card className='claim-card'>
                        <Form>                            
                            <Card.Title>
                                <Row>        
                                    <Col sm="6" className="test">
                                        {activeCompany}
                                    </Col>
                                    <Col sm="6">
                                        {canEditCreateFeeSchedules && 
                                            <SingleActionButton
                                                action="Build"
                                                title="Fee Schedule"
                                                img={addIcon}
                                                onClick={() => this.handleAddFeeSchedule()}
                                            />
                                        }
                                    </Col>                                    
                                </Row>
                            </Card.Title>
                            <Card.Body>                                                            
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Schedule Name</th>
                                            <th>Is Active</th>
                                            <th>Description</th>
                                            <th>Expiry Date</th>
                                            <th>Created Date</th>
                                            <th>Modified Date</th>
                                            <th>Created By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {feeSchedulesList.resourceList.map((fs: models.FeeSchedule, i: number) => (
                                            <tr key={i}>
                                            <td>{fs.scheduleName}</td>
                                            <td>{fs.isActive === true ? "Yes" : "No"}</td>
                                            <td>{fs.description}</td>
                                            <td>{dateFormat(fs.expiryDate, 'mm/dd/yyyy')}</td>
                                            <td>{dateFormat(fs.createdDate, 'mm/dd/yyyy')}</td>
                                            <td>{dateFormat(fs.modifiedDate, 'mm/dd/yyyy')}</td>
                                            <td>{fs.createdBy}</td>
                                            {canEditCreateFeeSchedules && 
                                                <td style={{overflow:'visible'}}>
                                                    <GearButton
                                                        actions={
                                                            [
                                                                {
                                                                    name: fs.isActive ? "Deactivate" : "Activate", 
                                                                    callBack: this.toggleFeeScheduleStatus
                                                                }, 
                                                                {
                                                                    name: 'Edit', 
                                                                    callBack: this.editFeeSchedule
                                                                },                                                                 
                                                                {
                                                                    name: 'Delete', 
                                                                    callBack: this.deleteFeeSchedule
                                                                }, 
                                                            ]
                                                        }
                                                        rowId={fs.feeScheduleId.toString()}
                                                    />
                                                </td>
                                            }
                                        </tr>
                                        ))}  
                                    </tbody>
                                </Table>                            
                            </Card.Body>
                        </Form>
                    </Card>
                </Col>
            </Row>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    feeSchedulesList: state.financials.financials.feeSchedulesResponse, 
    insCompanyDD: state.companies.dropdowns.insCompanyDD, 
    isInsCompanyDDLoading: state.companies.dropdowns.isInsCompanyDDLoading
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            toggleFeeScheduleStatus: financialsActions.toggleFeeScheduleStatus, 
            deleteFeeSchedule: financialsActions.deleteFeeSchedule, 
            getFeeSchedulesByCompany: financialsActions.requestFeeSchedulesByCompany,
            requestInsCompanyDD: companyActions.requestInsCompanyDD,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FeeSchedules);
