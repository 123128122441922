import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface ContactsStateShape {
    contact: models.Contact; 
    contactsResponse: models.ContactSimpleResponse;
    isContactLoading: boolean; 
    isContactListLoading: boolean;     
}

export const contactsDefaultState: ContactsStateShape = {    
    contact: models.defaultContact, 
    contactsResponse: models.defaultResponse,
    isContactLoading: false, 
    isContactListLoading: false,     
}

export interface ContactListsShape {
    companyExaminersList: models.ContactSimpleResponse; 
    examinersList: models.ContactSimpleResponse;
    isCompanyExaminersListLoading: boolean; 
    isExaminersListLoading: boolean; 
    fieldAdjustersList: models.ContactSimpleResponse;
    isFieldAdjuestersListLoading: boolean; 
    fieldDirectorsList: models.ContactSimpleResponse;
    isFieldDirectorsListLoading: boolean;     
    reviewersList: models.ContactSimpleResponse;
    isReviewersListLoading: boolean; 
}

export const contactListsDefaultState: ContactListsShape = {
    companyExaminersList: models.defaultResponse, 
    examinersList: models.defaultResponse,
    isCompanyExaminersListLoading: false, 
    isExaminersListLoading: false, 
    fieldAdjustersList: models.defaultResponse, 
    isFieldAdjuestersListLoading: false, 
    fieldDirectorsList: models.defaultResponse,
    isFieldDirectorsListLoading: false,     
    reviewersList: models.defaultResponse,
    isReviewersListLoading: false,  
};  

export interface DropdownsShape {
    adjustersDD: models.DropdownListResponse; 
    companyExaminerDD: models.DropdownListResponse; 
    examinersDD: models.DropdownListResponse; 
    fieldDirectorsDD: models.DropdownListResponse; 
    isAdjustersDDLoading: boolean; 
    isCompanyExaminerDDLoading: boolean; 
    isExaminersDDLoading: boolean; 
    isFieldDirectorsDDLoading: boolean; 
    isReviewersDDLoading: boolean; 
    reviewersDD: models.DropdownListResponse; 
}

export const DropDownDefaultState: DropdownsShape = {
    adjustersDD: models.defaultResponse, 
    companyExaminerDD: models.defaultResponse, 
    examinersDD: models.defaultResponse,
    fieldDirectorsDD: models.defaultResponse,  
    isAdjustersDDLoading: false, 
    isCompanyExaminerDDLoading: false, 
    isExaminersDDLoading: false, 
    isFieldDirectorsDDLoading: false, 
    isReviewersDDLoading: false, 
    reviewersDD: models.defaultResponse, 
}

export interface State {
    contacts: ContactsStateShape;
    contactLists: ContactListsShape;  
    dropdowns: DropdownsShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    contacts: (state = contactsDefaultState, action) => {
        switch (action.type) {
            case constants.RECEIVE_CONTACT:             
                return {
                    ...state, 
                    contact: action.payload.contact,
                    isContactLoading: false, 
                }; 

            case constants.REQUEST_CONTACT: 
                return {
                    ...state,
                    contactId: action.payload.contactId,
                    isContactLoading: true, 
                }; 
            
            case constants.REQUEST_CONTACT_LIST:
                return {
                    ...state, 
                    contactsResponse: models.defaultResponse, 
                    isContactListLoading: true, 
                }; 

            case constants.RECEIVE_CONTACT_LIST: 
                return {
                    ...state,
                    contactsResponse: action.payload.response, 
                    isContactListLoading: false, 
                }; 

            case constants.UPDATE_CONTACT: 
                return {
                    ...state, 
                    isContactLoading: true, 
                }; 
                
            default: 
                return state; 
        }
    },      
    contactLists: (state = contactListsDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_COMPANY_EXAMINERS: 
                return {
                    ...state,
                    isCompanyExaminersListLoading: true,
                }; 

            case constants.RECEIVE_COMPANY_EXAMINERS: 
                return {
                    ...state,
                    companyExaminersList: action.payload.response,
                    isCompanyExaminersListLoading: false, 
                }; 
            
            case constants.REQUEST_EXAMINERS_LIST:
                return {
                    ...state,
                    isExaminersListLoading: true, 
                };  
            case constants.RECEIVE_EXAMINERS_LIST: 
                return {
                    ...state,
                    examinersList: action.payload.response,
                    isExaminersListLoading: false,
                }; 

            case constants.REQUEST_FIELD_ADJSTERS_LIST: 
                return {
                    ...state, 
                    isFieldAdjuestersListLoading: true, 
                }; 
            case constants.RECEIVE_FIELD_ADJSTERS_LIST: 
                return {
                    ...state, 
                    fieldAdjustersList: action.payload.response,
                    isFieldAdjuestersListLoading: false, 
                }; 

            case constants.REQUEST_FIELD_DIRECTORS_LIST: 
                return {
                    ...state,
                    isFieldDirectorsListLoading: true,
                }; 
            case constants.RECEIVE_FIELD_DIRECTORS_LIST: 
                return {
                    ...state,
                    fieldDirectorsList: action.payload.response,
                    isFieldAdjuestersListLoading: false,
                }; 

            case constants.REQUEST_REVIEWERS_LIST:
                return {
                    ...state,
                    isReviewersListLoading: true,
                }; 
            case constants.RECEIVE_REVIEWERS_LIST:
                return {
                    ...state,
                    reviewersList: action.payload.response,
                    isReviewersListLoading: false,
                }; 

            default: 
                return state;
        }
    }, 
    dropdowns: (state = DropDownDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_ADJUSTERS_DD: 
                return {
                    ...state, 
                    isAdjustersDDLoading: true, 
                }

            case constants.RECEIVE_ADJUSTERS_DD: 
                return {
                    ...state, 
                    isAdjustersDDLoading: false, 
                    adjustersDD: action.payload.response, 
                }

            case constants.REQUEST_COMPANY_EXAMINERS_DD: 
                return {
                    ...state,
                    isCompanyExaminerDDLoading: true,
                };            
            case constants.RECEIVE_COMPANY_EXAMINERS_DD:
                return {
                    ...state, 
                    isCompanyExaminerDDLoading: false, 
                    companyExaminerDD: action.payload.response
                }; 

            case constants.REQUEST_EXAMINERS_DD: 
                return {
                    ...state, 
                    isExaminersDDLoading: true, 
                }

            case constants.RECEIVE_EXAMINERS_DD: 
                return {
                    ...state, 
                    isExaminersDDLoading: false, 
                    examinersDD: action.payload.response, 
                }

            case constants.REQUEST_FIELD_DIRECTORS_DD: 
                return {
                    ...state, 
                    isFieldDirectorsDDLoading: true, 
                }

            case constants.RECEIVE_FIELD_DIRECTORS_DD: 
                return {
                    ...state,
                    isFieldDirectorsDDLoading: false, 
                    fieldDirectorsDD: action.payload.response,
                }

            case constants.REQUEST_REVIEWERS_DD: 
                return {
                    ...state, 
                    isReviewersDDLoading: true, 
                }

            case constants.RECEIVE_REVIEWERS_DD: 
                return {
                    ...state, 
                    isReviewersDDLoading: false, 
                    reviewersDD: action.payload.response, 
                }

            default: 
                return state;
        }
    }
});
