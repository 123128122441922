import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Row, Col, Spinner } from 'react-bootstrap'; 
import dateFormat from 'dateformat'; 
import editIcon from '../../assets/images/Blue/edit.svg';
import SingleActionButton from '../common/SingleActionButton';
import InsuranceInformationModal from './InsuranceInformationModal';
import ContactInfoModal from '../common/ContactInfoModal'; 
import { userHasPermission } from '../../utils/helpers';
import * as models from '../../models';
import services from 'services';

interface Props extends PropsFromRedux {
}

interface State {    
    contactMethods?: models.ContactMethods; 
    showModal: boolean;
    showConctactInfoModal: boolean;
}

class InsuranceInformation extends React.Component<Props, State> {
    public state = {        
        contactMethods: undefined, 
        showModal: false, 
        showConctactInfoModal: false,         
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    }

    public toggleContactInfoModal = () => {        
        this.setState({showConctactInfoModal: !this.state.showConctactInfoModal});                
    }  

    public showContactInfo = async (contactId: number) => {
        const response = await services.api.contact.getContactMethods(contactId); 
        if(response.isSuccess){
            this.setState({contactMethods: response.data}); 
            this.toggleContactInfoModal(); 
        }
    }  

    public render() {
        const { contactMethods, showConctactInfoModal, showModal } = this.state;
        const { claim, isClaimLoading } = this.props; 
        const canEditClaim = userHasPermission("Can Edit Claims Info") && claim.status.toLowerCase() !== 'closed'; 

        return (
            <>
            <InsuranceInformationModal
                showModal={showModal}
                closeModal={this.toggleModal}
            />
            <ContactInfoModal
                showModal={showConctactInfoModal}
                closeModal={this.toggleContactInfoModal}
                contactMethods={contactMethods}
            />
            <Card className="claim-card">                
                <Card.Title>Insurance Information
                    {isClaimLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    {canEditClaim && 
                        <SingleActionButton
                            action="Edit"
                            title="Ins Info"
                            img={editIcon}
                            onClick={this.toggleModal}
                        />
                    }
                </Card.Title>
                <Row>
                    <Col md={3} lg={3} className="card-label">Insurance Company:</Col>
                    <Col md={3} lg={3} >{claim.insuranceCompany}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Insurance Claim Number:</Col>
                    <Col md={3} lg={3} >{claim.insClaimNumber}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Date of Loss:</Col>
                    <Col md={3} lg={3} >{claim.dateLoss && dateFormat(claim.dateLoss, 'mm/dd/yyyy')}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Date Reported:</Col>
                    <Col md={3} lg={3} >{claim.dateReported && dateFormat(claim.dateReported, 'mm/dd/yyyy')}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Date Received:</Col>
                    <Col md={3} lg={3} >{claim.claimID !== 0 && dateFormat(claim.dateReceived || "", 'mm/dd/yyyy')}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Insurance Examiner:</Col>
                    <Col md={3} lg={3} className="value-link" onClick={(e) => this.showContactInfo(claim.examiner?.id)}>{claim.examiner?.name}</Col>
                </Row>
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
    isClaimLoading: state.claims.claim.isClaimLoading
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(InsuranceInformation);