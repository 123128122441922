export interface Auth2UserSearchRequest {
    firstName: string,
    lastName: string,
    email: string,
    role: string
}

export const defaultAuth2UserSearchRequest: Auth2UserSearchRequest = {
    firstName: '',
    lastName: '',
    email: '',
    role: ''
}
