import * as React from 'react';
import gear from '../../assets/images/Blue/gear.svg';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ButtonAction } from '../../models/app/buttonAction'; 

interface Props {
    actions: ButtonAction[];
    rowId: string;  
 }

interface State {
}

class GearButton extends React.Component<Props, State> {
    sortActions(a: string, b: string) {
        return a < b ? -1 : (a > b ? 1 : 0); 
    }

    public render() {
        const { actions } = this.props;

        return (
            <>
                <OverlayTrigger
                    key="overlayTrigger"
                    placement="top"
                    overlay={
                        <Tooltip className="my-tooltip" id="toolTip">Actions</Tooltip>
                    }
                >
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <img src={gear}/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {actions.sort((a, b) => {return a.name >= b.name ? 1 : -1}).map((action: ButtonAction, i: number) => (
                                <Dropdown.Item key={i} onClick={() => action.callBack(this.props.rowId)} >{action.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </OverlayTrigger>
            </>
        ); 
    }
}

export default GearButton;
