import * as models from '..';

export interface InvoicesFullResponse extends models.ResponseBase<Invoice> { }

export interface Invoice {
    invoiceId: number; 
    claimId: number; 
    feeSchedule: string; 
    invoiceNumber: string;
    invoiceGroup: string; 
    insCompany: string; 
    claimNumber: string; 
    adjuster: string; 
    invoiceRevisionNumber: number; 
    invoiceDate: string;
    invoicePDF: string; 
    isSupp: boolean; 
    isTandE: boolean; 
    grossLoss: number; 
    isTaxExempt: boolean; 
    taxRate: number; 
    subTotal: number; 
    salesTax: number; 
    outstandingBalance: number; 
    grandTotal: number; 
    invoiceFees?: models.InvoiceFee[]; 
    comments: string; 
    hours?: number; 
    claimHasAdminFee: boolean; 
    isDeleted: boolean; 
}

export const defaultInvoice: Invoice = { 
    invoiceId: 0, 
    claimId: 0, 
    feeSchedule: "", 
    invoiceDate: "", 
    invoiceNumber: "", 
    invoiceGroup: "",
    insCompany: "",
    claimNumber: "", 
    adjuster: "", 
    invoicePDF: "", 
    invoiceRevisionNumber: 0, 
    isSupp: false, 
    isTandE: false, 
    grossLoss: 0, 
    isTaxExempt: false, 
    taxRate: 0, 
    subTotal: 0, 
    salesTax: 0, 
    outstandingBalance: 0,
    grandTotal: 0,
    comments: "",    
    claimHasAdminFee: false, 
    isDeleted: false, 
}