import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface CompaniesStateShape {
    company: models.Company; 
    companiesResponse: models.CompaniesResponse;
    insCompaniesList: models.CompaniesResponse; 
    isCompanyLoading: boolean;
    isCompanyListLoading: boolean; 
    isInsCompanyListLoading: boolean; 
 }

export const companiesDefaultState: CompaniesStateShape = {
    company: models.defaultCompany, 
    companiesResponse: models.defaultResponse,     
    insCompaniesList: models.defaultResponse, 
    isCompanyLoading: false, 
    isCompanyListLoading: false,    
    isInsCompanyListLoading: false, 
};

export interface FormFilters {
    allCompaniesDD: models.DropdownListResponse, 
    agentCompanyDD: models.DropdownListResponse, 
    companyDD: models.DropdownListResponse; 
    insCompanyDD: models.DropdownListResponse; 
    isAllCompanyDDLoading: boolean; 
    isAgentCompanyDDLoading: boolean; 
    isCompanyDDLoading: boolean; 
    isInsCompanyDDLoading: boolean; 
    isMortgageCompanyDDLoading: boolean; 
    mortgageCompanyDD: models.DropdownListResponse; 
}

export const FormFilterDefaultState: FormFilters = {
    allCompaniesDD: models.defaultResponse, 
    agentCompanyDD: models.defaultResponse, 
    companyDD: models.defaultResponse, 
    insCompanyDD: models.defaultResponse, 
    isAllCompanyDDLoading: false, 
    isAgentCompanyDDLoading: false, 
    isCompanyDDLoading: false, 
    isInsCompanyDDLoading: false,
    isMortgageCompanyDDLoading: false, 
    mortgageCompanyDD: models.defaultResponse, 
}

export interface State {    
    company: CompaniesStateShape;
    dropdowns: FormFilters; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    company: (state = companiesDefaultState, action) => {
        switch (action.type) {            
            case constants.RECEIVE_COMPANY:
                return {
                    ...state, 
                    company: action.payload.company,
                    isCompanyLoading: false, 
                }; 

            case constants.REQUEST_COMPANY:
                return {
                    ...state,
                    company: models.defaultCompany,
                    companyId: action.payload.companyId,
                    isCompanyLoading: true, 
                }; 

            case constants.REQUEST_COMPANY_LIST:
                return {
                    ...state, 
                    companiesResponse: models.defaultResponse,
                    isCompanyListLoading: true, 
                }; 

            case constants.RECEIVE_COMPANY_LIST: 
                return {
                    ...state,
                    companiesResponse: action.payload.response, 
                    isCompanyListLoading: false, 
                }; 

            case constants.REQUEST_INSURANCE_COMPANY_LIST: 
                return {
                    ...state, 
                    isInsCompanyListLoading: true,
                };

            case constants.RECEIVE_INSURANCE_COMPANY_LIST: 
                return {
                    ...state,
                    isInsCompanyListLoading: false, 
                    insCompaniesList: action.payload.response,
                }; 

            case constants.PATCH_COMPANY:
                return {
                    ...state, 
                    isCompanyLoading: true,
                }; 

            default: 
                return state; 
        }
    }, 
    dropdowns: (state = FormFilterDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_ALL_COMPANIES_DD: 
                return {                    
                    ...state,
                    isAllCompanyDDLoading: true, 
                }; 
            case constants.RECEIVE_ALL_COMPANIES_DD: 
                return {
                    ...state, 
                    allCompaniesDD: action.payload.response, 
                    isAllCompanyDDLoading: false, 
                }
            case constants.REQUEST_AGENT_COMPANY_DD: 
                return {
                    ...state, 
                    isAgentCompanyDDLoading: true, 
                }; 
            case constants.RECEIVE_AGENT_COMPANY_DD: 
                return {
                    ...state,
                    isAgentCompanyDDLoading: false, 
                    agentCompanyDD: action.payload.response, 
                }; 

            case constants.REQUEST_COMPANY_DROPDOWN: 
                return {
                    ...state,
                    isCompanyDDLoading: true, 
                }; 
            case constants.RECEIVE_COMPANY_DROPDOWN: 
                return {
                    ...state,
                    isCompanyDDLoading: false, 
                    companyDD: action.payload.response, 
                }; 

            case constants.REQUEST_INS_COMPANY_DD: 
                return {
                    ...state,
                    isInsCompanyDDLoading: true,
                }; 

            case constants.RECEIVE_INS_COMPANY_DD: 
                return {
                    ...state, 
                    isInsCompanyDDLoading: false, 
                    insCompanyDD: action.payload.response, 
                }; 

            case constants.REQUEST_MORTGAGE_COMPANY_DD: 
                return {
                    ...state,
                    isMortgageCompanyDDLoading: true, 
                }; 
            case constants.RECEIVE_MORTGAGE_COMPANY_DD: 
                return {
                    ...state,
                    mortgageCompanyDD: action.payload.response, 
                    isMortgageCompanyDDLoading: false, 
                }; 

            default: 
                return state; 
        }
    }, 
});
