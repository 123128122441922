import * as models from '..';

export interface FeeScheduleRange {
    feeScheduleId: number; 
    feeScheduleRangeId: number; 
    rangeStart?: number; 
    rangeEnd?: number; 
    feeType: string; 
    amount: number; 
    percent?: number; 
    rate?: number; 
}

export const defaultFeeScheduleRange : FeeScheduleRange =  {
    feeScheduleId: 0, 
    feeScheduleRangeId: 0,
    feeType: 'Flat',
    amount: 0.00,  
}