import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Toast, ToastContainer } from 'react-bootstrap';
import { userActions } from 'store/user';
import * as enums from 'models/enums'; 

interface Props extends PropsFromRedux {    
}

interface State {
}

class AppToaster extends React.Component<Props, State> {
    public state = {
    };

    public renderToast = () => {
        const { appToast, clearAppToast } = this.props; 
        const showToast = appToast !== undefined; 

        switch(appToast?.type) { 
            case enums.AppToastTypes.Failure: 
                return (
                    <Toast show={showToast} onClose={clearAppToast}>
                        <Toast.Header style={{color: "red"}} closeButton>ATTENTION !!</Toast.Header>
                        <Toast.Body style={{whiteSpace: "pre-line"}} >{appToast.message}</Toast.Body>
                    </Toast>
                ); 
            case enums.AppToastTypes.Success: 
                return (
                    <Toast autohide delay={3000} show={showToast} onClose={clearAppToast}>
                    <Toast.Body style={{backgroundColor: 'green', color: 'white'}}>{appToast.message}</Toast.Body>
                </Toast>
                ); 
            default: 
                return(<></>); 
            }        
    }
    
    public render() {
        return (
            <ToastContainer position='middle-center'>
                {this.renderToast()}
            </ToastContainer>
        ); 
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    appToast: state.user.actions.appToast,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            clearAppToast: userActions.clearAppToast,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AppToaster);


