import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import * as models from '../../models'; 
import _ from 'lodash'; 
import { claimsActions } from 'store/claims';

interface Props extends PropsFromRedux {
    appSectionCode: string;   
    showModal: boolean;
    closeModal: () => void; 
}

interface State {
    fileSelect: boolean; 
    files: file[]
    rawFiles: any
}

interface file {
    name?: string; 
    type?: string; 
    docType?: string; 
}

class AttachmentModal extends React.Component<Props, State> {
    public state = {
        fileSelect: true, 
        files: [{
            name: "", 
            type: "",
            docType: ""
        }], 
        rawFiles: '', 
    };

    public onFileSelect = (e: any) => {        
        const files = Array.from(e.target.files) as file[];

        this.setState({
            fileSelect: !this.state.fileSelect, 
            files: _.map(files, item => { return { name: item.name, type: item.type, docType: "" }}), 
            rawFiles: e.target.files
        }); 
    }

    public setDocType = (fileName: string, e: any) => {        
        this.setState({files: this.state.files.map(x => (x.name === fileName ? { ...x, docType: e.target.value} : x))});
    }

    public resetFormStateAndClose = () => {
        this.setState({fileSelect: true, files: []});
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        const { addClaimAttachment, claimId } = this.props; 
        e.preventDefault();      
                
        for (const key of Object.keys(this.state.files)) {
            const blobData = this.state.rawFiles[parseInt(key)]; 
            var formData = new FormData(); 
            formData.append('file', blobData); 
            addClaimAttachment(claimId, this.state.files[parseInt(key)].docType, formData); 
        }
        this.props.closeModal(); 
    }

    public render() {
        const { files } = this.state; 
        const { appSectionCode, closeModal, documentTypeResponse, showModal } = this.props;         
        const appSectionSpecificList = documentTypeResponse.resourceList.filter(x => x.applicationSection == appSectionCode);
        
        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >                
                <Modal.Header closeButton>
                    <Modal.Title>Upload Attachment</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>
                    <Modal.Body>           
                        <Form.Group as={Row} controlId="file" className="input-field-container">
                            <Col sm="12">
                                <Form.Control type="file" multiple onChange={(e) => this.onFileSelect(e)} />
                            </Col>
                        </Form.Group>                        
                        {files.filter(f => f.name !== "").length > 0 &&
                            files.map((f:file, i:number) => (
                                <Form.Group key={i} as={Row} controlId="fileDetails" className="input-field-container">
                                    <Col sm="5">
                                        <Form.Label key={i} >{f.name}</Form.Label>
                                    </Col>
                                    <Col sm="6">
                                        <Form.Select name="typeList" onChange={(e) => this.setDocType(f.name || "", e)} >
                                            <option key={-1}>Choose...</option>
                                            {appSectionSpecificList && appSectionSpecificList.map((dt: models.DocumentType, i: number) => (
                                                <option key={i}>{dt.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                            </Form.Group>
                            ))                        
                        }             
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>            
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    documentTypeResponse: state.admin.documentType.documentTypesResponse, 
    claimId: state.claims.claim.claim.claimID,  
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addClaimAttachment: claimsActions.addClaimAttachment, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AttachmentModal);
