import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Row, Col, Spinner } from 'react-bootstrap'; 
import dateFormat from 'dateformat'; 
import * as models from '../../models';
import NumberFormat from 'react-number-format';
import editIcon from '../../assets/images/Blue/edit.svg';
import SingleActionButton from '../common/SingleActionButton';
import PolicyInformationModal from './PolicyInformationModal'; 
import { userHasPermission } from '../../utils/helpers';

interface Props extends PropsFromRedux {
    policy: models.Policy; 
    isLoading: boolean; 
}

interface State {
    showModal: boolean; 
}

class PolicyInformation extends React.Component<Props, State> {
    public state = {
        showModal: false, 
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    }

    public render() {
        const { showModal } = this.state;
        const { claim, policy, isLoading } = this.props; 
        const canEditClaim = userHasPermission("Can Edit Claims Info") && claim.status.toLowerCase() !== 'closed'; 

        return (
            <>
            <PolicyInformationModal
                    showModal={showModal}
                    closeModal={this.toggleModal}
                /> 
            <Card className="claim-card">
                <Card.Title>Policy Information
                    {isLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    {canEditClaim && 
                        <SingleActionButton
                            action="Edit"
                            title="Policy"
                            img={editIcon}
                            onClick={this.toggleModal}
                        />
                    }
                </Card.Title>
                <Row>
                    <Col md={3} lg={3} className="card-label">Policy Number:</Col>
                    <Col md={3} lg={3}>{policy?.policyNumber}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Policy Type:</Col>
                    <Col md={3} lg={3}>{policy?.policyType}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Policy Deductible:</Col>
                    <Col md={3} lg={3}><NumberFormat value={policy?.policyDeductible?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Policy Deductible 2:</Col>
                    <Col md={3} lg={3}><NumberFormat value={policy?.policyDeductible2?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Named Storm Deductible:</Col>
                    <Col md={3} lg={3}><NumberFormat value={policy?.namedStormDeductible?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Effective Date:</Col>
                    <Col md={3} lg={3}>{policy?.effectiveDate && dateFormat(policy?.effectiveDate, 'mm/dd/yyyy')}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Expiration Date:</Col>
                    <Col md={3} lg={3}>{policy?.expirationDate && dateFormat(policy?.expirationDate, 'mm/dd/yyyy')}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Mortgage Company:</Col>
                    <Col md={3} lg={3}>{policy?.policyMortgageCompanyName}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Insurance Agency:</Col>
                    <Col md={3} lg={3}>{policy?.policyAgentCompanyName}</Col>
                </Row>
            </Card>   
            </>      
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,        
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PolicyInformation);