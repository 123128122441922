export interface Address {
    addressId: number; 
    addressType: string; 
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: string;
}

export const defaultAddress: Address = {
    addressId: 0, 
    addressType: '', 
    addressLine1: '', 
    addressLine2: '', 
    city: '', 
    state: '', 
    postalCode: '', 
}