import * as models from 'models'; 

export interface ZipSearch {
    zipCode: string; 
    stateName: string;
    countyName: string;
    cityName: string;
    cityList: CityList; 
    countyList: CountyList; 
}

export const defaultZipSearch: ZipSearch = {
    zipCode: '', 
    stateName: '',
    countyName: '',
    cityName: '',
    cityList: {cities: []},
    countyList: {counties: []},
}

export interface City {
    cityId: number; 
    cityName: string; 
}

export interface CityList {
    cities: City[]
 }

export interface County {
    countyId: number; 
    countyName: string; 
}

export interface CountyList {
    counties: County[]
 }