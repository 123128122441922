import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Card, Spinner } from 'react-bootstrap'; 
import * as models from '../../models';
import dateFormat from 'dateformat'; 
import addIcon from '../../assets/images/Blue/add.svg';
import trashIcon from '../../assets/images/Blue/trash.svg'; 
import SingleActionButton from '../common/SingleActionButton';
import AttachmentModal from './AttachmentModal'; 
import services from 'services';
import utils from '../../utils'; 
import { userHasPermission } from '../../utils/helpers';
import { claimsActions } from 'store/claims';
import { CLAIM_ACTION_STATUSES } from 'store/enums';

interface Props extends PropsFromRedux {
    appSection: string; 
    attachments?: models.Attachment[];
    claimId: number; 
    isLoading: boolean; 
    title: string; 
}

interface State {
    showModal: boolean; 
}

class Attachments extends React.Component<Props, State> {
    public state = {
        showModal: false, 
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    } 

    downloadFile = async (id: number) => {
        const response = await services.api.claim.getClaimAttachment(this.props.claimId, id); 
        if(response) {
            utils.filehelpers.downloadFile(response); 
        }
    }

    deleteAttachment = (id: number) => {
        this.props.deleteClaimAttachment(this.props.claimId, id);         
    }

    public render() {
        const { appSection, attachments, claim, isLoading, lastActionStatus, title } = this.props;
        const { showModal } = this.state; 
        const runSpinner = isLoading || (lastActionStatus === CLAIM_ACTION_STATUSES.DELETING_ATTACHMENT);
        const canEditClaim = userHasPermission("Can Edit Claims Info") && claim.status.toLowerCase() !== 'closed'; 

        return (
            <>
            <AttachmentModal
                appSectionCode={appSection}
                showModal={showModal}
                closeModal={this.toggleModal}
            /> 
            <Card className="claim-card">
                <Card.Title>{title}
                {runSpinner && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    {canEditClaim && 
                        <SingleActionButton
                            action="Add"
                            title={title}
                            img={addIcon}
                            onClick={this.toggleModal}
                        />
                    }
                </Card.Title>      
                {(attachments?.length || 0) > 0 &&
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Uploaded Date</th>
                                <th>Compass File Name<br></br>(Original File Name)</th>
                                <th>Document Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attachments?.map((attachment, i) => (
                                <tr key={i}>
                                    <td>{attachment.id !== 0 && dateFormat(attachment.uploadDate, 'mm/dd/yyyy')}</td>
                                    <td className="value-link" onClick={() => this.downloadFile(attachment.id)}>{attachment.fileName}<br></br>{attachment.id !== 0 && (attachment.originalfileName)}</td>
                                    <td>{attachment.documentType}</td>
                                    <td>
                                        <SingleActionButton
                                            action="Delete"
                                            title={"Attachment"}
                                            img={trashIcon}
                                            onClick={() => this.deleteAttachment(attachment.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
    lastActionStatus: state.claims.claim.lastActionStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deleteClaimAttachment: claimsActions.deleteClaimAttachment, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Attachments);