import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';
import { claim } from 'services/api-service';

// Claim Search 
export const requestClaims = (request: models.ClaimsRequest) =>
    action(constants.REQUEST_CLAIMS, { request });

export const receiveClaims = (response: models.ClaimsListResponse) =>
    action(constants.RECEIVE_CLAIMS, { response });

// Claims Export
export const requestClaimCSV = (request: models.ClaimsRequest) =>
    action(constants.REQUEST_CLAIMS_CSV, { request });     

export const receiveClaimCSV = () =>
    action(constants.RECEIVE_CLAIMS_CSV);         

// Claim Stuff
export const addClaimRequest = (request: models.AddClaim) => 
    action(constants.REQUEST_ADD_CLAIM, { request }); 
export const addClaimReceive = () =>
    action(constants.RECEIVE_ADD_CLAIM); 

export const initializeClaim = (claim: models.ClaimSimple) => 
    action(constants.INITIALIZE_CLAIM, { claim }); 

export const addClaimAttachment = (claimId: any, documentType: string, formData: FormData) =>
    action(constants.ADD_CLAIM_ATTACHMENT, { claimId, documentType, formData }); 

export const addClaimComment = (claimId: any, comment: models.Comment) =>
    action(constants.ADD_CLAIM_COMMENT, {claimId, comment}); 

export const addClaimInvoice = (claimId: number, request: any) =>
    action(constants.ADD_CLAIM_INVOICE, {claimId, request});

export const addReserveCoverage = (claimId: any, request: models.ReserveCoverage) =>
    action(constants.ADD_RESERVE_COVERAGE, {claimId, request}); 

export const clearClaimSingle = () =>
    action(constants.CLEAR_CLAIM_SINGLE); 

export const deleteClaimInvoice = (claimId: number, id: number, reason: string) =>
    action(constants.DELETE_CLAIM_INVOICE, {claimId, id, reason}); 

export const deleteClaimAttachment = (claimId: number, id: number) =>
    action(constants.DELETE_CLAIM_ATTACHMENT, {claimId, id}); 

export const deleteReserveCoverage = (claimId: number, id: number) => 
    action(constants.DELETE_RESERVE_COVERAGE, {claimId, id}); 

export const patchClaim = (claimId: any, patch: object) =>
    action(constants.PATCH_CLAIM, {claimId, patch}); 

export const patchClaimReserveCoverage = (claimId: number, id: number, patch: object) =>
    action(constants.PATCH_CLAIM_RESERVE_COVERAGE, {claimId, id, patch }); 

export const patchInsuredPerson = (claimId: any, insuredPersonId: any, patch: object) =>
    action(constants.PATCH_INSURED_PERSON, {claimId, insuredPersonId, patch}); 

export const patchLossLocation = (claimId: number, patch: object) =>
    action(constants.PATCH_LOSS_LOCATION, { claimId, patch }); 

export const patchPolicy = (claimId: number, patch: object) =>
    action(constants.PATCH_POLICY, { claimId, patch }); 

export const requestAttachments = (claimId: number) =>
    action(constants.REQUEST_ATTACHMENTS, { claimId }); 
export const receiveAttachments = (response: models.AttachmentsResponse) =>
    action(constants.RECEVIE_ATTACHMENTS, { response }); 

export const requestClaimParty = (claimId: number) =>
    action(constants.REQUEST_CLAIM_PARTY, { claimId });
export const receiveClaimParty = (response: models.ClaimPartyResponse) =>
    action(constants.RECEIVE_CLAIM_PARTY, { response }); 

export const requestClaimSingle = (claimId: number) =>
    action(constants.REQUEST_CLAIM_SINGLE, { claimId });

export const requestClaimSingleByClaimNumber = (claimNumber: string) =>
    action(constants.REQUEST_CLAIM_SINGLE_BY_CLAIM_NUMBER, { claimNumber });

export const requestClaimTypeList = (request: models.ClaimTypesRequest) =>
    action(constants.REQUEST_CLAIM_TYPE_LIST, { request }); 

export const receiveClaimTypeList = (response: models.ClaimTypesResponse) =>
    action(constants.RECEIVE_CLAIM_TYPE_LIST, { response }); 

export const requestEmailedClaims = (claimId: number) =>
    action(constants.REQUEST_EMAILED_CLAIMS, { claimId });
export const receiveEmailedClaims = (response: models.EmailedClaimsResponse) =>
    action(constants.RECEIVE_EMAILED_CLAIMS, { response }) ;

export const requestInvoices = (claimId: number) => 
    action(constants.REQUEST_INVOICES, { claimId }); 
export const receiveInvoices = (response: models.InvoicesListResponse) =>
    action(constants.RECEIVE_INVOICES, { response }); 

export const requestInsuredPerson = (claimId: number) =>
    action(constants.REQUEST_INSURED_PERSON, { claimId }); 
export const receiveInsuredPerson = (response: models.InsuredPerson) =>
    action(constants.RECEIVE_INSURED_PERSON, { response }); 

export const requestLossLocation = (claimId: number) =>
    action(constants.REQUEST_LOSS_LOCATION, { claimId }); 
export const receiveLossLocation = (response: models.Address) =>
    action(constants.RECEIVE_LOSS_LOCATION, { response }); 

export const requestMergedReports = (claimId: number) =>
    action(constants.REQUEST_MERGED_REPORTS, { claimId }); 
export const receiveMergedReports = (response: models.MergedReportsResponse) =>
    action(constants.RECEIVE_MERGED_REPORTS, { response }); 

export const requestPolicy = (claimId: number) =>
    action(constants.REQUEST_POLICY, { claimId }); 
export const receivePolicy = (response: models.Policy) =>
    action(constants.RECEIVE_POLICY, { response }); 

export const requestReserveCoverages = (claimId: number) =>
    action(constants.REQUEST_RESERVE_COVERAGES, { claimId }); 
export const receiveReserveCoverages = (response: models.ReserveCoveragesResponse) =>
    action(constants.RECEIVE_RESERVE_COVERAGES, { response }); 

export const setClaimActionStatus = (request: string) =>
    action(constants.SET_CLAIM_ACTION_STATUS, { request }); 

export const requestStatuses = () =>
    action(constants.REQUEST_STATUSES); 
export const receiveStatuses = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_STATUSES, { response }); 

// comment actions    
export const requestCommentsCorrection = (claimId: number, request: models.Paging) =>
    action(constants.REQUEST_COMMENTS_CORRECTION, { claimId, request }); 

export const receiveCommentsCorrection = (response: models.CommentsResponse) =>
    action(constants.RECEIVE_COMMENTS_CORRECTION, { response });     

export const requestCommentsActivityLog = (claimId: number, request: models.CommentsRequest) =>
    action(constants.REQUEST_COMMENTS_ACTIVITY_LOG, { claimId, request }); 

export const receiveCommentsActivityLog = (response: models.CommentsResponse) =>
    action(constants.RECEIVE_COMMENTS_ACTIVITY_LOG, { response });     

export const requestCommentsReserve = (claimId: number, request: models.Paging) =>
    action(constants.REQUEST_COMMENTS_RESERVE, { claimId, request }); 

export const receiveCommentsReserve = (response: models.CommentsResponse) =>
    action(constants.RECEIVE_COMMENTS_RESERVE, { response }); 
    
export const requestInvoiceDraft = (claimId: number) => 
    action(constants.REQUEST_INVOICE_DRAFT, { claimId }); 

export const receiveInvoiceDraft = (response: models.Invoice) =>
    action(constants.RECEIVE_INVOICE_DRAFT, { response }); 

export const toastClear = () => 
    action(constants.TOAST_CLEAR_MESSAGE); 

export const toastSet = (messages: string[]) => 
    action(constants.TOAST_SET_MESSGAGE, { messages }); 


// dropdowns
export const requestLossCausesDD = () =>
    action(constants.REQUEST_LOSS_CAUSES_DD); 
export const receiveLossCausesDD = (response: models.DropdownListResponse) => 
    action(constants.RECEIVE_LOSS_CAUSES_DD, { response }); 