import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col, Image } from 'react-bootstrap'; 
import * as models from '../../models'; 
import { claimsActions } from 'store/claims';
import PhoneNumberFormCard from '../common/PhoneNumberFormCard'; 
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg'; 
import { locationActions } from 'store/locations';

interface Props extends PropsFromRedux {  
    showModal: boolean;
    closeModal: () => void; 
}

interface State {
    insuredPerson: models.InsuredPerson, 
    zipSearch: models.ZipSearch,
    phoneNumbers: models.PhoneNumber[],  
}

class InsuredInformationModal extends React.Component<Props, State> {
    public state = {
        insuredPerson: models.defaultInsuredPerson,
        zipSearch: models.defaultZipSearch,
        phoneNumbers: [],
    };

    componentDidMount() {                
        this.setState({zipSearch: this.props.zipSearch}); 
    }

    public setPhoneNumberState = (insuredPerson: models.InsuredPerson) => {
        let phoneNumbers: models.PhoneNumber[] = []; 

        if(insuredPerson.cellNumber && insuredPerson.cellNumber.phoneNumber.length > 0) {
            phoneNumbers.push(insuredPerson.cellNumber); 
        }            
        if(insuredPerson.homeNumber && insuredPerson.homeNumber.phoneNumber.length > 0) {
            phoneNumbers.push(insuredPerson.homeNumber); 
        }
        if(insuredPerson.workNumber && insuredPerson.workNumber.phoneNumber.length > 0) {
            phoneNumbers.push(insuredPerson.workNumber); 
        }
        this.setState({phoneNumbers: phoneNumbers});
    }

    componentDidUpdate(prevProps: any) {        
        const prevInsuredPerson: models.InsuredPerson = {
            ...prevProps.insuredPerson
        }
        let newInsuredPerson: models.InsuredPerson = {
            ...this.props.insuredPerson
        }
        
        if (  !_.isEqual(prevInsuredPerson,newInsuredPerson)) {           
            this.setPhoneNumberState(newInsuredPerson); 
            this.setState({insuredPerson: newInsuredPerson});
        }

        const prevZipSearch: models.ZipSearch = {
            ...prevProps.zipSearch
        }
        const newZipSearch: models.ZipSearch = {
            ...this.props.zipSearch
        }
        if (  !_.isEqual(prevZipSearch,newZipSearch)) { 
            newInsuredPerson = {
                ...this.state.insuredPerson, 
                mailingAddress: {
                    ...this.state.insuredPerson.mailingAddress
                    , city: newZipSearch.cityName
                    , state: newZipSearch.stateName
                    , postalCode: newZipSearch.zipCode
                }
            }
            this.setState({insuredPerson: newInsuredPerson}); 
        }
    }

    public zipSearch = (zipCode: string) => {
        this.props.requestZipSearch(zipCode); 
    }

    public getchildData = (e: any, type: string) => {                        
        const { insuredPerson } = this.state; 
        let newInsuredPerson = insuredPerson; 

        switch (type.toLowerCase()) {
            case "cell": 
                newInsuredPerson = {
                    ...insuredPerson,
                    cellNumber: {
                        phoneNumberType: type,
                        phoneNumber: e.value
                    } 
                }
            break; 
            case "business": 
                newInsuredPerson = {
                    ...insuredPerson,
                    workNumber: {
                        phoneNumberType: type,
                        phoneNumber: e.value
                    }
                }
            break;
            case "home": 
                newInsuredPerson = {
                    ...insuredPerson,
                    homeNumber: {
                        phoneNumberType: type,
                        phoneNumber: e.value
                    }
                }
            break; 
        }
        this.setPhoneNumberState(newInsuredPerson);
        this.setState({insuredPerson: newInsuredPerson}); 
    }

    public resetFormStateAndClose = () => {
        this.setPhoneNumberState(this.props.insuredPerson); 
        this.setState({insuredPerson: this.props.insuredPerson});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault(); 
        const patchDocument = createPatch(this.props.insuredPerson, this.state.insuredPerson);
        console.log(patchDocument); 
        const insuredPersonId = this.props.insuredPerson.id;         
        if (patchDocument.length > 0)
            this.props.patchInsuredPerson(this.props.claimId, insuredPersonId, patchDocument);
        this.props.closeModal(); 
    }

    public render() {
        const { showModal, closeModal, isStateAbbrDDLoading, stateList } = this.props; 
        const { phoneNumbers } = this.state;
        const insuredPerson = this.state.insuredPerson; 

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                <Modal.Title>Edit Insured Info</Modal.Title>
                </Modal.Header>                
                <Form 
                    className="claim-modal"
                    onSubmit={this.submitForm}
                >
                    <Modal.Body>
                    <Form.Group as={Row} controlId="insuredName" className="input-field-container">
                        <Form.Label column sm="4">Insured Name</Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" value={insuredPerson?.insuredName || ""} onChange={(e) => this.setState({insuredPerson: {...insuredPerson, insuredName: e.target.value}})} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="mailingAddress1" className="input-field-container">
                        <Form.Label column sm="4">Address Line 1</Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" value={insuredPerson?.mailingAddress?.addressLine1 || ""} onChange={(e) => this.setState({insuredPerson: {...insuredPerson, mailingAddress: {...insuredPerson.mailingAddress, addressLine1: e.target.value}}})} />
                        </Col>
                    </Form.Group>
                        <Form.Group as={Row} controlId="mailingAddress2" className="input-field-container">
                            <Form.Label column sm="4">Address Line 2</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" value={insuredPerson?.mailingAddress?.addressLine2} onChange={(e) => this.setState({insuredPerson: {...insuredPerson, mailingAddress: {...insuredPerson.mailingAddress, addressLine2: e.target.value}}})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="mailingZip" className="input-field-container">
                            <Form.Label column sm="4">Zip Code</Form.Label>                            
                                <Col sm="6" className="flush-right">
                                    <Form.Control type="text" value={insuredPerson?.mailingAddress?.postalCode || ""} onChange={(e) => this.setState({insuredPerson: {...insuredPerson, mailingAddress: {...insuredPerson.mailingAddress, postalCode: e.target.value}}})} />
                                </Col>
                                <Col sm="2" className="inpput-search-col">
                                    <Button className="input-search-btn" onClick={() => this.zipSearch(this.state.insuredPerson.mailingAddress.postalCode)}><Image src={searchIcon}/></Button>
                                </Col>
                        </Form.Group>                    
                        <Form.Group as={Row} controlId="maillingCity" className="input-field-container">
                            <Form.Label column sm="4">City</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" value={insuredPerson?.mailingAddress?.city || ""} onChange={(e) => this.setState({insuredPerson: {...insuredPerson, mailingAddress: {...insuredPerson.mailingAddress, city: e.target.value}}})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="mailingState" className="input-field-container">
                            <Form.Label column sm="4">State</Form.Label>
                            <Col sm="8">
                                <Form.Select name="statesList" value={insuredPerson?.mailingAddress?.state || ""} onChange={(e) => this.setState({insuredPerson: {...insuredPerson, mailingAddress: {...insuredPerson.mailingAddress, state: e.target.value}}})} >
                                    {isStateAbbrDDLoading && <option>Loading...</option>}
                                    {!isStateAbbrDDLoading && <option key={-1}>Choose...</option>}
                                    {stateList.totalCount > 0 && stateList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                        <option key={i} value={c.name} >{c.name}</option>
                                    ))}
                            </Form.Select>  
                            </Col>
                        </Form.Group>                        
                    <PhoneNumberFormCard 
                        validTypes={["Home", "Cell", "Business"]}
                        phoneNumbers={phoneNumbers}
                        handleChange={this.getchildData}
                    />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    cityList: state.locations.locations.cityList,
    claimId: state.claims.claim.claim.claimID, 
    countyList: state.locations.locations.countyList,
    insuredPerson: state.claims.claim.insuredPerson,
    isStateAbbrDDLoading: state.locations.dropdowns.isStateAbbrDDLoading, 
    stateList: state.locations.dropdowns.stateDD, 
    zipSearch: state.locations.locations.zipSearch
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            patchInsuredPerson: claimsActions.patchInsuredPerson, 
            requestZipSearch: locationActions.requestZipSearch,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(InsuredInformationModal);