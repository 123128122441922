import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';
import { Auth2GroupResponse, Auth2UserResponse } from '../../models/security/auth2GroupsResponse';

export interface SecurityGroupsState {
    Auth2GroupResponse: Auth2GroupResponse;     
    isLoading: boolean;
    isUpdatingGroups: boolean;
    isUpdatingGroupsDone: boolean;
}

export interface SecurityUsersState {
    isLoading: boolean;
    isUpdatingUsers: boolean;
    isUpdatingUsersDone: boolean;
    Auth2UserResponse: Auth2UserResponse;
    isUpdatingUserPermissions: boolean;
    isUpdatingUserPermissionsDone: boolean;
}

export const SecurityGroupsDefaultState: SecurityGroupsState = {
    Auth2GroupResponse: models.defaultResponse,
    isLoading: false,
    isUpdatingGroups: false,
    isUpdatingGroupsDone: false,
};

export const SecurityUsersDefaultState: SecurityUsersState = {
    Auth2UserResponse: models.defaultResponse,
    isLoading: false,
    isUpdatingUsers: false,
    isUpdatingUsersDone: false,
    isUpdatingUserPermissions: false,
    isUpdatingUserPermissionsDone: false,
}

export interface SecurityPermissionsState {
    Auth2PermissionResponse: models.Auth2PermissionResponse; 
    isPermissionResponseLoading: boolean; 
}

export const SecurityPermissionsDefaultState: SecurityPermissionsState = {
    Auth2PermissionResponse: models.defaultResponse, 
    isPermissionResponseLoading: false, 
}


export interface State {
    groups: SecurityGroupsState;
    users: SecurityUsersState;
    permissions: SecurityPermissionsState; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    groups: (state = SecurityGroupsDefaultState, action) => {     
        switch (action.type) {
            case constants.REQUEST_AUTH2_GROUPS:     
                return { 
                        ...state,
                        isLoading: true
                    };

            case constants.RECEIVE_AUTH2_GROUPS:
                return {
                    ...state, 
                    Auth2GroupResponse: action.payload.response,
                    isLoading: false
                }; 
            case constants.UPDATE_GROUP_PERMISSION:
                return {
                    ...state,
                    isUpdatingGroups: true,
                    isUpdatingGroupsDone: false
                };
            case constants.UPDATE_GROUP_PERMISSION_DONE:
                return {
                    ...state,
                    isUpdatingGroupsDone: true,
                    isUpdatingGroups: false
                };
            default:
                return state;
        }
    },
    permissions: (state = SecurityPermissionsDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_AUTH2_PERMISSIONS: 
                return {
                    ...state, 
                    isPermissionResponseLoading: true, 
                }; 
            case constants.RECEIVE_AUTH2_PERMISSIONS: 
                return {
                    ...state, 
                    isPermissionResponseLoading: false, 
                    Auth2PermissionResponse: action.payload.response, 
                }; 
            
            default:
                return state; 
        }
    }, 
    users: (state = SecurityUsersDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_AUTH2_USERS:
                return {
                    ...state,
                    isLoading: true
                };
            case constants.RECEIVE_AUTH2_USERS:
                return {
                    ...state,
                    Auth2UserResponse: action.payload.response,
                    isLoading: false,
                };
            case constants.UPDATE_USER_GROUP_MEMBERSHIP_DONE:
                return {
                    ...state,
                    isUpdatingUsers: false,
                    isUpdatingUsersDone: true,
                }
            case constants.UPDATE_USER_GROUP_MEMBERSHIP:
                return {
                    ...state,
                    isUpdatingUsers: true,
                    isUpdatingUsersDone: false,
                }
            case constants.UPDATE_USER_PERMISSION:
                return {
                    ...state,
                    isUpdatingUserPermissions: true,
                    isUpdatingUserPermissionsDone: false,
                }
            case constants.UPDATE_USER_PERMISSION_DONE:
                return {
                    ...state,
                    isUpdatingUserPermissions: false,
                    isUpdatingUserPermissionsDone: true,
                }
            default:
                return state;
        }
    }
});
