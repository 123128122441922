import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import dateFormat from 'dateformat'; 
import * as models from '../../models'; 
import { claimsActions } from 'store/claims';
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import { contactsActions } from 'store/contacts';
import AddContactSimpleModal from './AddContactSimpleModal'; 

interface Props extends PropsFromRedux {
    showModal: boolean;
    closeModal: () => void;
}

interface State {
    addNewText: string; 
    claim: models.ClaimSimple;     
    showAddContactModal: boolean; 
}

class InsuranceInformationModal extends React.Component<Props, State> {
    public state = {
        addNewText: "<Add New>", 
        claim: models.defaultClaimSimple,        
        showAddContactModal: false, 
    };    

    componentDidMount() {        
    }

    componentDidUpdate(prevProps: any) {
        const prevClaim: models.ClaimSimple = {
            ...prevProps.claim
        }
        const newClaim: models.ClaimSimple = {
            ...this.props.claim
        }

        if (  !_.isEqual(prevClaim,newClaim)) {            
            this.setState({claim: newClaim}); 
            this.getCompanyExaminers(newClaim.insuranceCompany); 
        }        
    }

    public resetFormStateAndClose = () => {
        this.setState({claim: this.props.claim});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault(); 
        const patchDocument = createPatch(this.props.claim, this.state.claim);         
        const claimId = this.props.claim.claimID;         
        this.props.patchClaim(claimId, patchDocument);
        this.props.closeModal(); 
    }

    public getCompanyExaminers = async (insuranceCompany: string) =>{
        await this.props.requestCompanyExaminersDD(insuranceCompany); 
    }

    public onInsCompanyChange = (e: any) => {
        this.setState({claim: {...this.state.claim, insuranceCompany: e.target.value}}); 
        this.getCompanyExaminers(e.target.value); 
    }

    public handleChangeWithAddNew = (e:any) => {
        const field: string = e.target.name; 

        switch (field.toLowerCase()) {
            case "examiner"  : 
                if (e.target.value === this.state.addNewText) {
                    this.toggleAddContactModal(); 
                }
                else {
                    const examiner = this.props.companyExaminerDD.resourceList.find(x => x.id.toString() == e.target.value); 
                    examiner && this.setExaminer(examiner); 
                }                     
            break; 
        }        
    }

    public addExaminerCallback = async (contact: models.ContactSimple) => {        
        const examiner: models.ContactSimple = {
            ...contact,
            contactTypes: 'examiner', 
        }
        const examinerDD: models.DropDownListItem = {
            id: examiner.id,
            name: examiner.fullName
        }
        await this.props.addCompanyExaminer(examiner);
        await this.setExaminer(examinerDD);
        await this.getCompanyExaminers(contact.companyName);         
    }

    public toggleAddContactModal = () => {
        this.setState({showAddContactModal: !this.state.showAddContactModal}); 
    }

    public setExaminer = (examiner: models.DropDownListItem) => {        
        this.setState({claim: {...this.props.claim, examiner: examiner}}); 
    }

    public render() {
        const { companyExaminerDD, closeModal, insCompanyDD, isInsCompanyDDLoading, isCompanyExaminerDDLoading, showModal } = this.props;
        const { addNewText, claim, showAddContactModal } = this.state;

        return (
            <>
            <AddContactSimpleModal
                showModal={showAddContactModal}
                defaultValues={ {...models.defaultContactSimple, companyName: claim?.insuranceCompany}}
                closeModal={this.toggleAddContactModal}
                callback={this.addExaminerCallback}
            />
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Insurance Info</Modal.Title>
                </Modal.Header>                
                    <Form 
                        className="claim-modal"
                        onSubmit={this.submitForm}
                    >
                        <Modal.Body>
                        <Form.Group as={Row} controlId="claimNumber" className="input-field-container">
                            <Form.Label column sm="4" >Claim Number</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" value={claim?.insClaimNumber} onChange={(e) => this.setState({claim: {...claim, insClaimNumber: e.target.value}})} />
                            </Col>
                        </Form.Group>                        
                        <Form.Group as={Row} controlId="insCompany" className="input-field-container">
                            <Form.Label column sm="4">Company Name</Form.Label>
                            <Col sm="8">
                                <Form.Select name="insCompany" value={claim.insuranceCompany} onChange={this.onInsCompanyChange} >
                                        {isInsCompanyDDLoading && <option>Loading...</option>}
                                        {!isInsCompanyDDLoading && <option key={-1}></option>}                                        
                                        {insCompanyDD.totalCount > 0 && insCompanyDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="dateLoss" className="input-field-container">
                            <Form.Label column sm="4">Loss Date</Form.Label>
                            <Col sm="8">
                                <Form.Control type="date" value={dateFormat(claim?.dateLoss || "", 'yyyy-mm-dd')} onChange={(e) => this.setState({claim: {...claim, dateLoss: new Date(e.target.value)}})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="dateReported" className="input-field-container">
                            <Form.Label column sm="4">Reported Date</Form.Label>
                            <Col sm="8">
                                <Form.Control type="date" value={claim?.dateReported && dateFormat(claim.dateReported, 'yyyy-mm-dd') || ""} onChange={(e) => this.setState({claim: {...claim, dateReported: new Date(e.target.value)}})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="dateReceived" className="input-field-container">
                            <Form.Label column sm="4">Received Date</Form.Label>
                            <Col sm="8">
                                <Form.Control type="date" defaultValue={dateFormat(claim?.dateReceived || "", 'yyyy-mm-dd')} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="examiner" className="input-field-container">
                            <Form.Label column sm="4">Examiner</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="examiner" 
                                    value={claim?.examiner?.id || ''} 
                                    onChange={(e) => this.handleChangeWithAddNew(e)} 
                                >
                                        {isCompanyExaminerDDLoading && <option>Loading...</option>}
                                        {!isCompanyExaminerDDLoading && <option key={-1}>Choose...</option>}
                                        {!isCompanyExaminerDDLoading && <option key={-99}>{addNewText}</option>}
                                        {companyExaminerDD.totalCount > 0 && companyExaminerDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.id} >{c.name}</option>
                                        ))}
                                </Form.Select>   
                            </Col>
                        </Form.Group>                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                        CANCEL
                    </Button>
                    <Button variant="primary" type="submit">
                        SAVE
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
    companyExaminerDD: state.contacts.dropdowns.companyExaminerDD, 
    insCompanyDD: state.companies.dropdowns.insCompanyDD,
    isCompanyExaminerDDLoading: state.contacts.dropdowns.isCompanyExaminerDDLoading, 
    isInsCompanyDDLoading: state.companies.dropdowns.isInsCompanyDDLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            addCompanyExaminer: contactsActions.addCompanyExaminer, 
            patchClaim: claimsActions.patchClaim,             
            requestCompanyExaminersDD: contactsActions.requestCompanyExaminersDD, 
        },        
        dispatch
    )
    ;

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(InsuranceInformationModal);
