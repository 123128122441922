import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap'; 
import * as models from '../../models'; 
import { companyActions } from 'store/companies/'; 
import { locationActions } from 'store/locations';
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import { userHasPermission } from '../../utils/helpers';

interface Props extends PropsFromRedux {
}

interface State {
    company: models.Company, 
    isUpdated: boolean,
    timer: ReturnType<typeof setTimeout>, 
}

interface IReactRouterParams {
    companyId?: string; 
}

class CompanyDetail extends React.Component<Props & RouteComponentProps<IReactRouterParams>, State> {    
    public state = {
        company: models.defaultCompany, 
        isUpdated: false, 
        timer: setTimeout(function(){}, 1000),
    }

    public componentDidMount() {
        const paging: models.Paging = {
            pageNo: 1, 
            pageSize: 1000,
        }
        this.props.requestCompany(parseInt(this.props.match.params.companyId!)); 
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!_.isEqual(prevProps.company, this.props.company)) {            
            this.setState({company: {...this.props.company}}); 
        }

        if (  !_.isEqual(prevProps.zipSearch,this.props.zipSearch)) { 
            let company: models.Company = {
                ...this.state.company,
                mailingAddress: {...this.props.company.mailingAddress}
            }
            company = {
                ...this.state.company, 
                mailingAddress: {
                    ...this.state.company.mailingAddress
                    , city: this.props.zipSearch.cityName
                    , state: this.props.zipSearch.stateName
                    , postalCode: this.props.zipSearch.zipCode
                }
            }
            this.setState({company: company}); 
        }

        if(!_.isEqual(prevState.company.mailingAddress?.postalCode, this.state.company.mailingAddress?.postalCode) && this.state.company.mailingAddress?.postalCode) {
            this.handleTimelapse(); 
        }
    }

    handleTimelapse = () => {        
        clearTimeout(this.state.timer); 
        const zipCode = this.state.company.mailingAddress?.postalCode; 

        if(zipCode !== '')
            this.setState({timer: setTimeout(() => {this.props.requestZipSearch(this.state.company.mailingAddress?.postalCode)}, 1000)});
    }

    public submitForm = (e: any) => {
        e.preventDefault();         
        const patchDocument = createPatch(this.props.company, this.state.company); 
        const companyId = this.props.company.companyId;                 
        this.props.updateCompany(companyId, patchDocument);
        this.setState({isUpdated: false}); 
    }

    public handlePhoneNumberChange = (e: any) => {        
        const inputItem = e.target.name; 
        const inputvalue = e.target.value; 
        let phoneNumbers: models.PhoneNumber[]; 

        if (inputvalue != "") {
            phoneNumbers = this.state.company.phoneNumbers.filter(x => x.phoneNumberType !== inputItem).concat({phoneNumberType: inputItem, phoneNumber: inputvalue}); 
        }       
        else {
            phoneNumbers = this.state.company.phoneNumbers.filter(x => x.phoneNumberType !== inputItem);
        }         
        this.setState({
            company: {
                ...this.state.company, 
                phoneNumbers: phoneNumbers
            }, isUpdated: true
        }); 
    }

    public render() {
        const { isCompanyLoading, isStateAbbrDDLoading, stateResponse } = this.props; 
        const { company, isUpdated } = this.state; 
        const canEdit = userHasPermission("Manage Companies"); 

        return (              
            <Form onSubmit={this.submitForm}>
                {canEdit && 
                    <Row >                    
                        <Col></Col>
                        <Button className="submit-contact-btn" type="submit" disabled={!isUpdated}>
                            SUBMIT
                        </Button>                    
                    </Row>
                }
                <Card className="claim-card">
                    <Card.Title>Company Info
                        {isCompanyLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    </Card.Title>                    
                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} id="formIsActive">
                                <Form.Check 
                                    disabled={!canEdit}
                                    type="checkbox" 
                                    label="Active" 
                                    checked={company.isActive || false} 
                                    onChange={(e) => this.setState({company: {...company, isActive: !company.isActive}, isUpdated: true})}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formFirstName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    type="text" 
                                    placeholder="Enter Company Name..." 
                                    value={company.name || ""} 
                                    onChange={(e) => this.setState({company: {...company, name: e.target.value}, isUpdated: true})} 
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formEmail">
                                <Form.Label>Company Email</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    type="email" 
                                    placeholder="Enter Company Email Address..." 
                                    value={company.email || ""} 
                                    onChange={(e) => this.setState({company: {...company, email: e.target.value}, isUpdated: true})} 
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formEmail2">
                                <Form.Label>Claim Email</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    type="email" 
                                    placeholder="Enter Claim Email Address..." 
                                    value={company.claimEmail || ""} 
                                    onChange={(e) => this.setState({company: {...company, claimEmail: e.target.value}, isUpdated: true})} 
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="checkbox-group-hor" >
                                <Form.Check 
                                    disabled={!canEdit}
                                    inline={true}
                                    name="Adjuster"
                                    type="radio"
                                    label="Adjuster"
                                    id="chkAdjuster"
                                    checked={company.type == "Adjuster" || false}
                                    onChange={(e) => this.setState({company: {...company, type: e.target.name}, isUpdated: true})} 
                                />
                                <Form.Check 
                                    disabled={!canEdit}
                                    inline={true}
                                    name="Agent"
                                    type="radio"
                                    label="Agent"
                                    id="chkAgent"
                                    checked={company.type == "Agent" || false}
                                    onChange={(e) => this.setState({company: {...company, type: e.target.name}, isUpdated: true})} 
                                />
                                <Form.Check 
                                    disabled={!canEdit}
                                    inline={true}
                                    name="Independent Adjuster"
                                    type="radio"
                                    label="Independent Adjuster"
                                    id="chkIndAdjuster"
                                    checked={company.type == "Independent Adjuster" || false}
                                    onChange={(e) => this.setState({company: {...company, type: e.target.name}, isUpdated: true})} 
                                />
                                <Form.Check 
                                    disabled={!canEdit}
                                    inline={true}
                                    name="Insurance"
                                    type="radio"
                                    label="Insurance"
                                    id="chkInsurance"
                                    checked={company.type == "Insurance" || false}
                                    onChange={(e) => this.setState({company: {...company, type: e.target.name}, isUpdated: true})} 
                                />
                                <Form.Check 
                                    disabled={!canEdit}
                                    inline={true}
                                    name="Mortgage"
                                    type="radio"
                                    label="Mortgage"
                                    id="chkMortgage"
                                    checked={company.type == "Mortgage" || false}
                                    onChange={(e) => this.setState({company: {...company, type: e.target.name}, isUpdated: true})} 
                                />
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>                    
                <Card className="claim-card">
                    <Card.Title>Contact Numbers</Card.Title>
                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formBusiness">
                                <Form.Label>Business</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    name="Business"
                                    type="text" 
                                    placeholder="Enter Business #..." 
                                    value={company.phoneNumbers?.find(p => p?.phoneNumberType == "Business")?.phoneNumber || ''} 
                                    onChange={
                                        (e) => this.setState({
                                            company: {
                                                ...this.state.company, 
                                                phoneNumbers: [
                                                    ...company.phoneNumbers.filter(x => x.phoneNumberType !== "Business").concat({phoneNumberType: "Business", phoneNumber: e.target.value})
                                                ]
                                            }, isUpdated: true
                                        })
                                    } 
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formFax">
                                <Form.Label>Fax</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    name="Fax"
                                    type="text" 
                                    placeholder="Enter Fax #..." 
                                    value={company.phoneNumbers?.find(p => p?.phoneNumberType == "Fax")?.phoneNumber || ''} 
                                    onChange={
                                        (e) => this.setState({
                                            company: {
                                                ...company, 
                                                phoneNumbers: [
                                                    ...company.phoneNumbers.filter(x => x.phoneNumberType !== "Fax").concat({phoneNumberType: "Fax", phoneNumber: e.target.value})
                                                ]
                                            }, isUpdated: true
                                        })
                                    } 
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formOther">
                                <Form.Label>Other</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    name="Other"
                                    type="text" 
                                    placeholder="Enter Other #..." 
                                    value={company.phoneNumbers?.find(p => p?.phoneNumberType == "Other")?.phoneNumber || ''} 
                                    onChange={this.handlePhoneNumberChange} 
                                />
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="claim-card">
                    <Card.Title>Mailing Address</Card.Title>
                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formAddress1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    placeholder="1234 Main St" 
                                    value={company.mailingAddress?.addressLine1 || ""} 
                                    onChange={(e) => this.setState({company: {...company, mailingAddress: {...company.mailingAddress, addressLine1: e.target.value}}, isUpdated: true})} 
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formAddress2">
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    placeholder="Apartment, studio, or floor" 
                                    value={company.mailingAddress?.addressLine2 || ""} 
                                    onChange={(e) => this.setState({company: {...company, mailingAddress: {...company.mailingAddress, addressLine2: e.target.value}}, isUpdated: true})} 
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    placeholder='Enter City...' 
                                    value={company.mailingAddress?.city || ""} 
                                    onChange={(e) => this.setState({company: {...company, mailingAddress: {...company.mailingAddress, city: e.target.value}}, isUpdated: true})} 
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formState">
                                <Form.Label>State</Form.Label>
                                <Form.Select 
                                    disabled={!canEdit}
                                    name="states"  
                                    value={company.mailingAddress?.state || ""} 
                                    onChange={(e) => this.setState({company: {...company, mailingAddress: {...company.mailingAddress, postalCode: "", city: "", state: e.target.value}}, isUpdated: true})} 
                                >
                                    {isStateAbbrDDLoading && <option>Loading...</option>}
                                    {!isStateAbbrDDLoading && <option key={-1}>Choose...</option>}
                                    {stateResponse && stateResponse.resourceList?.map((s: models.DropDownListItem, i: number) => ( 
                                        <option key={i} value={s.name} >{s.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formZip">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control 
                                    disabled={!canEdit}
                                    placeholder='Enter Zip...' 
                                    value={company.mailingAddress?.postalCode || ""} 
                                    onChange={(e) => this.setState({company: {...company, mailingAddress: {...company.mailingAddress, postalCode: e.target.value}}, isUpdated: true})} 
                                />
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
        ); 
    }

}

const mapStateToProps = (state: MyTypes.RootState) => ({
    company: state.companies.company.company, 
    isCompanyLoading: state.companies.company.isCompanyLoading,    
    isStateAbbrDDLoading: state.locations.dropdowns.isStateDDLoading, 
    stateResponse: state.locations.dropdowns.stateDD,  
    zipSearch: state.locations.locations.zipSearch
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            requestCompany: companyActions.requestCompany, 
            requestZipSearch: locationActions.requestZipSearch,
            updateCompany: companyActions.updateCompany,            
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CompanyDetail);