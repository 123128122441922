import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { adminActions } from 'store/admin/';
import { Toast } from 'react-bootstrap';
import * as models from '../../models'; 
import PageHeader from 'components/common/PageHeader';
import AdminDetails from './AdminDetails';
import DocumentTypes from './DocumentTypes';
import CcpaScrub from './CcpaScrub';
import '../../assets/styles/Administration.css';
import { contactsActions } from '../../store/contacts';
import { companyActions } from '../../store/companies';  
import AdminGroups from './AdminGroups';

interface Props extends PropsFromRedux {
}

interface State {
    adminPage: string; 
    activeListItem:string; 
    toastMessage: string; 
    pagingParams: models.Paging; 
    adminDetailTypes: string[];     
}

class Admin extends React.PureComponent<Props, State> {
    public state = {
        adminPage: 'Contacts', 
        activeListItem: 'Adjuster', 
        toastMessage: '',
        pagingParams: {pageNo: 1, pageSize: 20},         
        adminDetailTypes: ['Contacts', 'Companies'],  
    };    

    public setAdminType = (value: string) => {        
        this.setState({activeListItem: value}); 
    }

    public handleTabs = (name: string) => {
        const defaultType = 'Adjuster'; 
        const adminTypesRequest: models.AdminTypesRequest = {
            adminType: name,
            pageNo: 1, 
            pageSize: 100, 
        }
        this.setState({adminPage: name});
        this.setAdminType(defaultType);         
        if (this.state.adminDetailTypes.includes(name)) {
            this.props.requestAdminTypeList(adminTypesRequest);
            this.getAdminContent(name, defaultType, {pageNo: 1, pageSize: 20});
        }        
    };

    public getAdminContent = async(adminPage: string, adminType: string, paging: models.Paging) => {
        switch (adminPage) {
            case 'Contacts': 
                const contactsRequest: models.ContactsRequest = {
                    ...models.defaultContactsRequest,
                    ...paging,
                    type: adminType,
                }
                await this.props.requestContactList(contactsRequest); 
                break;
            case 'Companies': 
                const companiesRequest: models.CompaniesRequest = {
                    ...paging, 
                    type: adminType,
                    activeOnly: false,
                }
                await this.props.requestCompanyList(companiesRequest); 
                break; 
        }        
    }; 

    public renderTab = () => {
        const { 
            typesList, 
            isLoading, 
            isCompanyListLoading,
            isContactListLoading,
            contactsResponse, 
            companiesResponse, 
            requestAdminTypeList 
        } = this.props;        

        const { adminPage, activeListItem } = this.state; 

        switch (adminPage) {
            case 'Contacts':
                return (
                    <>
                        {isCompanyListLoading && <span>Loading...</span>}
                        <AdminDetails 
                            getAdminContent={this.getAdminContent}
                            requestAdminTypeList={requestAdminTypeList}
                            contactsResponse={contactsResponse}
                            companiesResponse={companiesResponse}
                            typesList={typesList.resourceList}                        
                            adminItemsLoading={isLoading}
                            adminPage={adminPage}
                            activeListItem={activeListItem}
                            setActiveListItem={this.setAdminType}
                        />
                    </>
                );
            case 'Companies':
                return (
                    <>
                        {isContactListLoading && <span>Loading...</span>}
                        <AdminDetails 
                            getAdminContent={this.getAdminContent}
                            requestAdminTypeList={requestAdminTypeList}
                            contactsResponse={contactsResponse}
                            companiesResponse={companiesResponse}
                            typesList={typesList.resourceList}                        
                            adminItemsLoading={isLoading}
                            adminPage={adminPage}
                            activeListItem={activeListItem}
                            setActiveListItem={this.setAdminType}
                        />
                    </>
                );
            case 'RBAC':
                return <div>Roles & Security Info</div>;
            case 'Document Types':
                return (
                    <DocumentTypes/>
                ); 
            case 'CCPA Scrub':
                return (
                    <CcpaScrub/>
                ); 
            case 'Groups':
                return (
                    <AdminGroups></AdminGroups>
                );
            default:
                return <div>No Info</div>;
        }
    };   
    
    public render() {
        const { toastMessage } = this.state; 
        const tabs: string[] = ["Contacts","Companies","Document Types","CCPA Scrub"]; 
        return (
            <>      
                <PageHeader
                    getTabName={this.handleTabs}
                    tabs={tabs}
                />      
                {toastMessage && 
                    <Toast>
                        <Toast.Header>
                            <strong className="me-auto">Error!</strong>
                        </Toast.Header>
                        <Toast.Body>{toastMessage}</Toast.Body>
                    </Toast>
                }
                {this.renderTab()}
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    typesList: state.admin.admin.adminTypesResponse, 
    contactsResponse: state.contacts.contacts.contactsResponse, 
    isContactListLoading: state.contacts.contacts.isContactListLoading,
    companiesResponse: state.companies.company.companiesResponse, 
    isCompanyListLoading: state.companies.company.isCompanyListLoading,
    isLoading: state.admin.admin.isLoading, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestAdminTypeList: adminActions.requestAdminTypeList, 
            requestContactList: contactsActions.requestContactList,
            requestCompanyList: companyActions.requestCompanyList,            
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Admin);