import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import '../../assets/styles/printf.css';


import MyTypes from 'MyTypes';

interface Props {
}

interface State {
}

class Printf extends React.PureComponent<RouteComponentProps & Props, State> {

    public state = {
    };

    public componentDidMount() {
    }

    public componentDidUpdate(prevProps: RouteComponentProps & Props) {
    }

    public render() {
        return (
            <React.Fragment>
                <div className='printf-container'>
                    <h2>React ENV</h2>

                    <table>
                        <tbody>
                            <tr>
                                <td>NODE_ENV</td><td>{process.env.NODE_ENV}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_API_URL</td><td>{process.env.REACT_APP_API_URL}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_LOGIN_PATH</td><td>{process.env.REACT_APP_LOGIN_PATH}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_ADMIN_LOGIN_PATH</td><td>{process.env.REACT_APP_ADMIN_LOGIN_PATH}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_TEST_LOGIN_PATH</td><td>{process.env.REACT_APP_TEST_LOGIN_PATH}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_TEST_ADMIN_LOGIN_PATH</td><td>{process.env.REACT_APP_TEST_ADMIN_LOGIN_PATH}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_LOGIN_REDIRECT_ENCODED</td><td>{process.env.REACT_APP_LOGIN_REDIRECT_ENCODED}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_LOGIN_TOKEN</td><td>{process.env.REACT_APP_LOGIN_TOKEN}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_ENVIRONMENT</td><td>{process.env.REACT_APP_ENVIRONMENT}</td>
                            </tr>
                            <tr>
                                <td>REACT_APP_FEATURE_USER_SEARCH_ENABLED</td><td>{process.env.REACT_APP_FEATURE_USER_SEARCH_ENABLED}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2>Process ENV</h2>
                    <table>
                        <tbody>
                            {Object.keys(process.env).map((val, k) => {
                                return (
                                    <React.Fragment>
                                        <tr><td>{val}</td><td>{process.env[val]}</td></tr>
                                    </React.Fragment>
                                )

                            })}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Printf);