import { RouteComponentProps } from 'react-router';
import MyTypes from 'MyTypes';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as models from '../../models';
import { claimsActions } from '../../store/claims/';
import { companyActions } from 'store/companies';
import PageHeader from 'components/common/PageHeader';
import QuickSearch from 'components/common/QuickSearch';
import ClaimOverview from './ClaimOverview';
import InsuranceInformation from './InsuranceInformation';
import InsuredInformation from './InsuredInformation';
import LossInformation from './LossInformation';
import ClaimExtras from './ClaimExtras'; 
import PolicyInformation from './PolicyInformation';
import ResComments from './Reserves'; 
import ClaimStatusUpdates from './ClaimStatusUpdates';
import Attachments from './Attachments'; 
import Invoices from './Invoices';
import EmailedFiles from './EmailedFiles';
import Comments from './Comments';
import '../../assets/styles/ClaimDetails.css'; 
import { adminActions } from '../../store/admin';
import _ from 'lodash'; 

interface Props extends PropsFromRedux {
}

interface State {
    activeTab: string,
}

interface Params {
    claimId: string | undefined,
}

class ClaimDetails extends React.PureComponent<RouteComponentProps<Params> & Props, State> {
    public state = {
        activeTab: "Claim",
    }

    public componentDidMount = () => {
        this.props.requestDocumentTypeList({pageNo: 1, pageSize: 2000}); 
        if (this.props.match && this.props.match.params.claimId) {
            const claimId: number = Number.parseInt(this.props.match.params.claimId); 
            const loadedClaim = this.props.claimID; 
            if (this.props.claimID === 0 || claimId !== loadedClaim) {
                // only get claim if it's not loaded already                
                this.props.requestClaimSingle(claimId);
            }
            this.props.requestInsCompanyDD(); 
        }        
    };

    public componentDidUpdate(prevProps: any) {        
        if (!_.isEqual(prevProps.claimID, this.props.claimID) && this.props.claimID > 0) {
            const claimId = this.props.claimID; 
            this.props.requestCommentsActivityLog(claimId, models.defaultPaging); 
            this.props.requestCommentsCorrection(claimId, models.defaultPaging);
            this.props.requestCommentsReserve(claimId, models.defaultPaging);
        }
    }

    public handleTabs = (name: string) => {
        this.setState({ activeTab: name });
    };

    public renderTab = () => {
        const { activeTab } = this.state;
        const { 
            activityLogComments, 
            attachments,             
            claimID,
            emailedClaims, 
            invoices, 
            isAttachmentsLoading, 
            isEmailedClaimsLoading, 
            isInvoicesLoading,
            isMergedReportsLoading, 
            isPolicyLoading, 
            mergedReports, 
            policy            
        } = this.props; 
        
        switch (activeTab) {
            case "Claim":
                return (
                    <div>
                        <ClaimOverview/>
                        <InsuranceInformation/>
                        <InsuredInformation/>
                        <LossInformation/>
                        <ClaimExtras/> 
                    </div>
                );
            case "Policy":
                return (
                    <div>
                        <ClaimOverview/>                        
                        <PolicyInformation 
                            policy={policy} 
                            isLoading={isPolicyLoading}
                        />
                        <ResComments/>
                    </div>
                );
            case "Activity Log":
                return (
                    <div>
                        <ClaimOverview/>
                        <Comments
                            title="Activity Log"
                            commentType='Comment'
                            comments={activityLogComments}
                        />
                    </div>
                );
            case "Files":
                return (
                    <div>
                        <ClaimOverview/>
                        <Attachments
                            appSection="LNS"
                            attachments={attachments.resourceList.filter(x => x.appSection.toLocaleLowerCase() === 'loss notification')}
                            claimId={claimID}
                            isLoading={isAttachmentsLoading}
                            title="Loss Notice"
                        />
                        <Attachments
                            appSection="EST"
                            attachments={attachments.resourceList.filter(x => x.appSection.toLocaleLowerCase() === 'estimate')}
                            claimId={claimID}
                            isLoading={isAttachmentsLoading}
                            title="Estimate"
                        />  
                        <Invoices
                            invoices={invoices.resourceList}
                            isLoading={isInvoicesLoading}
                        /> 
                        <EmailedFiles
                            emailedFiles={emailedClaims.resourceList}
                            isLoading={isEmailedClaimsLoading}
                        />  
                    </div>
                );
            case "ClaimUpdates":
                return (
                    <div>
                        <ClaimOverview/>
                        {/* <ClaimStatusUpdates claimStatusUpdate={claimSingle.claimStatusUpdate} /> */}
                    </div>
                );
            default:
                return <div>No Info</div>;
        }
    }

    public render() {
        const { toastMessage, toastShow, toastClear } = this.props; 

        const tabs: string[] = ["Claim","Policy","Activity Log","Files"]; 
        return (
            <div>                
                <PageHeader
                    getTabName={this.handleTabs}
                    tabs={tabs}
                    HeaderComponent={<QuickSearch />}
                />
                {this.renderTab()}
            </div>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    attachments: state.claims.claim.attachments, 
    activityLogComments: state.claims.comments.activityLogResponse, 
    claimID: state.claims.claim.claim?.claimID, 
    claimSingle: state.claims.claim.claim,
    correctionComments: state.claims.comments.correctionResponse, 
    emailedClaims: state.claims.claim.emailedClaims, 
    invoices: state.claims.claim.invoices, 
    isAttachmentsLoading: state.claims.claim.isAttachmentsLoading,     
    isEmailedClaimsLoading: state.claims.claim.isEmailedClaimsLoading,     
    isMergedReportsLoading: state.claims.claim.isMergedReportsLoading, 
    isPolicyLoading: state.claims.claim.isPolicyLoading, 
    isInvoicesLoading: state.claims.claim.isInvoicesLoading, 
    mergedReports: state.claims.claim.mergedReports,     
    policy: state.claims.claim.policy, 
    reserveComments: state.claims.comments.reserveResponse, 
    toastMessage: state.claims.claim.toastMessage, 
    toastShow: state.claims.claim.toastShow, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestClaimSingle: claimsActions.requestClaimSingle, 
            requestCommentsActivityLog: claimsActions.requestCommentsActivityLog, 
            requestCommentsCorrection: claimsActions.requestCommentsCorrection,
            requestCommentsReserve: claimsActions.requestCommentsReserve,             
            requestInsCompanyDD: companyActions.requestInsCompanyDD, 
            requestDocumentTypeList: adminActions.requestDocumentTypeList,          
            toastClear: claimsActions.toastClear,                                    
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ClaimDetails);
