import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Row, Col, Spinner } from 'react-bootstrap'; 
import PhoneNumber from '../common/PhoneNumber';
import editIcon from '../../assets/images/Blue/edit.svg';
import SingleActionButton from '../common/SingleActionButton';
import InsuredInformationModal from './InsuredInformationModal'; 
import { userHasPermission } from '../../utils/helpers';

interface Props extends PropsFromRedux {
}

interface State {
    showModal: boolean; 
}

class InsuredInformation extends React.Component<Props, State> {
    public state = {
        showModal: false,
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    }

    public render() {
        const { showModal } = this.state;     
        const { claim, isClaimLoading, insuredPerson, isInsuredPersonLoading } = this.props; 
        const canEditClaim = userHasPermission("Can Edit Claims Info") && claim.status.toLowerCase() !== 'closed'; 
        const isLoading = isClaimLoading || isInsuredPersonLoading; 

        return (
            <>
            <InsuredInformationModal
                    showModal={showModal}
                    closeModal={this.toggleModal}
                /> 
            <Card className="claim-card">
                <Card.Title>Insured Information
                    {isLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    {canEditClaim && 
                        <SingleActionButton
                            action="Edit"
                            title="Insured"
                            img={editIcon}
                            onClick={this.toggleModal}
                        />
                    }
                </Card.Title>
                <Row>
                    <Col md={3} lg={3} className="card-label">Name:</Col>
                    <Col md={3} lg={3} >{insuredPerson?.insuredName}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Address:</Col>
                    <Col md={3} lg={3} >
                                {insuredPerson?.mailingAddress?.addressLine1}
                                <br/>{insuredPerson?.mailingAddress?.addressLine2}
                                <br/>{insuredPerson?.mailingAddress?.city}
                                <br/>{insuredPerson?.mailingAddress?.state}
                                <br/>{insuredPerson?.mailingAddress?.postalCode}
                            </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Phones:</Col>
                    <Col md={3} lg={3} >
                        <PhoneNumber
                            phoneNumber={insuredPerson?.workNumber || ""}
                        />
                        <PhoneNumber
                            phoneNumber={insuredPerson?.cellNumber || ""}
                        />
                        <PhoneNumber
                            phoneNumber={insuredPerson?.homeNumber || ""}
                        />
                    </Col>
                </Row>
            </Card> 
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({    
    claim: state.claims.claim.claim,
    insuredPerson: state.claims.claim.insuredPerson,     
    isClaimLoading: state.claims.claim.isClaimLoading,
    isInsuredPersonLoading: state.claims.claim.isInsuredPersonLoading, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(InsuredInformation);