export const PATCH_COMPANY = 'settings/PATCH_COMPANY'; 

export const REQUEST_COMPANY = 'admin/REQUEST_COMPANY';
export const RECEIVE_COMPANY = 'admin/RECEIVE_COMPANY';

export const REQUEST_COMPANY_LIST = 'admin/REQUEST_COMPANY_LIST';
export const RECEIVE_COMPANY_LIST = 'admin/RECEIVE_COMPANY_LIST';

export const REQUEST_INSURANCE_COMPANY_LIST = 'companies/REQUEST_INSURANCE_COMPANY_LIST';
export const RECEIVE_INSURANCE_COMPANY_LIST = 'companies/RECEIVE_INSURANCE_COMPANY_LIST';

export const REQUEST_COMPANY_DROPDOWN = 'dropdowns/REQUEST_COMPANY_DROPDOWN'; 
export const RECEIVE_COMPANY_DROPDOWN = 'dropdowns/RECEIVE_COMPANY_DROPDOWN'; 

// dropdowns
export const REQUEST_ALL_COMPANIES_DD = 'dropdowns/REQUEST_ALL_COMPANIES_DD'; 
export const RECEIVE_ALL_COMPANIES_DD = 'dropdowns/RECEIVE_ALL_COMPANIES_DD'; 

export const REQUEST_AGENT_COMPANY_DD = 'dropdowns/REQUEST_AGENT_COMPANY_DD'; 
export const RECEIVE_AGENT_COMPANY_DD = 'dropdowns/RECEIVE_AGENT_COMPANY_DD'; 

export const REQUEST_INS_COMPANY_DD = 'dropdowns/REQUEST_INS_COMPANY_DD'; 
export const RECEIVE_INS_COMPANY_DD = 'dropdowns/RECEIVE_INS_COMPANY_DD'; 

export const REQUEST_MORTGAGE_COMPANY_DD = 'dropdowns/REQUEST_MORTGAGE_COMPANY_DD'; 
export const RECEIVE_MORTGAGE_COMPANY_DD = 'dropdowns/RECEIVE_MORTGAGE_COMPANY_DD'; 