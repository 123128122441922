import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import * as models from '../../models';
import NumberFormat from 'react-number-format';
import _ from 'lodash'; 
import { financialsActions } from 'store/financials';

interface Props extends PropsFromRedux {
    closeModal: () => void; 
    showModal: boolean; 
    invoiceShort: {
        title: string, 
        amount: number, 
        invoiceFees: InvoiceFeeShort[],
    } 
}

interface InvoiceFeeShort {
    id: number; 
    amount: number; 
}

interface State {
    payment: models.Payment,
}

class QuickPayModal extends React.Component<Props, State> {
    public state = {
        payment: models.defaultPayment, 
    };  

    public resetFormStateAndClose = () => {
        this.setState({payment: models.defaultPayment}); 
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {                
        e.preventDefault();         
        const payment: models.AddPayment = {
            paymentInfo: this.state.payment.paymentInfo,
            paymentAmount: this.props.invoiceShort.amount,
            paymentDate: this.state.payment.paymentDate,
            fees: this.props.invoiceShort.invoiceFees,
        }
        this.props.createPayment(payment)
        this.resetFormStateAndClose(); 
    }

    public render() {
        const { showModal, invoiceShort } = this.props; 
        const { payment } = this.state; 

        return (
            <Modal centered={true} show={showModal} onHide={this.resetFormStateAndClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Quick Pay</Modal.Title>
                </Modal.Header>                
                <Form 
                    className="claim-modal" 
                    onSubmit={this.submitForm}
                >
                <Modal.Body>
                    <Row>
                        <Col sm={4}>Invoice Type: </Col>
                        <Col>{invoiceShort.title}</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>Amount: </Col>
                        <Col><NumberFormat value={invoiceShort.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                    </Row>                    
                    <Form.Group as={Row} controlId="paymentInfo" className="input-field-container2">                                                                                    
                        <Col sm={4}>
                            <Form.Label column>Payment Info</Form.Label>                                        
                        </Col>
                        <Col>
                            <Form.Control 
                                type="text" 
                                placeholder="Check or Confirmation #..." 
                                value={payment.paymentInfo} 
                                onChange={(e) => this.setState({payment: {...payment, paymentInfo: e.target.value}})} 
                            />
                        </Col>
                    </Form.Group>
                </Modal.Body>                
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>    
            </Modal>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            createPayment: financialsActions.createPayment,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(QuickPayModal); 