import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Card } from 'react-bootstrap'; 
import * as models from '../../models';
import gear from '../../assets/images/Blue/gear.svg';
import addIcon from '../../assets/images/Blue/add.svg'; 
import SingleActionButton from '../common/SingleActionButton';
import MultiActionButton from '../common/MultiActionButton';
import Pager from 'components/common/Pager';
import { adminActions } from 'store/admin';
import { ApplicationSection } from 'store/enums'; 

interface Props extends PropsFromRedux {
}

interface State {    
    documentTypesRequest: models.DocumentTypesRequest; 
}

class DocumentTypes extends React.Component<Props, State> {
    public state = {
        documentTypesRequest: {
            pageNo: 1, 
            pageSize: 20,
            applicationSectionCode: null,
        }
    }

    public componentDidMount = () => {
        this.props.requestDocumentTypeList(this.state.documentTypesRequest); 
    }

    public pagerCallback = (paging: models.Paging) => {
        const documentTypesRequest: models.DocumentTypesRequest = {
            ...paging,
        }
        this.props.requestDocumentTypeList(documentTypesRequest); 
    }; 

    public clickEvent = () => {
        alert("Add DocumentType"); 
    };
    
    public render() {
        const { documentTypesResponse } = this.props;
        const { documentTypesRequest } = this.state; 
        const actions = [{
            name: 'Deactivate'
        }]; 

        const showPagination = documentTypesResponse?.totalCount > documentTypesRequest.pageSize ? true : false; 

        return (
            <Card className="claim-card">
                <Card.Title>Document Types
                    <SingleActionButton
                        action="Add"
                        title="DocumentType"
                        onClick={this.clickEvent }
                        img={addIcon}
                    />
                </Card.Title>
                {documentTypesResponse?.totalCount > 0 &&
                    <Table striped>
                        <thead>    
                            <tr>
                                <th>Document Type</th>
                                <th>Active</th>
                                <th>Application Section</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentTypesResponse.resourceList.map((dt, i) => (
                            <tr key={i}>
                                <td>{dt.name}</td>
                                <td>{dt.activeYN ? 'Yes' : 'No'}</td>
                                <td>{ApplicationSection[dt.applicationSection as keyof typeof ApplicationSection]}</td>
                                <td><MultiActionButton img={gear} actions={actions} /></td>
                            </tr>
                        ))}
                        </tbody>                        
                    </Table>
                }
                {showPagination &&
                    <Pager
                        className = {"admin-pager"}
                        retrieveDataCallback = {this.pagerCallback}
                        pageSize = {documentTypesRequest.pageSize}
                        currentPage = {documentTypesRequest.pageNo}
                        totalCount = {documentTypesResponse.totalCount}
                        pagesInRange = {3}
                    />
                }
            </Card>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    documentTypesResponse: state.admin.documentType.documentTypesResponse, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestDocumentTypeList: adminActions.requestDocumentTypeList, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DocumentTypes);