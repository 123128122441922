import * as models from '..'; 

export interface ClaimsRequest extends models.RequestBase {
    insClaimNo: string; 
    claimNoOperator: string; 
    insCompany: string; 
    claimExaminer: string; 
    fieldAdjuster: string; 
    status: string;
    policyNumber: string;
    insuredName: string;
    lossCity: string;
    lossState: string;
    lossCounty: string;
    lossZipCode: string;
    receivedDateFrom: string;
    receivedDateTo: string;
    closedDateFrom: string;
    closedDateTo: string;
    lossDateFrom: string;
    lossDateTo: string;
    reportedDateFrom: string;
    reportedDateTo: string; 
    sort: string;
}

export const defaultClaimsRequest: ClaimsRequest = {
    pageNo: 1, 
    pageSize: 20, 
    insClaimNo: '', 
    claimNoOperator: 'LIKE', 
    insCompany: '', 
    claimExaminer: '', 
    fieldAdjuster: '', 
    status: '',
    policyNumber: '',
    insuredName: '', 
    lossCity: '',
    lossState: '',
    lossCounty: '',
    lossZipCode: '',
    receivedDateFrom: '',
    receivedDateTo: '',
    closedDateFrom: '',
    closedDateTo: '',
    lossDateFrom: '',
    lossDateTo: '',
    reportedDateFrom: '',
    reportedDateTo: '',
    sort: '-dateReceived',
}