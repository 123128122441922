import { request } from './base';
import { trackPromise } from 'react-promise-tracker';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const getZipSearchResponse = async(zipCode: string) => {
    const response = await request ({
      method: 'GET',
      url: `/Locations/ZipCodeSearch/${zipCode}`,
    }); 
    return response; 
}


// dropdowns
export const getStatesDD = async () => {
  const response = await request ({
    method: 'GET',
    url: `/Locations/dd/states`,
  }); 
  return response; 
}

export const getStatesAbbrDD = async () => {
  const response = await request ({
    method: 'GET',
    url: `/Locations/dd/statesAbbr`,
  }); 
  return response; 
}

export const getLocationsDDs = async (search: models.LocationsDDRequest) => {
  var qStr = utils.helpers.encodeQueryString(search);

  const response = await request ({
    method: 'GET',
    url: `/Locations?${qStr}`,
  }); 
  return response; 
}