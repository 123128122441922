import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string'; 
import '../../assets/styles/LoginCallback.css';
import services from '../../services';
import { ApiResponse } from 'services/api/base';

interface Props {
}
interface State {
    userMessage: string;
    isError: boolean;

}
interface Params {
    token: string
}
interface UserInfo {

}
class LoginCallback extends React.PureComponent<RouteComponentProps<Params> & Props, State> {

    public state = {
        userMessage: 'logging in...please wait...',
        isError: false,
    };
    
    public async componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        var token = params.token;


        var newState = {userMessage: 'Something went wront, no login info found!', isError: true};
        if (token && ((typeof token === 'string' || token instanceof String))) {

            // save the token
            localStorage.setItem('token', token as string);
            
            // get the current user info
            var userInfo:ApiResponse = await services.api.user.getCurrentUserInfo(token as string);
            if (userInfo.isSuccess) {
                localStorage.setItem('userInfo', JSON.stringify(userInfo.data));

                // redirect to
                newState = {userMessage: 'redirecting...', isError: false};
                window.location = "/" as (string | Location) & Location;
            } else {
                // clear the token since we couldn't get user info
                localStorage.setItem('token', '');
            }
        }
        this.setState(newState);
    }


    public render() {
        var homeLink = this.state.isError ? this.renderBackHomeLink() : "";
        return (
            <React.Fragment>
                <div className="main-message" >
                    <span>
                        <h2>{this.state.userMessage }</h2>
                    </span>
                </div>
                { homeLink }
            </React.Fragment>
            
        );
    }

    public renderBackHomeLink() {
        return (
            <React.Fragment>
                <div className="sub-message"><span><h2><a href="/">Compass Connect Home</a></h2></span></div>
            </React.Fragment>
        );
    }
}

export default LoginCallback;