import * as React from 'react'; 
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import Chart from 'react-apexcharts'; 
import services from '../../services';
import * as models from '../../models'; 
import { claimsActions } from '../../store/claims'; 

interface Props extends PropsFromRedux {
}

interface State {
    series: any;  
    options: any; 
}

interface openClaimsByCompany {
    companyName: string; 
    openClaims: number; 
}

class OpenClaimsByCompanyChart extends React.PureComponent<Props, State> {
    public state = {
        options: {},
        series: []        
    }

    getOpenClaimsByCompany = (companyName: string) => {
        const claimsRequest: models.ClaimsRequest = {
            ...models.defaultClaimsRequest, 
            insCompany: companyName,
            status: "Open,Reopened"
        }
        this.props.requestClaims(claimsRequest); 
        services.router.goto(services.router.url.claimList); 
    }
    
    public componentDidMount = async () => {
        const response = await services.api.dashboard.getOpenClaimsByCompany(); 
        if (response.isSuccess){
            const openClaimsByCompany: openClaimsByCompany[] = response.data; 
            const companyNames: string[] = openClaimsByCompany.map(x => {return x.companyName}); 
            const openClaims: number[] =  openClaimsByCompany.map(x => {return x.openClaims}); 

            this.setState({
                options: {
                    chart: {
                        id: "basic-bar",
                        events: {
                            dataPointSelection: (event:any, chartContext:any, config:any) => {
                                this.getOpenClaimsByCompany(companyNames[config.dataPointIndex]); 
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true
                        }
                    },  
                    xaxis: {
                    categories: companyNames
                    },
                    title: {
                        text: "Open Claims by Company",
                        align: 'center'
                    }
                }, 
                series: [
                    {
                        name: "Open Claims",
                        data: openClaims
                    }
                ]
            }); 
        }
    }

    render() {
        return (
            <div id="chart">
                <Chart 
                    options={this.state.options} 
                    series={this.state.series} 
                    type="bar" 
                    height={350} 
                />
            </div>     
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestClaims: claimsActions.requestClaims,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(OpenClaimsByCompanyChart); 