import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import * as models from '../../models'
import Pager from 'components/common/Pager';
import InvoiceList from './invoiceList'; 
import { financialsActions } from 'store/financials';
import _ from 'lodash'; 
import infoIcon from '../../assets/images/Blue/info-circle.svg'; 

interface Props extends PropsFromRedux {
}
interface State {
    invoicesRequest: models.InvoicesRequest;     
}
class Invoicing extends React.PureComponent<Props, State> {
    public state = {
        invoicesRequest: models.defaultInvoicesRequest,        
    }

    public componentDidMount() {
        this.fetchFormData(this.state.invoicesRequest);
    }

    public pagerCallback = (paging: models.Paging) => { 
        const invoicesRequest: models.InvoicesRequest = {
            ...this.state.invoicesRequest,
            ...paging,
        } 
        this.props.requestInvoiceList(invoicesRequest); 
    }; 

    private handleSearchSubmit = async (e: any) => {
        const { invoicesRequest } = this.state; 
        this.fetchFormData(invoicesRequest); 
        e.preventDefault(); 
    };    

    public fetchFormData = (request: models.InvoicesRequest) => {        
        this.props.requestInvoiceList(request);
    }

    public applyFilters = (e:any) => {        
        const { name, value } = e.target;
        let request: models.InvoicesRequest;

        switch (name) {
            case 'age':
                request = {
                    ...this.state.invoicesRequest,
                    age: value === "-1" ? undefined : parseInt(value.split(' ')[0]),
                }; 
                break; 
            case 'adjuster': 
                request = {
                    ...this.state.invoicesRequest,
                    adjuster : value === "-1" ? null : value,
                };
                break;
            case 'checkNo':
                    request = {
                        ...this.state.invoicesRequest,
                        checkNo: value === "-1" ? null : value,
                        isUnpaid: false, 
                    };
                    break; 
            case 'invoiceDateFrom':             
                request = {
                    ...this.state.invoicesRequest, 
                    invoiceDateFrom: value === "" ? null : value,
                }; 
                break; 
                
            case 'invoiceDateTo': 
                request = {
                    ...this.state.invoicesRequest,
                    invoiceDateTo: value === "" ? null : value, 
                }; 
                break; 
            case 'invoiceGroup':
                request = {
                    ...this.state.invoicesRequest,
                    invoiceGroup: value === "" ? null : value, 
                }
                break;
            case 'insClaimNo': 
                request = {
                    ...this.state.invoicesRequest,
                    insClaimNo : value === "" ? null : value,
                };
                break; 
            case 'insCompany':
                request = {
                    ...this.state.invoicesRequest,
                    insCompany: value === "-1" ? null : value,
                };
                break; 
            case 'isUnpaid':
                request ={
                    ...this.state.invoicesRequest,
                    isUnpaid: !this.state.invoicesRequest.isUnpaid,
                }; 
                break; 
            default: 
                request = {
                    ...this.state.invoicesRequest,
                };
        }
        this.setState({invoicesRequest: request}); 
        this.fetchFormData(request); 
        e.preventDefault(); 
    }

    public resetFilters = () => {
        this.setState({invoicesRequest: models.defaultInvoicesRequest}); 
        this.fetchFormData(models.defaultInvoicesRequest);
    }

    public render() {
        const { invoicesListResponse} = this.props; 
        const { invoicesRequest } = this.state; 
        const showPagination = true; 
        const dateBuckets: string[] = [
            '15 Days',
            '30 Days',
            '45 Days',
            '60 Days',
            '90 Days',
            '180 Days',
            '365 Days'
        ];         

        return (
            <>                        
                <Card className="claim-card">
                    <Card.Title>Invoice Search</Card.Title>
                    <Form onSubmit={this.handleSearchSubmit}>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Claim Number</Form.Label>
                                    <Form.Control name="insClaimNo" placeholder='Claim #' value={invoicesRequest.insClaimNo} onChange={this.applyFilters} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance Company</Form.Label>
                                    <Form.Select name="insCompany" value={invoicesRequest.insCompany} onChange={this.applyFilters} >
                                        {<option key={-1}></option>}
                                        {invoicesListResponse.insuranceCompanies && invoicesListResponse.insuranceCompanies.map((c: models.DropDownListItem, i: number) => (                                             
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Adjuster</Form.Label>
                                    <Form.Select name="adjuster" value={invoicesRequest.adjuster} onChange={this.applyFilters}>
                                        {<option key={-1}></option>}
                                        {invoicesListResponse.fieldAdjusters && invoicesListResponse.fieldAdjusters.map((c: models.DropDownListItem, i: number) =>
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Bulk Invoice</Form.Label>
                                    <Form.Select name="invoiceGroup" value={invoicesRequest.invoiceGroup} onChange={this.applyFilters} >
                                        {<option key={-1}></option>}
                                        {invoicesListResponse.bulkInvoices && invoicesListResponse.bulkInvoices.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>                                       
                        </Row>
                        <Row style={{paddingTop: "1em"}}>
                            <Form.Group as={Col}>
                                    <Form.Label>Invoice Dt Start</Form.Label>                                    
                                        <Form.Control 
                                            type="date"
                                            name="invoiceDateFrom" 
                                            value={invoicesRequest.invoiceDateFrom} 
                                            onChange={this.applyFilters}
                                            />                                            
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Invoice Dt End</Form.Label>
                                    <OverlayTrigger
                                        key="overlayTrigger"
                                        placement="top"
                                        overlay={
                                            <Tooltip className="my-tooltip" id="toolTip">Search will include through midnight of End Date</Tooltip>
                                        }
                                    >
                                        <img className="info-icon" src={infoIcon}/>
                                    </OverlayTrigger>                                    
                                        <Form.Control 
                                            type="date"
                                            name="invoiceDateTo" 
                                            value={invoicesRequest.invoiceDateTo} 
                                            onChange={this.applyFilters}
                                            />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Invoice Age</Form.Label>
                                <Form.Select name="age" value={invoicesRequest.age?.toString() + " Days" || undefined} onChange={this.applyFilters} >
                                    {<option key={-1}></option>}
                                    {dateBuckets.map((n: string, i: number) => ( 
                                        <option key={i} value={n} >{n}</option>
                                    ))}
                                </Form.Select>                                    
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Check #</Form.Label>
                                <Form.Control 
                                    name="checkNo" 
                                    placeholder='Check #' 
                                    value={invoicesRequest.checkNo} 
                                    onChange={this.applyFilters} 
                                />
                            </Form.Group>
                        </Row>
                        <Row style={{paddingTop: "1em", float: "right"}}>
                            <Form.Group>
                                <Form.Check 
                                    inline={true}
                                    name="isUnpaid"
                                    type="checkbox"
                                    label="Is Unpaid"
                                    id="chkUnpaid"
                                    checked={invoicesRequest.isUnpaid}
                                    onChange={this.applyFilters}
                                />          
                            </Form.Group>                                            
                        </Row>
                        <Button className="claim-search-btn" type='submit'>Search</Button>
                        <Button className="claim-search-btn" onClick={this.resetFilters}>Reset Filters</Button>
                    </Form>                                 
                </Card>                
                <InvoiceList 
                    title="Search Results"
                    isSortable={true}
                />    
                {showPagination &&
                    <div>
                        <Pager
                            className = {"admin-pager"}
                            retrieveDataCallback = {this.pagerCallback}
                            pageSize = {invoicesRequest.pageSize}
                            currentPage = {invoicesRequest.pageNo}
                            totalCount = {invoicesListResponse.totalCount}
                            pagesInRange = {3}
                        />
                    </div>
                }
            </>
        );
    }    
}

const mapStateToProps = (state: MyTypes.RootState) => ({      
    invoicesListResponse: state.financials.financials.invoicesListResponse, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {   
            requestInvoiceList: financialsActions.requestInvoiceList,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(Invoicing); 