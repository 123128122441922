import * as models from '..';

export interface FeeScheduleFee {
    feeName: string;
    quantity: number; 
    amount: number; 
}

export const defaultFeeScheduleFee : FeeScheduleFee =  {
    feeName: '',
    quantity: 1,
    amount: 0.00, 
}