import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import _ from 'lodash'; 
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums';

interface Props extends PropsFromRedux {    
    handleSubmit: (reason:string) => void; 
    closeModal: () => void;
    showModal: boolean;     
}

interface State {
    reason: string; 
}

class GetReasonModal extends React.Component<Props, State> {
    public state = {
        reason: '', 
    };

    public resetFormStateAndClose = () => {
        this.setState({reason: ''});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault();         
        if (this.state.reason == '' ){
            this.props.setAppToast({message: "Reason required!", type: AppToastTypes.Failure}); 
        }
        else {
            this.props.handleSubmit(this.state.reason); 
        }
    }

    public render() {
        const {closeModal, showModal } = this.props;

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Reason</Modal.Title>
                </Modal.Header>                
                <Form className="claim-modal" onSubmit={this.submitForm} >                        
                    <Modal.Body> 
                        <Form.Group as={Row} controlId="reason" className="input-field-container">
                            <Form.Control as="textarea" onChange={(e) => this.setState({ reason: e.target.value })} />
                        </Form.Group> 
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                        CANCEL
                    </Button>
                    <Button variant="primary" type="submit">
                        SAVE
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}


const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            setAppToast: userActions.setAppToast,  
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(GetReasonModal);