import { request } from './base';
import * as apiModels from 'models/security/auth2UpdateUserGroupMembershipRequest';
import * as models from '../../models';
import utils from '../../utils'; 


export const getAuth2Groups = async () => {
  const response = await request ({ method: 'GET', url: '/Auth2/Groups' });
  return response;
};

export const getAuth2UsersSearch = async (requestModel: models.Auth2UserSearchRequest) => {
  var qStr = utils.helpers.encodeQueryString(requestModel);  

  const response = await request ({
    method: 'GET', 
    url: `/Auth2/Users?${qStr}`
  });
  return response;
};


export const getAuth2Users = async () => {
  const response = await request ({ method: 'GET', url: '/Auth2/Users' });
  return response;
};

export const getAuth2Permissions = async () => {
  const response = await request ({method: 'GET', url: '/Auth2/Permissions'}); 
  return response; 
}

export const updateUserGroupMembership = async (apiRequestModel: apiModels.Auth2UpdateUserGroupMembershipRequest) => {
  const response = await request ({
    method: 'POST',
    url: `/Auth2/UpdateUserGroupMembership`,
    data: apiRequestModel
  }); 
  return response;
};

export const updateGroupPermission = 
  async (apiRequestModel: apiModels.Auth2UpdateGroupPermissionRequest) => {
    const response = await request ({
      method: 'POST',
      url: `/Auth2/UpdateGroupPermission`,
      data: apiRequestModel
    }); 
    return response;
  };

export const updateUserPermission = 
  async (apiRequestModel: apiModels.Auth2UpdateUserPermissionRequest) => {
    const response = await request ({
      method: 'POST',
      url: `/Auth2/UpdateUserPermission`,
      data: apiRequestModel
    });
    return response;
  }