import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect } from 'react-redux'; 
import { bindActionCreators, Dispatch } from 'redux';
import { Row, Col, Nav, Spinner } from 'react-bootstrap'; 
import * as models from '../../models'; 
import Pager from 'components/common/Pager';
import QuickSearch from '../common/QuickSearch';
import Contact from './Contact';
import Company from './Company'; 
import NoResults from 'components/common/NoResults';
import addIcon from '../../assets/images/Blue/add.svg';
import '../../assets/styles/Administration.css'; 
import services from 'services';
import SingleActionButton from '../common/SingleActionButton'; 
import { adminActions } from 'store/admin';

interface Props {
    getAdminContent: (adminPage: string, adminType: string, paging: models.Paging) => void;
    requestAdminTypeList: typeof adminActions.requestAdminTypeList;
    contactsResponse: models.ContactSimpleResponse; 
    companiesResponse: models.CompaniesResponse; 
    typesList: models.AdminType[];          
    adminItemsLoading: boolean; 
    adminPage: string; 
    activeListItem: string;
    setActiveListItem: (name: string)=>void;  
}

interface State {
    pagingParams: {
        pageNo: number;
        pageSize: number; 
    }; 
}

class AdminDetails extends React.Component<Props, State> {
    public state = {
        pagingParams: {pageNo: 1, pageSize: 20},
        toastMessage: '',
    }    

    public componentDidMount = () => {   
        this.props.setActiveListItem('Adjuster'); 
        if (this.props.adminPage !== '')
        {            
            const adminTypesRequest: models.AdminTypesRequest = {
                adminType: this.props.adminPage,
                pageNo: 1, 
                pageSize: 200,
            }
            this.props.requestAdminTypeList(adminTypesRequest); 
            this.props.getAdminContent(this.props.adminPage, this.props.activeListItem, {pageNo: 1, pageSize: 20});
        }
    }

    public handleItemClick = async (name: string) => {        
        if (name != null) {
            this.props.setActiveListItem(name); 
            this.props.getAdminContent(this.props.adminPage, name, {pageNo: 1, pageSize: 20});
        }
    };

    public pagerCallback = (paging: {pageNo: number, pageSize: number}) => {
        this.setState({ 
            pagingParams: paging
        });
        this.props.getAdminContent(this.props.adminPage, this.props.activeListItem, paging); 
    }; 

    public addItemClickEvent = () => {
        const { adminPage } = this.props; 

        let path = ""; 

        switch(adminPage) {
            case "Contacts":
                path = services.router.url.contactDetail.replace(":contactId", "0"); 
                break;
            case "Companies": 
                path = services.router.url.companyDetail.replace(":companyId", "0");
                break;
            default: 
                path = "/"; 
                break;
        }        
        services.router.goto(path);
    }; 
    
    public render() {                
        const { activeListItem, typesList, adminPage, adminItemsLoading, contactsResponse, companiesResponse } = this.props; 
        const { pagingParams } = this.state; 

        const responseTotalCount = adminPage == "Contacts" ? contactsResponse.totalCount : companiesResponse.totalCount; 
        const showPagination = responseTotalCount > pagingParams.pageSize ? true : false;

        const items = () => {           
            switch(adminPage) {
                case "Contacts":
                    return (                        
                        <>
                            {contactsResponse.totalCount !== 0 ? (
                                contactsResponse.resourceList && 
                                contactsResponse.resourceList.map((c: models.ContactSimple, i: number) => (
                                    <Contact 
                                        key={i}                                
                                        contact={c}
                                    />                
                                ))
                            ) : <NoResults/>}
                        </>
                    );
                case "Companies":                    
                    return (                        
                        <>
                            {companiesResponse.totalCount !== 0 ? (
                                companiesResponse.resourceList && 
                                companiesResponse.resourceList.map((c: models.Company, i: number) => (
                                    <Company 
                                        key={i}                                
                                        company={c}
                                    />                
                                ))
                            ) : <NoResults/>}
                        </>
                    );    

                default: <NoResults/>
            }
        };         

        return (
            <React.Fragment>   
                {typesList &&      
                <Row className="admin-container">
                    <Col className="admin-vertical-nav-column">
                        <Nav variant='pills' className="secondary-vertical-menu">
                            {typesList.sort((a: models.AdminType, b: models.AdminType) => {
                                if (a.name > b.name) {
                                    return 1; 
                                }
                                if (a.name < b.name) {
                                    return -1; 
                                }
                                return 0; 
                            }).map((x: models.AdminType, i: number) => {
                                return (
                                    <Nav.Link
                                        key={i}
                                        active={activeListItem === x.name}
                                        eventKey={x.name}
                                        onClick={() => this.handleItemClick(x.name)}
                                        >{x.name}
                                        {adminItemsLoading && activeListItem === x.name && 
                                            <Spinner animation="border" role="status" variant="primary" size="sm" className="float-end"/>
                                        }
                                    </Nav.Link>
                                );
                            })}
                        </Nav>
                    </Col>
                    <Col style={{justifyContent:'left'}}>                  
                        <div className="admin-list-label">List                            
                            <SingleActionButton
                                action="Add"
                                title={adminPage}
                                img={addIcon}
                                onClick={this.addItemClickEvent}
                            />
                            <QuickSearch />
                        </div>                        
                        {items()}
                        {showPagination &&
                            <> 
                                <Pager
                                    className = {"admin-pager"}
                                    retrieveDataCallback = {this.pagerCallback}
                                    pageSize = {pagingParams.pageSize}
                                    currentPage = {pagingParams.pageNo}
                                    totalCount = {responseTotalCount}
                                    pagesInRange = {3}
                                />
                            </>
                        }  
                    </Col>
                </Row>
                }                
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect (
        mapStateToProps,
        mapDispatchToProps    
) (AdminDetails);

