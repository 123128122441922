import { action } from 'typesafe-actions';
import * as constants from './constants';
import * as models from '../../models';

export const updateCompany = (companyId: number, patch: object) =>
    action(constants.PATCH_COMPANY, { companyId, patch }); 
    
export const requestCompany = (companyId: number) =>
    action(constants.REQUEST_COMPANY, { companyId }); 

export const receiveCompany = (company: models.Company) => 
    action(constants.RECEIVE_COMPANY, { company }); 

export const requestCompanyList = (request: models.CompaniesRequest) =>
    action(constants.REQUEST_COMPANY_LIST, { request }); 

export const receiveCompanyList = (response: models.CompaniesResponse) =>
    action(constants.RECEIVE_COMPANY_LIST, { response }); 

export const requestInsCompanyList = (paging: models.Paging) =>
    action(constants.REQUEST_INSURANCE_COMPANY_LIST, { paging }); 

export const receiveInsCompanyList = (response: models.CompaniesResponse) =>
    action(constants.RECEIVE_INSURANCE_COMPANY_LIST, { response });    
    
export const requestCompanyDropdown = (request: models.CompaniesRequest) =>
    action(constants.REQUEST_COMPANY_DROPDOWN, { request }); 

export const receiveCompanyDropdown = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_COMPANY_DROPDOWN, { response }); 

// dropdowns
export const requestAllCompaniesDD = () => 
    action(constants.REQUEST_ALL_COMPANIES_DD); 
export const receiveAllCompanyiesDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_ALL_COMPANIES_DD, { response }); 

export const requestAgentCompanyDD = () => 
    action(constants.REQUEST_AGENT_COMPANY_DD); 
export const receiveAgentCompanyDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_AGENT_COMPANY_DD, { response }); 

export const requestInsCompanyDD = () =>
    action(constants.REQUEST_INS_COMPANY_DD); 
export const receiveInsCompanyDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_INS_COMPANY_DD, { response }); 

export const requestMortgageCompanyDD = () =>
    action(constants.REQUEST_MORTGAGE_COMPANY_DD); 
export const receiveMortgageCompanyDD = (response: models.DropdownListResponse) =>
    action(constants.RECEIVE_MORTGAGE_COMPANY_DD, { response }); 