import * as React from 'react';
import MyTypes from 'MyTypes';
import { AppMenu } from './AppMenu'
import Login from './Login'
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import '../../assets/styles/Sidebar.css';
import '../../assets/styles/Header.css';
import logo from '../../assets/images/compass-logo-vector.svg';
import iconAvatar from '../../assets/images/no-avatar.svg'; 

import services from '../../services';
import components from '../../components'
import { Container, Dropdown, Row, Col, Image } from 'react-bootstrap';
import AppToaster from './AppToaster';
import Profile from '../profile'; 
import { getAuthUsername, userHasPermission } from '../../utils/helpers';
import { userActions } from 'store/user';
import * as helpers from '../../utils/helpers'; 
import { locationActions } from 'store/locations';
import { policyActions } from 'store/policies';
import { adminActions } from 'store/admin';
import { claimsActions } from 'store/claims';

interface Props extends PropsFromRedux {
}

interface State {
    showProfileModal: boolean; 
}

class Layout2 extends React.PureComponent<RouteComponentProps & Props, State> {

    public state = {
        showProfileModal: false, 
    };

    public componentDidMount() {        
        const userName = helpers.getAuthUsername();         
        if(userName && true) { // don't fetch stuff if user is not authorized yet...
            this.props.requestCurrentUser(userName);
            this.props.requestPolicyTypeDD(); 
            this.props.requestStatesAbbrDD(); 
            this.props.requestStatesDD(); 
            this.props.requestCatCodeDD(); 
            this.props.requestLossCausesDD(); 
        }
    }

    public goHome = () => {
        services.router.goto(services.router.url.dashboard); 
    }
    
    public render() {
        const currentUser = getAuthUsername();        

        let returnPage = currentUser 
            ? this.getAuthorizedPage(currentUser) 
            : this.getLoginPage();

        return returnPage;
    }

    public getLoginPage() {
        return (
            <Login></Login>
        );
    }

    public toggleProfileModal = () => {
        this.setState({showProfileModal: !this.state.showProfileModal}); 
    }

    public getAuthorizedPage(currentUser: string) {     
        return (
            <React.Fragment>
                <Profile
                    showModal={this.state.showProfileModal}
                    closeModal={this.toggleProfileModal}
                />
                <AppToaster/>
                <div >
                    <div className="layout-header">
                        <Container fluid className="layout-header-container-top">
                            <Row>
                                <Col xs={2} className="layout-header-logo">
                                    <img alt="Compass Logo" style={{ height: '87px', cursor: 'pointer' }} src={logo} onClick={this.goHome} />
                                </Col>
                                <Col xs={8} className="layout-header-title-container">                                    
                                    <Dropdown>
                                        <Dropdown.Toggle className='profile-dd'>
                                            <Image                                                 
                                                style={{maxHeight:'3em', cursor: 'pointer'}} 
                                                src={iconAvatar} 
                                                roundedCircle 
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={this.toggleProfileModal}> 
                                                <span>My Profile</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => services.router.goto(services.router.url.logout)}>
                                                <span>Logout</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Container fluid className="layout-body-container">
                    <Row>
                        <Col xs={2} className="sidebar-container-column">
                            <AppMenu></AppMenu>
                        </Col>
                        <Col xs={8} className="content-container-column">
                            <Route
                                path={services.router.url.claimList}
                                component={components.ClaimsList}
                            />
                            <Route
                                path={services.router.url.queues}
                                component={components.Queues}
                            />
                            <Route
                                exact={true}
                                path={services.router.url.dashboard}
                                render={() => {
                                    return (
                                        userHasPermission("Access Dashboard") ?
                                        <Route exact path={services.router.url.dashboard} component={components.Dashboard} /> :
                                        <Redirect to={services.router.url.claimList} />
                                    )
                                }}
                            />
                            <Route
                                exact={true}
                                path={services.router.url.admin}
                                render={() => {
                                    return (
                                        userHasPermission("Access Settings") ?
                                        <Route exact path={services.router.url.admin} component={components.Admin} /> :
                                        <Redirect to={services.router.url.claimList} />
                                    )
                                }}
                            />
                            <Route                                
                                path={services.router.url.security}
                                component={components.Security}
                            />
                            <Route
                                path={services.router.url.financials}
                                render={() => {
                                    return (
                                        userHasPermission("Access Financials") ?
                                        <Route path={services.router.url.financials} component={components.Financials} /> :
                                        <Redirect to={services.router.url.claimList} />
                                    )
                                }}                                
                            />
                            <Route
                                path={services.router.url.reports}
                                render={() => {
                                    return (
                                        userHasPermission("Access Reports") ?                                        
                                        <Redirect to={window.location.href = 'https://reports.compassadjusters.com/ReportServer/logon.aspx?ReturnUrl=/ReportServer/localredirect?url=%252fReports'} /> :
                                        <Redirect to={services.router.url.claimList} />
                                    )
                                }}
                            />
                            <Route
                                path={services.router.url.claimProfile}
                                component={components.ClaimDetails}
                            />
                            <Route
                                exact={true}
                                path={services.router.url.contactDetail}
                                component={components.ContactDetail}
                            />
                            <Route
                                exact={true}
                                path={services.router.url.companyDetail}
                                component={components.CompanyDetail}
                            />
                            <Route                                
                                path={services.router.url.unauthorized}
                                component={components.Unauthorized}
                            />                     
                            <Route path={services.router.url.notFound} 
                                component={components.NotFound} 
                            />                            
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimSingle: state.claims.claim.claim,
    currentUser: state.user.user.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestCatCodeDD: adminActions.requestCatCodeDD,
            requestCurrentUser: userActions.requestCurrentUser,
            requestLossCausesDD: claimsActions.requestLossCausesDD, 
            requestPolicyTypeDD: policyActions.requestPolicyTypeDD, 
            requestStatesAbbrDD: locationActions.requestStateAbbrDD, 
            requestStatesDD: locationActions.requestStateDD, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Layout2);

