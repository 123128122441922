import * as React from 'react';
import { Pagination, Dropdown, DropdownButton } from 'react-bootstrap';
import { DropdownToggle } from 'reactstrap';
import * as models from '../../models'; 

interface Props {
    retrieveDataCallback: (paging: {pageNo: number, pageSize: number}) => void; 
    className: string; 
    pageSize: number; 
    currentPage: number; 
    totalCount: number; 
    pagesInRange: number; 
}

interface State {    
    pagingParams: models.Paging; 
}

class Pager extends React.Component<Props, State> {
    public state = {
        pagingParams: {
            pageNo: this.props.currentPage,
            pageSize: this.props.pageSize,            
        }
    }

    public hanglePageClick = (e:any) => {
        const newCurrentPage = 
            e.target.innerText === 'NEXT' 
                ? this.props.currentPage + 1 
                : e.target.innerText === 'PREVIOUS'  ? this.props.currentPage - 1 
                : e.target.innerText
            ; 
        const pagingParams = {pageNo: parseInt(newCurrentPage), pageSize: this.props.pageSize};
        this.setState({pagingParams: pagingParams}); 
        this.props.retrieveDataCallback(pagingParams);
    }

    public handlePageSizeSelect = (e:any) => {
        const pagingParams: models.Paging = {
            pageNo: this.props.currentPage,
            pageSize: parseInt(e),
        }
        this.setState({pagingParams: pagingParams}); 
        this.props.retrieveDataCallback(pagingParams);
    }

    public executeCallback(paging: models.Paging) {
        this.props.retrieveDataCallback(paging);
    }

    public render() {
        const { className, totalCount, pagesInRange } = this.props;
        const { pagingParams } = this.state; 
       
        const lastPage = Math.floor(totalCount / pagingParams.pageSize) + 1;
        const tempStart = lastPage - pagesInRange;  
        const displayRangeStart = [1,2].includes(pagingParams.pageNo) ? 2 : ( [lastPage, lastPage -1].includes(pagingParams.pageNo) ? (tempStart < 1  ? 1 : tempStart) : pagingParams.pageNo - 1); 
        const tempEnd = displayRangeStart + pagesInRange - 1; 
        const displayRangeEnd = pagingParams.pageNo == lastPage || tempEnd > lastPage ? lastPage - 1 : tempEnd;
        
        const displayPageRange = []; 
        for (let number = displayRangeStart; number <= displayRangeEnd; number++){
            displayPageRange.push(number); 
        }
        !displayPageRange.includes(1) && displayPageRange.unshift(1); 
        !displayPageRange.includes(lastPage) && displayPageRange.push(lastPage); 

        const showPrevNext = Math.max(...displayPageRange) > pagesInRange ? true : false; 

        const items = new Array(); 
        displayPageRange.forEach((i) => items.push(
            <Pagination.Item 
                key={i} 
                active={pagingParams.pageNo===i} 
                onClick={(event) => this.hanglePageClick(event)}
            >{i}</Pagination.Item>));

        const pageItemStart = pagingParams.pageNo === 1 ? pagingParams.pageNo : pagingParams.pageNo * pagingParams.pageSize + 1; 
        const pageItemEnd = pageItemStart + pagingParams.pageSize - 1;         
        const pageSizeChoices = [20,50,100,250,500]; 

        return (
            <div className={className}>             
                <Dropdown className='pageSizeDD'>
                    <Dropdown.Toggle>{pagingParams.pageSize}
                        <Dropdown.Menu>
                            {pageSizeChoices.map((choice, i) => (
                                <Dropdown.Item key={i} onClick={() => this.handlePageSizeSelect(choice)}>{choice}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown.Toggle>
                </Dropdown>
                <div>Displaying {pageItemStart.toLocaleString()} - {pageItemEnd < totalCount ? pageItemEnd.toLocaleString() : totalCount.toLocaleString()} of {totalCount.toLocaleString()} records</div>
                {pageItemEnd < totalCount && 
                    <Pagination>
                        {showPrevNext && pagingParams.pageNo != 1 && <Pagination.Prev key={'prev'} onClick={(event) => this.hanglePageClick(event)}>PREVIOUS</Pagination.Prev>}
                        {items}
                        {showPrevNext && pagingParams.pageNo != displayPageRange[displayPageRange.length -1] && <Pagination.Next key={'next'} onClick={(event) => this.hanglePageClick(event)}>NEXT</Pagination.Next>}
                    </Pagination>
                }
            </div>
        ); 
    }
}

export default Pager; 