import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap'; 

interface Props {    
    activeFeeType: string; 
    value: number; 
    percent?: number; 
    rate?: number; 
    setPercent: (e:any) => void; 
    setValue: (e:any) => void; 
    setRate: (e:any) => void; 
}

interface State {
    showPercent:boolean; 
}

class FeeAmount extends React.Component<Props, State> {
    public state = {        
        showPercent: false,
    }

    public setLabel() {
        const defaultLabel = "Fee Amount"; 
        let label: string = defaultLabel;
        switch(this.props.activeFeeType){
            case "Flat":
                label = defaultLabel; 
                break; 
            case "PercentMin":
                label = "OR Min"; 
                break; 
            case "TandE":
                label = "OR Min"; 
                break; 
        }
        return label; 
    }

    public render() {
        const { activeFeeType, percent, value, rate } = this.props; 

        switch (activeFeeType) {
            case "PercentMin":
                return (
                    <Row>
                        <Col sm="4">                            
                            <Form.Group controlId="feePercent" className="input-field-container">
                                <Col sm="4">
                                    <Form.Label className="card-label">%</Form.Label>
                                </Col>
                                <Col sm="8">
                                    <Form.Control            
                                        type="number" 
                                        value={percent === 0 ? '' : percent}
                                        onChange={this.props.setPercent}
                                    />
                                </Col>
                            </Form.Group>  
                        </Col>
                        <Col sm="8">
                            <Form.Group controlId="feeAmount" className="input-field-container">
                                <Form.Label column sm="4" className="card-label">{this.setLabel()}</Form.Label>
                                <Form.Control                                 
                                    type="number" 
                                    value={value === 0 ? '' : value}
                                    placeholder='Enter Value...'                                    
                                    onChange={this.props.setValue}
                                />
                            </Form.Group>  
                        </Col>
                    </Row>
                );
            case "TandE":
                return (
                    <Row>
                        <Col sm="4">                            
                            <Form.Group controlId="feePercent" className="input-field-container">
                                <Col sm="5">
                                    <Form.Label className="card-label">Rate</Form.Label>
                                </Col>
                                <Col sm="8">
                                    <Form.Control            
                                        type="number" 
                                        value={rate === 0 ? '' : rate}
                                        onChange={this.props.setRate}
                                    />
                                </Col>
                            </Form.Group>  
                        </Col>
                        <Col sm="8">
                            <Form.Group controlId="feeAmount" className="input-field-container">
                                <Form.Label column sm="4" className="card-label">{this.setLabel()}</Form.Label>
                                <Form.Control                                 
                                    type="number" 
                                    value={value === 0 ? '' : value}
                                    placeholder='Enter Value...'                                    
                                    onChange={this.props.setValue}
                                />
                            </Form.Group>  
                        </Col>
                    </Row>
                );
            default: 
                return (
                    <Form.Group controlId="feeAmount" className="input-field-container">
                        <Form.Label column sm="4" className="card-label">{this.setLabel()}</Form.Label>
                        <Form.Control 
                            type="number" 
                            value={value === 0 ? '' : value}
                            placeholder='Enter Value...'                                    
                            onChange={this.props.setValue}
                        />
                    </Form.Group>  
                ); 
        }        
    }
}

export default (FeeAmount);
