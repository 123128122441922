import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux'; 
import { bindActionCreators, Dispatch } from 'redux';
import '../../assets/styles/Queues.css'; 
import { Table, Card, Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import { isFeatureEnabled } from '../../utils/featureFlags';


interface Props extends PropsFromRedux {  
}

interface State {
}

class Queues extends React.PureComponent<Props, State> {

    public state:State = {
    };    

    componentDidMount() {     
    }
    
    public renderConstruction() {
        return (
            <div className="construction">
                <div className="construction-title">Under Construction</div>
                <div className="construction-sub">Coming Soon</div>
            </div>
        );
    }

    public renderQueueTabs() {

        let rowsList = [];
        for (let i = 1; i <= 5; i++) {
            rowsList.push(
                <tr key={i}>
                    <td>Row {i}-1</td>
                    <td>Row {i}-2</td>
                </tr>
            );
        }

        return (
            <Card className="tab-card">
                <Card.Body>
                    <Card.Title>Queue</Card.Title>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Col 1</th>
                                <th>Col 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowsList}
                        </tbody>

                    </Table>
                </Card.Body>
            </Card>
        );

    }
  
    public render() {    
        let queuesEnabled = isFeatureEnabled("QUEUES");
        let renderContent = queuesEnabled ?
            this.renderQueueTabs() :
            this.renderConstruction();
        return (renderContent);
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Queues);
