import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import * as models from '../../models'; 
import _ from 'lodash'; 
import { claimsActions } from 'store/claims';

interface Props extends PropsFromRedux {  
    commentType: string; 
    showModal: boolean;
    closeModal: () => void; 
}

interface State {
    comment: string, 
}

class CommentModal extends React.Component<Props, State> {
    public state = {
        comment: "", 
    };

    public resetFormStateAndClose = () => {
        this.setState({comment: ""});
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {        
        const { addClaimComment, claimId, closeModal, commentType, currentUser } = this.props; 
        e.preventDefault(); 
        const newComment: models.Comment = {
            commentId: 0,
            commentType: commentType,
            comment: e.target.elements.comment.value,
            modifiedBy: currentUser.fullName, 
            modifiedDate: new Date().toDateString()
        }        
        addClaimComment(claimId, newComment); 
        closeModal(); 
    }

    public addComment = (e:any) => {
        this.setState({comment: e.target.value}); 
    }

    public render() {
        const { commentType, showModal, closeModal } = this.props; 

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Add Comment</Modal.Title>
                </Modal.Header>
                <Form 
                    className="claim-modal" 
                    onSubmit={this.submitForm}
                >
                    <Modal.Body>
                        <Form.Group as={Row} controlId="comment" className="input-field-container">
                            <Form.Label column sm="4">{ commentType }</Form.Label>
                            <Col sm="8">
                                <Form.Control as="textarea" onChange={this.addComment}/>
                            </Col>
                        </Form.Group>                   
                    </Modal.Body>                
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>                    
            </Modal>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    claimId: state.claims.claim.claim.claimID, 
    currentUser: state.user.user.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addClaimComment: claimsActions.addClaimComment, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CommentModal);