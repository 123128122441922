import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap'; 
import { adminActions } from 'store/admin';
import dateFormat from 'dateformat'; 
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import * as models from '../../models'; 
import { claimsActions } from 'store/claims';
import { policyActions } from 'store/policies';
import { companyActions } from 'store/companies'; 
import { getAgentCompanyDD, getMortgageCompanyDD } from 'services/api/companies';

interface Props extends PropsFromRedux {  
    showModal: boolean;
    closeModal: () => void; 
}

interface State {    
    claim: models.ClaimSimple;
    policy: models.Policy; 
}

class PolicyInformationModal extends React.Component<Props, State> {
    public state = {
        claim: this.props.claim, 
        policy: this.props.policy, 
    };    

    componentDidMount() {
        this.props.getAgentCompanyDD(); 
        this.props.getMortgageCompanyDD(); 
    }

    componentDidUpdate(prevProps: any) {
        if (  !_.isEqual(prevProps.claim, this.props.claim)) {
            this.setState({claim: this.props.claim});
        }
    }

    public resetFormStateAndClose = () => {
        this.setState({claim: this.props.claim});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault(); 
        const patchDocument = createPatch(this.props.claim, this.state.claim);
        if(patchDocument.length > 0)
            this.props.patchClaim(this.props.claim.claimID, patchDocument);

        const patchDocument2 = createPatch(this.props.policy, this.state.policy); 
        if(patchDocument2.length > 0)
            this.props.patchPolicy(this.props.claim.claimID, patchDocument2); 
        this.props.closeModal(); 
    }

    public render() {
        const { 
            agentCompanyList, 
            closeModal, 
            isAgentCompanyDDLoading, 
            isMortgageCompanyDDLoading, 
            isPolicyTypesListLoading, 
            mortgageCompanyList, 
            policyTypes, 
            showModal 
        } = this.props; 
        const { policy } = this.state; 

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                <Modal.Title>Edit Policy Info</Modal.Title>
                </Modal.Header>                
                <Form className="claim-modal" onSubmit={this.submitForm}>
                    <Modal.Body>   
                        <Form.Group as={Row} controlId="policyNumber" className="input-field-container">
                            <Form.Label column sm="4" >Policy Number</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="text" 
                                    value={policy?.policyNumber || ""} 
                                    onChange={(e) => this.setState({policy: {...policy, policyNumber: e.target.value}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="policyType" className="input-field-container">
                            <Form.Label column sm="4">Policy Type</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="policyTypes" 
                                    value={policy?.policyType || ""}
                                    onChange={(e) => this.setState({policy: {...policy, policyType: e.target.value}})} >
                                        {isPolicyTypesListLoading && <option>Loading...</option>}
                                        {!isPolicyTypesListLoading && <option key={-1}></option>}
                                        {policyTypes.totalCount > 0 && policyTypes.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                </Form.Select>  
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="policyDeductible" className="input-field-container">
                            <Form.Label column sm="4">Policy Deductible</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="0.00" 
                                        value={policy?.policyDeductible?.toFixed(2) || 0.00} 
                                        onChange={(e) => this.setState({policy: {...policy, policyDeductible: Number.parseFloat(e.target.value)}})} 
                                    />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="policyDeductible2" className="input-field-container">
                            <Form.Label column sm="4">Policy Deductible 2</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="0.00" 
                                        value={policy?.policyDeductible2?.toFixed(2) || 0} 
                                        onChange={(e) => this.setState({policy: {...policy, policyDeductible2: Number.parseFloat(e.target.value)}})} 
                                    />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="nsDeductible" className="input-field-container">
                            <Form.Label column sm="4">Storm Deductible</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="0.00" 
                                        value={policy?.namedStormDeductible?.toFixed(2) || 0} 
                                        onChange={(e) => this.setState({policy: {...policy, namedStormDeductible: Number.parseFloat(e.target.value)}})} 
                                    />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="effectiveDate" className="input-field-container">
                            <Form.Label column sm="4">Effective Date</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="date" 
                                    defaultValue={dateFormat(policy?.effectiveDate || "", 'yyyy-mm-dd')} 
                                    onChange={(e) => this.setState({policy: {...policy, effectiveDate: new Date(e.target.value)}})} 
                                />
                            </Col>
                        </Form.Group>                        
                        <Form.Group as={Row} controlId="expirationDate" className="input-field-container">
                            <Form.Label column sm="4">Expiration Date</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="date" 
                                    defaultValue={dateFormat(policy?.expirationDate || "", 'yyyy-mm-dd')} 
                                    onChange={(e) => this.setState({policy: {...policy, expirationDate: new Date(e.target.value)}})} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="mortgageCompany" className="input-field-container">
                            <Form.Label column sm="4">Mortgage Company</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="insCompany" 
                                    value={policy?.policyMortgageCompanyName || ""} 
                                    onChange={(e) => this.setState({policy: {...policy, policyMortgageCompanyName: e.target.value}})} 
                                >
                                    {isMortgageCompanyDDLoading && <option>Loading...</option>}
                                    {!isMortgageCompanyDDLoading && <option key={-1}>Choose...</option>}
                                    {mortgageCompanyList.totalCount > 0 && mortgageCompanyList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                        <option key={i} value={c.name} >{c.name}</option>
                                    ))}
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="insuranceAgency" className="input-field-container">
                            <Form.Label column sm="4">Insurance Agency</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="insCompany" 
                                    value={policy?.policyAgentCompanyName || ""} 
                                    onChange={(e) => this.setState({policy: {...policy, policyAgentCompanyName: e.target.value}})} 
                                >
                                    {isAgentCompanyDDLoading && <option>Loading...</option>}
                                    {!isAgentCompanyDDLoading && <option key={-1}>Choose...</option>}
                                    {agentCompanyList.totalCount > 0 && agentCompanyList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                        <option key={i} value={c.name} >{c.name}</option>
                                    ))}
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>                    
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                        CANCEL
                    </Button>
                    <Button variant="primary" type="submit">
                        SAVE
                    </Button>
                    </Modal.Footer>
                </Form>                 
            </Modal>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    agentCompanyList: state.companies.dropdowns.agentCompanyDD,        
    claim: state.claims.claim.claim, 
    isAgentCompanyDDLoading: state.companies.dropdowns.isAgentCompanyDDLoading,
    isCompanyListLoading: state.companies.company.isCompanyListLoading,
    isMortgageCompanyDDLoading: state.companies.dropdowns.isMortgageCompanyDDLoading,
    isPolicyTypesListLoading: state.policy.dropdowns.isPolicyTypesDDLoading,
    mortgageCompanyList: state.companies.dropdowns.mortgageCompanyDD,    
    policy: state.claims.claim.policy,
    policyTypes: state.policy.dropdowns.policyTypesDD,  
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getAgentCompanyDD: companyActions.requestAgentCompanyDD, 
            getMortgageCompanyDD: companyActions.requestMortgageCompanyDD,
            patchClaim: claimsActions.patchClaim, 
            patchPolicy: claimsActions.patchPolicy, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PolicyInformationModal);