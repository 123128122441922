import { assert } from "console";
import { useDispatch } from "react-redux";
import { userActions } from "../store/user";

export function encodeQueryString(reqeuestObject: any) {
    const querystring = require('query-string'); 
    let queryString = querystring.stringify(reqeuestObject, {skipNull: true}); 
    return queryString; 
}

export function getAuthUsername() {
    let token = localStorage.getItem('token');
    if (token) {
        //console.log("token: " + token);
        var tokenInfo = parseJwt(token);
        return parseJwt(token).sub;
    }
    return null;
}

// Returns true if the current user has the defined permission
export function userHasPermission(permissionName: string): boolean {
    let userInfo = getUserInfo();
    if (! userInfo || ! userInfo.permissions) return false;
    return userInfo.permissions.indexOf(permissionName) > -1;
}

export function getUserInfo() {
    let userInfoStr = localStorage.getItem('userInfo');
    if (userInfoStr) {
        return JSON.parse(userInfoStr)
    } 
    return null;
}

function parseJwt (token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export function getLocalToken() {
    const token = localStorage.getItem('token'); 
    if (token === null || token === undefined) {
        return; 
    }
    return token; 
}

export function roundTo(num: number, places: number) {
    const factor = 10 ** places; 
    return Math.round(num * factor) / factor; 
}; 

export function getErrorsFromAPIResponse(responseData: any) {
    const errorMessage = responseData.Message ?? responseData; 
    const innerException = responseData.InnerException?.Message ?? ""; 
    return errorMessage + "\r\n\r\n" + innerException; 
}

export function getErrorsFromAPIResponse2(responseData: any) {
    const errorMessage = responseData.data.Message ?? responseData; 
    const innerException = responseData.data.InnerException?.Message ?? ""; 
    return innerException !== "" ? innerException : errorMessage; 
}

export function get400Errors(responseData: any) {    
    const errorMessage = responseData.title; 
    const errorArray = responseData.errors[""].toString(); 
    return errorMessage + "\r\n\r\n" + errorArray; 
}

export function getResponseErrors(responseData: any) {  
    console.log(responseData);   
    const status = responseData.status; 
    switch (status) {
        case 400: 
            return get400Errors(responseData); 
        case 401:
            return responseData.data.message; 
        case 415: 
            return responseData.title; 
        case 500:
            return getErrorsFromAPIResponse2(responseData); 
        default: 
            return getErrorsFromAPIResponse(responseData); 
    }
}