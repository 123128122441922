import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import * as models from '../../models';
import { adminActions } from 'store/admin';
import { claimsActions } from 'store/claims';
import _ from 'lodash'; 
import { createPatch } from 'rfc6902';
import { contactsActions } from 'store/contacts';
import AddCatCodeModal from './AddCatCodeModal'; 
import AddContactSimpleModal from './AddContactSimpleModal'; 

interface Props extends PropsFromRedux {          
    closeModal: () => void;
    showModal: boolean;     
}

interface State {
    addNewText: string; 
    claim: models.ClaimSimple;
    showAddCatCodeModal: boolean;
    showAddContactModal: boolean; 
}

class ClaimExtrasModal extends React.Component<Props, State> {
    public state = {
        addNewText: "<Add New>", 
        claim: this.props.claim,
        showAddCatCodeModal: false, 
        showAddContactModal: false, 
    };

    componentDidMount() {
        const pagedList: models.Paging = {
            pageNo: 1,
            pageSize: 5000,
        }
        this.props.requestAdjusters(pagedList); 
        this.props.requestExaminers({...models.defaultContactsRequest, ...pagedList }); 
        this.props.requestFieldDirectors(pagedList); 
        this.props.requestReviewers(pagedList); 
        this.props.requestClaimTypes({pageNo:1, pageSize:100}); 
    }

    componentDidUpdate(prevProps: any) {
        if (  !_.isEqual(prevProps.claim, this.props.claim)) {
            this.setState({claim: this.props.claim});            
        }
/*
        if ( !_.isEqual(prevProps.reviewerList, this.props.reviewerList)){
            if(this.state.claim.reviewerid === 0)
                {                    
                    const reviewer = this.props.reviewerList.resourceList.find(x => x.fullName === this.state.claim.claimTeam.reviewer?.fullName)
                    if (reviewer)
                        this.setReviewer(reviewer); 
                }
        } */ 
    }
    
    public resetFormStateAndClose = () => {
        this.setState({claim: this.props.claim});
        this.props.closeModal(); 
    }
    
    public submitForm = (e: any) => {
        e.preventDefault(); 
        const patchDocument = createPatch(this.props.claim, this.state.claim); 
        const claimId = this.props.claim.claimID;         
        this.props.patchClaim(claimId, patchDocument);
        
        this.props.closeModal(); 
    }

    public handleChangeWithAddNew = (e:any) => {
        const field: string = e.target.name; 
        switch (field.toLowerCase()) {
            case "catcode" :
                if (e.target.value === this.state.addNewText) {
                    this.toggleAddCatCodeModal(); 
                }
                else 
                    this.setCatCode(e.target.value); 
            break;
            case "reviewer"  : 
                if (e.target.value === this.state.addNewText) {
                    this.toggleAddContactModal(); 
                }
                else {                    
                    const reviewer = this.props.reviewersDD.resourceList.find(x => x.id.toString() == e.target.value); 
                    reviewer && this.setReviewer(reviewer); 
                }                     
            break; 
        }        
    }

    //cat codes
    public addCatCodeCallback = (catCode: models.CatCode) => {
        this.props.addCatCode(catCode); 
        this.setCatCode(catCode.catCode); 
    }

    public setCatCode = (catCode: string) => {
        this.setState({claim: {...this.props.claim, catCode: catCode}});
    }

    public toggleAddCatCodeModal = () => {
        this.setState({showAddCatCodeModal: !this.state.showAddCatCodeModal}); 
    }

    // reviewers
    /*
    public addReviewerCallback = (contact: models.DropDownListItem) => {        
        const reviewer: models.DropDownListItem = {
            ...contact,
            contactTypes: 'reviewer'
        }
        this.props.addReviewer(reviewer); 
        this.setReviewer(reviewer);
    }*/

    public toggleAddContactModal = () => {
        this.setState({showAddContactModal: !this.state.showAddContactModal}); 
    }

    public setReviewer = (reviewer: models.DropDownListItem) => {
        this.setState({claim: {...this.state.claim, reviewer: reviewer}});
    }

    public render() {
        const {             
            adjustersDD,            
            catCodeDD, 
            claimTypesResponse, 
            closeModal, 
            examinersDD, 
            fieldDirectorsDD, 
            isAdjustersDDLoading, 
            isCatCodeDDLoading, 
            isExaminersDDLoading, 
            isFieldDirectorsDDLoading, 
            isReviewersDDLoading,            
            reviewersDD, 
            showModal,                        
        } = this.props;
        
        const { showAddCatCodeModal, showAddContactModal, addNewText, claim } = this.state;

        return (
            <>
            <AddCatCodeModal
                showModal={showAddCatCodeModal}
                closeModal={this.toggleAddCatCodeModal}
                callback={this.addCatCodeCallback}
            />            
            {/* <AddContactSimpleModal
                showModal={showAddContactModal}
                closeModal={this.toggleAddContactModal}
                callback={this.addReviewerCallback}
            /> */}
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Claim Extras</Modal.Title>
                </Modal.Header>                
                <Form className="claim-modal" onSubmit={this.submitForm} >                        
                    <Modal.Body>  
                        <Form.Group as={Row} controlId="fieldAdjuster" className="input-field-container">
                            <Form.Label column sm="4">Compass Field Adjuster</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="fieldAdjuster" 
                                    value={claim?.adjuster?.id || ''} 
                                    onChange={(e) => this.setState({claim: {...this.state.claim, adjuster: adjustersDD.resourceList.find(x => x.id.toString() == e.target.value)!}})} 
                                >
                                        {isAdjustersDDLoading && <option>Loading...</option>}
                                        {!isAdjustersDDLoading && <option key={-1}>Choose...</option>}
                                        {adjustersDD.totalCount > 0 && adjustersDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.id} >{c.name}</option>
                                        ))}
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="fieldDirector" className="input-field-container">
                            <Form.Label column sm="4">Field Director</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="fieldDirector" 
                                    value={claim?.director?.id || ''} 
                                    onChange={(e) => this.setState({claim: {...this.state.claim,  director: fieldDirectorsDD.resourceList.find(x => x.id.toString() == e.target.value)!}})} 
                                >
                                        {isFieldDirectorsDDLoading && <option>Loading...</option>}
                                        {!isFieldDirectorsDDLoading && <option key={-1}>Choose...</option>}
                                        {fieldDirectorsDD.totalCount > 0 && fieldDirectorsDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.id} >{c.name}</option>
                                        ))}
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="compassExaminer" className="input-field-container">
                            <Form.Label column sm="4">Compass Examiner</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="compassExaminer" 
                                    value={claim?.examiner?.id || ''} 
                                    onChange={(e) => this.setState({claim: {...this.state.claim, examiner: examinersDD.resourceList.find(x => x.id.toString() == e.target.value)!}})} 
                                >
                                        {isExaminersDDLoading && <option>Loading...</option>}
                                        {!isExaminersDDLoading && <option key={-1}>Choose...</option>}
                                        {examinersDD.totalCount > 0 && examinersDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.id} >{c.name}</option>
                                        ))}
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="reviewer" className="input-field-container">
                            <Form.Label column sm="4">Reviewer</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="reviewer" 
                                    value={claim?.reviewer?.id || ''} 
                                    onChange={(e) => this.handleChangeWithAddNew(e)} 
                                >
                                        {isReviewersDDLoading && <option>Loading...</option>}
                                        {!isReviewersDDLoading && <option key={-1}>Choose...</option>}
                                        {!isReviewersDDLoading && <option key={-99}>{addNewText}</option>}                                        
                                        {reviewersDD.totalCount > 0 && reviewersDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={c.id} >{c.name}</option>
                                        ))}                                        
                                </Form.Select>                                    
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="catCode" className="input-field-container">
                            <Form.Label column sm="4">Cat Code</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="catCode" 
                                    value={claim?.catCode || "Choose..."} 
                                    onChange={(e) => this.handleChangeWithAddNew(e)} 
                                >
                                    {isCatCodeDDLoading && <option>Loading...</option>}
                                    {!isCatCodeDDLoading && <option key={-1}>Choose...</option>}
                                    <option key={-99}>{addNewText}</option>
                                    {catCodeDD.totalCount > 0 && catCodeDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                        <option key={i} value={c.name} >{c.name}</option>
                                    ))}
                                </Form.Select> 
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="claimType" className="input-field-container">
                            <Form.Label column sm="4">Claim Type</Form.Label>
                            <Col sm="8">
                                <Form.Select 
                                    name="claimType" 
                                    value={claim?.claimType || "Choose..."} 
                                    onChange={(e) => this.setState({claim: {...claim, claimType: e.target.value}})} 
                                >
                                    <option key={-1}>Choose...</option>
                                    {claimTypesResponse?.resourceList?.length > 0 && claimTypesResponse.resourceList.map((c: models.ClaimType, i: number) => ( 
                                        <option key={i} value={c.claimTypeName} >{c.claimTypeName}</option>
                                    ))}
                                </Form.Select>  
                            </Col>
                        </Form.Group>                    
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                        CANCEL
                    </Button>
                    <Button variant="primary" type="submit">
                        SAVE
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            </>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({
    catCodeDD: state.admin.dropdowns.catCodeDD, 
    claim: state.claims.claim.claim,
    claimTypesResponse: state.claims.claim.claimTypesResponse,
    adjustersDD: state.contacts.dropdowns.adjustersDD, 
    examinersDD: state.contacts.dropdowns.examinersDD, 
    fieldDirectorsDD: state.contacts.dropdowns.fieldDirectorsDD, 
    isAdjustersDDLoading: state.contacts.dropdowns.isAdjustersDDLoading, 
    isCatCodeDDLoading: state.admin.dropdowns.isCatCodeDDLoading, 
    isExaminersDDLoading: state.contacts.dropdowns.isExaminersDDLoading, 
    isFieldDirectorsDDLoading: state.contacts.dropdowns.isFieldDirectorsDDLoading, 
    isReviewersDDLoading: state.contacts.dropdowns.isReviewersDDLoading, 
    reviewersDD: state.contacts.dropdowns.reviewersDD, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addCatCode: adminActions.addCatCode, 
            addReviewer: contactsActions.addReviewer, 
            patchClaim: claimsActions.patchClaim,
            requestAdjusters: contactsActions.requestFieldAdjustersList,
            requestClaimTypes: claimsActions.requestClaimTypeList, 
            requestExaminers: contactsActions.requestExaminersList,
            requestFieldDirectors: contactsActions.requestFieldDirectorsList, 
            requestReviewers: contactsActions.requestReviewersList,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ClaimExtrasModal);