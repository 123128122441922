import * as React from 'react';
import '../../assets/styles/Profile.css';
import * as models from '../../models';
import NumberFormat from 'react-number-format'; 

interface Props {
    phoneNumber: models.PhoneNumber
}

interface State {
}

class PhoneNumber extends React.Component<Props, State> {
    public render() {
        return (
            <div>
                <NumberFormat 
                    format={this.props.phoneNumber.phoneNumberType + ": (###) ###-####"} 
                    placeholder="(xxx) xxx-xxxx"
                    displayType='text'
                    value={this.props.phoneNumber.phoneNumber}
                />
            </div>
        );
    }
}

export default PhoneNumber;