// users
export const REQUEST_CURRENT_USER = 'user/REQUEST_CURRENT_USER'; 
export const RECEIVE_CURRENT_USER = 'user/RECEIVE_CURRENT_USER';

export const UPDATE_PROFILE = 'user/UPDATE_PROFILE'; 

// actions
export const CLEAR_APP_TOAST = 'user/CLEAR_APP_TOAST'; 
export const SET_APP_TOAST = 'user/SET_APP_TOAST'; 
export const SET_LAST_USER_ACTION = 'user/SET_LAST_USER_ACTTION'; 

// events
export const SET_LAST_INVOICE_CREATED_ID = 'events/SET_LAST_INVOICE_CREATED_ID'; 