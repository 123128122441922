import * as React from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'; 
import { CardBody } from 'reactstrap';
import * as models from '../../models'
import NumberFormat from 'react-number-format';
import _ from 'lodash'; 
import AdjusterFeeTable from './AdjusterFeeTable';

interface Props{  
    adjustersList: models.DropDownListItem[]; 
    advanceCandidates: models.AdjusterFee[]; 
    showModal: boolean;
    closeModal: () => void;
    callback: (x: models.AdjusterFee[]) => void;
}

interface State {    
    fieldAdjuster: number;  
    payrollItems: models.AdjusterFee[];    
    selectedItems: number[];    
}

class PayrollAdvanceModal extends React.Component<Props, State> {
    public state = {
        fieldAdjuster: 0,
        payrollItems: new Array<models.AdjusterFee>,
        selectedItems: new Array<number>, 
    };

    public handleAdjusterChange = (e: any) => {
        this.setState({
            fieldAdjuster: parseInt(e.target.value), 
            payrollItems: this.props.advanceCandidates.filter(x => x.adjusterId === e.target.value), 
        });
    }

    public toggleItem = (id: number) => {
        let newSelectedItems = new Array<number>; 

        if(this.state.selectedItems.includes(id)){
            newSelectedItems = this.state.selectedItems.filter(x => x !== id); 
        }
        else {
            newSelectedItems = this.state.selectedItems.concat(id); 
        }
        this.setState({selectedItems: newSelectedItems}); 
    }

    public resetFormStateAndClose = () => {
        this.setState({
            fieldAdjuster: 0,
            payrollItems: new Array<models.AdjusterFee>,
            selectedItems: new Array<number>, 
        }); 
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {        
        e.preventDefault();
        const advanceFees: models.AdjusterFee[] = this.props.advanceCandidates.filter(x => this.state.selectedItems.includes(x.invoiceFeeId)); 
        this.props.callback(advanceFees); 
        this.resetFormStateAndClose(); 
    }

    sumPayments = (invoiceFees: models.AdjusterFee[]) => invoiceFees.reduce((a, c) => a = a + c.adjusterTotal, 0); 

    public render() {
        const { adjustersList, advanceCandidates, showModal } = this.props;
        const { fieldAdjuster, selectedItems } = this.state; 

        const selectedTotal = this.sumPayments(advanceCandidates.filter(x => selectedItems.includes(x.invoiceFeeId))); 
        const availableTotal = this.sumPayments(advanceCandidates.filter(x => x.adjusterId === fieldAdjuster)); 

        return (
            <Modal size='lg' centered={true} show={showModal} onHide={this.resetFormStateAndClose} >                                
                <Modal.Header closeButton>
                    <Modal.Title>Payroll Advance</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>
                    <Modal.Body> 
                        <Card className="claim-card">
                            <Card.Title className="inv-card-title">
                                <Row>
                                    <Col>Selected:</Col>
                                    <Col><NumberFormat value={selectedTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                </Row>
                                <Row>
                                    <Col>Available:</Col>
                                    <Col><NumberFormat value={availableTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                </Row>
                            </Card.Title>
                            <CardBody>
                                <Form.Group as={Col}>
                                    <Form.Label>Field Adjuster</Form.Label>
                                    <Form.Select name="adjuster" value={fieldAdjuster} onChange={this.handleAdjusterChange} >
                                        <option key={-1}>Choose...</option>
                                        {adjustersList.map((n: models.DropDownListItem, i: number) => ( 
                                            <option key={i} value={n.id} >{n.name}</option>
                                        ))}
                                    </Form.Select>                                    
                                </Form.Group>     
                                {advanceCandidates &&
                                    <AdjusterFeeTable 
                                        adjusterFees={advanceCandidates.filter(x => x.adjusterId === fieldAdjuster)}
                                        selectedItems={selectedItems}
                                        toggleItem={this.toggleItem}
                                    />
                                }                                   
                            </CardBody>
                        </Card>          
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>            
        );
    }
}

export default PayrollAdvanceModal;