import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models';
import * as actions from './actions';
import * as constants from './constants';

export interface DashboardStateShape {
    monthlyClaimsActivity: models.MonthlyClaimActivityResponse; 
 }

export const dashboardDefaultState: DashboardStateShape = {
    monthlyClaimsActivity: models.defaultDashbardResponse, 
};

export interface State {    
    dashboards: DashboardStateShape;
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    dashboards: (state = dashboardDefaultState, action) => {
        switch (action.type) {   
            case constants.REQUEST_MONTHLY_CLAIM_ACTIVITY: 
                return {
                    ...state,                    
                }
            case constants.RECEIVE_MONTHLY_CLAIM_ACTIVITY:
                return {
                    ...state, 
                    monthlyClaimsActivity: action.payload.response,
                }

            default: 
                return state; 
        }
    }
});
