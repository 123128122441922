import * as React from 'react';
import MyTypes from 'MyTypes';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Accordion, Button, Card, CardGroup, Col, Form, Row } from 'react-bootstrap'; 
import { CardBody } from 'reactstrap';
import * as models from '../../models'
import PayrollAdvanceModal from './PayrollAdvanceModal';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import AdjusterFeeTable from './AdjusterFeeTable';
import { companyActions } from 'store/companies';
import { financialsActions } from 'store/financials';
import _ from 'lodash'; 
import iconCheck from '../../assets/images/icons8-ok.svg'; 
import { userHasPermission } from '../../utils/helpers';

interface Props extends PropsFromRedux {
}

interface State {            
    comment: string; 
    company: string; 
    inclegacy: boolean; 
    payrollFees: models.AdjusterFee[]; 
    payrollDateStart: string; 
    payrollDateTo: string;    
    payrollMode: string; 
    showAdvancedModal: boolean;    
}

interface feesByAdjuster {
    adjuster: string, 
    feeCount: number, 
    sum: number, 
    advSum: number, 
    fees: models.AdjusterFee[]
}

class Payroll extends React.Component<Props, State> {
    public state = {        
        comment: '', 
        company: '', 
        inclegacy: true, 
        payrollFees: new Array<models.AdjusterFee>,
        payrollDateStart: '',  
        payrollDateTo: '',     
        payrollMode: "",
        showAdvancedModal: false, 
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if(!_.isEqual(prevState.payrollDateStart, this.state.payrollDateStart)) {            
            if (this.state.payrollDateTo != "")
                this.setState({payrollMode: "search"}); 
        }
        if(!_.isEqual(prevState.payrollDateTo, this.state.payrollDateTo)) {
            if (this.state.payrollDateStart != "")
                this.setState({payrollMode: "search"}); 
        }
        if(!_.isEqual(prevState.payrollFees, this.state.payrollFees) && this.state.payrollDateStart.length > 0) {
            this.setState({payrollMode: "ready"}); 
        }
        if(!_.isEqual(prevProps.isPayrollRunning, this.props.isPayrollRunning) && !this.props.isPayrollRunning){
            this.setState({payrollMode: "complete"}); 
        }        
    }   

    public componentDidMount = () => {
        const request: models.AdjusterFeeRequest = {
            isPaid: false, 
            hasAdjusterAmt: true, 
        }        
        this.props.requestAdjusterFeesList(request);   
        this.props.requestInsCompanyDD();        
    }
    
    public submitForm = (e:any) => {                
        e.preventDefault();         
        const addPayroll: models.AddPayroll = {
            startDate: this.state.payrollDateStart,
            endDate: this.state.payrollDateTo,
            comments: this.state.comment,          
            addLegacy: this.state.inclegacy,  
            payrollFees: this.state.payrollFees.map(i => ({id: i.adjusterFeeId, amt: i.adjusterBalance} as models.AddPayrollFee)),
        }                       
        this.props.addPayroll(addPayroll);                        
    }

    public handleSearch = () => {
        if(this.state.payrollDateStart == "" || this.state.payrollDateTo == ""){
            alert("Start and End Dates must be populated"); 
            return; 
        }
        let endDate = new Date(this.state.payrollDateTo);         
        endDate.setDate(endDate.getDate() + 2); 
        
        const newPayrollFees = this.props.adjusterFeesList.resourceList.filter(
            x => x.adjusterBalance > 0 
            && x.lastPaymentDate >= this.state.payrollDateStart 
            && x.lastPaymentDate <= endDate.toDateString()
            && x.insCompanyName === (this.state.company === '' ? x.insCompanyName : this.state.company)
        ); 
        if (newPayrollFees.length > 0)
            this.setState({payrollFees: this.state.payrollFees.concat(newPayrollFees)}); 
        else 
            alert("Zero Fees to Payout..."); 
    }

    public toggleAdvancedModal = () => {
        this.setState({showAdvancedModal: !this.state.showAdvancedModal}); 
    }

    public advancedModalCallback =(advanceFees: models.AdjusterFee[]) => {        
        const newFeesArray = this.state.payrollFees.concat(advanceFees.map(x => ({...x, isAdvance: true})));         
        this.setState({payrollFees: [...newFeesArray]}); 
    }

    public sumAdjusters = (fees: models.AdjusterFee[]) => {
        let returnValue: feesByAdjuster[] = new Array<feesByAdjuster>();

        fees?.forEach((item) => {
            const i: number = returnValue.findIndex(x => x.adjuster === item.adjuster);             
            if(i > -1){
                returnValue[i].sum += item.isAdvance ? item.adjusterTotal : item.adjusterBalance;
                returnValue[i].feeCount += 1; 
                returnValue[i].advSum += (item.isAdvance ? item.adjusterTotal : 0); 
            }
            else {                
                returnValue.push({
                    adjuster: item.adjuster, 
                    feeCount: 1, 
                    sum: (item.isAdvance ? item.adjusterTotal : item.adjusterBalance), 
                    advSum: (item.isAdvance ? item.adjusterTotal : 0), 
                    fees: fees.filter(x => x.adjuster === item.adjuster)
                }); 
            }
        }
        ); 
        return returnValue; 
    }

    public clearForm = () => {        
        this.setState({
            comment: '', 
            company: '', 
            inclegacy: true, 
            payrollFees: new Array<models.AdjusterFee>, 
            payrollDateStart: '',  
            payrollDateTo: '', 
            payrollMode: ""
        });
    }    

    public render() {     
        const { comment, inclegacy, payrollDateStart, payrollDateTo, payrollFees, payrollMode, showAdvancedModal } = this.state;
        const { adjusterFeesList, insCompanyDD, isAdjusterFeesLoading, isInsCompanyDDLoading, isPayrollRunning, isZipDownloading } = this.props; 
        
        const advancesByAdjuster = this.sumAdjusters(payrollFees);        
        const feeCount = [...new Set(payrollFees?.map((item) => item.adjuster))].length || 0; 
        const advanceCount = [...new Set(payrollFees.filter(x => x.isAdvance).map((i) => i.adjuster))].length || 0; 
        const advanceAmount = payrollFees?.filter(x => x.isAdvance).reduce((a, c) => a = a + c.adjusterTotal, 0); 
        const adjusterCount = [...new Set(payrollFees.map((i) => i.adjuster))].length || 0; 
        const totalAmount = payrollFees?.reduce((a, c) => a = a + (c.isAdvance ? c.adjusterTotal : c.adjusterBalance), 0);

        const advanceFeeCandidates = adjusterFeesList.resourceList.filter(x => x.feesPaid == 0); 
        const advanceAdjusterIds = advanceFeeCandidates.map((i) => i.adjusterId); 

        const payrollAccess = userHasPermission("Access Payroll"); 

        return (
            <>
                <PayrollAdvanceModal 
                    adjustersList={adjusterFeesList.payrollAdjusters.filter(x => advanceAdjusterIds.includes(x.id))}
                    advanceCandidates={advanceFeeCandidates}
                    showModal={showAdvancedModal}
                    closeModal={this.toggleAdvancedModal}
                    callback={this.advancedModalCallback}
                />            
                <Form onSubmit={this.submitForm}>
                    <CardGroup>
                        <Card className="claim-card">  
                            <Card.Title className="inv-card-title">Setup</Card.Title>
                            <CardBody>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Payroll Dt Start</Form.Label>                                    
                                            <Form.Control 
                                                disabled={!payrollAccess}
                                                type="date"
                                                name="payrollDateFrom" 
                                                value={payrollDateStart} 
                                                onChange={(e) => this.setState({payrollDateStart: e.target.value})}
                                            />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Payroll Dt End</Form.Label>                                    
                                            <Form.Control 
                                                disabled={!payrollAccess}
                                                type="date"
                                                name="payrollDateTo" 
                                                value={payrollDateTo} 
                                                onChange={(e) => this.setState({payrollDateTo: e.target.value})}
                                            />
                                    </Form.Group>
                                </Row>
                                <Row style={{paddingTop: ".5em"}}>
                                    <Col sm={8}>
                                        <Form.Group controlId="insCompany" className="input-field-container2">
                                            <Form.Label column sm="4">Company Name</Form.Label>
                                            <Form.Select style={{marginLeft: "1em"}} name="insCompany" 
                                                value={this.state.company} 
                                                onChange={(e) => this.setState({company: e.target.value})}
                                                >
                                                    {isInsCompanyDDLoading && <option>Loading...</option>}
                                                    {!isInsCompanyDDLoading && <option key={-1}>Choose...</option>}                                        
                                                    {insCompanyDD.totalCount > 0 && insCompanyDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                        <option key={i} value={c.name} >{c.name}</option>
                                                    ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="isCat" className="input-field-container2">
                                            <Form.Check 
                                                inline={true}
                                                name="incLegacy"
                                                type="checkbox"
                                                label="Include Legacy"
                                                id="chkIncLegacy"
                                                checked={inclegacy}
                                                onChange={(e) => this.setState({inclegacy: !inclegacy})}
                                            />                                
                                        </Form.Group>   
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Group controlId="comment" className="input-field-container2">
                                        <Col sm={{span: 3}}>
                                            <Form.Label>Comment</Form.Label>
                                        </Col>
                                        <Col sm={{span: 9}}>
                                            <Form.Control
                                                disabled={!payrollAccess}                                                     
                                                as="textarea"                                         
                                                value={comment || ""} 
                                                onChange={(e) => this.setState({comment: e.target.value})} 
                                            />
                                        </Col>
                                    </Form.Group>    
                                </Row>
                                <Row className="payroll-btn-grp">
                                    <Col>
                                        <Button disabled={!payrollAccess} onClick={this.clearForm}>RESET</Button>
                                    </Col>
                                    <Col>
                                        <Button disabled={!payrollAccess} onClick={this.toggleAdvancedModal}>ADVANCE</Button>
                                    </Col>
                                    <Col>
                                        <Button 
                                            onClick={this.handleSearch}
                                            disabled={!payrollAccess &&payrollMode !== "search"}
                                            >{isAdjusterFeesLoading ? "LOADING..." : "SEARCH"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="claim-card">         
                            <Card.Title className="inv-card-title">Summary
                            </Card.Title>
                            <CardBody className='payroll-summary'>
                                <Col>
                                    <Row>
                                        <Col>Start Date: </Col>
                                        <Col>{payrollDateStart && dateFormat(payrollDateStart, "mediumDate", true)}</Col>
                                    </Row>
                                    <Row>
                                        <Col>End Date: </Col>
                                        <Col>{payrollDateTo && dateFormat(payrollDateTo, "mediumDate", true)}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Advances: </Col>
                                        <Col>{advanceCount}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Adv Amt: </Col>
                                        <Col><NumberFormat value={advanceAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                    </Row>
                                    <Row>
                                        <Col>Adjusters: </Col>
                                        <Col>{adjusterCount}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Total Amt: </Col>
                                        <Col><NumberFormat value={totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Col>
                                    </Row>
                                </Col>
                                <Col className='payroll-summary-img'>
                                    {payrollMode === "complete" && <img src={iconCheck} style={{width: "15%"}}/>}                                    
                                    <Button 
                                        disabled={!payrollAccess && payrollMode !== "ready"} 
                                        type='submit'                                        
                                    >
                                        {isPayrollRunning ? "RUNNING..." : (isZipDownloading ? "DOWNLOADING..." : "FINALIZE")}
                                    </Button>
                                </Col>
                            </CardBody>
                        </Card>
                    </CardGroup>
                    {feeCount > 0 &&
                    <Card className="claim-card">
                        <Card.Title className="inv-card-title">Adjuster Payroll Fees</Card.Title>
                        <Card.Body>
                            <Accordion className="inv-acc-card" alwaysOpen>
                                {advancesByAdjuster?.map((x: feesByAdjuster, i:number) => {
                                    return (
                                        <Accordion.Item key={i} eventKey={x.adjuster}>
                                            <Accordion.Header className="inv-acc-hdr">
                                                <Col>Adjuster: {x.adjuster}</Col>
                                                <Col>Fees: {x.feeCount}</Col>                                                     
                                                <Col>Advance Amt: <NumberFormat value={x.advSum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                                <Col>Total: <NumberFormat value={x.sum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Col>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AdjusterFeeTable
                                                    adjusterFees={x.fees}
                                                    showAdvance={true}
                                                />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ); 
                                })}
                            </Accordion>      
                        </Card.Body>
                    </Card>
                    }                    
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    adjusterFeesList: state.financials.payroll.adjusterFeeResponse, 
    isAdjusterFeesLoading: state.financials.payroll.isAdjusterFeesLoading,    
    insCompanyDD: state.companies.dropdowns.insCompanyDD,
    isInsCompanyDDLoading: state.companies.dropdowns.isInsCompanyDDLoading, 
    isPayrollRunning: state.financials.payroll.isPayrollRunning, 
    isZipDownloading: state.financials.payroll.isZipDownloading, 
}); 

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {       
            addPayroll: financialsActions.addPayroll, 
            requestAdjusterFeesList: financialsActions.requestAdjusterFeesList,    
            requestInsCompanyDD: companyActions.requestInsCompanyDD, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(Payroll);
