import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const sendEmail = async (emailRequest: models.EmailRequest) => {
    const response = await request ({
        method: 'POST',
        url: `/notifications/sendEmail`,
        data: emailRequest
    }); 
    return response; 
};
