import * as models from '..';

export interface Company {
    companyId: number; 
    name: string; 
    isActive: boolean; 
    type: string; 
    email: string; 
    claimEmail: string; 
    phoneNumbers: models.PhoneNumber[]; 
    mailingAddress: models.Address; 
}

export interface CompaniesRequest extends models.RequestBase {
    type: string; 
    activeOnly: boolean; 
}

export interface CompaniesResponse extends models.ResponseBase<Company> { }

export const defaultCompany: Company = {
    companyId: 0,
    name: '', 
    isActive: false, 
    type: '', 
    email: '', 
    claimEmail: '',
    phoneNumbers: [models.defaultPhoneNumber],
    mailingAddress: models.defaultAddress,
}
