import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux'; 
import { bindActionCreators, Dispatch } from 'redux';
import PageHeader from 'components/common/PageHeader';
import { Table, Card, Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import '../../assets/styles/styles.css';
import '../../assets/styles/security.css'
import { securityActions } from 'store/security';
import SingleActionButton from 'components/common/SingleActionButton';
import editIcon from '../../assets/images/Blue/edit.svg';
import { Auth2User, Auth2Group } from 'models/security/auth2GroupsResponse';
import * as models from '../../models'; 
import { userHasPermission } from '../../utils/helpers';
// import { builtinModules } from 'module';

interface Props extends PropsFromRedux {  
}

interface State {
    tab: string;
    userChecks: boolean[][];
    permissionChecks: boolean[][];
    userPermissionCheckState: boolean[];
    showUserPermissionModal: boolean;
    userPermissionModelUser: Auth2User|null;
    userSearchRequest: models.Auth2UserSearchRequest;
}

class Security extends React.PureComponent<Props, State> {
    tabs: string[] = ["Users", "Permissions", "Groups"]; 

    public state:State = {
        tab: this.tabs[0],
        userChecks: [],
        permissionChecks: [],
        userPermissionCheckState: [],
        showUserPermissionModal: false,
        userPermissionModelUser: null,
        userSearchRequest: models.defaultAuth2UserSearchRequest
    };    

    componentDidMount() {     
        this.props.requestGroups();
        this.fetchUserData();
        this.props.requestPermissions(); 
    }
    
    static createUserGroupsCheckState(users: Auth2User[], groups: Auth2Group[]) {
        var userChecks:boolean[][] = [];
        for(var i:number = 0; i < users.length; i++) {
            userChecks[i] = [];
            for (var j:number = 0; j < groups.length; j++) {
                userChecks[i][j] = users[i].groupIds.indexOf(groups[j].groupId) > -1;
            }
        }
        return userChecks;
    }

    static createUserPermissionsCheckState(permissions:models.Auth2Permission[], user:Auth2User|null) {
        var permChecks:boolean[] = [];
        if (user == null) {
            return permChecks;
        }
        for(var i:number = 0; i < permissions.length; i++) {
            permChecks[i] = user.permissionIds.indexOf(permissions[i].permissionId) > -1;
        }
        return permChecks;
    }

    static createPermissionGroupCheckState(permissions:models.Auth2Permission[], groups:Auth2Group[]) {
        var permChecks:boolean[][] = [];
        for (var i:number = 0; i < permissions.length; i++) {
            permChecks[i] = [];
            for (var j:number = 0; j < groups.length; j++) {
                permChecks[i][j] = groups[j].permissionIds.indexOf(permissions[i].permissionId) > -1;
            }
        }
        return permChecks;
    }

    public handleTabs = (name: string) => {
        this.setState({tab: name});
    }

    public updateUserGroup(userId: number, groupId: number, isInGroup: boolean) {
        this.props.updateUserGroupMembership({ userId: userId, groupId: groupId, isInGroup: isInGroup})
    }

    public updateUserPermission(userId: number, permissionId: number, include: boolean) {
        console.log(`Updating user permission for UserId: ${userId}, PermissionId: ${permissionId}, Include: ${include}`);
        this.props.updateUserPermission({ userId: userId, permissionId: permissionId, include: include});
    }

    public openUserPermissionModal(user:Auth2User) {
        this.setState({
                showUserPermissionModal: true, 
                userPermissionModelUser: user,
                userPermissionCheckState: this.getInitialUserPermissionCheckState(user)
            });
    }

    public renderTabs() {

        let tabContent;

        if (this.props.isLoading) {
            tabContent = <span>loading...</span>
        } else {
            switch (this.state.tab) {
                case 'Users':
                    tabContent = this.renderUsers();
                    break;
                case 'Groups':
                    tabContent = this.renderGroups();
                    break;
                case 'Permissions':
                    tabContent = this.renderPermissions();
                    break;
                default:
                    tabContent = (<span>Under Construction</span>);
            }
        }

        return (
            <React.Fragment>
                {tabContent}
            </React.Fragment>
        );
    }

    public renderPermissions() {

        var permissions = this.props.permissions.resourceList;

        var permChecks = Security.createPermissionGroupCheckState(permissions, this.props.groups);

        var message = 'Displaying ' + permissions.length + ' permissions.';
        if (this.props.isUpdatingGroups) {
            message += '...updating permissions...'; 
        } else if (this.props.isUpdatingGroupsDone) {
            message += '...permissions saved.'; 
        }
        return (
            <React.Fragment>
                <span>{message}</span>
                <Table striped>
                <thead>    
                    <tr>
                        <th>Permission Name</th>
                        {this.props.groups.map((group, i) => (
                            <th key={i}>{group.groupName}</th>
                        ))}
                    </tr>
                </thead>                
                <tbody>
                    {permissions.map((permission, i) => (
                    <tr key={i}>
                        <td>{permission.permissionName}</td>
                        {this.props.groups.map((group, j) => {
                                return (
                                    <td key={j}>
                                        <input type="checkbox"  
                                            id={`group-perm-checkbox-${i}-${j}`}
                                            name='GroupPermissions'
                                            value='GroupPermissions'
                                            checked={permChecks[i][j]}
                                            onChange={() => this.props.updateGroupPermission({ groupId: group.groupId, permissionId: permission.permissionId, include: !permChecks[i][j]})}
                                        />
                                    </td>
                                )
                                })}
                    </tr>
                ))}
                </tbody>                        
            </Table>
            </React.Fragment>
        );
    }
    
    public renderGroups() {
        return (
            <Table striped>
                <thead>    
                    <tr>
                        <th>Group Name</th>
                    </tr>
                </thead>                
                <tbody>
                    {this.props.groups.map((group, i) => (
                        <tr key={i}>
                            <td>{group.groupName}</td>
                        </tr>
                    ))}
                </tbody>                        
            </Table>
        );
    }

    public applyUserSearchFilter = (e:any) => {
        const { name, value } = e.target;
        let userSearchRequest: models.Auth2UserSearchRequest;
        switch (name) {
            case 'firstName':
                userSearchRequest = {
                    ...this.state.userSearchRequest,
                    firstName: value
                };
                break;
            case 'lastName':
                userSearchRequest = {
                    ...this.state.userSearchRequest,
                    lastName: value
                };
                break;
            case 'email':
                userSearchRequest = {
                    ...this.state.userSearchRequest,
                    email: value
                };
                break;
            case 'roles':
                userSearchRequest = {
                    ...this.state.userSearchRequest,
                    role: value
                };
                break;
            default:
                userSearchRequest = this.state.userSearchRequest;
        }
        this.setState({userSearchRequest: userSearchRequest});
    }

    public searchUsers = () => {
        this.fetchUserData();
    }

    public resetSearchUsers = () => {
        this.setState({userSearchRequest: models.defaultAuth2UserSearchRequest});
        this.fetchUserData();
    }

    public fetchUserData = () => {
        // alert('fetchUserData()');
        this.props.requestUsers(this.state.userSearchRequest);
    }


    public renderUsers() {
        var userChecks = Security.createUserGroupsCheckState(this.props.users, this.props.groups);

        var message = 'Found ' + this.props.users.length + ' users.';
        if (this.props.isUpdatingUsers) {
            message += '...updating permissions...'; 
        } else if (this.props.isUpdatingUsersDone) {
            message += '...permissions saved.'; 
        }

        return (
            <React.Fragment>
                {(process.env.REACT_APP_FEATURE_USER_SEARCH_ENABLED == "true") && (
                    <Card className="tab-card">
                        <Card.Body>
                            <Card.Title>User Search</Card.Title>
                            <Form>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control name="firstName" 
                                                          placeholder='First Name' 
                                                          value={this.state.userSearchRequest.firstName}
                                                          onChange={this.applyUserSearchFilter}
                                                         />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control name="lastName" 
                                                          placeholder='Last Name' 
                                                          value={this.state.userSearchRequest.lastName}
                                                          onChange={this.applyUserSearchFilter}
                                                         />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control name="email" 
                                                          placeholder='Email' 
                                                          value={this.state.userSearchRequest.email}
                                                          onChange={this.applyUserSearchFilter}
                                                         />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Roles</Form.Label>
                                            <Form.Select name="roles" onChange={this.applyUserSearchFilter}>
                                                <option key={-1} value="">Select a Role</option>
                                                {this.props.groups.map((group, j) => {
                                                    return (
                                                        <option key={j} value={group.groupId}>{group.groupName}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button onClick={this.searchUsers}>Search</Button>
                                        <Button onClick={this.resetSearchUsers}>Reset Filters</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                )}
                <Card className="tab-card">
                    <Card.Body>
                        <Card.Title>{message}</Card.Title>
                        <Table striped >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>User</th>
                                    {this.props.groups.map((group, i) => (
                                        <th key={i}>{group.groupName}</th>
                                    ))}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.users.map((user, i) => (
                                    <tr key={i}>
                                        <td>{user.userFullName}</td>
                                        <td>{user.userEmail}</td>
                                        {this.props.groups.map((group, j) => {
                                            return (
                                                <td key={j}>
                                                    <input type="checkbox"
                                                        id={`user-group-checkbox-${i}-${j}`}
                                                        name='UserGroups'
                                                        value='UserGroups'
                                                        checked={userChecks[i][j]}
                                                        onChange={() => this.updateUserGroup(user.userId, group.groupId, !userChecks[i][j])}
                                                    />
                                                </td>
                                            )
                                        })}
                                        <td>
                                            <SingleActionButton
                                                action="Edit"
                                                title="User Permissions"
                                                img={editIcon}
                                                onClick={() => this.openUserPermissionModal(user)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {this.renderUserPermissions()}
                    </Card.Body>
                </Card>

            </React.Fragment>
        )
    }

    handlePermissionCheckChange = (position:number) => {
        const updatedCheckedState = this.state.userPermissionCheckState.map((item, index) =>
            index === position ? !item : item
        );
        this.setState({userPermissionCheckState: updatedCheckedState});
    };

    getInitialUserPermissionCheckState(user:Auth2User) {
        if (user == null) return [];

        var permissions = this.props.permissions.resourceList;
        return new Array(permissions.length).fill(false).map((_item, index) =>
            user.permissionIds.indexOf(permissions[index].permissionId) > -1
        );
    }


    public closeUserPermissionModal = () => {
        this.setState({showUserPermissionModal: false});
    }

    public saveUserPermissionModel = () => {
        if (this.state.userPermissionModelUser == null) return;

        var newPermissionIds = this.state.userPermissionCheckState;
        var oldPermissionIds = this.getInitialUserPermissionCheckState(this.state.userPermissionModelUser);

        for (var i:number = 0; i < this.props.permissions.totalCount; i++) {
            if (newPermissionIds[i] == oldPermissionIds[i]) continue;

            this.props.updateUserPermission( {
                userId: this.state.userPermissionModelUser.userId,
                permissionId: this.props.permissions.resourceList[i].permissionId,
                include: newPermissionIds[i] 
            });
        }

        this.setState({showUserPermissionModal: false});
    }


    public renderUserPermissions() {
        var userEmail = this.state.userPermissionModelUser == null ? "" : this.state.userPermissionModelUser.userEmail;

        var message = "this is a message";
        var groupPermissionIds = new Map();
        if (this.state.showUserPermissionModal && this.state.userPermissionModelUser != null) {
            var user = this.state.userPermissionModelUser;
            var groups = this.props.groups;
            for (var groupId of user.groupIds) {
                var group = groups.find(x => x.groupId === groupId);
                if (group) {
                    for (var permissionId of group.permissionIds) {
                        groupPermissionIds.set(permissionId, true);
                    } 
                }
            }
        }

        var groupPermissionsMessage = <div>MyMessage</div>

        return (
            <Modal centered={true} 
                show={this.state.showUserPermissionModal} 
                onHide={this.closeUserPermissionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Permissions for {userEmail}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <div>{message}</div> */}
                    <Table striped>
                        <thead>    
                            <tr>
                                <th>Permission Name</th>
                                <th></th>
                            </tr>
                        </thead>                
                        <tbody>
                            {this.props.permissions.resourceList.map((permission, i) => (
                                <tr key={i}>
                                    <td>{permission.permissionName}</td>
                                    <td key={i}>
                                        <input type="checkbox"
                                            id={`user-permission-checkbox-${i}`}
                                            name='UserPermission'
                                            value='UserPermissions'
                                            checked={this.state.userPermissionCheckState[i] || groupPermissionIds.has(permission.permissionId)}
                                            onChange={() => this.handlePermissionCheckChange(i)}
                                            disabled={groupPermissionIds.has(permission.permissionId)}
                                            />
                                        <i className="bi bi-info-circle"></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>                        
                    </Table>
                    <p><strong>Note:</strong> Permissions that are included from a group are checked and grayed out.</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.closeUserPermissionModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={this.saveUserPermissionModel}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    public renderIsLoading() {
        return (
            <span>loading...</span>
        );
    }
    
    public render() {        
        return (            
            <>
                {userHasPermission("Security Administrator") ? (
                    <>
                    <PageHeader
                        getTabName={this.handleTabs}
                        tabs={this.tabs}
                    />
                    {this.renderTabs()}
                    </>
                ) : <h3>USER NOT AUTHORIZED</h3> }
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    groups: state.security.groups.Auth2GroupResponse.resourceList,
    isLoading: state.security.groups.isLoading,
    permissions: state.security.permissions.Auth2PermissionResponse, 
    isPermissionsLoading: state.security.permissions.isPermissionResponseLoading, 
    users: state.security.users.Auth2UserResponse.resourceList,
    isUpdatingUsers: state.security.users.isUpdatingUsers,
    isUpdatingUsersDone: state.security.users.isUpdatingUsersDone,
    isUpdatingGroups: state.security.groups.isUpdatingGroups,
    isUpdatingGroupsDone: state.security.groups.isUpdatingGroupsDone,
    isUpdatingUserPermissions: state.security.users.isUpdatingUserPermissions,
    isUpdatingUserPermissionsDone: state.security.users.isUpdatingUserPermissionsDone,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            requestGroups: securityActions.requestGroups,
            requestPermissions: securityActions.requestPermissions, 
            requestUsers: securityActions.requestUsers,
            updateUserGroupMembership: securityActions.updateUserGroupMembership,
            updateGroupPermission: securityActions.updateGroupPermission,
            updateUserPermission: securityActions.updateUserPermission,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Security);
