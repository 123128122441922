import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';
import { ApiResponse } from 'services/api/base';

export default function* () {
    yield takeEvery(constants.ADD_CONTACT_SIMPLE, addContactSimple);     
    yield takeEvery(constants.ADD_COMPANY_EXAMINER, addCompanyExaminer); 
    yield takeEvery(constants.ADD_REVIEWER, addReviewer); 
    yield takeEvery(constants.REQUEST_ADJUSTERS_DD, requestAdjustersDD); 
    yield takeEvery(constants.REQUEST_COMPANY_EXAMINERS, requestCompanyExaminersList); 
    yield takeEvery(constants.REQUEST_COMPANY_EXAMINERS_DD, requestCompanyExaminerDD); 
    yield takeEvery(constants.REQUEST_CONTACT, requestContact); 
    yield takeEvery(constants.REQUEST_CONTACT_LIST, requestContactList); 
    yield takeEvery(constants.REQUEST_EXAMINERS_DD, requestExaminersDD); 
    yield takeEvery(constants.REQUEST_EXAMINERS_LIST, requestExaminersList); 
    yield takeEvery(constants.REQUEST_FIELD_DIRECTORS_DD, requestFieldDirectorsDD); 
    yield takeEvery(constants.REQUEST_FIELD_ADJSTERS_LIST, requestFieldAdjustersList); 
    yield takeEvery(constants.REQUEST_FIELD_DIRECTORS_LIST, requestFieldDirectorsList); 
    yield takeEvery(constants.REQUEST_REVIEWERS_LIST, requestReviewersList); 
    yield takeEvery(constants.REQUEST_REVIEWERS_DD, requestReviewersDD); 
    yield takeEvery(constants.UPDATE_CONTACT, updateContact); 
}

export function* addContactSimple(action: ActionType<typeof actions.addContactSimple>): any {
    const userRequest: ApiResponse = yield call(services.api.contact.addContactSimple, action.payload.request); 
    if (userRequest.isSuccess) {
        yield userRequest;        
    }
    else {
        console.log(userRequest);  
    }        
}

export function* addCompanyExaminer(action: ActionType<typeof actions.addCompanyExaminer>): any {
    const userRequest: ApiResponse = yield call(services.api.contact.addContactSimple, action.payload.contact); 
    if (userRequest.isSuccess) {                
        yield put(actions.requestCompanyExaminersList({pageNo: 1, pageSize: 2000}, action.payload.contact.companyName)); 
    }
    else {
        console.log(userRequest);  
    }     
}

export function* addReviewer(action: ActionType<typeof actions.addReviewer>): any {
    const userRequest: ApiResponse = yield call(services.api.contact.addContactSimple, action.payload.contact); 
    if (userRequest.isSuccess) {                
        yield put(actions.requestReviewersList({pageNo: 1, pageSize: 2000})); 
    }
    else {
        console.log(userRequest);  
    }     
}

export function* requestCompanyExaminersList(action: ActionType<typeof actions.requestCompanyExaminersList>): any {
    const contactReqeust: models.ContactsRequest = {
        ...models.defaultContactsRequest,
        ...action.payload.paging,
        type: 'Examiner', 
        companyName: action.payload.companyName
    }
    const userRequest = yield call(services.api.contact.getContacts, contactReqeust); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCompanyExaminersList(userRequest.data)); 
    }
    else {
        console.log(userRequest.data); 
    }
}

export function* requestContact(action: ActionType<typeof actions.requestContact>): any {
    const userRequest = yield call(services.api.admin.getContactByID, action.payload.contactId);     
    if (userRequest.isSuccess) {
        yield put(actions.receiveContact(userRequest.data)); 
    }
}

export function* requestContactList(action: ActionType<typeof actions.requestContactList>): any {
    const userRequest = yield call(services.api.contact.getContacts, action.payload.request);     
    if (userRequest.isSuccess) {
        yield put(actions.receiveContactList(userRequest.data)); 
    }
}

export function* requestExaminersList(action: ActionType<typeof actions.requestExaminersList>): any {
    const contactRequest:models.ContactsRequest = {
        ...models.defaultContactsRequest,
        ...action.payload.request,
        type: 'Examiner', 
    }
    const userRequest = yield call(services.api.contact.getContacts, contactRequest);
    if (userRequest.isSuccess) {        
        yield put(actions.receiveExaminersList(userRequest.data)); 
    }
}

export function* requestFieldAdjustersList(action: ActionType<typeof actions.requestFieldAdjustersList>): any {
    const contactRequest:models.ContactsRequest = {
        ...models.defaultContactsRequest,
        ...action.payload.request,
        type: 'Field Adjuster', 
    }

    const userRequest = yield call(services.api.contact.getContacts, contactRequest);
    if (userRequest.isSuccess) {        
        yield put(actions.receiveFieldAdjsutersList(userRequest.data)); 
    }
}

export function* requestFieldDirectorsList(action: ActionType<typeof actions.requestFieldDirectorsList>): any {
    const contactRequest:models.ContactsRequest = {
        ...models.defaultContactsRequest,
        ...action.payload.request,
        type: 'Field Director', 
    }

    const userRequest = yield call(services.api.contact.getContacts, contactRequest);
    if (userRequest.isSuccess) {        
        yield put(actions.receiveFieldDirectorsList(userRequest.data)); 
    }
}

export function* requestReviewersList(action: ActionType<typeof actions.requestReviewersList>): any {
    const contactRequest:models.ContactsRequest = {
        ...models.defaultContactsRequest,
        ...action.payload.request,
        type: 'Reviewer', 
    }
    const userRequest = yield call(services.api.contact.getContacts, contactRequest);
    if (userRequest.isSuccess) {        
        yield put(actions.receiveReviewersList(userRequest.data)); 
    }
}

export function* updateContact(action: ActionType<typeof actions.updateContact>): any {      
    const userRequest = yield call(services.api.contact.updateContact, action.payload.contactId, action.payload.patch);
     if (userRequest.isSuccess) {        
        yield put(actions.requestContact(action.payload.contactId)); 
    }    
    else {
        console.log(userRequest.data); 
    }
}

// dropdowns
export function* requestAdjustersDD(action: ActionType<typeof actions.requestAdjustersDD>): any {
    const userRequest = yield call(services.api.contact.getAdjusters);
    if (userRequest.isSuccess) {
       yield put(actions.receiveAdjustersDD(userRequest.data)); 
   }    
   else console.log(userRequest);
}

export function* requestCompanyExaminerDD(action: ActionType<typeof actions.requestCompanyExaminersDD>): any {
    const userRequest = yield call(services.api.contact.getCompanyExaminers, action.payload.companyName);
    if (userRequest.isSuccess) {
       yield put(actions.receiveCompanyExaminersDD(userRequest.data)); 
   }    
   else console.log(userRequest);    
}

export function* requestExaminersDD(action: ActionType<typeof actions.requestExaminersDD>): any {
    const userRequest = yield call(services.api.contact.getExaminers);
    if (userRequest.isSuccess) {
       yield put(actions.receiveExaminersDD(userRequest.data)); 
   }    
   else console.log(userRequest);
}

export function* requestFieldDirectorsDD(action: ActionType<typeof actions.requestFieldDirectorsDD>): any {
    const userRequest = yield call(services.api.contact.getFieldDirectors);
    if (userRequest.isSuccess) {
       yield put(actions.receiveFieldDirectorsDD(userRequest.data)); 
   }    
   else console.log(userRequest);
}

export function* requestReviewersDD(action: ActionType<typeof actions.requestReviewersDD>): any {
    const userRequest = yield call(services.api.contact.getReviewers);
    if (userRequest.isSuccess) {
       yield put(actions.receiveReviewersDD(userRequest.data)); 
   }    
   else console.log(userRequest);
}