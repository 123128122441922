import { ApiResponse, request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const addContactSimple = async (contact: models.ContactSimple) => {
  const response = await request ({
    method: 'POST',
    url: `/Contacts`,
    data: contact
  }); 
  return response; 
}

export const getConnectAdjuster = async (contactId: number) => {
  const response = await request ({
    method: 'GET',
    url: `/Contacts/sync/${contactId}`
  }); 
  return response; 
}

export const getContacts = async (contactRequest: models.ContactsRequest) => {  
  var qStr = utils.helpers.encodeQueryString(contactRequest); 

  const response = await request ({
    method: 'GET', 
    url: `/Contacts?${qStr}`, 
  }); 
  return response; 
}; 

export const updateContact = async (contactId: number, patch: object) => {
  return await request({
      method: 'PATCH',
      url: `/contacts/${contactId}`,
      data: patch,
  }); 
}

export const getContactMethods = async (id: number) => {
  return await request({
      method: 'GET',
      url: `/contacts/${id}/contactMethods`,      
  }); 
}

// dropdowns
export const getAdjusters = async () => {
  return await request({
      method: 'GET',
      url: `/contacts/adjusters`,      
  }); 
}

export const getCompanyExaminers = async (companyName: string) => {
  return await request({
      method: 'GET',
      url: `/contacts/companyExaminers/${companyName}`,      
  }); 
}

export const getExaminers = async () => {
  return await request({
      method: 'GET',
      url: `/contacts/examiners`,      
  }); 
}

export const getFieldDirectors = async () => {
  return await request({
      method: 'GET',
      url: `/contacts/fieldDirectors`,      
  }); 
}

export const getReviewers = async () => {
  return await request({
      method: 'GET',
      url: `/contacts/reviewers`,      
  }); 
}