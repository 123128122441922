import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Card, CardGroup, Col, Form, Image, InputGroup, Modal, Row, Spinner, ToastContainer, Toast, ToastBody } from 'react-bootstrap'; 
import NumberFormat from 'react-number-format'; 
import { claimsActions } from 'store/claims';
import { adminActions } from 'store/admin';
import { userActions } from 'store/user'; 
import { locationActions } from 'store/locations';
import { USER_ACTIONS } from 'store/enums'; 
import { AppToastTypes } from 'models/enums'; 
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg'; 
import _ from 'lodash'; 
import * as models from '../../models';
import AddCatCodeModal from './AddCatCodeModal'; 

interface Props extends PropsFromRedux {
    closeModal: () => void; 
    showModal: boolean;     
}

interface State {        
    addNewText: string,     
    claim: models.AddClaim;     
    showAddCatCodeModal: boolean;
    validated: boolean;     
    zipSearchAddress: string; 
}

class ClaimCreateModal extends React.Component<Props, State> {
    public state = {
        addNewText: "<Add New>", 
        claim: {
            lossAddSame: false,
            lossInformation: {
                policy: {} 
            },
            insuredPerson: {
                mailingAddress: models.defaultAddress
            }
        } as models.AddClaim,
        showAddCatCodeModal: false,
        validated: false,         
        zipSearchAddress: '',  
    }

    public componentDidMount = () => {        
        this.props.requestClaimTypes({pageNo: 1, pageSize: 10});        
    }

    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.zipSearch, this.props.zipSearch)) { 
            if (this.state.zipSearchAddress === 'loss') {                
                this.populateLossFromZipSearch(); 
            }
            else {
                this.populateMailFromZipSearch(); 
            }
        }
      
        if(!_.isEqual(prevProps.lastUserAction, this.props.lastUserAction) && this.props.lastUserAction === USER_ACTIONS.CLAIM_CREATING_SUCCESS) {
            this.resetFormStateAndClose(); 
        }
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        const validationMessage: string = this.RunCustomValidations(); 
        if( validationMessage === "passed") {
            const form = e.currentTarget; 
            this.setState({validated: true});
            if(form.checkValidity() === false){                        
                e.stopPropagation(); 
            } 
            else {
                this.props.addClaim(this.state.claim); 
            }
        }
        else 
            this.props.setAppToast({message: validationMessage, type: AppToastTypes.Failure });

    }

    public RunCustomValidations = () => {
        const {claim} = this.state; 
        if(claim.lossInformation.policy.policyNumber && !claim.lossInformation.policy.policyType) 
            return "Policy Type must be included on policy.";         
        if(claim.lossInformation.policy.policyType && !claim.lossInformation.policy.policyNumber) 
            return "Policy Number must be included on policy.";
        return "passed"; 
    }

    public resetFormStateAndClose = () => {
        this.setState({
            claim: {} as models.AddClaim,
            validated: false, 
        }); 
        this.props.closeModal(); 
    }

    public handleChangeWithAddNew = (e:any) => {
        const field: string = e.target.name; 
        switch (field.toLowerCase()) {
            case "catcode" :
                if (e.target.value === this.state.addNewText) {
                    this.toggleAddCatCodeModal(); 
                }
                else 
                    this.setCatCode(e.target.value); 
            break;            
        }        
    }

    public zipSearch = (zipCode: string, addressType: string) => {        
        this.setState({zipSearchAddress: addressType});
        if(zipCode !== this.props.zipSearch.zipCode)
            this.props.requestZipSearch(zipCode);
        else {
            if(addressType === 'loss')
                this.populateLossFromZipSearch(); 
            if(addressType === 'mailing')
                this.populateMailFromZipSearch(); 
        } 
    }

    public populateLossFromZipSearch = () => {
        const newAddress: models.Address = {
            ...this.state.claim.lossInformation?.lossLocation
            , city: this.props.zipSearch.cityName
            , state: this.props.zipSearch.stateName
            , postalCode: this.props.zipSearch.zipCode
        }
        this.setState({claim: {...this.state.claim, lossInformation: {...this.state.claim.lossInformation, lossLocation: newAddress}}}); 
    }

    public populateMailFromZipSearch = () => {
        const newAddress: models.Address = {
            ...this.state.claim.insuredPerson?.mailingAddress
            , city: this.props.zipSearch.cityName
            , state: this.props.zipSearch.stateName
            , postalCode: this.props.zipSearch.zipCode
        }
        this.setState({claim: {...this.state.claim, insuredPerson: {...this.state.claim.insuredPerson, mailingAddress: newAddress}}}); 
    }

    public handleSameAddressToggle = () => {
        const newFlag = !this.state.claim.lossAddSame; 
        const newInsuredPerson: {
            insuredName: string,        
            mailingAddress: models.Address, 
            cellNumber: string, 
            homeNumber: string, 
            workNumber: string, 
        } = {
            ...this.state.claim.insuredPerson,
            mailingAddress: newFlag ? {...this.state.claim.lossInformation.lossLocation} : {...this.state.claim.insuredPerson.mailingAddress}
        }
        this.setState({
            claim: {                
                ...this.state.claim,
                insuredPerson: newInsuredPerson,
                lossAddSame: newFlag,
                }                
        });         
    }

    public handleDateChanges = (e: any) => {
        const { name, value } = e.target;
        let claim: models.AddClaim;

        switch (name) {
            case 'receivedDate':
                claim = {
                    ...this.state.claim, 
                    dateReceived: value === "" ? null : value,
                };  
                break; 
            case 'dateReported':
                claim = {
                    ...this.state.claim, 
                    dateReported: value === "" ? null : value,
                };  
                break;       
            case 'lossDate':
                claim = {
                    ...this.state.claim, 
                    lossInformation: {
                        ...this.state.claim.lossInformation,
                        dateLoss: value === "" ? null : value,
                    }
                };  
                break;
            case 'policyStartDate':
                claim = {
                    ...this.state.claim, 
                    lossInformation: {
                        ...this.state.claim.lossInformation, 
                        policy: {
                            ...this.state.claim.lossInformation.policy,
                            effectiveDate: value === "" ? null : value, 
                        }
                    }
                };  
                break;
            case 'policyEndDate':
                claim = {
                    ...this.state.claim, 
                    lossInformation: {
                        ...this.state.claim.lossInformation, 
                        policy: {
                            ...this.state.claim.lossInformation.policy,
                            expirationDate: value === "" ? null : value, 
                        }
                    }
                };  
                break;
            default: 
                claim = {
                    ...this.state.claim,
                };
        }
        this.setState({claim: claim}); 
    }

    //cat codes
    public addCatCodeCallback = (catCode: models.CatCode) => {
        this.props.addCatCode(catCode); 
        this.setCatCode(catCode.catCode); 
    }

    public setCatCode = (catCode: string) => {
        this.setState({claim: {...this.state.claim, catCode: catCode}});
    }

    public toggleAddCatCodeModal = () => {
        this.setState({showAddCatCodeModal: !this.state.showAddCatCodeModal}); 
    }

        
    public render() {
        const { 
            adjustersDD,
            agentCompanyDD, 
            catCodesList, 
            claimTypesResponse, 
            examinersDD, 
            fieldDirectorsDD, 
            insCompaniesList,
            isAdjustersDDLoading,  
            isExaminersDDLoading, 
            isFieldDirectorsDDLoading,
            isInsCompanyDDLoading, 
            isMortgageCompanyDDLoading, 
            isPolicyTypesListLoading, 
            isReviewersDDLoading, 
            isStateAbbrDDLoading,
            lastUserAction,
            lauseCausesDD, 
            mortgageCompanyDD, 
            policyTypes, 
            reviewersDD,
            showModal, 
            stateList,
        } = this.props; 
        const { addNewText, claim, showAddCatCodeModal, validated } = this.state; 

        return (
            <>            
            <AddCatCodeModal
                showModal={showAddCatCodeModal}
                closeModal={this.toggleAddCatCodeModal}
                callback={this.addCatCodeCallback}
            />     
            <Modal fullscreen={true} centered={true} show={showModal} onHide={this.resetFormStateAndClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Claim Builder
                        {lastUserAction === USER_ACTIONS.CLAIM_CREATING  && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    </Modal.Title>
                </Modal.Header>
                <Form 
                    className="claim-modal" 
                    noValidate={true}
                    onSubmit={this.submitForm}
                    validated={validated}
                >
                    <Modal.Body>
                        <Row>                       
                            <CardGroup>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Claim Info</Card.Title>
                                    <Card.Body>
                                        <Form.Group controlId="insCompany" className="input-field-container2">
                                            <Col sm={{span: 5}}>
                                                <Form.Label>Insurance Company</Form.Label>
                                            </Col>
                                            <Col sm={{span:6}}>
                                                <Form.Select name="insCompany"
                                                    required
                                                    value={claim.insuranceCompanyId || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, insuranceCompanyId: parseInt(e.target.value)}})}                                                        
                                                    >
                                                        {<option key={-1}>Choose...</option>}                                        
                                                        {insCompaniesList.totalCount > 0 && insCompaniesList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                            <option key={i} value={c.id} >{c.name}</option>
                                                        ))}
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="claimNumber" className="input-field-container2">
                                            <Col sm={{span: 5}}>
                                                <Form.Label>Ins. Claim Number</Form.Label>
                                            </Col>
                                            <Col sm={{span:6}}>
                                                <Form.Control name="claimNumber"
                                                    required
                                                    type='text'
                                                    value={claim.insClaimNumber || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, insClaimNumber: e.target.value}})}                                                        
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="dateRecevied" className="input-field-container2">
                                            <Col sm={{span: 5}}>
                                                <Form.Label>Received Date</Form.Label>
                                            </Col>
                                            <Col sm={{span:6}}>
                                                <Form.Control 
                                                    type="date"
                                                    name="receivedDate" 
                                                    value={claim.dateReceived?.toLocaleString() || ""}                                                     
                                                    onChange={this.handleDateChanges}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="dateReported" className="input-field-container2">
                                            <Col sm={{span: 5}}>
                                                <Form.Label>Reported Date</Form.Label>
                                            </Col>
                                            <Col sm={{span:6}}>
                                                <Form.Control 
                                                    type='date'
                                                    name="dateReported"                                                    
                                                    value={claim.dateReported?.toLocaleString() || ""} 
                                                    onChange={this.handleDateChanges}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="claimType" className="input-field-container2">
                                            <Col sm={{span: 5}}>
                                                <Form.Label>Claim Type</Form.Label>
                                            </Col>
                                            <Col sm={{span:6}}>
                                                <Form.Select 
                                                    name="claimType"
                                                    value={claim.claimTypeId || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, claimTypeId: parseInt(e.target.value)}})}                                                        
                                                    >
                                                        {<option key={-1}>Choose...</option>}                                        
                                                        {claimTypesResponse.totalCount > 0 && claimTypesResponse.resourceList.map((c: models.ClaimType, i: number) => ( 
                                                            <option key={i} value={c.id} >{c.claimTypeName}</option>
                                                        ))}
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>  
                                    </Card.Body>
                                </Card>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Loss Info</Card.Title>
                                    <Card.Body>
                                        <Form.Group controlId="dateLoss" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>Loss Date</Form.Label>
                                            </Col>
                                            <Col sm={{span:6}}>
                                                <Form.Control 
                                                    type="date"
                                                    name="lossDate" 
                                                    value={claim.lossInformation?.dateLoss?.toLocaleString() || ""} 
                                                    onChange={this.handleDateChanges}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="lossCause" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>Loss Cause</Form.Label>
                                            </Col>
                                            <Col sm={{span:6}}>
                                            <Form.Select 
                                                name="causesList" 
                                                value={claim?.lossInformation?.lossCause || "-1"} 
                                                onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, lossCause: e.target.value}}})} >
                                                    <option key={-1}>Choose...</option>
                                                    {lauseCausesDD.totalCount > 0 && lauseCausesDD.resourceList.map((c: models.Cause, i: number) => ( 
                                                        <option key={i} value={c.name} >{c.name}</option>
                                                    ))}
                                            </Form.Select> 
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="lossDescription" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>Loss Description</Form.Label>
                                            </Col>
                                            <Col sm={{span: 6}}>
                                                <Form.Control 
                                                    name="lossDesc"
                                                    as='textarea'
                                                    value={claim.lossInformation?.lossDescription || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, lossDescription: e.target.value}}})}                                                        
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="catCode" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>Cat Code</Form.Label>
                                            </Col>
                                            <Col sm={{span: 6}}>
                                                <Form.Select name="catCode"
                                                    value={claim.catCode || "-1"} 
                                                    onChange={(e) => this.handleChangeWithAddNew(e)} 
                                                    >
                                                        <option key={-1}>Choose...</option>
                                                        <option key={-99}>{addNewText}</option>
                                                        {catCodesList.totalCount > 0 && catCodesList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                            <option key={i} value={c.name} >{c.name}</option>
                                                        ))}
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>  
                                    </Card.Body>
                                </Card>   
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Loss Location</Card.Title>
                                    <Card.Body>
                                        <Form.Group controlId="lossAddress1" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>Address Line 1</Form.Label>
                                            </Col>
                                            <Col sm={{span: 8}}>
                                                <Form.Control 
                                                    required
                                                    type="text" 
                                                    value={claim.lossInformation?.lossLocation?.addressLine1 || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, lossLocation: {...claim.lossInformation?.lossLocation, addressLine1: e.target.value}}}})} 
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="lossAddress2" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>Address Line 2</Form.Label>
                                            </Col>
                                            <Col sm={{span: 8}}>
                                                <Form.Control 
                                                    type="text"
                                                    value={claim.lossInformation?.lossLocation?.addressLine2 || ""}  
                                                    onChange={(e) => this.setState({claim: {...claim, lossInformation:{...claim.lossInformation, lossLocation: {...claim.lossInformation?.lossLocation, addressLine2: e.target.value}}}})} 
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="lossZip" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>Zip Code</Form.Label>
                                            </Col>
                                            <Col sm={{span: 3}}>
                                                <Form.Control 
                                                    required
                                                    type="text" 
                                                    value={claim.lossInformation?.lossLocation?.postalCode || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, lossLocation: {...claim.lossInformation?.lossLocation, postalCode: e.target.value}}}})} 
                                                />
                                            </Col>
                                            <Col sm="2" className="inpput-search-col">
                                                <Button 
                                                    className="input-search-btn" 
                                                    onClick={() => this.zipSearch(claim.lossInformation?.lossLocation?.postalCode, 'loss')}>
                                                        <Image src={searchIcon}/>
                                                </Button>
                                            </Col>
                                        </Form.Group>                        
                                        <Form.Group controlId="lossCity" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label column sm="4">City</Form.Label>
                                            </Col>
                                            <Col sm={{span: 6}}>
                                                <Form.Control 
                                                    required
                                                    type="text" 
                                                    value={claim.lossInformation?.lossLocation?.city || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, lossLocation: {...claim.lossInformation?.lossLocation, city: e.target.value}}}})} 
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="lossState" className="input-field-container2">
                                            <Col sm={{span: 4}}>
                                                <Form.Label>State</Form.Label>
                                            </Col>
                                            <Col sm={{span: 6}}>
                                                <Form.Select 
                                                    required
                                                    name="statesList" 
                                                    value={claim.lossInformation?.lossLocation?.state || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, lossLocation: {...claim.lossInformation?.lossLocation, state: e.target.value}}}})} >                                                        
                                                        {isStateAbbrDDLoading && <option>Loading...</option>}
                                                        {!isStateAbbrDDLoading && <option key={-1}>Choose...</option>}
                                                        {stateList.totalCount > 0 && stateList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                            <option key={i} value={c.name} >{c.name}</option>
                                                        ))}
                                                </Form.Select>  
                                            </Col>
                                        </Form.Group> 
                                    </Card.Body>
                                </Card>
                            </CardGroup>     
                        </Row>
                        <Row>
                            <CardGroup>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Claim Team</Card.Title>
                                    <Card.Body>
                                        <Row>
                                            <Form.Group as={Col} controlId="compassAdjuster" className="input-field-container2">
                                                <Col sm={{span: 5}}>
                                                    <Form.Label>Compass Adjuster</Form.Label>
                                                </Col>
                                                <Col sm={{span: 5}}>
                                                    <Form.Select                                                 
                                                        value={claim.adjusterId || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, adjusterId: parseInt(e.target.value)}})} >
                                                            {isAdjustersDDLoading && <option>Loading...</option>}
                                                            {!isAdjustersDDLoading && <option key={-1}>Choose...</option>}
                                                            {adjustersDD.totalCount > 0 && adjustersDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                                <option key={i} value={c.id} >{c.name}</option>
                                                            ))}
                                                    </Form.Select>  
                                                </Col>
                                            </Form.Group> 
                                            <Form.Group as={Col} controlId="insuredState" className="input-field-container2">
                                                <Col sm={{span: 5}}>
                                                    <Form.Label>Field Director</Form.Label>
                                                </Col>
                                                <Col sm={{span: 5}}>
                                                    <Form.Select                                                 
                                                        value={claim.directorId || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, directorId: parseInt(e.target.value)}})} >
                                                            {isFieldDirectorsDDLoading && <option>Loading...</option>}
                                                            {!isFieldDirectorsDDLoading && <option key={-1}>Choose...</option>}
                                                            {fieldDirectorsDD.totalCount > 0 && fieldDirectorsDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                                <option key={i} value={c.id} >{c.name}</option>
                                                            ))}
                                                    </Form.Select>  
                                                </Col>
                                            </Form.Group>                                        
                                            <Form.Group as={Col} controlId="insuredState" className="input-field-container2">
                                                <Col sm={{span: 6}}>
                                                    <Form.Label>Compass Examiner</Form.Label>
                                                </Col>
                                                <Col sm={{span: 5}}>
                                                    <Form.Select                                                 
                                                        value={claim.examinerId || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, examinerId: parseInt(e.target.value)}})} >
                                                            {isExaminersDDLoading && <option>Loading...</option>}
                                                            {!isExaminersDDLoading && <option key={-1}>Choose...</option>}
                                                            {examinersDD.totalCount > 0 && examinersDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                                <option key={i} value={c.id} >{c.name}</option>
                                                            ))}
                                                    </Form.Select>  
                                                </Col>
                                            </Form.Group> 
                                            <Form.Group as={Col} controlId="insuredState" className="input-field-container2">
                                                <Col sm={{span: 4}}>
                                                    <Form.Label>Reviewer</Form.Label>
                                                </Col>
                                                <Col sm={{span: 5}}>
                                                    <Form.Select                                                 
                                                        value={claim.reviewerId || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, reviewerId: parseInt(e.target.value)}})} >
                                                            {isReviewersDDLoading && <option>Loading...</option>}
                                                            {!isReviewersDDLoading && <option key={-1}>Choose...</option>}
                                                            {reviewersDD.totalCount > 0 && reviewersDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                                <option key={i} value={c.id} >{c.name}</option>
                                                            ))}
                                                    </Form.Select>  
                                                </Col>
                                            </Form.Group> 
                                        </Row>
                                    </Card.Body>
                                </Card>  
                            </CardGroup>
                        </Row>
                        <Row>    
                            <CardGroup>
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Insured Info</Card.Title>
                                    <Card.Body>
                                        <Form.Group controlId="lossCause" className="input-field-container2">
                                            <Col sm={{span: 3}}>
                                                <Form.Label>Insured Full Name</Form.Label>
                                            </Col>
                                            <Col sm={{span:4}}>
                                                <Form.Control 
                                                    required
                                                    name="lossCause"
                                                    type='text'
                                                    value={claim.insuredPerson?.insuredName || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, insuredName: e.target.value}}})}
                                                />
                                            </Col>                                            
                                        </Form.Group>
                                        <Row>
                                            <Form.Group as={Col} controlId="insuredCell" className="input-field-container2">
                                                <Col sm={{span: 3}}>
                                                    <Form.Label>Cell</Form.Label>
                                                </Col>
                                                <Col sm={{span: 7}}>
                                                    <NumberFormat 
                                                        format="(###) ###-####" 
                                                        placeholder="(xxx) xxx-xxxx"
                                                        displayType='input'
                                                        className="number-format-input"
                                                        value={claim.insuredPerson?.cellNumber} 
                                                        onValueChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, cellNumber: e.value}}})}
                                                    />
                                                </Col>
                                            </Form.Group>                                        
                                            <Form.Group as={Col} controlId="insuredHome" className="input-field-container2">
                                                <Col sm={{span: 3}}>
                                                    <Form.Label>Home</Form.Label>
                                                </Col>
                                                <Col sm={{span: 7}}>
                                                    <NumberFormat 
                                                        format="(###) ###-####" 
                                                        placeholder="(xxx) xxx-xxxx"
                                                        displayType='input'
                                                        className="number-format-input"
                                                        value={claim.insuredPerson?.homeNumber} 
                                                        onValueChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, homeNumber: e.value}}})}
                                                    />
                                                </Col>
                                            </Form.Group>                                        
                                            <Form.Group as={Col} controlId="insuredWork" className="input-field-container2">
                                                <Col sm={{span: 3}}>
                                                    <Form.Label>Work</Form.Label>
                                                </Col>
                                                <Col sm={{span: 7}}>
                                                    <NumberFormat 
                                                        format="(###) ###-####" 
                                                        placeholder="(xxx) xxx-xxxx"
                                                        displayType='input'
                                                        className="number-format-input"
                                                        value={claim.insuredPerson?.workNumber} 
                                                        onValueChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, workNumber: e.value}}})}
                                                    />
                                                </Col>
                                            </Form.Group>                                        
                                        </Row>
                                        <div style={{fontSize: "24", fontWeight: "bold"}}>Mail Address</div>
                                        <div style={{border: "1px solid", padding: "1em"}}>
                                            <Form.Check
                                                name="isLoss"
                                                type="checkbox"
                                                label="Same as Loss"
                                                id="chkSameAsLoss"
                                                checked={claim.lossAddSame}
                                                onChange={() => this.handleSameAddressToggle()} 
                                            />                                               
                                            <Form.Group controlId="insuredAddress1" className="input-field-container2">
                                                <Col sm={{span: 3}}>
                                                    <Form.Label>Address Line 1</Form.Label>
                                                </Col>
                                                <Col sm={{span: 6}}>
                                                    <Form.Control 
                                                        disabled={claim.lossAddSame}
                                                        type="text" 
                                                        value={claim.insuredPerson?.mailingAddress?.addressLine1 || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, mailingAddress: {...claim.insuredPerson.mailingAddress, addressLine1: e.target.value}}}})} 
                                                    />
                                                </Col>
                                            </Form.Group>
                                        <Form.Group controlId="insuredAddress2" className="input-field-container2">
                                            <Col sm={{span: 3}}>
                                                <Form.Label>Address Line 2</Form.Label>
                                            </Col>
                                            <Col sm={{span: 6}}>
                                                <Form.Control 
                                                    disabled={claim.lossAddSame}
                                                    type="text"
                                                    value={claim.insuredPerson?.mailingAddress?.addressLine2 || ""}  
                                                    onChange={(e) => this.setState({claim: {...claim, insuredPerson:{...claim.insuredPerson, mailingAddress: {...claim.insuredPerson.mailingAddress, addressLine2: e.target.value}}}})} 
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="insuredZip" className="input-field-container2">
                                            <Col sm={{span: 3}}>
                                                <Form.Label>Zip Code</Form.Label>
                                            </Col>
                                            <Col sm={{span: 3}}>
                                                <Form.Control 
                                                    disabled={claim.lossAddSame}
                                                    type="text" 
                                                    value={claim.insuredPerson?.mailingAddress?.postalCode || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, mailingAddress: {...claim.insuredPerson.mailingAddress, postalCode: e.target.value}}}})} 
                                                />
                                            </Col>
                                            <Col sm="2" className="inpput-search-col">
                                                <Button 
                                                    disabled={claim.lossAddSame}
                                                    className="input-search-btn" 
                                                    onClick={() => this.zipSearch(claim.insuredPerson?.mailingAddress?.postalCode, 'mailing')}>
                                                        <Image src={searchIcon}/>
                                                </Button>
                                            </Col>
                                        </Form.Group>                        
                                        <Form.Group controlId="insuredCity" className="input-field-container2">
                                            <Col sm={{span: 3}}>
                                                <Form.Label column sm="4">City</Form.Label>
                                            </Col>
                                            <Col sm={{span: 4}}>
                                                <Form.Control 
                                                    disabled={claim.lossAddSame}
                                                    type="text" 
                                                    value={claim.insuredPerson?.mailingAddress?.city || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, mailingAddress: {...claim.insuredPerson.mailingAddress, city: e.target.value}}}})} 
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="insuredState" className="input-field-container2">
                                            <Col sm={{span: 3}}>
                                                <Form.Label>State</Form.Label>
                                            </Col>
                                            <Col sm={{span: 3}}>
                                                <Form.Select                                                 
                                                    name="statesList" 
                                                    disabled={claim.lossAddSame}
                                                    value={claim.insuredPerson?.mailingAddress?.state || ""} 
                                                    onChange={(e) => this.setState({claim: {...claim, insuredPerson: {...claim.insuredPerson, mailingAddress: {...claim.insuredPerson.mailingAddress, state: e.target.value}}}})} >
                                                        {isStateAbbrDDLoading && <option>Loading...</option>}
                                                        {!isStateAbbrDDLoading && <option key={-1}>Choose...</option>}
                                                        {stateList.totalCount > 0 && stateList.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                            <option key={i} value={c.name} >{c.name}</option>
                                                        ))}
                                                </Form.Select>  
                                            </Col>
                                        </Form.Group> 
                                    </div>
                                    </Card.Body>
                                </Card>                                                                                                                                                                              
                                <Card className="claim-card">
                                    <Card.Title className="inv-card-title">Policy Info</Card.Title>
                                    <Card.Body>   
                                        <Row>
                                            <Form.Group as={Col} controlId="policyNumber" className="input-field-container">
                                                <Form.Label column sm="4" >Policy Number</Form.Label>
                                                <Col sm="8">
                                                    <Form.Control 
                                                        type="text" 
                                                        value={claim.lossInformation?.policy?.policyNumber || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, policy: {...claim.lossInformation.policy, policyNumber: e.target.value}}}})} 
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="policyType" className="input-field-container">
                                                <Form.Label column sm="4">Policy Type</Form.Label>
                                                <Col sm="8">
                                                    <Form.Select 
                                                        name="policyTypes" 
                                                        value={claim.lossInformation?.policy?.policyType || ""}
                                                        onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, policy: {...claim.lossInformation.policy, policyType: e.target.value}}}})} >
                                                            {isPolicyTypesListLoading && <option>Loading...</option>}
                                                            {!isPolicyTypesListLoading && <option key={-1}></option>}
                                                            {policyTypes.totalCount > 0 && policyTypes.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                                <option key={i} value={c.name} >{c.name}</option>
                                                            ))}
                                                    </Form.Select>  
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <Row>                                            
                                            <Form.Group as={Col} controlId="policyDeductible" className="input-field-container">
                                                <Form.Label column sm="4">Ded. 1</Form.Label>
                                                <Col sm="8">
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control 
                                                            type="number" 
                                                            placeholder="0.00" 
                                                            value={claim.lossInformation?.policy?.policyDeductible || ""} 
                                                            onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, policy: {...claim.lossInformation.policy, policyDeductible: Number.parseFloat(e.target.value)}}}})} 
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="policyDeductible2" className="input-field-container">
                                                <Form.Label column sm="4">Ded. 2</Form.Label>
                                                <Col sm="8">
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control 
                                                            type="number" 
                                                            placeholder="0.00" 
                                                            value={claim.lossInformation?.policy?.policyDeductible2 || ""} 
                                                            onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, policy: {...claim.lossInformation.policy, policyDeductible2: Number.parseFloat(e.target.value)}}}})} 
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <Form.Group as={Row} controlId="nsDeductible" className="input-field-container">
                                            <Form.Label column sm="4">Storm Deductible</Form.Label>
                                            <Col sm="8">
                                                <InputGroup>
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                    <Form.Control 
                                                        type="number" 
                                                        placeholder="0.00" 
                                                        value={claim.lossInformation?.policy?.namedStormDeductible || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, policy: {...claim.lossInformation.policy, namedStormDeductible: Number.parseFloat(e.target.value)}}}})} 
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Form.Group as={Col} controlId="effectiveDate" className="input-field-container">
                                                <Form.Label column sm="4">Eff. Date</Form.Label>
                                                <Col sm="8">
                                                    <Form.Control 
                                                        type="date" 
                                                        name="policyStartDate"
                                                        value={claim.lossInformation?.policy?.effectiveDate?.toLocaleString() || ""} 
                                                        onChange={this.handleDateChanges}
                                                    />
                                                </Col>
                                            </Form.Group>                        
                                            <Form.Group as={Col} controlId="expirationDate" className="input-field-container">
                                                <Form.Label column sm="4">Exp. Date</Form.Label>
                                                <Col sm="8">
                                                    <Form.Control 
                                                        type="date" 
                                                        name="policyEndDate"
                                                        value={claim.lossInformation?.policy?.expirationDate?.toLocaleString() || ""} 
                                                        onChange={this.handleDateChanges}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} controlId="mortgageCompany" className="input-field-container">
                                                <Form.Label column sm="4">Mortgage Company</Form.Label>
                                                <Col sm="8">
                                                    <Form.Select 
                                                        name="insCompany" 
                                                        value={claim.lossInformation?.policy?.policyMortgageCompanyName || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, policy: {...claim.lossInformation.policy, policyMortgageCompanyName: e.target.value}}}})} 
                                                    >
                                                        {isMortgageCompanyDDLoading && <option>Loading...</option>}
                                                        {!isMortgageCompanyDDLoading && <option key={-1}>Choose...</option>}
                                                        {mortgageCompanyDD.totalCount > 0 && mortgageCompanyDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                            <option key={i} value={c.name} >{c.name}</option>
                                                        ))}
                                                    </Form.Select>                                    
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="insuranceAgency" className="input-field-container">
                                                <Form.Label column sm="4">Insurance Agency</Form.Label>
                                                <Col sm="8">
                                                    <Form.Select 
                                                        name="insCompany" 
                                                        value={claim.lossInformation?.policy?.policyAgentCompanyName || ""} 
                                                        onChange={(e) => this.setState({claim: {...claim, lossInformation: {...claim.lossInformation, policy: {...claim.lossInformation.policy, policyAgentCompanyName: e.target.value}}}})} 
                                                    >
                                                        {isInsCompanyDDLoading && <option>Loading...</option>}
                                                        {!isInsCompanyDDLoading && <option key={-1}>Choose...</option>}
                                                        {agentCompanyDD.totalCount > 0 && agentCompanyDD.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                            <option key={i} value={c.name} >{c.name}</option>
                                                        ))}
                                                    </Form.Select>                                    
                                                </Col>
                                            </Form.Group>                                       
                                        </Row>
                                    </Card.Body>
                                </Card>  
                            </CardGroup>     
                        </Row>                      
                    </Modal.Body>                
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            CREATE
                        </Button>
                    </Modal.Footer>
                </Form>    
            </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    adjustersDD: state.contacts.dropdowns.adjustersDD, 
    agentCompanyDD: state.companies.dropdowns.agentCompanyDD,
    catCodesList: state.admin.dropdowns.catCodeDD, 
    claimTypesResponse: state.claims.claim.claimTypesResponse,
    examinersDD: state.contacts.dropdowns.examinersDD, 
    fieldDirectorsDD: state.contacts.dropdowns.fieldDirectorsDD,     
    insCompaniesList: state.companies.dropdowns.insCompanyDD, 
    isAdjustersDDLoading: state.contacts.dropdowns.isAdjustersDDLoading,     
    isExaminersDDLoading: state.contacts.dropdowns.isExaminersDDLoading, 
    isFieldDirectorsDDLoading: state.contacts.dropdowns.isFieldDirectorsDDLoading, 
    isInsCompanyDDLoading: state.companies.dropdowns.isInsCompanyDDLoading, 
    isMortgageCompanyDDLoading: state.companies.dropdowns.isMortgageCompanyDDLoading, 
    isPolicyTypesListLoading: state.policy.dropdowns.isPolicyTypesDDLoading,
    isReviewersDDLoading: state.contacts.dropdowns.isReviewersDDLoading, 
    isStateAbbrDDLoading: state.locations.dropdowns.isStateDDLoading,    
    lastUserAction: state.user.actions.lastUserAction, 
    lauseCausesDD: state.claims.dropdowns.lossCausesDD, 
    mortgageCompanyDD: state.companies.dropdowns.mortgageCompanyDD, 
    policyTypes: state.policy.dropdowns.policyTypesDD, 
    reviewersDD: state.contacts.dropdowns.reviewersDD, 
    stateList: state.locations.dropdowns.stateDD,     
    zipSearch: state.locations.locations.zipSearch
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
            addCatCode: adminActions.addCatCode, 
            addClaim: claimsActions.addClaimRequest,             
            requestClaimTypes: claimsActions.requestClaimTypeList,            
            requestZipSearch: locationActions.requestZipSearch,
            setAppToast: userActions.setAppToast, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ClaimCreateModal);