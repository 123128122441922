import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Row, Col, Spinner } from 'react-bootstrap'; 
import * as models from '../../models';
import editIcon from '../../assets/images/Blue/edit.svg';
import SingleActionButton from '../common/SingleActionButton';
import ClaimExtrasModal from './ClaimExtrasModal'; 
import ContactInfoModal from '../common/ContactInfoModal'; 
import { userHasPermission } from '../../utils/helpers';
import services from 'services';

interface Props extends PropsFromRedux {
}

interface State {
    contactMethods?: models.ContactMethods; 
    showModal: boolean; 
    showConctactInfoModal: boolean;
}

class ClaimExtras extends React.Component<Props, State> {
    public state = {        
        contactMethods: undefined,
        showModal: false, 
        showConctactInfoModal: false,
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    }

    public toggleContactInfoModal = () => {          
        this.setState({showConctactInfoModal: !this.state.showConctactInfoModal});         
    }    

    public showContactInfo = async (contactId: number) => {
        const response = await services.api.contact.getContactMethods(contactId); 
        if(response.isSuccess) {
            this.setState({contactMethods: response.data}); 
            this.toggleContactInfoModal(); 
        }
    }    

    public render() {
        const { contactMethods, showConctactInfoModal, showModal } = this.state;       
        const { claim, isClaimLoading } = this.props; 
        const canEditClaim = userHasPermission("Can Edit Claims Info") && claim.status.toLowerCase() !== 'closed'; 

        return (
            <>
            <ClaimExtrasModal
                    showModal={showModal}
                    closeModal={this.toggleModal}
            /> 
            <ContactInfoModal
                showModal={showConctactInfoModal}
                closeModal={this.toggleContactInfoModal}
                contactMethods={contactMethods}
            />
            <Card className="claim-card">
                <Card.Title>Claim Team, Claim Type, and Cat Code
                    {isClaimLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    {canEditClaim && 
                        <SingleActionButton
                            action="Edit"
                            title="Claim Team"
                            img={editIcon}
                            onClick={this.toggleModal}
                        />                    
                    }
                </Card.Title>
                <Row>
                    <Col md={3} lg={3} className="card-label">Compass Field Adjuster:</Col>
                    <Col md={3} lg={3} className="value-link" onClick={() => this.showContactInfo(claim.adjuster?.id)} >{claim.adjuster?.name}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Field Director:</Col>
                    <Col md={3} lg={3} className="value-link" onClick={() => this.showContactInfo(claim.director?.id)} >{claim.director?.name}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Compass Examiner:</Col>
                    <Col md={3} lg={3} className="value-link" onClick={() => this.showContactInfo(claim.examiner?.id)} >{claim.examiner?.name}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Reviewer:</Col>
                    <Col md={3} lg={3} className="value-link" onClick={() => this.showContactInfo(claim.reviewer?.id)}>{claim.reviewer?.name}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Cat Code:</Col>
                    <Col md={3} lg={3} >{claim.catCode}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Claim Type:</Col>
                    <Col md={3} lg={3} >{claim.claimType}</Col>
                </Row>
            </Card>    
            </>          
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,
    isClaimLoading: state.claims.claim.isClaimLoading
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ClaimExtras);