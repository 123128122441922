import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Col, Modal, Button, Form, Table } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';

interface Props extends PropsFromRedux {
    showModal: boolean;
    suggestions?: models.FeeScheduleSuggestionResponse; 
    closeModal: () => void;
    callback: (e:any) => void; 
}

interface State {
    selection: number;  
}

class FeeScheduleSuggestionModal extends React.Component<Props, State> {
    public state = {
        selection: 0, 
    };
    
    public resetFormStateAndClose = () => {
        this.setState({selection: 0});
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        this.props.callback(this.state.selection); 
        this.resetFormStateAndClose(); 
    }

    public handleSelect = (f: number) => {
        this.setState({selection: f}); 
    }

    public render() {
        const { closeModal, showModal, suggestions } = this.props;
        const { selection } = this.state; 

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton onHide={this.resetFormStateAndClose}>
                    <Modal.Title>Fee Schedule Suggestions</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>
                    <Modal.Body>
                    {suggestions && 
                        <Table striped>
                            <thead>    
                                <tr>
                                    <th></th>
                                    <th>Fee Schedule</th>
                                    <th>Suggestion Reason(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                            {suggestions.resourceList?.map((f: models.FeeScheduleSuggestion, i: number) => (
                                <tr key={f.feeScheduleId}>
                                <td>
                                    <Form.Group as={Col} controlId="isChecked" className="input-field-container">
                                        <Form.Check 
                                            name="isChecked"
                                            type="radio"
                                            id="chkSelected"
                                            value={selection}
                                            checked={f.feeScheduleId === selection ? true : false}
                                            onChange={() => this.handleSelect(f.feeScheduleId)}
                                        />                                
                                    </Form.Group>
                                </td>
                                <td>{f.feeScheduleName}</td>
                                <td>{f.suggestionReasons?.join(", ")}</td>
                            </tr>
                            ))}  
                            </tbody>                        
                        </Table>
                    }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>            
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {            
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FeeScheduleSuggestionModal);