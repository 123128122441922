import { ApiResponse, request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const patchInsuredPerson =async (id: any, patch: object) => {    
    return await request({
        method: 'PATCH',
        url: `/insuredPersons/${id}`,
        data: patch,
    }); 
}; 

export const getInsuredPerson = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/insuredPersons/${id}`, 
    });
    return response; 
}; 