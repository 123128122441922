import * as React from 'react';

interface Props {
}

interface State {    
}

class AdminGroups extends React.Component<Props, State> {
    public state = {

    }

    public render() {
        return (
            <h1>Groups</h1>
        );
    }

}

export default AdminGroups;