// @ts-nocheck
import * as React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
    onClick: () => void; 
    action: string;     
    title: string;
    img: React.Component;    
    disabled?: boolean; 
 }

interface State {
}

class SingleActionButton extends React.Component<Props, State> {

    public clickEvent = () => {
        this.props.onClick(); 
    }; 

    public render() {
        const { action, title, img, disabled } = this.props;
        return (
            <>
                <OverlayTrigger
                    key="overlayTrigger"
                    placement="top"
                    overlay={
                        <Tooltip className="my-tooltip" id="toolTip">
                            { action + ' ' + title }
                        </Tooltip>
                    }
                >
                    <Button disabled={disabled} bsPrefix="card-btn" variant="light" onClick={ this.clickEvent }>
                        <img src={img} />
                    </Button>
                </OverlayTrigger>
            </>
        ); 
    }
}

export default SingleActionButton;
