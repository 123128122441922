
export const REQUEST_AUTH2_GROUPS = 'auth2/REQUEST_AUTH2_GROUPS';
export const RECEIVE_AUTH2_GROUPS = 'auth2/RECEIVE_AUTH2_GROUPS';
export const REQUEST_AUTH2_USERS = 'auth2/REQUEST_AUTH2_USERS';
export const RECEIVE_AUTH2_USERS = 'auth2/RECEIVE_AUTH2_USERS';
export const REQUEST_AUTH2_PERMISSIONS = 'auth2/REQUEST_AUTH2_PERMISSIONS';
export const RECEIVE_AUTH2_PERMISSIONS = 'auth2/RECEIVE_AUTH2_PERMISSIONS';
export const UPDATE_USER_GROUP_MEMBERSHIP = 'auth2/UPDATE_USER_GROUP_MEMBERSHIP';
export const UPDATE_USER_GROUP_MEMBERSHIP_DONE = 'auth2/UPDATE_USER_GROUP_MEMBERSHIP_DONE';
export const UPDATE_GROUP_PERMISSION = 'auth2/UPDATE_GROUP_PERMISSION';
export const UPDATE_GROUP_PERMISSION_DONE = 'auth2/UPDATE_GROUP_PERMISSION_DONE';
export const UPDATE_USER_PERMISSION = 'auth2/UPDATE_USER_PERMISSION';
export const UPDATE_USER_PERMISSION_DONE = 'auth2/UPDATE_USER_PERMISSION_DONE';