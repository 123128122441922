export interface PolicyCoverage {
    policyCoverageId: number; 
    coverageType: string;
    amount: number; 
}

export const defaultPolicyCoverage: PolicyCoverage = {
    policyCoverageId: 0, 
    coverageType: "", 
    amount: 0
}