import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';

export default function* () {
    yield takeEvery(constants.REQUEST_AGENT_COMPANY_DD, requestAgentCompanyDD); 
    yield takeEvery(constants.REQUEST_MORTGAGE_COMPANY_DD, requestMortgageCompanyDD); 
    yield takeEvery(constants.REQUEST_COMPANY, requestCompany); 
    yield takeEvery(constants.REQUEST_ALL_COMPANIES_DD, requestAllCompaniesDD); 
    yield takeEvery(constants.REQUEST_COMPANY_DROPDOWN, requestCompanyDropdown); 
    yield takeEvery(constants.REQUEST_COMPANY_LIST, requestCompanyList); 
    yield takeEvery(constants.REQUEST_INSURANCE_COMPANY_LIST, requestInsCompanyList); 
    yield takeEvery(constants.REQUEST_INS_COMPANY_DD, requestInsCompanyDD); 
    yield takeEvery(constants.PATCH_COMPANY, updateCompany);     
}

export function* requestCompany(action: ActionType<typeof actions.requestCompany>): any {
    const userRequest = yield call(services.api.company.getCompanyByID, action.payload.companyId);
    if (userRequest.isSuccess) {
        yield put(actions.receiveCompany(userRequest.data)); 
    }
}

export function* requestCompanyDropdown(action: ActionType<typeof actions.requestCompanyDropdown>): any {
    const userRequest = yield call(services.api.company.getCompanies, action.payload.request);
    if (userRequest.isSuccess) {        
        yield put(actions.receiveCompanyDropdown(userRequest.data)); 
    }    
    else console.log(userRequest); 
}

export function* requestCompanyList(action: ActionType<typeof actions.requestCompanyList>): any {
    const userRequest = yield call(services.api.company.getCompanies, action.payload.request); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCompanyList(userRequest.data)); 
    }
}

export function* requestInsCompanyList(action: ActionType<typeof actions.requestInsCompanyList>): any {
    const companyReqeust: models.CompaniesRequest = {
        ...action.payload.paging,
        type: 'Insurance', 
        activeOnly: false, 
    }
    const userRequest = yield call(services.api.company.getCompanies, companyReqeust); 
    if (userRequest.isSuccess) {        
        yield put(actions.receiveInsCompanyList(userRequest.data)); 
    }    
}

export function* updateCompany(action: ActionType<typeof actions.updateCompany>): any {  
    const userRequest = yield call(services.api.company.updateCompany, action.payload.companyId, action.payload.patch);
     if (userRequest.isSuccess) {
        yield put(actions.requestCompany(action.payload.companyId)); 
    }    
    else console.log(userRequest); 
}

// dropdowns
export function* requestAllCompaniesDD(action: ActionType<typeof actions.requestAgentCompanyDD>): any {
    const userRequest = yield call(services.api.company.getAllCompaniesDD);
    if (userRequest.isSuccess) {
       yield put(actions.receiveAllCompanyiesDD(userRequest.data)); 
   }    
   else console.log(userRequest);    
}


export function* requestAgentCompanyDD(action: ActionType<typeof actions.requestAgentCompanyDD>): any {
    const userRequest = yield call(services.api.company.getAgentCompanyDD);
    if (userRequest.isSuccess) {
       yield put(actions.receiveAgentCompanyDD(userRequest.data)); 
   }    
   else console.log(userRequest);    
}

export function* requestInsCompanyDD(action: ActionType<typeof actions.requestInsCompanyDD>): any {
    const userRequest = yield call(services.api.company.getInsCompanyDD);
    if (userRequest.isSuccess) {
       yield put(actions.receiveInsCompanyDD(userRequest.data)); 
   }    
   else console.log(userRequest);    
}

export function* requestMortgageCompanyDD(action: ActionType<typeof actions.requestMortgageCompanyDD>): any {
    const userRequest = yield call(services.api.company.getMortgageCompanyDD);
    if (userRequest.isSuccess) {
       yield put(actions.receiveMortgageCompanyDD(userRequest.data)); 
   }    
   else console.log(userRequest);                     
}