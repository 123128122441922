import * as models from '../';

export interface ContactSimple {
    id: number; 
    fullName: string; 
    email: string;
    cellNumber: string; 
    homeNumber: string; 
    workNumber: string;
    contactTypes: string;
    companyName: string;  
}

export const defaultContactSimple: ContactSimple = {
    id: 0, 
    fullName: '', 
    email: '', 
    cellNumber: '', 
    homeNumber: '', 
    workNumber: '', 
    contactTypes: '',
    companyName: '',
}

export interface ContactSimpleResponse extends models.ResponseBase<ContactSimple> { }