import { action } from 'typesafe-actions';
import * as constants from './constants';
import { Auth2GroupResponse, Auth2UserResponse } from 'models/security/auth2GroupsResponse';
import * as apiModels from 'models/security/auth2UpdateUserGroupMembershipRequest';
import * as models from '../../models'; 

// Groups
export const requestGroups = () =>
    action(constants.REQUEST_AUTH2_GROUPS, { });

export const receiveGroups = (response: Auth2GroupResponse) =>
    action(constants.RECEIVE_AUTH2_GROUPS, { response });

// Users
export const requestUsers = (request: models.Auth2UserSearchRequest) =>
    action(constants.REQUEST_AUTH2_USERS, { request });

export const receiveUsers = (response: Auth2UserResponse) => 
    action(constants.RECEIVE_AUTH2_USERS, { response });

// Permissions
export const requestPermissions = () => 
    action(constants.REQUEST_AUTH2_PERMISSIONS, {}); 
export const receivePermissions = (response: models.Auth2PermissionResponse) => 
    action(constants.RECEIVE_AUTH2_PERMISSIONS, { response }); 

// Update User Group Membership
export const updateUserGroupMembership = (request: apiModels.Auth2UpdateUserGroupMembershipRequest) =>
    action(constants.UPDATE_USER_GROUP_MEMBERSHIP, { request });

export const updateUserGroupMembershipDone = () =>
    action(constants.UPDATE_USER_GROUP_MEMBERSHIP_DONE, { });    

// Update Group Permission
export const updateGroupPermission = (request: apiModels.Auth2UpdateGroupPermissionRequest) =>
    action(constants.UPDATE_GROUP_PERMISSION, { request });

export const updateGroupPermissionDone = () =>
    action(constants.UPDATE_GROUP_PERMISSION_DONE, { });

export const updateUserPermission = (request: apiModels.Auth2UpdateUserPermissionRequest) =>
    action(constants.UPDATE_USER_PERMISSION, { request });

export const updateUserPermissionDone = (userId: number) =>
    action(constants.UPDATE_USER_PERMISSION_DONE, { userId });
