import MyTypes from 'MyTypes';
import * as React from 'react';
import { Form, Image } from 'react-bootstrap'; 
import { connect, ConnectedProps } from 'react-redux';
import serivces from '../../services/'; 
import { bindActionCreators, Dispatch } from 'redux';
import searchIcon from '../../assets/images/Blue/icn-search-blue.svg'; 
import * as models from '../../models/'; 
import { claimsActions } from 'store/claims';
import { userActions } from 'store/user';
import { AppToastTypes } from 'models/enums'; 
import _ from 'lodash'; 
import { USER_ACTIONS } from 'store/enums';

interface Props extends PropsFromRedux {  
}

interface State {
    claimNumber: string; 
}

class QuickSearch extends React.Component<Props, State> {
    public state ={
        claimNumber: '', 
    }

    public componentDidUpdate = (prevProps: any) => {       
        if(!_.isEqual(prevProps.claimListResponse, this.props.claimListResponse) && this.props.lastUserAction === USER_ACTIONS.CLAIM_SEARCH_SUCCESS && this.state.claimNumber !== ''){                        
            if(this.props.claimListResponse.totalCount == 1) {
                const claimID = this.props.claimListResponse.resourceList[0].claimID; 
                this.props.requestClaimSingle(claimID); 
                let path = "/claim/" + claimID;            
                serivces.router.goto(path);                 
            }
            else {                 
                this.props.setAppToast({message: `Quicksearch found ${this.props.claimListResponse.totalCount === 0 ? "zero" : "multiple"} results...`, type: AppToastTypes.Failure });            
            }
            this.resetForm(); 
        }
    }

    public submitForm = (e:any) => {        
        e.preventDefault();        
        this.claimSearch(this.state.claimNumber); 
    }

    public claimSearch = (claimNo: string) => {
        const claimQuery : models.ClaimsRequest = {
            ...models.defaultClaimsRequest,
            pageNo: 1,
            pageSize: 2,
            insClaimNo: claimNo,
            claimNoOperator: "="
        }
        this.props.requestClaims(claimQuery);        
        this.resetForm(); 
    }    

    public handlePasteClaimNumber = (e: any) => {
        const claimNo = e.clipboardData.getData('Text'); 
        this.setState({claimNumber: claimNo}); 
        this.claimSearch(claimNo); 
        return false; 
    }

    public resetForm = () => {
        this.setState({claimNumber: ""}); 
    }
  
    public render() {
        const { claimNumber } = this.state; 
        const searchLoading = this.props.lastUserAction === USER_ACTIONS.CLAIM_SEARCHING; 

        return (

            <div className="quick-search">                
                <Form 
                    onSubmit={(e) => this.submitForm(e)}>
                    <Form.Control 
                        name="quickSearch"
                        placeholder="Quick Search..." 
                        value = {searchLoading ? "Loading..." : claimNumber}
                        onChange={(e) => this.setState({claimNumber: e.target.value})}
                        onPaste={(e) => this.handlePasteClaimNumber(e)}
                />
                </Form>
                <Image src={searchIcon} className="icon"/>
            </div>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimListResponse: state.claims.search.claimsListResponse,
    lastUserAction: state.user.actions.lastUserAction,
}); 

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestClaims: claimsActions.requestClaims,
            requestClaimSingle: claimsActions.requestClaimSingle,
            setAppToast: userActions.setAppToast, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(QuickSearch); 
