import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import services from '../../services';
import Layout2 from './Layout2';
import LoginCallback from './LoginCallback';
import Logout from './Logout';
import Printf from './Printf';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/styles/BootstrapStyles.css'; 
import '../../assets/styles/PageHeader.css'; 

class App extends Component {    
    public render() {
        return (
                <Switch>                                        
                    <Route exact path={services.router.url.dashboard} component={Layout2} />
                    <Route path={services.router.url.claimProfile} component={Layout2} />                    
                    <Route path={services.router.url.admin} component={Layout2} />
                    <Route path={services.router.url.reports} component={Layout2} />
                    <Route path={services.router.url.financials} component={Layout2} />
                    <Route path={services.router.url.claimList} component={Layout2} />
                    <Route path={services.router.url.queues} component={Layout2} />
                    <Route path={services.router.url.contactDetail} component={Layout2} />
                    <Route path={services.router.url.companyDetail} component={Layout2} />
                    <Route path={services.router.url.security} component={Layout2} />
                    <Route path={services.router.url.loginCallback} component={LoginCallback} />
                    <Route path={services.router.url.logout} component={Logout} />                                                                                 
                    <Route path={services.router.url.printf} component={Printf} />
                    <Route path='*' component={Layout2} />
                </Switch>
        )
    }
}

export default App; 