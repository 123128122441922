import * as React from 'react';

interface Props {
}

interface State {
}

class NotFound extends React.PureComponent<Props, State> {
    public state = {
    };

    public render() {
        return (
            <>
                <h1>404</h1>
                <h3>NOT FOUND</h3>
            </>
        );
    }
}

export default NotFound;