export const ADD_FEE = 'financials/ADD_FEE'; 
export const DELETE_FEE = 'financials/DELETE_FEE'; 

export const ADD_FEE_TO_SCHEDULE = 'financials/ADD_FEE_TO_SCHEDULE'; 

export const ADD_INVOICE_GROUP = 'financials/ADD_INVOICE_GROUP'; 

export const ADD_INVOICE_TO_GROUP = 'invoices/ADD_INVOICE_TO_GROUP'; 
export const REMOVE_INVOICE_FROM_GROUP = 'invoices/REMOVE_INVOICE_FROM_GROUP'; 

export const PATCH_FEE = 'financials/PATCH_FEE'; 

export const REQUEST_FEE_LIST = 'financials/REQUEST_FEES_LIST';
export const RECEIVE_FEE_LIST = 'financials/RECEIVE_FEE_LIST';

export const REQUEST_FEE_SCHEDULE = 'financials/REQUEST_FEE_SCHEDULE'; 
export const RECEIVE_FEE_SCHEDULE = 'financials/RECEIVE_FEE_SCHEDULE'; 

export const REQUEST_FEE_SCHEDULE_BY_COMPANY = 'financials/REQUEST_FEE_SCHEDULE_BY_COMPANY'; 
export const RECEIVE_FEE_SCHEDULE_BY_COMPANY = 'financials/RECEIVE_FEE_SCHEDULE_BY_COMPANY'; 

// invoices
export const ADD_INVOICE_CORRECTION = 'invoices/ADD_CORRECTION';

export const BUILD_PDF = 'invoices/BUILD_PDF';

export const CLEAR_INVOICE = 'invoices/CLEAR_INVOICE'; 

export const PATCH_INVOICE = 'invoices/PATCH_INVOICE'; 

export const REPLACE_INVOICE = 'invoices/REPLACE_INVOICE'; 

export const REQUEST_ADJUSTER_FEES = 'invoices/REQUEST_ADJUSTER_FEES'; 
export const RECEIVE_ADJUSTER_FEES = 'invoices/RECEIVE_ADJUSTER_FEES'; 

export const REQUEST_INVOICE = 'invoices/REQUEST_INVOICE';
export const RECEIVE_INVOICE = 'invoices/RECEIVE_INVOICE';

export const REQUEST_INVOICES_CSV = 'invoices/REQUEST_INVOICES_CSV'; 
export const RECEIVE_INVOICES_CSV = 'invoices/RECEIVE_INVOICES_CSV'; 

export const REQUEST_INVOICE_LIST = 'invoices/REQUEST_INVOICE_LIST'; 
export const RECEIVE_INVOICE_LIST = 'invoices/RECEIVE_INVOICE_LIST'; 

export const REQUEST_INVOICE_ADJUSTERS_DD = 'invoices/REQUEST_INVOICE_ADJUSTERS_DD'; 
export const RECEIVE_INVOICE_ADJUSTERS_DD = 'invoices/RECEIVE_INVOICE_ADJUSTERS_DD'; 

export const REQUEST_INVOICE_COMPANY_DD = 'invoices/REQUEST_INVOICE_COMPANY_DD'; 
export const RECEIVE_INVOICE_COMPANY_DD = 'invoices/RECEVIE_INVOICE_COMPANY_DD'; 

export const REQUEST_INVOICE_GROUPS_BY_COMPANY = 'invoices/REQUEST_INVOICE_GROUPS_BY_COMPANY'; 
export const RECEIVE_INVOICE_GROUPS_BY_COMPANY = 'invoices/RECEIVE_INVOICE_GROUPS_BY_COMPANY'; 

export const REQUEST_INVOICE_GROUP_DD = 'invoices/REQUEST_INVOICE_GROUP_DD'; 
export const RECEIVE_INVOICE_GROUP_DD = 'invoices/RECEVIE_INVOICE_GROUP_DD'; 

export const REQUEST_INVOICE_GROUP_LIST = 'invoiceGroups/REQUEST_INVOICE_GROUP_LIST';
export const RECEIVE_INVOICE_GROUP_LIST = 'invoiceGroups/RECEIVE_INVOICE_GROUP_LIST';

export const SET_SELECTED_INVOICES = 'invoices/SET_SELECTED_INVOICES';
export const SET_CONNECT_FEES_FLAG = 'invoices/SET_CONNECT_FEES_FLAG'; 
export const CLEAR_SELECTED_INVOICES = 'invoices/CLEAR_SELECTED_INVOICES'; 

// fee schedules
export const CREATE_FEE_SCHEDULE = 'feeSchedules/CREATE_FEE_SCHEDULE'; 
export const DELETE_FEE_SCHEDULE = 'feeSchedules/DELETE_FEE_SCHEDULE'; 
export const PATCH_FEE_SCHEDULE = 'feeSchedules/PATCH_FEE_SCHEDULE'; 
export const TOGGLE_FEE_SCHEDULE_STATUS = 'feeSchedules/TOGGLE_FEE_SCHEDULE_STATUS'; 

// payments
export const CLEAR_RECONCILE_LIST = 'payments/CLEAR_RECONCILE_LIST'; 
export const CREATE_PAYMENT = 'payments/CREATE_PAYMENT'; 

export const REQUEST_RECONCILE_LIST = 'payments/REQUEST_RECONCILE_LIST';
export const RECEIVE_RECONCILE_LIST = 'payments/RECEIVE_RECONCILE_LIST'; 

export const REQUEST_RECONCILE_LIST_BULK = 'payments/REQUEST_RECONCILE_LIST_BULK'; 

// payroll
export const ADD_PAYROLL = 'payroll/ADD_PAYROLL'; 
export const ADD_PAYROLL_COMPLETE = 'payroll/ADD_PAYROLL_COMPLETE'; 
export const CLEAR_ADJUSTER_FEES_LIST = 'payroll/CLEAR_ADJUSTER_FEES_LIST'; 
export const REQUEST_ADJUSTER_FEES_LIST = 'payroll/REQUEST_ADJUSTER_FEES_LIST'; 
export const RECEIVE_ADJUSTER_FEES_LIST = 'payroll/RECEIVE_ADJUSTER_FEES_LIST'; 
export const REQUSET_PAYROLL_ZIPFILE = 'payroll/REQUSET_PAYROLL_ZIPFILE';
export const RECEIVE_PAYROLL_ZIPFILE = 'payroll/RECEIVE_PAYROLL_ZIPFILE'; 