import { trackPromise } from 'react-promise-tracker';
import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const getCompanies = async (companiesRequest: models.CompaniesRequest) => {  
    var qStr = utils.helpers.encodeQueryString(companiesRequest); 
  
    const response = await request ({
      method: 'GET', 
      url: `/Companies?${qStr}`, 
    }); 
    return response; 
  }; 
  
  export const getCompanyByID = async (companyId: number) => {  
    const response = await request ({
      method: 'GET', 
      url: `/Companies/${companyId}`, 
    }); 
    return response; 
  }; 

  export const updateCompany = async (companyId: number, patch: object) => {
    return await request({
        method: 'PATCH',
        url: `/companies/${companyId}`,
        data: patch,
    }); 
  }

  // dropdowns
  export const getAllCompaniesDD = async () => {
    return await request({
        method: 'GET',
        url: `/companies/dd/all`,
    }); 
  }

  export const getAgentCompanyDD = async () => {
    return await request({
        method: 'GET',
        url: `/companies/dd/agent`,
    }); 
  }

  export const getInsCompanyDD = async () => {
    return await request({
        method: 'GET',
        url: `/companies/dd/insurance`,
    }); 
  }

  export const getMortgageCompanyDD = async () => {
    return await request({
        method: 'GET',
        url: `/companies/dd/mortgage`,
    }); 
  }