import * as React from 'react'; 
import Chart from 'react-apexcharts'; 

interface Props {
}

interface State {
    series: any;  
    options: any; 
}

interface openClaimsByCompany {
    companyName: string; 
    openClaims: number; 
}

class OpenYTDCard extends React.PureComponent<Props, State> {
    public state = {
        options: {},
        series: []        
    }     
    
    public componentDidMount = async () => {
        this.setState({
            series: [{
                name: 'Monthly Opened', 
                data: [4229,2166,4847,3411,4138,4160,2518,2383,1063]
                },
                {
                    name: 'Last Year', 
                    data: [1577,1191,1547,1839,2365,1833,1423,1558,3249]
                }
            ],
            options: {               
                chart: {               
                    background: '#fff',
                    type: 'line',
                    height: 90,                    
                    toolbar: false, 
                    sparkline: {
                        enabled: true
                    }, 
                    zoom: {
                        enabled: false,
                    }                   
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: undefined, 
                }, 
                labels: ['Jan-2023','Feb-2023','Mar-2023','Apr-2023','May-2023','Jun-2023','Jul-2023','Aug-2023','Sep-2023'], 
                legend: {
                    fontSize: '10px'
                },
                markers: {
                    size: 0
                }, 
                stroke: {
                    curve: 'straight',
                    width: 2
                },
                fill: {
                    opacity: 0.4,
                    type: 'solid'
                },
                grid: {
                    show: false,
                    padding: {
                        top: 0,
                        right: 2,
                        bottom: 0,
                        left: 2
                    },
                },
                yaxis: {
                    show: false
                },        
                title: {
                    text: '28,974',
                    offsetX: 0,
                    style: {
                    fontSize: '24px',
                    }
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false
                    },
                },
                subtitle: {
                    text: 'Open - YTD',
                    offsetX: 0,
                    style: {
                    fontSize: '14px',
                    }
                }
            },             
        }); 
    }    

    render() {
        return (
            <div id="chart">
                <Chart 
                    options={this.state.options} 
                    series={this.state.series} 
                    type="line" 
                    height={190} 
                />
            </div>     
        );
    }
}

export default OpenYTDCard; 