export const ADD_CONTACT_SIMPLE = 'contacts/ADD_CONTACT_SIMPLE'; 
export const ADD_COMPANY_EXAMINER = 'contacts/ADD_COMPANY_EXAMINER'; 
export const ADD_REVIEWER = 'contacts/ADD_REVIEWER'; 

export const REQUEST_COMPANY_EXAMINERS = 'contacts/REQUEST_COMPANY_EXAMINERS'; 
export const RECEIVE_COMPANY_EXAMINERS = 'contacts/RECEIVE_COMPANY_EXAMINERS'; 

export const REQUEST_CONTACT = 'contacts/REQUEST_CONTACT';
export const RECEIVE_CONTACT = 'contacts/RECEIVE_CONTACT';

export const REQUEST_CONTACT_LIST = 'contacts/REQUEST_CONTACTS_LIST';
export const RECEIVE_CONTACT_LIST = 'contacts/RECEIVE_CONTACTS_LIST';

export const REQUEST_FIELD_ADJSTERS_LIST = 'contacts/REQUEST_FIELD_ADJSTERS_LIST'; 
export const RECEIVE_FIELD_ADJSTERS_LIST = 'contacts/RECEIVE_FIELD_ADJSTERS_LIST'; 

export const REQUEST_FIELD_DIRECTORS_LIST = 'contacts/REQUEST_FIELD_DIRECTORS_LIST'; 
export const RECEIVE_FIELD_DIRECTORS_LIST = 'contacts/RECEIVE_FIELD_DIRECTORS_LIST'; 

export const REQUEST_EXAMINERS_LIST = 'contacts/REQUEST_EXAMINERS_LIST'; 
export const RECEIVE_EXAMINERS_LIST = 'contacts/RECEIVE_EXAMINERS_LIST'; 

export const REQUEST_REVIEWERS_LIST = 'contacts/REQUEST_REVIEWERS_LIST'; 
export const RECEIVE_REVIEWERS_LIST = 'contacts/RECEIVE_REVIEWERS_LIST'; 

export const UPDATE_CONTACT = 'contacts/UPDATE_CONTACT'; 

// dropdowns
export const REQUEST_ADJUSTERS_DD = 'dropdowns/REQUEST_ADJUSTERS_DD'; 
export const RECEIVE_ADJUSTERS_DD = 'dropdowns/RECEIVE_ADJUSTERS_DD'; 

export const REQUEST_COMPANY_EXAMINERS_DD = 'dropdowns/REQUEST_COMPANY_EXAMINERS_DD'; 
export const RECEIVE_COMPANY_EXAMINERS_DD = 'dropdowns/RECEIVE_COMPANY_EXAMINERS_DD'; 

export const REQUEST_EXAMINERS_DD = 'dropdowns/REQUEST_EXAMINERS_DD'; 
export const RECEIVE_EXAMINERS_DD = 'dropdowns/RECEIVE_EXAMINERS_DD'; 

export const REQUEST_FIELD_DIRECTORS_DD = 'dropdowns/REQUEST_FIELD_DIRECTORS_DD'; 
export const RECEIVE_FIELD_DIRECTORS_DD = 'dropdowns/RECEIVE_FIELD_DIRECTORS_DD'; 

export const REQUEST_REVIEWERS_DD = 'dropdowns/REQUEST_REVIEWERS_DD'; 
export const RECEIVE_REVIEWERS_DD = 'dropdowns/RECEIVE_REVIEWERS_DD'; 

