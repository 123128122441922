import * as React from 'react';
import { Card } from 'react-bootstrap'; 
import services from '../../services'; 
import * as models from '../../models';

interface Props {
    company: models.Company; 
}

interface State {
}

class Contact extends React.Component<Props, State> {   
    public getPhoneNumberToDisplay() {                
        const phoneNumbers = this.props.company.phoneNumbers.filter(phone => phone.phoneNumber !== "").sort(); 
        if (phoneNumbers.length !== 0){            
            return phoneNumbers[0].phoneNumber; 
        }
        else {
            return ""; 
        }                
    }

    public onCardClick = (companyId: number) => {
        let path = services.router.url.companyDetail.replace(":companyId", companyId.toString());
        services.router.goto(path);
    }; 

    public render() {
        const {company} = this.props; 
        
        return (
        <Card style={{cursor: 'pointer'}} className="admin-card" onClick={() => this.onCardClick(company.companyId)}>
            <Card.Title>
                {company.name}
                <div className="float-end">{this.getPhoneNumberToDisplay()}</div>
            </Card.Title>
            <Card.Text as="span">
                <div className="float-end">{company.email}</div>
            </Card.Text>
        </Card>
        );
    }
}

export default Contact;