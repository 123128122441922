import { combineReducers} from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models'; 
import { CLAIM_ACTION_STATUSES } from '../../store/enums'; 
import * as actions from './actions';
import * as constants from './constants';

export interface ClaimStateShape {
    attachments: models.AttachmentsResponse; 
    claim: models.ClaimSimple;    
    lastActionStatus: string; 
    claimParty: models.ClaimPartyResponse; 
    claimTypesResponse: models.ClaimTypesResponse;
    draftInvoice: models.Invoice; 
    emailedClaims: models.EmailedClaimsResponse; 
    invoices: models.InvoicesListResponse; 
    insuredPerson: models.InsuredPerson;  
    isAttachmentsLoading: boolean; 
    isClaimLoading:boolean;
    isDraftInvoiceLoading: boolean;
    isEmailedClaimsLoading: boolean; 
    isInvoicesLoading: boolean; 
    isInsuredPersonLoading: boolean; 
    isLossLocationLoading: boolean; 
    isMergedReportsLoading: boolean; 
    isPolicyLoading: boolean; 
    isReservesloading: boolean; 
    lossLocation: models.Address;
    mergedReports: models.MergedReportsResponse;  
    policy: models.Policy; 
    reserveCoverages: models.ReserveCoveragesResponse; 
    statuses: models.DropdownListResponse; 
    toastMessage: string[]; 
    toastShow: boolean;  
}

export const ClaimsDefaultState: ClaimStateShape = {
    attachments: models.defaultResponse, 
    claim: models.defaultClaimSimple,
    lastActionStatus: "", 
    claimParty: models.defaultResponse,
    claimTypesResponse: models.defaultResponse,    
    draftInvoice: models.defaultInvoice, 
    emailedClaims: models.defaultResponse, 
    invoices: models.defaultResponse, 
    insuredPerson: models.defaultInsuredPerson, 
    isAttachmentsLoading: false,
    isClaimLoading: false,     
    isDraftInvoiceLoading: false, 
    isEmailedClaimsLoading: false, 
    isInvoicesLoading: false, 
    isInsuredPersonLoading: false,
    isLossLocationLoading: false, 
    isMergedReportsLoading: false, 
    isPolicyLoading: false, 
    isReservesloading: false, 
    lossLocation: models.defaultAddress,  
    mergedReports: models.defaultResponse, 
    policy: models.defaultPolicy, 
    reserveCoverages: models.defaultResponse, 
    statuses: models.defaultResponse, 
    toastMessage: new Array<string>(), 
    toastShow: false, 
}

export interface ClaimCommentsShape {
    activityLogResponse: models.CommentsResponse;
    correctionResponse: models.CommentsResponse;  
    reserveResponse: models.CommentsResponse; 
}

export const ClaimCommentsDefaultState: ClaimCommentsShape = {
    activityLogResponse: models.defaultResponse,
    correctionResponse: models.defaultResponse, 
    reserveResponse: models.defaultResponse, 
}

export interface ClaimSearchShape {    
    claimsListResponse: models.ClaimsListResponse;    
    claimsRequest: models.ClaimsRequest;
    isClaimsListLoading: boolean; 
    isClaimsListDownloading: boolean;  
}

export const ClaimSearchDefaultState: ClaimSearchShape = {  
    claimsListResponse: {
        ...models.defaultResponse,
        statuses: new Array<models.DropDownListItem>(),
        insuranceCompanies: new Array<models.DropDownListItem>(),
        examiners: new Array<models.DropDownListItem>(),
        fieldAdjusters: new Array<models.DropDownListItem>()
    },
    claimsRequest: models.defaultClaimsRequest,     
    isClaimsListLoading: false, 
    isClaimsListDownloading: false, 
}

export interface DropdownsShape {
    isLossCauseDDLoading: boolean; 
    lossCausesDD: models.DropdownListResponse; 
}
export const DropdownsDefaultState: DropdownsShape = {
    isLossCauseDDLoading: false, 
    lossCausesDD: models.defaultResponse, 
}

export interface State {
    claim: ClaimStateShape; 
    comments: ClaimCommentsShape; 
    dropdowns: DropdownsShape; 
    search: ClaimSearchShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({  
    claim: (state = ClaimsDefaultState, action) => {                         
        switch (action.type) {          
            case constants.ADD_CLAIM_INVOICE:
                return {
                    ...state,
                }

            case constants.CLEAR_CLAIM_SINGLE: 
                return {
                    ...state,
                    claim: models.defaultClaimSimple
                }

            case constants.DELETE_CLAIM_ATTACHMENT: 
                return {
                    ...state,
                    lastActionStatus: CLAIM_ACTION_STATUSES.DELETING_ATTACHMENT,
                }

            case constants.DELETE_CLAIM_INVOICE: 
                return {
                    ...state,
                }

            case constants.INITIALIZE_CLAIM: 
                return {
                    ...state, 
                    claim: action.payload.claim, 
                    isClaimLoading: false,                     
                }

            case constants.PATCH_LOSS_LOCATION: 
                return {
                    ...state, 
                    isLossLocationLoading: true, 
                }

            case constants.PATCH_POLICY: 
                return {
                    ...state, 
                    isPolicyLoading: true, 
                }
            
            case constants.REQUEST_ADD_CLAIM: 
                return {
                    ...state,
                }
            case constants.RECEIVE_ADD_CLAIM: 
                return {
                    ...state,
                }
            
            case constants.REQUEST_ATTACHMENTS: 
                return {
                    ...state,                     
                    isAttachmentsLoading: true, 
                    attachments: models.defaultResponse, 
                }

            case constants.RECEVIE_ATTACHMENTS: 
                return {
                    ...state,
                    isAttachmentsLoading: false, 
                    attachments: action.payload.response, 
                }
            
            case constants.REQUEST_INVOICES: 
                return {
                    ...state,
                    isInvoicesLoading: true, 
                    invoices: models.defaultResponse, 
                }

            case constants.RECEIVE_INVOICES: 
                return {
                    ...state, 
                    isInvoicesLoading: false, 
                    invoices: action.payload.response,
                }

            case constants.REQUEST_CLAIM_PARTY: 
                return {
                    ...state,
                }

            case constants.RECEIVE_CLAIM_PARTY: 
                return {
                    ...state, 
                    claimParty: action.payload.response,
                }

            case constants.REQUEST_CLAIM_SINGLE:
                return {                     
                    ...state,
                    isClaimLoading: true,  
                }            

            case constants.REQUEST_CLAIM_SINGLE_BY_CLAIM_NUMBER: 
                return {
                    ...state,
                    isClaimLoading: true, 
                    claim: ClaimsDefaultState.claim, 
                }

            case constants.REQUEST_EMAILED_CLAIMS: 
                return {
                    ...state, 
                    isEmailedClaimsLoading: true,     
                    emailedClaims: models.defaultResponse,                 
                }

            case constants.RECEIVE_EMAILED_CLAIMS: 
                return {
                    ...state,
                    isEmailedClaimsLoading: false, 
                    emailedClaims: action.payload.response, 
                }

            case constants.REQUEST_INSURED_PERSON: 
                return {
                    ...state, 
                    isInsuredPersonLoading: true, 
                    insuredPerson: models.defaultInsuredPerson, 
                }

            case constants.RECEIVE_INSURED_PERSON: 
                return {
                    ...state, 
                    isInsuredPersonLoading: false, 
                    insuredPerson: action.payload.response, 
                }

            case constants.REQUEST_LOSS_LOCATION: 
                return {
                    ...state, 
                    isLossLocationLoading: true, 
                    lossLocation: models.defaultAddress, 
                }

            case constants.RECEIVE_LOSS_LOCATION: 
                return {
                    ...state, 
                    isLossLocationLoading: false, 
                    lossLocation: action.payload.response, 
                }

            case constants.REQUEST_MERGED_REPORTS: 
                return {
                    ...state, 
                    isMergedReportsLoading: true, 
                    mergedReports: models.defaultResponse, 
                }

            case constants.RECEIVE_MERGED_REPORTS: 
                return {
                    ...state, 
                    isMergedReportsLoading: false, 
                    mergedReports: action.payload.response, 
                }

            case constants.REQUEST_POLICY: 
                return {
                    ...state, 
                    isPolicyLoading: true, 
                    policy: models.defaultPolicy, 
                }

            case constants.RECEIVE_POLICY: 
                return {
                    ...state, 
                    isPolicyLoading: false, 
                    policy: action.payload.response, 
                }
            
            case constants.REQUEST_RESERVE_COVERAGES: 
                return {
                    ...state, 
                    isReservesloading: true, 
                    reserveCoverages: models.defaultResponse, 
                }

            case constants.RECEIVE_RESERVE_COVERAGES: 
                return {
                    ...state, 
                    isReservesloading: false, 
                    reserveCoverages: action.payload.response, 
                }

            case constants.PATCH_CLAIM: 
                return {
                    ...state, 
                }            

            case constants.REQUEST_CLAIM_TYPE_LIST: 
                return {
                    ...state,
                }
            
            case constants.RECEIVE_CLAIM_TYPE_LIST: 
                return {
                    ...state,
                    claimTypesResponse: action.payload.response,                    
                }

            case constants.REQUEST_INVOICE_DRAFT: 
                return {
                    ...state,
                    isDraftInvoiceLoading: true,  
                }
            
            case constants.RECEIVE_INVOICE_DRAFT: 
                return {
                    ...state, 
                    draftInvoice: action.payload.response, 
                    isDraftInvoiceLoading: false, 
                }

            case constants.SET_CLAIM_ACTION_STATUS:
                return {
                    ...state,
                    lastActionStatus: action.payload.request,
                }

            case constants.REQUEST_STATUSES: 
                return {
                    ...state,
                }
            case constants.RECEIVE_STATUSES: 
                return {
                    ...state,
                    statuses: action.payload.response,
                }

            case constants.TOAST_CLEAR_MESSAGE: 
                return {
                    ...state, 
                    isClaimCreating: false, 
                    isClaimLoading: false, 
                    toastShow: false, 
                    toastMessage: new Array<string>(), 
                }

            case constants.TOAST_SET_MESSGAGE: 
                return {
                    ...state, 
                    toastShow: true,
                    toastMessage: action.payload.messages,
                }

            default:
                return state;
        }
    },
    comments: (state = ClaimCommentsDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_COMMENTS_ACTIVITY_LOG: 
                return {
                    ...state, 
                } 

            case constants.RECEIVE_COMMENTS_ACTIVITY_LOG: 
                return {
                    ...state, 
                    activityLogResponse: action.payload.response, 
                } 

            case constants.REQUEST_COMMENTS_CORRECTION:
                return {
                    ...state
                }

            case constants.RECEIVE_COMMENTS_CORRECTION: 
                return {
                    ...state,
                    correctionResponse: action.payload.response, 
                }

            case constants.REQUEST_COMMENTS_RESERVE: 
                return {
                    ...state, 
                }

            case constants.RECEIVE_COMMENTS_RESERVE: 
                return {
                    ...state, 
                    reserveResponse: action.payload.response,
                }

            default: 
                return state; 
        } 
    }, 
    dropdowns: (state = DropdownsDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_LOSS_CAUSES_DD: 
                return {
                    ...state,
                    isLossCauseDDLoading: true, 
                }
            case constants.RECEIVE_LOSS_CAUSES_DD: 
                return {
                    ...state,
                    lossCausesDD: action.payload.response, 
                    isLossCauseDDLoading: false, 
                }
            default: 
                return state; 
        }
    }, 
    search: (state = ClaimSearchDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_CLAIMS:     
                return { 
                    ...state
                    , claims: []
                    , isClaimsListLoading: true
                    , claimsRequest: action.payload.request
                    , claimsListResponse: {
                        ...models.defaultResponse, 
                        statuses: new Array<models.DropDownListItem>(),
                        insuranceCompanies: new Array<models.DropDownListItem>(),
                        examiners: new Array<models.DropDownListItem>(),
                        fieldAdjusters: new Array<models.DropDownListItem>()
                    }
                }

            case constants.RECEIVE_CLAIMS:
                return {
                    ...state, 
                    claimsListResponse: action.payload.response,                    
                    isClaimsListLoading: false
                } 

            case constants.REQUEST_CLAIMS_CSV: 
                return {
                    ...state
                    , isClaimsListDownloading: true,
                }
            
            case constants.RECEIVE_CLAIMS_CSV: 
                return {
                    ...state, 
                    isClaimsListDownloading: false,
                }
            
            default: 
                return state;
        }
    }, 
});
