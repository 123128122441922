import * as React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';

interface Props {  
    showModal: boolean;
    closeModal: () => void;
    callback: (catCode: models.CatCode) => void;
}

interface State {
    catCode: string; 
    abbr: string; 
}

class AddCatCodeModal extends React.Component<Props, State> {
    public state = {
        catCode: "", 
        abbr: ""
    };

    public resetFormStateAndClose = () => {
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        const catCode: models.CatCode = {
            id: 0,
            catCode: this.state.catCode,
            catCodeAbbreviation: this.state.abbr,
        }
        this.props.callback(catCode);  
        this.props.closeModal(); 
    }

    public render() {
        const { closeModal, showModal } = this.props;         
        
        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >                                
                <Modal.Header closeButton>
                    <Modal.Title>Create Cat Code</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>
                    <Modal.Body>           
                        <Form.Group as={Row} controlId="catCode" className="input-field-container">
                            <Form.Label column sm="4">Cat Code</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" placeholder='Enter Cat Code...' onChange={(e) => this.setState({catCode: e.target.value})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="catCodeAbbr" className="input-field-container">
                            <Form.Label column sm="4">Abbreviation</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" placeholder='Enter Abbr...' onChange={(e) => this.setState({abbr: e.target.value})} />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>            
        );
    }
}

export default AddCatCodeModal;
