import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';

export interface DropdownsStateShape {
    isPolicyTypesDDLoading: boolean; 
    policyTypesDD: models.DropdownListResponse; 
}

export const DropdownsDefaultState: DropdownsStateShape = {
    isPolicyTypesDDLoading: false, 
    policyTypesDD: models.defaultResponse,
};

export interface State {
    dropdowns: DropdownsStateShape; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    dropdowns: (state = DropdownsDefaultState, action) => {
        switch (action.type) {
            case constants.REQUEST_POLICY_TYPE_DD:
                return { 
                    ...state,
                    isPolicyTypesDDLoading: true, 
                };

            case constants.RECEIVE_POLICY_TYPE_DD:
                return {
                    ...state, 
                    policyTypesDD: action.payload.response, 
                    isPolicyTypesDDLoading: false, 
                }; 
                
            default:
                return state;
        }
    },
});
