import * as React from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap'; 

interface Props {    
}

interface State {
}

class CcpaScrub extends React.Component<Props, State> {
    public render() {
       
        return (
        <Card className="admin-card">
            <Card.Title>
                CCPA Scrub
            </Card.Title>
            <Card.Text>
                CCPA Scrub is permanent and irreversable. Please ensure you want to move forward before pressing the 'Scrub' button.
            </Card.Text>
            <Card.Body>
                <Form className="ccpa-scrub-form">
                    <Form.Group as={Row} className="mb-2" controlId="formCCPAScrub">
                        <Form.Label column sm="2">Claim Number</Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="Enter Claim Number"/>
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit">CCPA Scrub</Button>
                </Form>
            </Card.Body>
        </Card>
        );
    }
}

export default CcpaScrub;