import { ApiResponse, request } from '../services/api/base'; 

export const downloadFile = (response: ApiResponse) => {
    let filename = ''; 
    const disposition = response.headers['content-disposition']; 
    const contentType = response.headers['content-type']; 
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');            
        }        
    }

    const url = window.URL || window.webkitURL; 
    let binaryData = []; 
    binaryData.push(response.data); 
    const downloadUrl = url.createObjectURL(new Blob(binaryData, {type: contentType}));      
    if (filename) {
        const a = document.createElement('a'); 
        a.href = downloadUrl; 
        a.download = filename;         
        document.body.appendChild(a);        
        a.click(); 
    }

    setTimeout(() => {
        url.revokeObjectURL(downloadUrl); 
    }); 
}

export const downloadCSVFile = (response: ApiResponse) => {
    let filename = ''; 
    const disposition = response.headers['content-disposition']; 
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');            
        }        
    }
    
    const url = window.URL || window.webkitURL; 
    let binaryData = []; 
    binaryData.push(response.data); 
    const downloadUrl = url.createObjectURL(new Blob(binaryData, {type: "application/csv"})); 
    if (filename) {
        const a = document.createElement('a'); 
        a.href = downloadUrl; 
        a.download = filename; 
        document.body.appendChild(a); 
        a.click(); 
    }

    setTimeout(() => {
        url.revokeObjectURL(downloadUrl); 
    }); 
}

export const readAsDataURL = (file: Blob) => {    
    return new Promise((resolve, reject) => {
        const fr = new FileReader(); 
        fr.onerror = reject;
        fr.onload = () => {
            resolve(fr.result);
        }
        fr.readAsText(file); 
    }); 
}

export const openFileNewTab = (file: Blob) => {
    const fileURL = window.URL.createObjectURL(file); 
    const newWindow = window.open(fileURL);    
}