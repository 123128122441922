import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';
import { USER_ACTIONS } from '../../store/enums'; 

export interface UserStateShape {
    currentUser: models.Contact;        
}

export const UserDefaultState: UserStateShape = {
    currentUser: models.defaultContact,     
};

export interface UserActionStateShape {
    appToast?: models.AppToast;     
    lastUserAction: USER_ACTIONS;         
}

export const UserActionsDefaultState: UserActionStateShape = {
    lastUserAction: USER_ACTIONS.INIT, 
}

export interface UserEventData {
    lastInvCreatedId?: number; 
}
export const UserEventDataDefaultState: UserEventData = {}

export interface State {
    user: UserStateShape; 
    actions: UserActionStateShape; 
    events: UserEventData; 
}

export type Action = ActionType<typeof actions>;

export default combineReducers<State, Action>({
    user: (state = UserDefaultState, action) => {
        switch (action.type) {            
            case constants.RECEIVE_CURRENT_USER: 
            return {
                ...state,
                currentUser: action.payload.response, 
            }; 

            case constants.REQUEST_CURRENT_USER: 
            return {
                ...state,                
            }; 
            default:
                return state;
        }
    }, 
    actions: (state = UserActionsDefaultState, action) => {
        switch (action.type) {
            case constants.CLEAR_APP_TOAST: 
                return {
                    ...state,
                    appToast: undefined, 
                } 

            case constants.SET_APP_TOAST:             
                return {
                    ...state,
                    appToast: action.payload.appToast, 
                }

            case constants.SET_LAST_USER_ACTION: 
                return {
                    ...state,
                    lastUserAction: action.payload.request, 
                }
                
            default:
                return state;
        }            
    },
    events: (state = UserEventDataDefaultState, action) => {
        switch (action.type) {
            case constants.SET_LAST_INVOICE_CREATED_ID: 
                return {
                    ...state,
                    lastInvCreatedId: action.payload.invoiceId,
                }
            default: 
                return {
                    ...state
                }
        }
    }    
});
