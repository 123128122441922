import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as actions from './actions';
import * as constants from './constants';
import * as helpers from '../../utils/helpers'; 
import { claimsActions } from 'store/claims';
import { CLAIM_ACTION_STATUSES } from '../../store/enums'; 
import { userActions } from '.';

export default function* () {
    yield takeEvery(constants.REQUEST_CURRENT_USER, requestCurrentUser); 
    yield takeEvery(constants.UPDATE_PROFILE, updateProfile); 
}

export function* requestCurrentUser(action: ActionType<typeof actions.requestCurrentUser>): any {
    const userRequest = yield call(services.api.user.getCurrentUser, action.payload.email); 
    if (userRequest.isSuccess) {
        yield put(actions.receiveCurrentUser(userRequest.data)); 
    }
}

export function* updateProfile(action: ActionType<typeof actions.updateProfile>): any {
    const { contactId, patch } = action.payload;     
    yield put(claimsActions.setClaimActionStatus(CLAIM_ACTION_STATUSES.UPDATING_PROFILE)); 
    const userRequest = yield call(services.api.contact.updateContact, contactId, patch); 
    if(userRequest.isSuccess) {
        const userName = helpers.getAuthUsername(); 
        yield put(userActions.requestCurrentUser(userName)); 
        yield put(claimsActions.setClaimActionStatus(CLAIM_ACTION_STATUSES.UPDATING_PROFILE_SUCCESS)); 
    }
    else {
        yield put(claimsActions.setClaimActionStatus(CLAIM_ACTION_STATUSES.UPDATING_PROFILE_FAILURE)); 
        yield put(claimsActions.toastSet(userRequest.data.Message))
    }
}
