import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import services from '../../services';
import * as models from '../../models'; 
import * as actions from './actions';
import * as constants from './constants';


export default function* () {
    yield takeEvery(constants.REQUEST_MONTHLY_CLAIM_ACTIVITY, requestMonthlyClaimActivity);     
}

export function* requestMonthlyClaimActivity(action: ActionType<typeof actions.requestMonthlyClaimActivity>): any {
    const userRequest = yield call(services.api.dashboard.getMonthlyClaimActivity, action.payload.monthsBack, action.payload.months);
    if (userRequest.isSuccess) {
        yield put(actions.receiveMonthlyClaimActivity(userRequest.data)); 
    }
}