import * as models from '..';

export interface Fee {
    feeId: number; 
    feeName: string; 
    feeType: string; 
    defaultValue: number; 
    isActive: boolean; 
    isTaxExempt: boolean; 
}

export interface FeeResponse extends models.ResponseBase<Fee>{}

export const defaultFee : Fee =  {
    feeId: 0, 
    feeName: '', 
    feeType: 'Add On',
    defaultValue: 0,
    isActive: true, 
    isTaxExempt: false, 
}