import * as React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import NumberFormat from 'react-number-format'; 
import * as models from '../../models';

interface Props {      
    closeModal: () => void;
    showModal: boolean;
    contactMethods?: models.ContactMethods; 
}

interface State {    
}

class ContactInfoModal extends React.Component<Props, State> {
    public state = {        
    };

    public render() {
        const { closeModal, contactMethods, showModal} = this.props; 

        if(contactMethods?.contactId == 0) {
            return <></>
        } else {
            return (
                <Modal centered={true} show={showModal} onHide={closeModal} >
                    <Modal.Header closeButton>Contact Info</Modal.Header>                
                        <Modal.Body>
                            <Row>
                                <Col xs="3">Email:</Col>                                
                                <Col xs="4">{contactMethods?.email || "No email on file..."}</Col>
                            </Row>
                            {contactMethods?.homeNumber && 
                                <Row>                                
                                    <Col xs="3">Home #:</Col>
                                    <Col xs="4">
                                        <NumberFormat 
                                            format="(###) ###-####" 
                                            placeholder="(xxx) xxx-xxxx"
                                            displayType='text'
                                            value={contactMethods?.homeNumber || ""}
                                        />
                                    </Col>
                                </Row>
                            }
                            { contactMethods?.cellNumber && 
                                <Row>
                                    <Col xs="3">Cell #:</Col>
                                    <Col xs="4">
                                        <NumberFormat 
                                            format="(###) ###-####" 
                                            placeholder="(xxx) xxx-xxxx"                                            
                                            displayType='text'
                                            value={contactMethods?.cellNumber || ""}
                                        />
                                    </Col>
                                </Row>
                            }
                            {contactMethods?.workNumber && 
                                <Row>
                                    <Col xs="3">Work #:</Col>
                                    <Col xs="4">
                                        <NumberFormat 
                                            format="(###) ###-####" 
                                            placeholder="(xxx) xxx-xxxx"
                                            displayType='text'
                                            value={contactMethods?.workNumber || ""}
                                        />
                                    </Col>
                                </Row>
                            }
                        </Modal.Body>
                </Modal>
            );
        }        
    }
}
export default(ContactInfoModal);