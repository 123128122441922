import { request } from './base';
import * as models from '../../models'; 
import utils from '../../utils'; 

export const addClaim = async (addClaim: models.AddClaim) => {
    return await request ({
        method: 'POST', 
        url: `/claims`,
        data: addClaim,
    }); 
}

export const addClaimAttachment = async(claimId: number, documentTypeName: string, formData: FormData) => {    
    return await request ({
        method: 'POST', 
        url: `/claims/${claimId}/attachments/?documentTypeName=${documentTypeName}`,
        data: formData,
        headers: 'Content-Type: mutipart/form-data'
    }); 
}

export const addClaimComment = async (id: any, comment: models.Comment) => {
    return await request({
        method: 'POST',
        url: `/claims/${id}/comments`,
        data: comment,
    }); 
}; 

export const addClaimInvoice = async (id: number, data: any) => {
    return await request({
        method: 'POST',
        url: `/claims/${id}/invoices`,
        data: data
    }); 
}; 

export const addReserveCoverage = async (id: any, reserveCoverage: models.ReserveCoverage) => {
    return await request({        
        method: 'POST',
        url: `/claims/${id}/reserves`,
        data: reserveCoverage,
    }); 
}; 

export const patchClaim =async (id: any, patch: object) => {    
    return await request({
        method: 'PATCH',
        url: `/claims/${id}`,
        data: patch,
    }); 
}; 

export const patchLossLocation = async(id: number, patch: object) => {
    return await request({
        method: 'PATCH',
        url: `/claims/${id}/lossLocation`,
        data: patch,
    }); 
}; 

export const patchPolicy = async(id: number, patch: object) => {
    return await request({
        method: 'PATCH',
        url: `/claims/${id}/policy`,
        data: patch,
    }); 
}; 

export const deleteClaimInvoice = async (claimId: number, id: number, reason: string) => {
    return await request ({
        method: 'DELETE',
        url: `/claims/${claimId}/invoices/${id}`, 
        data: {reason: reason}, 
    }); 
}

export const deleteClaimAttachment = async (claimId: number, id: number) => {
    return await request ({
        method: 'DELETE', 
        url: `/claims/${claimId}/attachments/${id}`
    }); 
}

export const deleteReserveCoverage = async (claimId: number, id: number) => {
    return await request({
        method: 'DELETE',
        url: `/claims/${claimId}/reserves/${id}`        
    }); 
}

export const patchReserveCoverage =async (claimId: number, id: number, patch: object) => {    
    return await request({
        method: 'PATCH',
        url: `/claims/${claimId}/reserves/${id}`,
        data: patch,
    }); 
}; 

export const getAttachments = async(claimId: number) => {    
    return await request ({
        method: 'GET', 
        url: `/claims/${claimId}/attachments`,        
    }); 
}

export const getClaimAttachment = async (claimId: number, id: number) => {
    return await request({
        method: 'GET',
        url: `/claims/${claimId}/attachments/${id}`,
        responseType: 'blob',
    }); 
}; 

export const getClaimComments = async (id: number, commentRequest: models.CommentsRequest) => {
    var qStr = utils.helpers.encodeQueryString(commentRequest);  

    const response = await request({
        method: 'GET',
        url: `/claims/${id}/comments?${qStr}`, 
    });
    return response; 
}; 

export const getClaimParty = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/claims/${id}/claimParty`, 
    });
    return response; 
}; 

export const getClaimSingle = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/claims/${id}`, 
    });
    return response; 
}; 

export const getClaimsList = async (claimRequest: models.ClaimsRequest) => {
    var qStr = utils.helpers.encodeQueryString(claimRequest);     

    const response = await request({
        method: 'GET',
        url: `/claims?${qStr}`, 
    });
    return response; 
}

export const getClaimExaminers = async (claimRequest: models.ClaimsRequest) => {
    var qStr = utils.helpers.encodeQueryString(claimRequest);

    const response = await request ({
        method: 'GET', 
        url: `/claims/examiners?${qStr}`, 
    }); 
    return response; 
}

export const getClaimTypes = async (claimTypesRequeest: models.ClaimTypesRequest) => {
    var qStr = utils.helpers.encodeQueryString(claimTypesRequeest);

    const response = await request ({
        method: 'GET', 
        url: `/claimTypes?${qStr}`, 
    }); 
    return response; 
}

export const getDraftInvoice = async (claimId: number) => {
    const response = await request ({
      method: 'GET',
      url: `/claims/${claimId}/draftInvoice`
    }); 
    return response; 
  }; 

export const getEmailedClaims = async (claimId: number) => {
    const response = await request ({
        method: 'GET', 
        url: `/claims/${claimId}/emailedClaims`, 
    });
    return response; 
}

export const getFieldAdjusters = async (claimRequest: models.ClaimsRequest) => {
    var qStr = utils.helpers.encodeQueryString(claimRequest);

    const response = await request ({
        method: 'GET', 
        url: `/claims/fieldAdjusters?${qStr}`, 
    });
    return response; 
}

export const getInsuranceCompanies = async (claimRequest: models.ClaimsRequest) => {
    var qStr = utils.helpers.encodeQueryString(claimRequest);

    const response = await request ({
        method: 'GET', 
        url: `/claims/insuranceCompanies?${qStr}`, 
    });
    return response; 
}

export const getInsuredPerson = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/claims/${id}/insuredPerson`, 
    });
    return response; 
}; 

export const getInvoices = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/claims/${id}/invoices`, 
    });
    return response; 
}; 

export const getLossLocation = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/claims/${id}/lossLocation`, 
    });
    return response; 
}; 

export const getLossCausesDD = async () => {
    return await request({
        method: 'GET', 
        url: `/claims/dd/lossCauses`
    }); 
}

export const getMergedReports = async (claimId: number) => {
    const response = await request ({
        method: 'GET', 
        url: `/claims/${claimId}/mergedReports`, 
    });
    return response; 
}

export const getPolicy = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/claims/${id}/policy`, 
    });
    return response; 
}; 

export const getReserveCoverages = async (id: any) => {    
    const response = await request({
        method: 'GET',
        url: `/claims/${id}/reserves`, 
    });
    return response; 
}; 

export const getClaimStatuses = async (claimRequest: models.ClaimsRequest) => {
    var qStr = utils.helpers.encodeQueryString(claimRequest);

    const response = await request ({
        method: 'GET', 
        url: `/claims/statuses?${qStr}`, 
    });
    return response; 
}

export const getClaimCSV = async (claimsRequest: models.ClaimsRequest) => {
    var qStr = utils.helpers.encodeQueryString(claimsRequest);     
    const response = await request ({
        method: 'GET',
        url: `/claims/export?${qStr}`, 
    });     
    if (response.isSuccess) {
        const responseData = await utils.filehelpers.readAsDataURL(new Blob([response.data], {type: "application/csv"}));
        try {
            utils.filehelpers.downloadFile(response);

        } catch (err) {
            return {
                error: err,
              };
        }     
    return true; 
    }
    else {
        console.log(response); 
    }
}

export const getScheduleSuggetions = async (claimId: number) => {
    const response = await request ({
      method: 'GET',
      url: `/claims/${claimId}/feeScheduleSuggestions`
    }); 
    return response; 
  }; 


export const getStatuses = async () => {
    const response = await request ({
        method: 'GET',
        url: `/claims/Statuses`
      }); 
      return response;    
}