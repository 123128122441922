import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'; 
import { claimsActions } from 'store/claims';
import { userActions } from 'store/user'; 
import { USER_ACTIONS } from 'store/enums';
import { AppToastTypes } from 'models/enums';
import _ from 'lodash'; 

interface Props extends PropsFromRedux {
    closeModal: () => void;
    showModal: boolean;
    invoiceId: number;      
}

interface State {
    reason: string; 
}

class DeleteInvoiceModal extends React.Component<Props, State> {
    public state = {
        reason: '' 
    };

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if(!_.isEqual(prevProps.lastUserAction, this.props.lastUserAction) && this.props.lastUserAction === USER_ACTIONS.INVOICE_DELETE_SUCCESS){
            this.resetFormStateAndClose(); 
        }
    }

    public resetFormStateAndClose = () => {
        this.setState({reason: ''});
        this.props.closeModal(); 
    }

    public submitForm = (e: any) => {
        e.preventDefault();         
        if (this.state.reason == '' ){
            this.props.setAppToast({message: "Reason cannot be blank", type: AppToastTypes.Failure });
        }
        else {
            this.props.deleteClaimInvoice(this.props.claimId, this.props.invoiceId, this.state.reason);  
        }
    }

    public render() {
        const {closeModal, showModal } = this.props;

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Reason for Deleting</Modal.Title>
                </Modal.Header>                
                <Form className="claim-modal" onSubmit={this.submitForm} >                        
                    <Modal.Body> 
                        <Form.Group as={Row} controlId="reason" className="input-field-container">
                            <Form.Label column sm="4">Reason</Form.Label>
                            <Col sm="8">
                                <Form.Control as="textarea" onChange={(e) => this.setState({ reason: e.target.value })} />
                            </Col>
                        </Form.Group> 
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                        CANCEL
                    </Button>
                    <Button variant="primary" type="submit">
                        SAVE
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
const mapStateToProps = (state: MyTypes.RootState) => ({    
    claimId: state.claims.claim.claim.claimID,
    lastUserAction: state.user.actions.lastUserAction, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deleteClaimInvoice: claimsActions.deleteClaimInvoice, 
            setAppToast: userActions.setAppToast, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DeleteInvoiceModal);