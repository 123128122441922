import * as models from '../';

export interface ContactsRequest extends models.RequestBase {
    type: string;
    companyName: string;  
}

export const defaultContactsRequest: ContactsRequest = {
    pageNo: 1, 
    pageSize: 20, 
    type: '',
    companyName: ''    
}