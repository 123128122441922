import * as React from 'react';
import { Table } from 'react-bootstrap'; 
import GearButton from 'components/common/GearButton';
import * as models from '../../models';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import services from 'services';
import utils from '../../utils';

interface Props {
    buttonActions?: (i: models.InvoicesList) => models.ButtonAction[]; 
    invoices: models.InvoicesList[]; 
}

interface State {
}

class InvoiceSummaryTable extends React.Component<Props, State> {
    public state = {
    };

    public downloadFile = async (id: number) => {
        const response = await services.api.financials.getInvoiceAttachment(id);         
        if(response) {            
            utils.filehelpers.openFileNewTab(response.data); 
        }
    }

    public render() {
        const { buttonActions, invoices } = this.props;

        return (
            <>
            {invoices[0] &&             
            <Table striped>
                <thead>
                    <tr>
                        <th>Invoice #</th>
                        <th>Supplemental</th>
                        <th>Gross Loss</th>
                        <th>Total</th>
                        <th>Invoice Date</th>
                        <th>Invoice PDF</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.sort(
                        (a, b) => (a.invoiceNumber > b.invoiceNumber) ? 1 : ((b.invoiceNumber > a.invoiceNumber) ? -1 : 0)
                    ).map((invoice, i) => (
                        <tr key={i}>
                            <td>{invoice.invoiceNumber}</td>
                            <td>{invoice.invoiceId !== 0 && invoice?.isSupp ? "Yes" : "No"}</td>
                            <td><NumberFormat value={invoice.grossLoss.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                            <td><NumberFormat value={invoice.grandTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
                            <td>{dateFormat(invoice.invoiceDate, 'mm/dd/yyyy')}</td>
                            <td 
                                style={{cursor: (invoice.invoicePDF ? 'pointer' : 'auto')}}
                                className="value-link"                                 
                                onClick={() => this.downloadFile(invoice.invoiceId)}>{invoice.invoicePDF}
                            </td>
                            {buttonActions && 
                                <td style={{overflow:'visible'}}>
                                    <GearButton
                                        actions={buttonActions(invoice) || new Array<models.ButtonAction>()}
                                        rowId={invoice?.invoiceId.toString()}
                                    />
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>
            }
            </>
        );
    }
}
export default InvoiceSummaryTable;
