import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';
import services from '../../services'; 
import Chips from 'react-chips'; 

interface Props extends PropsFromRedux {
    closeModal: () => void; 
    showModal: boolean;     
}

interface MailMessage {
    to: string[],
    cc: string[],
    from: string,
    subject: string,
    body: string; 
}

interface State {
    message: models.EmailRequest,  
}

class Mail extends React.Component<Props, State> {
    public state = {
        message: {} as models.EmailRequest,
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if(!_.isEqual(prevProps.showModal, this.props.showModal) && this.props.showModal) {
            this.setState({message: {...this.state.message, subject: 'Claim # ' + this.props.claim.insClaimNumber + ' - ' + this.props.claim.insuredPerson}}); 
        }
    }

    public submitForm = async (e:any) => {                
        e.preventDefault();         
        const response = await services.api.notifications.sendEmail(this.state.message); 
        this.resetFormStateAndClose(); 
    }

    public resetFormStateAndClose = () => {
        this.setState({message: {} as models.EmailRequest}); 
        this.props.closeModal(); 
    }

    public addClaimParty = (e: any) => {
        let emailsToAdd: string[] = new Array<string>; 
        const emailToAdd = this.props.claimParty.resourceList.find(x => x.claimParty == e.target.value)!.email;
        emailsToAdd = emailsToAdd.concat(emailToAdd);
        this.setState({message: {...this.state.message, toAddresses: this.state.message.toAddresses.concat(emailsToAdd)}}); 
    }

    onToChange = (chips: string[]) => {
        this.setState({message: {...this.state.message, toAddresses: chips}}); 
    }

    onCCChange = (chips: string[]) => {
        this.setState({message: {...this.state.message, ccAddresses: chips}}); 
    }

    public render() {
        const { claimParty, showModal } = this.props; 
        const { message } = this.state;         

        return (            
            <Modal size="lg" centered={true} show={showModal} onHide={this.resetFormStateAndClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Email</Modal.Title>
                </Modal.Header>
                <Form 
                    className="claim-modal" 
                    onSubmit={this.submitForm}
                >
                    <Modal.Body>                                                       
                        <Form.Group as={Row} controlId="claimParty" className="input-field-container2">
                            <Col sm={{offset: 1, span:5}}>
                                <Form.Select name="claimParty"              
                                    value={-1}                         
                                    onChange = {(e) => this.addClaimParty(e)}
                                    >
                                        {<option key={-1}>Add From Claim...</option>}
                                        {claimParty.totalCount > 0 && claimParty.resourceList.map((l: models.ClaimParty, i: number) => ( 
                                            <option key={i} value={l.claimParty} >{l.claimParty}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>                                        
                        <Row>                            
                            <Form.Group as={Col} controlId="to" className="input-field-container2">
                                <Col sm={{span: 1}}>
                                    <Form.Label>To:</Form.Label>
                                </Col>                                
                                <Col sm={{span: 11}}> 
                                    <Chips
                                        value={this.state.message.toAddresses}
                                        onChange={this.onToChange}                                
                                    />
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="cc" className="input-field-container2">
                                <Col sm={{span: 1}}>
                                    <Form.Label>cc:</Form.Label>
                                </Col>
                                <Col sm={{span: 11}}> 
                                    <Chips
                                        value={this.state.message.ccAddresses}
                                        onChange={this.onCCChange}                                
                                    />
                                </Col>
                            </Form.Group>
                        </Row>                       
                        <Row>
                            <Form.Group as={Col} controlId="subject" className="input-field-container2">
                                <Col sm={{span: 1}}>
                                    <Form.Label>Subject:</Form.Label>
                                </Col>
                                <Col sm={{span: 11}}> 
                                    <InputGroup>                                        
                                        <Form.Control                                             
                                            type="string"                                             
                                            value={message.subject || ''} 
                                            onChange={(e) => this.setState({message: {...message, subject: e.target.value}})} 
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="messageBody" className="input-field-container2">
                                <Col sm={{span: 12}}> 
                                    <InputGroup>                                        
                                        <Form.Control                                             
                                            className='mail-msg-body'
                                            as="textarea"
                                            value={message.body || ''} 
                                            onChange={(e) => this.setState({message: {...message, body:e.target.value}})} 
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Row>
                    </Modal.Body>                
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SEND
                        </Button>
                    </Modal.Footer>
                </Form>    
            </Modal>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({    
    claim: state.claims.claim.claim,
    claimParty: state.claims.claim.claimParty,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {   
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Mail);