export interface InvoiceFee {
    invoiceFeeId: number; 
    feeName: string;
    quantity: number; 
    rate: number; 
    total: number; 
    isTaxable: boolean; 
    comment: string;     
    adjusterPercent: number; 
    adjusters?: {id: number, share: number}[]; 
    payments?: {id: number, amount: number}[]; 
    paymentAmt: number; 
}

export const defaultInvoiceFee: InvoiceFee= { 
    invoiceFeeId: 0, 
    feeName: "", 
    quantity: 0, 
    rate: 0, 
    total: 0, 
    isTaxable: true,    
    comment: "", 
    adjusterPercent: 0, 
    paymentAmt: 0, 
}