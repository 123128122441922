import * as models from '../';

export interface Contact {
    contactId: number;
    isActive: boolean;  
    firstName: string;
    lastName: string;
    middleName: string; 
    preferredName: string;
    fullName: string;
    email: string; 
    compassEmail: string; 
    phoneNumbers: models.PhoneNumber[]; 
    xnAddress: string;
    xnAddress2: string;    
    companyName: string; 
    title: string; 
    contactTypes: string[]; 
    hasComtrackAccess: boolean;
    role: string; 
    mailingAddress: models.Address;    
    adjusterPercents?: models.ConnectAdjusterFees; 
}

export interface ContactsResponse extends models.ResponseBase<Contact> { }

export const defaultContact: Contact = {
    contactId: 0,
    isActive: false, 
    firstName: '',
    lastName: '',
    middleName: '',
    preferredName: '',
    fullName: '',
    email: '',
    compassEmail: '', 
    phoneNumbers: [models.defaultPhoneNumber],
    xnAddress: '',
    xnAddress2: '', 
    companyName: '',
    title: '',
    contactTypes: [],
    hasComtrackAccess: false,
    role: '',
    mailingAddress: models.defaultAddress
}