import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, Row, Col, Spinner } from 'react-bootstrap'; 
import editIcon from '../../assets/images/Blue/edit.svg';
import SingleActionButton from '../common/SingleActionButton';
import LossInformationModal from './LossInformationModal'; 
import { userHasPermission } from '../../utils/helpers';

interface Props extends PropsFromRedux {
}

interface State {
    showModal: boolean; 
}

class LossInformation extends React.Component<Props, State> {
    public state = {
        showModal: false, 
    };

    public toggleModal = () => {
        this.setState({showModal: !this.state.showModal}); 
    }

    public render() {
        const { showModal } = this.state;   
        const { claim, isClaimLoading, isLossLocationLoading, lossLocation }  = this.props; 
        const canEditClaim = userHasPermission("Can Edit Claims Info") && claim.status.toLowerCase() !== 'closed'; 
        const isLoading = isClaimLoading || isLossLocationLoading; 

        return (
            <>
            <LossInformationModal
                    showModal={showModal}
                    closeModal={this.toggleModal}
                /> 
            <Card className="claim-card">
                <Card.Title>Loss Information
                    {isLoading && <span>&nbsp;&nbsp;<Spinner animation="border" role="status" variant="primary" size="sm"/></span>}
                    {canEditClaim && 
                        <SingleActionButton
                            action="Edit"
                            title="Loss Info"
                            img={editIcon}
                            onClick={this.toggleModal}
                        />
                        }
                </Card.Title>
                <Row>
                    <Col md={3} lg={3} className="card-label">Cause:</Col>
                    <Col md={3} lg={3} >{claim.lossCause}</Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Location:</Col>
                    <Col md={3} lg={3} >{lossLocation.addressLine1}
                            <br/>{lossLocation.addressLine2}
                            <br/>{lossLocation?.city}
                            <br/>{lossLocation?.state}
                            <br/>{lossLocation?.postalCode}
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className="card-label">Loss Description:</Col>
                    <Col md={3} lg={3} >{claim.lossDescription}</Col>
                </Row>
            </Card>   
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claim: state.claims.claim.claim,    
    lossLocation: state.claims.claim.lossLocation,
    isClaimLoading: state.claims.claim.isClaimLoading,
    isLossLocationLoading: state.claims.claim.isLossLocationLoading, 
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(LossInformation);