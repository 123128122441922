import internal from 'stream';
import * as models from '..'; 

export interface InvoicesRequest extends models.RequestBase {
    insClaimNo: string; 
    insCompany: string; 
    invoiceGroup: string; 
    adjuster: string; 
    invoiceDateFrom: string;
    invoiceDateTo: string;
    isUnpaid: boolean; 
    hasPDF?: boolean; 
    age?: number; 
    checkNo: string; 
    sort: string;
}

export const defaultInvoicesRequest: InvoicesRequest = {
    pageNo: 1, 
    pageSize: 20, 
    insClaimNo: '', 
    insCompany: '', 
    invoiceGroup: '',
    adjuster: '', 
    invoiceDateFrom: '',
    invoiceDateTo: '',
    isUnpaid: true,    
    hasPDF: true, 
    checkNo: '', 
    sort: '-invoiceDate',
}