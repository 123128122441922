import * as React from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap'; 
import _ from 'lodash'; 
import * as models from '../../models';

interface Props {
    callback: (fee: models.Fee) => void;
    fee?: models.Fee;  
    showModal: boolean;
    closeModal: () => void;
}

interface State {
    action: string; 
    fee: models.Fee; 
}

class FeeModal extends React.Component<Props, State> {
    public state = {
        action: "Add", 
        fee: {...models.defaultFee}
    };

    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.fee, this.props.fee) && this.props.fee !== undefined) {
            this.setState({fee: this.props.fee});
        }
    }

    public resetFormStateAndClose = () => {
        this.setState({fee: {...models.defaultFee}}); 
        this.props.closeModal(); 
    }

    public submitForm = (e:any) => {
        e.preventDefault();
        this.props.callback(this.state.fee); 
        this.resetFormStateAndClose(); 
    }

    public render() {
        const { closeModal, showModal } = this.props;         
        const { action, fee } = this.state;      

        return (
            <Modal centered={true} show={showModal} onHide={closeModal} >
                <Modal.Header closeButton onHide={this.resetFormStateAndClose}>
                    <Modal.Title>{action} Fee</Modal.Title>
                </Modal.Header>
                <Form className="claim-modal" onSubmit={(e) => this.submitForm(e)}>
                    <Modal.Body>           
                        <Form.Group as={Row} controlId="feeName" className="input-field-container">
                            <Form.Label column sm="4" className="card-label">Name</Form.Label>
                            <Col sm="8">
                                <Form.Control 
                                    type="text" 
                                    value={fee.feeName}
                                    placeholder='Enter Fee Name...'                                     
                                    onChange={(e) => this.setState({fee: {...fee, feeName: e.target.value}})} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="defaultValue" className="input-field-container">
                            <Form.Label column sm="4" className="card-label">Default Value</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control 
                                        type="number" 
                                        value={fee.defaultValue || ''}
                                        placeholder='Enter Value...' 
                                        onChange={(e) => this.setState({fee: {...fee, defaultValue: Number(e.target.value)}})} />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group className="fee-types-chk-grp" >
                            <Form.Label column sm="4" className="card-label">Type</Form.Label>
                            <Col sm="8" className="fee-types-cks">
                                <Form.Check 
                                    inline={true}
                                    name="Add On"
                                    type="radio"
                                    label="Add On"
                                    id="chkAddOnFee"
                                    checked={fee.feeType == "Add On" || false}
                                    onChange={(e) => this.setState({fee: {...fee, feeType: e.target.name}})}
                                />
                                <Form.Check 
                                    inline={true}
                                    name="Default"
                                    type="radio"
                                    label="Default"
                                    id="chkDefaultFee"
                                    checked={fee.feeType == "Default" || false}
                                    onChange={(e) => this.setState({fee: {...fee, feeType: e.target.name}})}
                                />
                                <Form.Check 
                                    inline={true}
                                    name="T&E"
                                    type="radio"
                                    label="T & E"
                                    id="chkTEFee"
                                    checked={fee.feeType == "T&E" || false}
                                    onChange={(e) => this.setState({fee: {...fee, feeType: e.target.name}})}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="feeActive" className="input-field-container">
                            <Form.Label column sm="4" className="card-label">Active</Form.Label>
                            <Col sm="8">
                                <Form.Switch                                
                                    id="tglStatus"
                                    className="status-toggle"
                                    checked={fee.isActive}
                                    label={"(" + (fee.isActive === true ? "Active" : "Disabled") + ")"}
                                    onChange={(e) => this.setState({fee: {...fee, isActive: e.target.checked}})}
                                />
                            </Col>
                        </Form.Group>                        
                        <Form.Group as={Row} controlId="taxExempt" className="input-field-container">
                            <Form.Label column sm="4" className="card-label">Tax Exempt</Form.Label>
                            <Col sm="8">
                                <Form.Switch                                
                                    id="tglStatus"
                                    className="status-toggle"
                                    checked={fee.isTaxExempt}
                                    label={"(" + (fee.isTaxExempt === true ? "Exempt" : "Taxable") + ")"}
                                    onChange={(e) => this.setState({fee: {...fee, isTaxExempt: e.target.checked}})}
                                />
                            </Col>
                        </Form.Group>                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.resetFormStateAndClose}>
                            CANCEL
                        </Button>
                        <Button variant="primary" type="submit">
                            SAVE
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>            
        );
    }
}

export default FeeModal;