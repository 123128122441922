import * as models from 'models'; 

export interface ResponseBase<T> {
    totalCount: number; 
    resourceList: Array<T>;
    error: models.ResponseError;     
}

export interface RequestBase extends models.Paging { }

export const defaultResponse = {
    totalCount: 0, 
    resourceList: [], 
    error: {code: 0, message: ''}
}

export interface DashboardBase<T> {
    dataDate: Date; 
    totalCount: number; 
    dataSet?: Array<T>; 
}

export const defaultDashbardResponse = {
    dataDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 
    totalCount: 0,
}