import * as React from 'react';
import MyTypes from 'MyTypes';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Card } from 'react-bootstrap'; 
import addIcon from '../../assets/images/Blue/add.svg'; 
import SingleActionButton from '../common/SingleActionButton';
import FeeModal from './FeeModal'; 
import NumberFormat from 'react-number-format';
import GearButton from 'components/common/GearButton';
import * as models from '../../models';
import { financialsActions } from 'store/financials';
import { createPatch } from 'rfc6902';

interface Props extends PropsFromRedux {
    feesResponse: models.FeeResponse; 
}

interface State {
    showModal: boolean; 
    editFee: models.Fee | undefined;  
}

class Fees extends React.Component<Props, State> {
    public state = {
        showModal: false, 
        editFee: undefined, 
    }

    public toggleModal = () => {        
        this.setState({showModal: !this.state.showModal}); 
    } 

    public addFee = () => {
        this.setState({editFee: undefined}); 
        this.toggleModal(); 
    }

    public editFee = (rowId: string) => {
        const item: models.Fee | undefined = this.props.feesResponse.resourceList.find(x => x.feeId === parseInt(rowId)); 
        if (item)
            this.setState({editFee: item}); 
        this.toggleModal(); 
    }    

    public feeModalCallback = (fee: models.Fee) => {
        const feeId = fee.feeId; 
        if(this.state.editFee != undefined){            
            const patchDocument = createPatch(this.state.editFee, fee);
            this.props.patchFee(feeId, patchDocument);
        }
        else {
            this.props.addFee(fee);
        }
    }

    public render() {
        const { editFee, showModal } = this.state; 
        const { feesResponse } = this.props;  
        
        const buttonActions: models.ButtonAction[] = [
            {
                name: 'Edit', 
                callBack: this.editFee
            }, 
            {
                name: 'Delete', 
                callBack: this.props.deleteFee
            }, 
        ]

        return (
            <>
            <FeeModal
                callback={this.feeModalCallback}
                fee={editFee}
                showModal={showModal}
                closeModal={this.toggleModal}
            /> 
            <Card className="claim-card">
                <Card.Title>Fees
                    <SingleActionButton
                        action="Add"
                        title="Fee"
                        onClick={this.addFee}
                        img={addIcon}
                    />
                </Card.Title>
                <Table striped>
                    <thead>    
                        <tr>
                            <th>Fee Name</th>
                            <th>Fee Type</th>
                            <th>Active</th>
                            <th>Tax Exempt</th>
                            <th>Default Value</th>
                        </tr>
                    </thead>
                    <tbody>
                    {feesResponse.resourceList.map((fee: models.Fee, i: number) => (
                        <tr key={i}>
                        <td>{fee.feeName}</td>
                        <td>{fee.feeType}</td>
                        <td>{fee.isActive === true ? "Yes" : "No"}</td>
                        <td>{fee.isTaxExempt === true ? "Yes" : "No"}</td>
                        <td><NumberFormat value={fee.defaultValue > 0 ? fee.defaultValue.toFixed(2) : ""} prefix={'$'} thousandSeparator={true} displayType={'text'}  /></td>                        
                        <td style={{overflow:'visible'}}>
                            <GearButton
                                actions={buttonActions}
                                rowId={fee.feeId.toString()}
                            />
                        </td>
                    </tr>
                    ))}  
                    </tbody>                        
                </Table>            
            </Card>
            </>
        );
    }
}

const mapStateToProps = (state: MyTypes.RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addFee: financialsActions.addFee,             
            deleteFee: financialsActions.deleteFee, 
            patchFee: financialsActions.patchFee,
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Fees);