import * as React from 'react';

import '../../assets/styles/LoginCallback.css';


interface Props {
}
interface State {
    userMessage: string;
}

class Logout extends React.PureComponent<Props, State> {

    public state = {
        userMessage: 'please wait...',
    };
    
    public async componentDidMount() {
        await new Promise(r => setTimeout(r, 300));
        localStorage.setItem('token', '');
        localStorage.setItem('userInfo', ''); 
        this.setState({userMessage: 'redirecting...'})
        await new Promise(r => setTimeout(r, 300));
        window.location = "/" as (string | Location) & Location;        
    }

    public render() {
        return (
            <React.Fragment>
                <div>
                    <span>
                        <h2>logging out...{ this.state.userMessage }</h2>
                    </span>
                </div>
            </React.Fragment>
            
        );
    }
}

export default Logout;