import * as React from 'react';
import { Container, Nav, Spinner } from 'react-bootstrap';
import QuickSearch from 'components/common/QuickSearch2';
import _ from 'lodash'; 

import '../../assets/styles/Vertical.css'; 

interface Props {
    isLoading: boolean; 
    tabs: string[];
    activeTab: string;     
    selectItem: (name:string) => void;     
}

interface State {
    activeTab: string | null;
    isSearchRunning: boolean; 
    items: string[]; 
    searchString: string;
}

class VerticalManu extends React.Component<Props, State> {
    public state = {
        activeTab: null,
        isSearchRunning: false, 
        items: [''], 
        searchString: '', 
    }

    componentDidMount(): void {
        this.setState({items: this.props.tabs}); 
    }

    componentDidUpdate(prevProps: any) {
        if (!_.isEqual(prevProps.tabs, this.props.tabs) ) {
            this.setState({items: this.props.tabs}); 
        }
    }

    public onSelect = (tab: string) => {        
        this.props.selectItem(tab); 
    }

    public handleSearchList = (searchString: string) => {
        this.setState({searchString: searchString}, () => this.filterItems());         
    }

    public filterItems = () => {
        const filter = this.state.searchString; 
        if (filter !== '') {            
            const newItems = this.props.tabs.filter( x => x.toLowerCase().includes(filter.toLowerCase()) ); 
            this.setState({items: newItems}); 
        }
        else {
            this.setState({items: this.props.tabs}); 
        }
    }

    public render() {
        const { activeTab, isLoading } = this.props; 
        const { isSearchRunning, items, searchString } = this.state; 
        return (
            <Container className='vertical-nav-secondary'>
                {isLoading &&
                    <div>
                    <Spinner animation="border" role="status" variant="primary" size="sm" style={{alignContent:'center'}}/>
                    <span>Loading...</span>
                    </div>
                }
                <Nav className='vertical-nav'>
                    {!isLoading && 
                        <QuickSearch 
                            search={this.handleSearchList}
                            value={searchString}
                            searchLoading={isSearchRunning}
                        />
                    }                    
                    {items.map((tab: string, i: number) => {
                    return (
                        <Nav.Link
                            key={i}
                            className='vertical-nav-item'
                            active={activeTab === tab}
                            eventKey={tab}
                            onClick={() => this.onSelect(tab)}
                            >{tab}
                        </Nav.Link>
                    );
                })}
                </Nav>
            </Container>
        );
    }
}

export default VerticalManu;
