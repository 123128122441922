import * as React from 'react'; 
import { Card, Form, Row, Col } from 'react-bootstrap'; 
import * as models from '../../models'; 
import NumberFormat from 'react-number-format'; 

interface Props {
    enableEdit?: boolean; 
    phoneNumbers?: models.PhoneNumber[]; 
    validTypes: string[];
    handleChange: (event: any, type: string) => void; 
}

interface State {
}

class PhoneNumberFormCard extends React.Component<Props, State> {
    public state = {     
        phone: '',    
    }

    public render() {
        const { phoneNumbers, validTypes } = this.props;

        return (
            <Card className="form-card">
                <Card.Title>Phone Numbers</Card.Title>
                <Card.Body>
                        {phoneNumbers && validTypes.sort().map((t:string, i:number) => (
                            <Form.Group key={t} as={Row} className="input-field-container" >
                                <Form.Label column sm="4">{t}</Form.Label>
                                <Col>
                                    <NumberFormat 
                                        disabled={!this.props.enableEdit}
                                        format="(###) ###-####" 
                                        placeholder="(xxx) xxx-xxxx"
                                        displayType='input'
                                        className="number-format-input"
                                        value={phoneNumbers?.find(p => p.phoneNumberType == t)?.phoneNumber} 
                                        onValueChange={(e)=>this.props.handleChange(e, t)}
                                    />
                                </Col>
                            </Form.Group>
                        ))}                        
                </Card.Body>
            </Card>
        ); 
    }
}

export default(PhoneNumberFormCard); 