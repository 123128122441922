import MyTypes from 'MyTypes';
import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { Accordion, Card, Form, Button, Row, Col, CardGroup } from 'react-bootstrap';
import { claimsActions } from '../../store/claims'; 
import { companyActions } from 'store/companies';
import { contactsActions } from '../../store/contacts/';
import addIcon from '../../assets/images/Blue/add.svg';
import SingleActionButton from '../common/SingleActionButton';
import _ from 'lodash'; 
import * as models from '../../models/'
import ClaimList from './claimList'; 
import Pager from 'components/common/Pager';
import utils from '../../utils'; 
import ClaimCreateModal from 'components/claim-details/ClaimCreateModal';
import { userActions } from 'store/user';
import { locationActions } from 'store/locations';
interface Props extends PropsFromRedux {
}

interface State {
    claimsRequest: models.ClaimsRequest; 
    locationDDRequest: models.LocationsDDRequest; 
    showCreateClaimModal: boolean; 
    timer: ReturnType<typeof setTimeout>, 
}

interface Params {
}

class ClaimsList extends React.PureComponent<RouteComponentProps<Params> & Props, State> {
    public state = {    
        claimsRequest: {
            ...this.props.claimsRequest,
            status: "Open,Reopened"
        }, 
        locationDDRequest: {} as models.LocationsDDRequest,
        showCreateClaimModal: false,
        timer: setTimeout(function(){}, 1000),
    }

    public componentDidMount() {
        if(this.props.claimsListResponse.totalCount === 0) {
            this.fetchFormData(this.state.claimsRequest);
        }
        // Preload Dropdows for Claim related screens
        this.props.requestLocationDDs(this.state.locationDDRequest); 
        this.props.requestAgentCompanyDD(); 
        this.props.requestAdjustersDD(); 
        this.props.requestExaminersDD(); 
        this.props.requestFieldDirectorsDD(); 
        this.props.requestInsCompanyDD();
        this.props.requestMortgageCompanyDD(); 
        this.props.requestReviewersDD();
        this.props.requestStatuses(); 
    }

    componentDidUpdate(prevProps: any, prevState: any) { 
        if(!_.isEqual(prevProps.claimsListResponse, this.props.claimsListResponse)) {
            //if cascaded parameters only have one value, set form to value
            let newClaimRequest: models.ClaimsRequest = {
                ...this.state.claimsRequest
            }
            if(this.props.claimsListResponse.fieldAdjusters.length == 1){
                newClaimRequest = {
                    ...newClaimRequest,
                    fieldAdjuster: this.props.claimsListResponse.fieldAdjusters[0].name
                }
            }
            if(this.props.claimsListResponse.examiners.length == 1){
                newClaimRequest = {
                    ...newClaimRequest,
                    claimExaminer: this.props.claimsListResponse.examiners[0].name
                }
            }
            if(this.props.claimsListResponse.insuranceCompanies.length == 1){
                newClaimRequest = {
                    ...newClaimRequest,
                    insCompany: this.props.claimsListResponse.insuranceCompanies[0].name
                }
            }
            this.setState({claimsRequest: newClaimRequest}); 
        }
    }

    public pagerCallback = (paging: models.Paging) => {   
        const claimsRequest: models.ClaimsRequest = {
            ...this.state.claimsRequest,
            ...paging,
        }
        this.props.requestClaims(claimsRequest); 
    }; 

    private handleSearchSubmit = async (e: any) => {
        const { claimsRequest } = this.state; 
        this.props.history.push(this.props.location.pathname + "?" + utils.helpers.encodeQueryString(claimsRequest));
        this.fetchFormData(claimsRequest); 
        e.preventDefault(); 
    };

    public applyFilters = (e:any, isPaste: boolean) => {      
        const { name, value } = e.target;
        const { locationDDs } = this.props; 
        let claimsRequest: models.ClaimsRequest;
        let isAdvancedSearch: boolean = false;  
        let locationsDDRequest: models.LocationsDDRequest = {}; 

        switch (name) {
            case 'claimExaminer': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    claimExaminer: value === "-1" ? null : value,
                };                
                break;            
            case 'claimStatus': 
                const statusList = value === "" ? null : value === "Open" ? "Open,Reopened" : value; 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    status: statusList,
                };
                break;
            case 'fieldAdjuster': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    fieldAdjuster : value === "-1" ? null : value,
                };
                break;
            case 'closedDateFrom':             
                claimsRequest = {
                    ...this.state.claimsRequest, 
                    closedDateFrom: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'closedDateTo': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    closedDateTo: value === "" ? null : value, 
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'insClaimNo': 
                claimsRequest = {
                    ...models.defaultClaimsRequest,
                    insClaimNo : value === "" ? null : value,
                    claimNoOperator: isPaste ? "=" : models.defaultClaimsRequest.claimNoOperator, 
                };                  
                break; 
            case 'insCompany':
                claimsRequest = {
                    ...this.state.claimsRequest,
                    insCompany: value === "" ? null : (value === "<NONE>" ? "<NULL>" : value),
                };
                break; 
            case 'insuredName':                
                claimsRequest = {
                    ...this.state.claimsRequest,
                    insuredName: value === "" ? null : value,
                };
                isAdvancedSearch = true; 
                break; 
            case 'insPolicyNumber': 
                claimsRequest = {
                    ...this.state.claimsRequest, 
                    policyNumber: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'lossCity': 
                locationsDDRequest = {
                    ...this.state.locationDDRequest,
                    cityId: parseInt(value),
                }                
                this.props.requestLocationDDs(locationsDDRequest); 

                claimsRequest = {
                    ...this.state.claimsRequest, 
                    lossCity: locationDDs.citiesDDResponse.resourceList.find(x => x.id === parseInt(value))?.name || "",
                }; 
                isAdvancedSearch = true; 
                break;
            case 'lossCounty': 
                locationsDDRequest = {
                    ...this.state.locationDDRequest,
                    countyId: parseInt(value),
                }                
                this.props.requestLocationDDs(locationsDDRequest); 

                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossCounty: locationDDs.countiesDDResponse.resourceList.find(x => x.id === parseInt(value))?.name || "",
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'lossState': 
                locationsDDRequest = {
                    ...this.state.locationDDRequest,
                    stateId: parseInt(value),
                }                
                this.props.requestLocationDDs(locationsDDRequest); 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossState: locationDDs.statesDDResponse.resourceList.find(x => x.id === parseInt(value))?.name || "",
                }; 
                isAdvancedSearch = true; 
                break;
            case 'lossZipCode': 
                locationsDDRequest = {
                    ...this.state.locationDDRequest,
                    zipCode: value
                }                
                this.props.requestLocationDDs(locationsDDRequest); 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossZipCode: locationDDs.zipCodesDDResponse.resourceList.find(x => x.id === parseInt(value))?.name || "",
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'lossDateFrom': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossDateFrom: value === "" ? null : value, 
                }; 
                isAdvancedSearch = true; 
                break;
            case 'lossDateTo':
                claimsRequest = {
                    ...this.state.claimsRequest,
                    lossDateTo: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'receivedDateFrom': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    receivedDateFrom: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'receivedDateTo': 
                claimsRequest = {
                    ...this.state.claimsRequest, 
                    receivedDateTo: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'reportedDateFrom': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    reportedDateFrom: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            case 'reportedDateTo': 
                claimsRequest = {
                    ...this.state.claimsRequest,
                    reportedDateTo: value === "" ? null : value,
                }; 
                isAdvancedSearch = true; 
                break; 
            default: 
                claimsRequest = {
                    ...this.state.claimsRequest,
                };
        }
        this.setState({claimsRequest: claimsRequest, locationDDRequest: locationsDDRequest}); 
        if (!isAdvancedSearch && name !== "insClaimNo"){
            const waitTime = (name === "insClaimNo" ? 1000 : 0); 
            this.setState({timer: setTimeout(() => {this.fetchFormData(claimsRequest)}, waitTime)});
            return false; 
        }
    }
    
    public handlePasteClaimNumber = (e: any) => {
        e.preventDefault(); 
        const claimNo = e.clipboardData.getData('text/plain').trimEnd();           
        this.applyFilters({target: {name: 'insClaimNo', value: claimNo}}, true); 
    }
    
    public fetchFormData = (request: models.ClaimsRequest) => {   
        this.props.requestClaims(request);        
    }

    public resetFilters = () => {
        this.setState({
            claimsRequest: {...models.defaultClaimsRequest, status: "Open, Reopened" }, 
            locationDDRequest: {} as models.LocationsDDRequest
        }); 
        this.props.requestLocationDDs({} as models.LocationsDDRequest); 
        this.fetchFormData(models.defaultClaimsRequest);        
    }

    public toggleCreateClaimModal = () => {
        this.setState({showCreateClaimModal: !this.state.showCreateClaimModal}); 
    }    

    public render() {
        const { claimsRequest, locationDDRequest, showCreateClaimModal } = this.state; 
        const { claimsListResponse, locationDDs, isLocationDDsLoading, statuses } = this.props; 
        const showPagination =  claimsListResponse?.totalCount > claimsRequest.pageSize ? true : false; 

        return (
            <>
                <ClaimCreateModal
                    showModal={showCreateClaimModal}
                    closeModal={this.toggleCreateClaimModal}
                /> 
                <Card className="claim-card">
                    <Card.Title>Claim Search
                        <SingleActionButton
                            action="Add"
                            title="Claim"
                            img={addIcon}
                            onClick={this.toggleCreateClaimModal}
                        />
                    </Card.Title>
                    <Form onSubmit={this.handleSearchSubmit}>
                        <Row>                    
                            <Col>
                                <Form.Group>
                                    <Form.Label>Claim Number</Form.Label>
                                    <Form.Control 
                                        name="insClaimNo" 
                                        placeholder='Claim #' 
                                        value={claimsRequest.insClaimNo || ""} 
                                        onChange={(e) => this.applyFilters(e, false)} 
                                        onPaste={(e) => this.handlePasteClaimNumber(e)}
                                        />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Claim Status</Form.Label>                                
                                    <Form.Select name="claimStatus" value={claimsRequest.status?.includes('Open') ? 'Open' : claimsRequest.status} onChange={(e) => this.applyFilters(e, false)}  >
                                        <option key={-1} value=""></option>
                                        {statuses && statuses.resourceList.map((c: models.DropDownListItem, i: number) =>
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance Company</Form.Label>
                                    <Form.Select name="insCompany" value={claimsRequest.insCompany} onChange={(e) => this.applyFilters(e, false)}  >
                                        <option key={-1} value=""></option>
                                        {claimsListResponse.insuranceCompanies && claimsListResponse.insuranceCompanies.map((c: models.DropDownListItem, i: number) => (                                             
                                            <option key={i} value={c.name} >{c.name}</option>
                                        ))}
                                    </Form.Select>                                    
                                </Form.Group>
                            </Col>                            
                            <Col>
                                <Form.Group>
                                    <Form.Label>Examiner</Form.Label>
                                    <Form.Select name="claimExaminer" value={claimsRequest.claimExaminer || ""} onChange={(e) => this.applyFilters(e, false)} >
                                        <option key={-1} value=""></option>
                                        {claimsListResponse.examiners && claimsListResponse.examiners.map((c: models.DropDownListItem, i:number) => 
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Field Adjuster</Form.Label>
                                    <Form.Select name="fieldAdjuster" value={claimsRequest.fieldAdjuster} onChange={(e) => this.applyFilters(e, false)} >
                                        <option key={-1} value=""></option>
                                        {claimsListResponse.fieldAdjusters && claimsListResponse.fieldAdjusters.map((c: models.DropDownListItem, i: number) =>
                                            <option key={i} value={c.name}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        <Accordion className="accordion-card" defaultActiveKey="1">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Advanced...</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Policy #</Form.Label>
                                        <Form.Control name="insPolicyNumber" placeholder='Policy #...' value={claimsRequest.policyNumber} onChange={(e) => this.applyFilters(e, false)}  />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Insured Name</Form.Label>
                                        <Form.Control name="insuredName" placeholder='Insured Name...' value={claimsRequest.insuredName} onChange={(e) => this.applyFilters(e, false)}  />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss State</Form.Label>
                                        <Form.Select                                                 
                                            name="lossState" 
                                            value={locationDDRequest.stateId || -1} 
                                            onChange={(e) => this.applyFilters(e, false)}  >
                                                {isLocationDDsLoading && <option>Loading...</option>}
                                                {!isLocationDDsLoading && <option key={-1}>Loss State...</option>}
                                                {locationDDs.statesDDResponse.totalCount > 0 && locationDDs.statesDDResponse.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                    <option key={i} value={c.id} >{c.name}</option>
                                                ))}
                                        </Form.Select> 
                                    </Form.Group>                                    
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss City</Form.Label>                                        
                                        <Form.Select                                                 
                                            name="lossCity" 
                                            disabled={claimsRequest.lossState == ''}
                                            value={locationDDRequest.cityId || -1} 
                                            onChange={(e) => this.applyFilters(e, false)}  >
                                                {isLocationDDsLoading && <option>Loading...</option>}
                                                {!isLocationDDsLoading && <option key={-1}>Loss City...</option>}
                                                {locationDDRequest.stateId && locationDDs.citiesDDResponse.totalCount > 0 && locationDDs.citiesDDResponse.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                    <option key={i} value={c.id} >{c.name}</option>
                                                ))}
                                        </Form.Select> 
                                    </Form.Group>                                    
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss County</Form.Label>
                                        <Form.Select                                                 
                                            name="lossCounty" 
                                            disabled={claimsRequest.lossState == ''}
                                            value={locationDDRequest.countyId || -1} 
                                            onChange={(e) => this.applyFilters(e, false)}  >
                                                {isLocationDDsLoading && <option>Loading...</option>}
                                                {!isLocationDDsLoading && <option key={-1}>Loss County...</option>}
                                                {locationDDRequest.stateId && locationDDs.countiesDDResponse.totalCount > 0 && locationDDs.countiesDDResponse.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                    <option key={i} value={c.id} >{c.name}</option>
                                                ))}
                                        </Form.Select> 
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Loss Zip Code</Form.Label>
                                        <Form.Select                                                 
                                            name="lossZipCode" 
                                            disabled={claimsRequest.lossState == ''}
                                            value={locationDDRequest.zipCode || ''} 
                                            onChange={(e) => this.applyFilters(e, false)}  >
                                                {isLocationDDsLoading && <option>Loading...</option>}
                                                {!isLocationDDsLoading && <option key={-1}>Loss Zip...</option>}
                                                {locationDDRequest.stateId && locationDDs.zipCodesDDResponse.totalCount > 0 && locationDDs.zipCodesDDResponse.resourceList.map((c: models.DropDownListItem, i: number) => ( 
                                                    <option key={i} value={c.name} >{c.name}</option>
                                                ))}
                                        </Form.Select> 
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <CardGroup>
                                        <Card className="advanced-search-date">                                    
                                            <Card.Body>
                                                <Card.Title>Received Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="receivedDateFrom" 
                                                                value={claimsRequest.receivedDateFrom} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="receivedDateTo" 
                                                                value={claimsRequest.receivedDateTo} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Closed Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="closedDateFrom" 
                                                                value={claimsRequest.closedDateFrom} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="closedDateTo" 
                                                                value={claimsRequest.closedDateTo} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Loss Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="lossDateFrom" 
                                                                value={claimsRequest.lossDateFrom} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="lossDateTo" 
                                                                value={claimsRequest.lossDateTo} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        <Card className="advanced-search-date">
                                            <Card.Body>
                                                <Card.Title>Reported Date</Card.Title>
                                                <Form.Group>
                                                    <Row>
                                                        <Form.Label column xs={4}>From</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="reportedDateFrom" 
                                                                value={claimsRequest.reportedDateFrom} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form.Label column xs={4}>To</Form.Label>
                                                        <Col xs={8}>
                                                            <Form.Control 
                                                                type="date"
                                                                name="reportedDateTo" 
                                                                value={claimsRequest.reportedDateTo} 
                                                                onChange={(e) => this.applyFilters(e, false)} 
                                                                />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>                                       
                                    </CardGroup>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  
                        </Row>
                        <Button className="claim-search-btn" type='submit'>Search</Button>
                        <Button className="claim-search-btn" onClick={this.resetFilters}>Reset Filters</Button>
                    </Form>                                 
                </Card>                
                <ClaimList 
                        title="Search Results"
                        isSortable={true}
                    />                    
                {showPagination &&
                    <div>
                        <Pager
                            className = {"admin-pager"}
                            retrieveDataCallback = {this.pagerCallback}
                            pageSize = {claimsListResponse.resourceList?.length}
                            currentPage = {claimsRequest.pageNo}
                            totalCount = {claimsListResponse.totalCount}
                            pagesInRange = {3}
                        />
                    </div>
                }
            </>
        );
    }    
}

const mapStateToProps = (state: MyTypes.RootState) => ({
    claimsListResponse: state.claims.search.claimsListResponse, 
    isClaimsListLoading: state.claims.search.isClaimsListLoading, 
    claimsRequest: state.claims.search.claimsRequest,
    locationDDs: state.locations.dropdowns.locationDDs,
    isLocationDDsLoading: state.locations.dropdowns.isLocationDDsLoading,
    isClaimsListDownloading: state.claims.search.isClaimsListDownloading,
    statuses: state.claims.claim.statuses, 
});

const mapDispatchToProps = (dispatch: Dispatch) => 
    bindActionCreators(
        {
            requestAdjustersDD: contactsActions.requestAdjustersDD, 
            requestAgentCompanyDD: companyActions.requestAgentCompanyDD, 
            requestClaims: claimsActions.requestClaims,
            requestClaimCSV: claimsActions.requestClaimCSV,
            requestExaminersDD: contactsActions.requestExaminersDD, 
            requestFieldDirectorsDD: contactsActions.requestFieldDirectorsDD, 
            requestInsCompanyDD: companyActions.requestInsCompanyDD, 
            requestLocationDDs: locationActions.requestLocationDDs, 
            requestMortgageCompanyDD: companyActions.requestMortgageCompanyDD, 
            requestReviewersDD: contactsActions.requestReviewersDD, 
            requestStatuses: claimsActions.requestStatuses,
            setAppToast: userActions.setAppToast, 
        },
        dispatch
    );

const connector = connect(mapStateToProps, mapDispatchToProps); 
type PropsFromRedux = ConnectedProps<typeof connector>
    
export default connector(ClaimsList); 