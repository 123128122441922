// Application Stuff - move later...
export const TOAST_CLEAR_MESSAGE = 'app/TOAST_CLEAR_MESSAGE'; 
export const TOAST_SET_MESSGAGE = 'app/TOAST_SET_MESSAGE'; 
export const TOAST_TOGGLE = 'app/TOAST_TOGGLE'; 

// Claim Search 
export const INITIALIZE_CLAIM = 'claims/INITIALIZE_CLAIM'; 
export const REQUEST_CLAIMS = 'claims/REQUEST_CLAIMS';
export const RECEIVE_CLAIMS = 'claims/RECEIVE_CLAIMS'; 

// Claims Export
export const REQUEST_CLAIMS_CSV = 'claims/REQUEST_CLAIMS_CSV'; 
export const RECEIVE_CLAIMS_CSV = 'claims/RECEIVE_CLAIMS_CSV';

// Claims Stuff
export const REQUEST_ADD_CLAIM = 'claims/REQUEST_ADD_CLAIM'; 
export const RECEIVE_ADD_CLAIM = 'claims/RECEIVE_ADD_CLAIM'; 

export const ADD_CLAIM_ATTACHMENT = 'claims/ADD_CLAIM_ATTACHMENT'; 

export const ADD_CLAIM_COMMENT = 'claims/ADD_CLAIM_COMMENT'; 

export const ADD_CLAIM_INVOICE = 'claims/ADD_CLAIM_INVOICE'; 

export const ADD_RESERVE_COVERAGE = 'claims/ADD_RESERVE_COVERAGE'; 

export const CLEAR_CLAIM_SINGLE = 'claims/CLEAR_CLAIM_SINGLE'; 

export const DELETE_CLAIM_ATTACHMENT = 'claims/DELETE_CLAIM_ATTACHMENT'; 
export const DELETE_CLAIM_INVOICE = 'claims/DELETE_CLAIM_INVOICE'; 
export const DELETE_RESERVE_COVERAGE = 'claims/DELETE_RESERVE_COVERAGE'; 

export const PATCH_CLAIM = 'claims/PATCH_CLAIM'; 
export const PATCH_CLAIM_RESERVE_COVERAGE = '/claims/PATCH_CLAIM_RESERVE_COVERAGE'; 
export const PATCH_INSURED_PERSON = 'claims/PATCH_INSURED_PERSON'; 
export const PATCH_LOSS_LOCATION = 'claims/PATCH_LOSS_LOCATION'; 
export const PATCH_POLICY = 'claims/PATCH_POLICY'; 

export const REQUEST_ATTACHMENTS = 'claims/REQUEST_ATTACHMENTS'; 
export const RECEVIE_ATTACHMENTS = 'claims/RECEIVE_ATTACHMENTS'; 

export const REQUEST_CLAIM_PARTY = 'claims/REQUEST_CLAIM_PARTY'; 
export const RECEIVE_CLAIM_PARTY = 'claims/RECEIVE_CLAIM_PARTY'; 

export const REQUEST_CLAIM_SINGLE = 'claims/REQUEST_CLAIM_SINGLE';
export const REQUEST_CLAIM_SINGLE_BY_CLAIM_NUMBER = 'claims/REQUEST_CLAIM_SINGLE_BY_CLAIM_NUMBER';
export const RECEIVE_CLAIM_SINGLE = 'claims/RECEIVE_CLAIM_SINGLE'; 

export const REQUEST_CLAIM_TYPE_LIST = 'claims/REQUEST_CLAIM_TYPE_LIST'; 
export const RECEIVE_CLAIM_TYPE_LIST = 'claims/RECEIVE_CLAIM_TYPE_LIST'; 

export const REQUEST_COMMENTS_ACTIVITY_LOG = 'claims/REQUEST_COMMENTS_ACTIVITY_LOG'; 
export const RECEIVE_COMMENTS_ACTIVITY_LOG = 'claims/RECEIVE_COMMENTS_ACTIVITY_LOG'; 

export const REQUEST_COMMENTS_CORRECTION = 'claims/REQUEST_COMMENTS_CORRECTION'; 
export const RECEIVE_COMMENTS_CORRECTION = 'claims/RECEIVE_COMMENTS_CORRECTION'; 

export const REQUEST_COMMENTS_RESERVE = 'claims/REQUEST_COMMENTS_RESERVE'; 
export const RECEIVE_COMMENTS_RESERVE = 'claims/RECEIVE_COMMENTS_RESERVE'; 

export const REQUEST_EMAILED_CLAIMS = 'claims/REQUEST_EMAILED_CLAIMS'; 
export const RECEIVE_EMAILED_CLAIMS = 'claims/RECEIVE_EMAILED_CLAIMS'; 

export const REQUEST_INVOICES = 'claims/REQUEST_INVOICES'; 
export const RECEIVE_INVOICES = 'claims/RECEIVE_INVOICES'; 

export const REQUEST_INSURED_PERSON = 'claims/REQUEST_INSURED_PERSON'; 
export const RECEIVE_INSURED_PERSON = 'claims/RECEIVE_INSURED_PERSON'; 

export const REQUEST_INVOICE_DRAFT = 'claims/REQUEST_INVOICE_DRAFT'; 
export const RECEIVE_INVOICE_DRAFT = 'claims/RECEIVE_INVOICE_DRAFT'; 

export const REQUEST_LOSS_LOCATION = 'claims/REQUEST_LOSS_LOCATION'; 
export const RECEIVE_LOSS_LOCATION = 'claims/RECEIVE_LOSS_LOCATION'; 

export const REQUEST_MERGED_REPORTS = 'claims/REQUEST_MERGED_REPORTS'; 
export const RECEIVE_MERGED_REPORTS = 'claims/RECEIVE_MERGED_REPORTS'; 

export const REQUEST_POLICY = 'claims/REQUEST_POLICY'; 
export const RECEIVE_POLICY = 'claims/RECEIVE_POLICY'; 

export const REQUEST_RESERVE_COVERAGES = 'claims/REQUEST_RESERVE_COVERAGES'; 
export const RECEIVE_RESERVE_COVERAGES = 'claims/RECEIVE_RESERVE_COVERAGES'; 

export const REQUEST_STATUSES = 'claims/REQUEST_STATUSES'; 
export const RECEIVE_STATUSES = 'claims/RECEIVE_STATUSES'; 

export const SET_CLAIM_ACTION_STATUS = 'claims/SET_CLAIM_ACTION_STATUS'; 

// comment types
export const ACTIVITY_LOG_TYPES = ["Adjuster", "Comment", "Correction", "Reserve"]; 
export const CORRECTION_TYPE = ["Correction"]; 
export const RESERVES_TYPE = ["Reserve"];

// dropdowns
export const REQUEST_LOSS_CAUSES_DD = 'dropdowns/REQUEST_LOSS_CAUSES_DD'; 
export const RECEIVE_LOSS_CAUSES_DD = 'dropdowns/RECEIVE_LOSS_CAUSES_DD'; 
